var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"600"}},[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_vm._v(" Spendenbarometer "+_vm._s(_vm.collection.name)+" ")]),_c('v-card-subtitle',[_vm._v(" Hier können Sie das Spendenbarometer für Ihre Organisation konfigurieren. ")]),_c('v-card-text',{staticClass:"d-flex align-end"},[_c('span',{staticClass:"mr-3 text-center"},[_vm._v(" Was ist das Spendenziel? ")]),_c('CurrencyInput',{staticClass:"my-0 py-0",staticStyle:{"max-width":"130px"},attrs:{"hide-details":"","dense":"","placeholder":"10.000"},model:{value:(_vm.form.donation_goal),callback:function ($$v) {_vm.$set(_vm.form, "donation_goal", $$v)},expression:"form.donation_goal"}})],1),_c('v-card-text',[_vm._v(" Auf welcher Domain möchten Sie das Spendenbarometer einsetzen? Zum Beispiel auf der Website Ihrer Organisation oder eines Projektes: "),_c('v-combobox',{attrs:{"placeholder":"z.B. www.meine-domain.de","multiple":"","rules":[
      value => {
    if (!value?.length) return true
    const domainRegex = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
    return value.every(domain => domainRegex.test(domain)) || 'Please enter valid domain names only'
  }
    ]},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('v-chip',{attrs:{"label":""}},[_c('span',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.form.allowed_origins),callback:function ($$v) {_vm.$set(_vm.form, "allowed_origins", $$v)},expression:"form.allowed_origins"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.confirm_delete_dialog= true}}},[_vm._v(" löschen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.validForm},on:{"click":_vm.save}},[_vm._v(" Speichern ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.confirm_delete_dialog),callback:function ($$v) {_vm.confirm_delete_dialog=$$v},expression:"confirm_delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this donation meter? ")]),_c('v-card-text',[_vm._v("if you delete the donation meter the donation page will not be accessible in future")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":" darken-1","text":""},on:{"click":function($event){_vm.confirm_delete_dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteDonationMeter}},[_vm._v(" löschen ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }