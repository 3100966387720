<template>
  <v-container id="eval-disbursement-orders">
    <v-row>
      <v-col cols="12">
        <h2 class="mt-6">Kollektenweiterleitung  & -meldung</h2>
        <p>Hier finden Sie alle beendeten Weiterleitungskollekten. Bitte schließen Sie diese zeitnah ab.</p>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
       <EvalDisbursementOrdersTable
        v-if="tableList.length > 0"
          :headers="headers"
          :items="tableList"
          @exportDataMethod="exportList"
          @downloadPdf="download"/>
        <p v-if="!hasPermission(currentOrganization.url, DISBURSEMENT_ORDER)">
          Sie haben nicht ausreichend Rechte, um diese Seite anzuzeigen. Wenden Sie sich an Ihren Administrator.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import { downloadBlob } from '@/lib/file-tools'
import EvalDisbursementOrdersTable from "@/components/evaluation/EvalDisbursementOrdersTable"
import {
  FETCH_ORGANIZATION_COLLECTION_PLANS, FETCH_DISBURSEMENT_ORDERS, FETCH_LOCATIONS, FETCH_DATES_GLOBAL
} from '@/store/action-types'
import { shortenParish } from "@/lib/regex-tools"
import { DISBURSEMENT_ORDER } from '@/lib/permission-tools'
export default {
  name: "EvalDisbursementOrders",
  components: { EvalDisbursementOrdersTable },
  data () {
    return {
      DISBURSEMENT_ORDER,
      donationReceiptRequests: [],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters('user', ['hasPermission']),
    ...mapState('location', ['locations']),
    ...mapGetters("organization", ["currentOrganization", "allOrganizations", "organizations"]),
    ...mapGetters("collectionPlan", ["currentCollectionPlans"]),
    ...mapGetters("disbursement", ["disbursementOrders"]),
    ...mapGetters("date", ["globalDatesByUrl"]),

    organizationsListGroupedByParent () {
      return this.groupOrganizationsByParent(this.organizations, ["id", "name", "url"])
    },
    currentOrganizationWithChildren () {
      return this.findOrganizationById(this.organizationsListGroupedByParent, this.currentOrganization.id)
    },
    tableList () {
      if (!this.currentOrganization || !this.currentOrganization.url) {
        return []
      }
      if (!this.currentCollectionPlans || !this.currentCollectionPlans.length) {
        return []
      }
      if (!this.disbursementOrders || !this.disbursementOrders.length) {
        return []
      }
      const disbursementMap = this.disbursementOrders.reduce((acc, disbursement) => {
        acc[disbursement.plan] = disbursement
        return acc
      }, {})
      const combinedList = this.disbursementOrders.filter(disbursement => disbursement.organization === this.currentOrganization.url).map(disbursement => {
        const plan = this.currentCollectionPlans.find(plan => plan.url === disbursement.plan)
        const location = plan.location ? this.locations[plan.location]?.name : this.allOrganizations[plan.organization].name
        return {
          id: plan.id,
          name: plan.name,
          url: plan.url,
          handling_organization: shortenParish(this.allOrganizations[disbursementMap[plan.url]?.handling_organization]?.name),
          organization_name: this.allOrganizations[plan.organization]?.name,
          type: plan.collection_variant,
          start: plan.start,
          end: plan.end,
          location: shortenParish(location),
          date: plan.date,
          day: this.globalDatesByUrl[plan.date]?.name,
          disbursement,
        }
      }
      )

      const sortedList = combinedList.sort((a, b) => moment(a.end).isBefore(b.end) ? 1 : -1)

      return sortedList
    },
    headers () {
      return [
        { text: "Sammlungsname", value: "name" },
        { text: "Typ", value: "type", filterable: false },
        { text: "Geltungsbereich", value: "location", filterable: false },
        { text: "Liturgischer Tag", value: "day" },
        { text: "Startdatum", value: "start" },
        { text: "Enddatum", value: "end" },
        { text: "Spendensumme", value: "disbursement.total_amount", align: 'right' },
        { text: "Baranteil", value: "disbursement.total_cash_amount", align: 'right' },
        { text: "Online-Anteil", value: "disbursement.total_device_amount", align: 'right' },
        { text: "", value: "add_cash_action", sortable: false, width: '20px' },
        { text: "", value: "cancel_action", sortable: false, width: '20px' },
        { text: "", value: "download_pdf_action", sortable: false, width: '20px' },
        { text: "", value: "state", sortable: false, width: '20px' },
      ]
    },
  },
  methods: {
    async download ({ url, date }) {
      const response = await this.$store.getters.restApi.get(`${url}/download/`, { responseType: 'blob' })
      downloadBlob(response.data, `${date}_Auszahlungsanordnung_ID.pdf`, response.headers['content-type'])
    },
    async exportList () {
      const response = await this.$store.getters.restApi.get(`pdata/donation_receipt/xlsx/`, {
        responseType: 'blob',
        params: {
          organization: this.currentOrganization.id
        }
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.currentOrganization.name}_Angefragte Zuwendungsbestätigungen.xlsx`, response.headers['content-type'])
    },
    groupOrganizationsByParent: (organizations, allowedKeys) => {
      if (!organizations.length) {
        return []
      }

      function filterOrganization (org) {
        return Object.keys(org)
          .filter(key => allowedKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = org[key]
            return obj
          }, {})
      }
      const orgMap = {}

      organizations.forEach(org => {
        const filteredOrg = filterOrganization(org)
        filteredOrg.children = []
        orgMap[org.url] = filteredOrg
      })

      const topLevelOrgs = []

      organizations.forEach(org => {
        const filteredOrg = orgMap[org.url]
        if (org.parent && orgMap[org.parent]) {
          orgMap[org.parent].children.push(filteredOrg)
        } else {
          topLevelOrgs.push(filteredOrg)
        }
      })

      return topLevelOrgs
    },
    findOrganizationById (orgs, id) {
      for (const org of orgs) {
        if (org.id === id) {
          return org
        }
        if (org.children && org.children.length > 0) {
          const found = this.findOrganizationById(org.children, id)
          if (found) {
            return found
          }
        }
      }
      return null
    },
  },
  async mounted () {
    await Promise.allSettled([
      await this.$store.dispatch('date/' + FETCH_DATES_GLOBAL),
      await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.currentOrganization.url),
      await this.$store.dispatch('disbursement/' + FETCH_DISBURSEMENT_ORDERS),
      await this.$store.dispatch('location/' + FETCH_LOCATIONS),

    ])
  },
}
</script>
<style lang="stylus" scoped>

.v-data-table ::v-deep th
  font-size: 0.9em !important/*
.v-data-table ::v-deep td
  font-size: 0.9em !important*/
.archived
  color: #BDBDBD
</style>
