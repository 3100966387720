<template>
  <mqtt-connection
    :devices="devices"
    :extendedDevicesMqttIds="extendedDevicesMqttIds"
    :showWifiDeviceMqttId="showWifiDeviceMqttId"
    v-slot="{ mqttState, mqttVpnState, mqttConnected, wifiList }"
    @disconnected="showWifiDeviceMqttId=false"
  >
    <v-container>
      <template v-if="!devices || !devices.length || !locations.length">
        <v-layout justify-center>
          <v-progress-circular color="primary" indeterminate :size="100"></v-progress-circular>
        </v-layout>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="filterString" label="Suche" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select v-model="filterType" label="Gerätetyp" outlined item-text="name" item-value="id"
                      :items="deviceClassFilterItems"></v-select>
          </v-col>
        </v-row>
        <pulse-loader :size="10" color="var(--clr-environment, #554193)" id="mqtt-loading"
                      :loading="!mqttConnected"></pulse-loader>
        <div v-if="devices && devices.length" id="content">
          <v-row>
            <Device
              v-for="device in activeDevices"
              :key="device.url"
              :is-active="true"
              :device="device"
              :mqtt-state="mqttState[device.mqtt_identity]"
              :mqtt-vpn-state="mqttVpnState[device.bcmserno]"
              :show-widgets="false"
              :current-organization="null"
              @show-wifi-list="showWifiDeviceMqttId=device.mqtt_identity"
              @expand="extendedDevicesMqttIds=new Set([...Array.from(extendedDevicesMqttIds || []), device.mqtt_identity])"
              @unexpand="extendedDevicesMqttIds.delete(device.mqtt_identity)"
            >
            </Device>
          </v-row>
          <device-wifi-list :wifi-list="wifiList" :is-open="!!showWifiDeviceMqttId"
                            @close="showWifiDeviceMqttId = null"></device-wifi-list>
        </div>
        <div v-else-if="devices && devices.length === 0">
          <p>
            Aktuell sind keine aktiven Spendengeräte vorhanden.
            Bitte kontaktieren Sie uns, wenn Sie Fragen oder Probleme haben!
          </p>
          <p><a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a></p>
        </div>
      </template>
    </v-container>
  </mqtt-connection>
</template>

<script>
import { PulseLoader } from '@saeris/vue-spinners'
import Device from '@/components/device/Device'
import MqttConnection from '@/components/device/MqttConnection'
import { mapGetters, mapState } from 'vuex'
import { FETCH_GLOBAL_DEVICES, FETCH_LOCATIONS, FETCH_USER_DATA } from '@/store/action-types'
import DeviceWifiList from '@/components/device/DeviceWifiList'
import { DEVICE_CLASSES } from '@/lib/device-db'

export default {
  name: 'device-admin',
  components: {
    Device,
    PulseLoader,
    MqttConnection,
    DeviceWifiList
  },
  data () {
    return {
      extendedDevicesMqttIds: new Set(),
      showWifiDeviceMqttId: null,
      filterString: null,
      filterType: null,
    }
  },
  filters: {
    deviceLocation: function (device) {
      if (device.current_location__name) {
        return '(' + (device.current_location__organization__name ? (device.current_location__organization__name + ' / ') : '') +
          device.current_location__name + ')'
      }
    }
  },
  computed: {
    ...mapState(['now']),
    ...mapGetters('organization', { organizations: 'allOrganizations' }),
    ...mapState('location', ['locations']),
    ...mapGetters('location', ['locationsLookup']),
    ...mapState('device', ['devices']),
    activeDevices () {
      if (!this.devices) return []
      const search = this.filterString?.toLowerCase() || null
      return this.devices.filter((device) => {
        if (!device) return false
        if (this.filterType && this.filterType !== device.device_class) return false
        if (search) {
          if (device.name.toLowerCase().indexOf(search) !== -1) return true
          if (device.id.toLowerCase().indexOf(search) !== -1) return true
          if (device.mqtt_identity && device.mqtt_identity.toLowerCase().indexOf(search) !== -1) return true
          if (device.bcmserno && device.bcmserno.toLowerCase().indexOf(search) !== -1) return true
          if (device.current_location) {
            if (this.locationsLookup[device.current_location]) {
              return this.locationsLookup[device.current_location].name.toLowerCase().indexOf(search) !== -1
            }
          }
          return false
        }
        return true
      }) || []
    },
    deviceClassFilterItems () {
      const retval = [{ id: '', name: '' }]
      for (const [deviceClass, name] of Object.entries(DEVICE_CLASSES)) {
        retval.push({ id: deviceClass, name })
      }
      return retval
    },
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('user/' + FETCH_USER_DATA),
        this.$store.dispatch('location/' + FETCH_LOCATIONS),
        this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES)
      ])
    })
  },
  methods: {
  }
}
</script>
<style lang="stylus" scoped>
#content
  margin: 18px 36px;
  display: flex
  flex-direction: column
h3
  color: $clr-secondary-text-light
#mqtt-loading
  position: absolute
  top: 40px
  right: 50px
.wifiListSpinner
  height: 150px
  flex: 0 0 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
#filters
  display: flex
  width: 80%
  margin-left: 36px
  .bunt-input
    width: 300px
    margin-right: 16px
</style>
