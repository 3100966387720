import Vue from 'vue'
import VueI18n from 'vue-i18n'
import deMessages from './de-DE'

Vue.use(VueI18n)
const messages = {
  de: deMessages
}

const i18n = new VueI18n({
  locale: 'de',
  messages
})

export default i18n
