import { SET_DATES_FOR_ORGANIZATION, SET_DATES_GLOBAL } from './mutation-types'
import { FETCH_DATES_FOR_ORGANIZATION, FETCH_DATES_GLOBAL } from './action-types'
import { cachedActionWrapper } from '@/modules/common/store/tools'
import { CACHE_DATES_BY_ORGANIZATION, CACHE_DATES_GLOBAL } from '@/store/cache-types'

export default {
  namespaced: true,
  state: function () {
    return {
      // globalDates is the list of *all* all dates, across all organizations.
      // The global list ist only useful for lookup, never for presentation, so global access should almost always
      // be through globalDatesByUrl lookup.
      globalDates: [],
      // datesByOrganization lists organization specific dates by organization url. This list can be used for
      // presentation (use datesListForUrl) or lookup (use globalDatesByUrl, which includes also organization specific dates).
      datesByOrganization: {},
    }
  },
  getters: {
    globalDatesByUrl: (state) => {
      const result = {}
      for (const date of state.globalDates) {
        result[date.url] = date
      }
      for (const dates of Object.values(state.datesByOrganization)) {
        for (const date of dates) {
          result[date.url] = date
        }
      }
      return result
    },
    datesListForUrl: (state) => (organizationUrl) => {
      return state.datesByOrganization[organizationUrl] ?? []
    },
  },
  mutations: {
    [SET_DATES_GLOBAL]: (state, payload) => {
      if (!payload) {
        payload = []
      }
      state.globalDates = payload
    },
    [SET_DATES_FOR_ORGANIZATION]: (state, { organizationUrl, data }) => {
      state.datesByOrganization = { ...state.datesByOrganization, [organizationUrl]: data }
    }
  },
  actions: {
    [FETCH_DATES_GLOBAL]: async (context) => {
      return cachedActionWrapper(context, CACHE_DATES_GLOBAL, async () => {
        const response = await context.rootGetters.restApi.get('date/')
        await context.commit(SET_DATES_GLOBAL, response.data)
      }, async () => {
        await context.commit(SET_DATES_GLOBAL, [])
      })
    },
    [FETCH_DATES_FOR_ORGANIZATION]: async (context, organizationUrl) => {
      return cachedActionWrapper(context, CACHE_DATES_BY_ORGANIZATION + organizationUrl, async () => {
        const response = await context.rootGetters.restApi.get(organizationUrl + 'dates/')
        await context.commit(SET_DATES_FOR_ORGANIZATION, { organizationUrl, data: response.data })
      }, async () => {
        await context.commit(SET_DATES_FOR_ORGANIZATION, { organizationUrl, data: [] })
      })
    }
  }
}
