export const CACHE_CONTENTIMAGES = "contentimages"
export const CACHE_ORGANIZATIONS = "organizations"

export const CACHE_CONTACTS = 'contacts'
export const CACHE_LOCATIONS = "locations"
export const CACHE_DEVICES = "devices"
export const CACHE_DEVICES_BY_ORGANIZATION = "devicesByOrganization/"
export const CACHE_USER_DATA = "userData"
export const CACHE_DATES_GLOBAL = "datesGlobal"
export const CACHE_DATES_BY_ORGANIZATION = "datesByOrganization/"
export const CACHE_USER_LIST = 'userList'
export const CACHE_USER_CREDENTIALS = 'userCredentials'
export const CACHE_STATIC_CONFIGURATION = 'staticConfiguration'
export const CACHE_BILLING_PLANS = 'billingPlans'
export const CACHE_CONTRACTS = 'contracts'

export const CACHE_SEARCH_FIRMWARE_INFORMATION = 'searchFirmwareInformation/'
export const CACHE_DIRECT_PAYMENT_PAIN_FILES = 'directPaymentPainFiles'
