<template>
  <v-card outlined>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              outlined
              label="Ereignisse filtern"
              v-model="selectedEventType"
              :items="availableEventTypes"
            />
          </v-col>
          <v-col cols="12" md="3" offset-md="3">
            <!-- <div class="d-flex justify-end">
              <v-btn
                color="primary"
                @click="isCreatePaymentEntryDialogOpen = true"
                :disabled="true"
              ><v-icon>{{ mdiFileDocumentPlus }}</v-icon>
                Zuwendungsbestätigung erstellen</v-btn>
            </div> -->
          </v-col>
          <v-col cols="12" md="3">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                @click="isCreatePaymentEntryDialogOpen = true"
              ><v-icon>{{ mdiBankPlus }}</v-icon>
                Zahlung erfassen</v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="eventTableHeaders"
              :items="events"
            >
              <template #item.eventDate="{ item }">
                {{ item.eventDate | isoToHuman("L") }}
              </template>
              <template #item.eventType="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                    >{{ item.eventIcon }}</v-icon>
                  </template>
                  {{ item.eventIconTooltip }}
                </v-tooltip>
              </template>
              <template #item.eventDescription="{ item }">
                <span :class="item.eventClass">{{ item.eventDescription }}</span>
              </template>
              <template #item.amount="{ item }">
                <template v-if="item.amount">
                  {{ item.amount|amount }} €
                </template>
                <template v-else>--</template>
              </template>
              <template #item._actions="{ item }">
                <v-tooltip
                  v-if="['payment'].includes(item.eventType) && !item.disallow_receipt && item.state === 'completed' && !item.donation_receipts.some(x => !x.archived)"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      x-small
                      color="primary"
                      @click="createSingleDonationReceiptFor=item"
                    >
                      <v-icon v-on="on" v-bind="attrs">{{ mdiFileDocumentPlus }}</v-icon>
                    </v-btn>
                  </template>
                  Zuwendungsbestätigung erstellen
                </v-tooltip>
                  <v-tooltip
                    v-else-if="item.assignedDonationReceipt"
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        fab
                        outlined
                        x-small
                        color="primary"
                        @click="downloadDonationReceipt(item.assignedDonationReceipt)"
                      >
                        <v-icon v-on="on" v-bind="attrs">{{ mdiFileDownload }}</v-icon>
                      </v-btn>
                    </template>
                    {{ item.assignedDonationReceipt.file_name }},
                    {{ item.assignedDonationReceipt.created_at | isoToHuman("LLL") }}
                  </v-tooltip>
              </template>
              <template #item._additional_actions="{ item }">
                <v-tooltip
                  v-if="item.assignedDonationReceipt"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      outlined
                      x-small
                      color="primary"
                      @click="requestDonationReceiptCancellation(item)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon>{{ mdiFileCancelOutline }}</v-icon>
                    </v-btn>
                  </template>
                  Stornieren
                </v-tooltip>
                <v-tooltip
                  v-if="item.isDeletable"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    outlined
                    x-small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="paymentEntryMarkedForDeletion = item"
                  >
                    <v-icon>{{ mdiDelete }}</v-icon>
                  </v-btn>
                  </template>
                  Zahlung löschen
                </v-tooltip>

              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <base-dialog
      :is-open="isCreatePaymentEntryDialogOpen"
      @close="cancelPaymentCreation"
    >
      <template #dialog-title>Zahlung manuell erfassen</template>
      <template #dialog-content>
        <v-card flat>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-menu
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          @input="onPaymentDateInputChange"
                          v-model="formattedPaymentDate"
                          placeholder="Datum auswählen"
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="onPaymentDatePicked"
                        v-model="newPayment.booking_date"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      suffix="€"
                      type="number"
                      v-model="newPayment.amount"
                      label="Betrag"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      label="Zahlart"
                      v-model="newPayment.type"
                      :items="getAvailableEnumChoices('ManualEntryPaymentType')"
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="newPayment.special_purpose"
                      label="Haushaltsstelle (opt.)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="newPayment.disallow_receipt"><template #label>Zuwendungsbestätigung&nbsp;<em>nicht</em>&nbsp;ausstellen</template></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newPayment.purpose"
                      label="Kommentar"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="grey"
              @click="cancelPaymentCreation"
              text
            >Abbrechen</v-btn>
            <v-btn
              :disabled="!isPaymentCreationFormValid"
              color="primary"
              @click="createPayment"
            >Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </base-dialog>

    <base-dialog
      v-if="!!paymentEntryMarkedForDeletion"
      :is-open="!!paymentEntryMarkedForDeletion"
      @close="paymentEntryMarkedForDeletion = null"
    >
      <template #dialog-title>Zahlung löschen</template>
      <template #dialog-content>
        <v-card-text>
          Sind Sie sicher, dass Sie die Zahlung über <strong>{{ paymentEntryMarkedForDeletion.amount | amount }}€</strong> vom
          <strong>{{ paymentEntryMarkedForDeletion.eventDate | isoToHuman("L") }}</strong> löschen möchten?
          <br>
          <br>
          Diese Aktion kann nicht rückgängig gemacht werden.
          <v-divider/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="deletePayment(paymentEntryMarkedForDeletion)"
          >Ja, löschen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="closePaymentDeletionDialog"
          >Nein, abbrechen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
    <base-dialog
      :is-open="!!createSingleDonationReceiptFor"
       @close="createSingleDonationReceiptFor = null"
      :persistent="true"
    >
      <template #dialog-title>Zuwendungsbestätigung erstellen</template>
      <template #dialog-content>
        <create-donation-receipt
          v-if="createSingleDonationReceiptFor"
          :selector="{payments: [createSingleDonationReceiptFor.url]}"
          :person-name="currentPersonData.vornamen + ' ' + currentPersonData.familienname"
          @close="createSingleDonationReceiptFor = null"
          @done="createSingleDonationReceiptFor = null; $emit('refresh-payments')"
        />
      </template>

    </base-dialog>
    <base-dialog
      v-if="donationReceiptPaymentMarkedForCancellation"
      :is-open="isCancelDonationReceiptDialogOpen"
      @close="isCancelDonationReceiptDialogOpen = false"
    >
      <template #dialog-title>Zuwendungsbestätigung  wirklich stornieren?</template>
      <template #dialog-content>
        <v-card-text>
          Möchten Sie die Zuwendungsbestätigung für den Zweck "{{ donationReceiptPaymentMarkedForCancellation.purpose }}"
          am  {{ donationReceiptPaymentMarkedForCancellation.eventDate | isoToHuman('LL') }}
          für {{ currentPersonData.vornamen + ' ' + currentPersonData.familienname }}
          i.H.v. {{ donationReceiptPaymentMarkedForCancellation.amount | amount }} € wirklich stornieren?
          <br>
          <br>
          Diese Aktion kann nicht rückgängig gemacht werden.
          <v-divider/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="cancelDonationReceipt"
          >Ja, stornieren
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="closeDonationReceiptCancellationDialog"
          >Nein, abbrechen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
  </v-card>
</template>

<script>
import { sortAlphaNumeric } from "@/lib/sort-utils"
import moment from "moment"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import { mapGetters } from "vuex"
import { CANCEL_DONATION_RECEIPT, DELETE_PAYMENT, SAVE_PAYMENT } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import { OrgGemeinde } from "@/lib/icons"
import {
  mdiBankPlus,
  mdiCash,
  mdiCashRefund,
  mdiCashRemove,
  mdiCircleMultipleOutline,
  mdiFileCancelOutline,
  mdiFileDocumentOutline,
  mdiFileDocumentPlus,
  mdiFileDocumentRemoveOutline,
  mdiFileDownload,
  mdiHistory,
  mdiDelete
} from '@mdi/js'
import CreateDonationReceipt from '@/modules/kirchgeld/components/CreateDonationReceipt.vue'
import { downloadBlob } from '@/lib/file-tools'
import { loadingStateWrapper } from '@/modules/common/store/tools'

export default {
  name: 'PersonDetailEvents',
  components: {
    CreateDonationReceipt,
    BaseDialog
  },

  props: {
    personUrl: {
      type: String,
      required: true
    },
    payments: {
      type: Array,
      required: true
    },
    letters: {
      type: Array,
      required: true
    },
    currentPersonData: {
      type: Object,
      required: true
    },
  },
  emits: ['payment-was-created', 'refresh-payments'],
  data () {
    return {
      eventTableHeaders: [
        {
          text: 'Datum',
          value: 'eventDate',
          width: '100px'
        },
        {
          text: 'Betrag',
          value: 'amount',
          width: '100px'
        },
        {
          text: 'Typ',
          value: 'eventType',
          width: '75px'
        },
        {
          text: '',
          value: '_actions',
          width: '48px',
        },
        {
          text: 'Beschreibung',
          value: 'eventDescription'
        },
        {
          text: '',
          value: '_additional_actions',
          width: '48px'
        }
      ],
      selectedEventType: 'all',
      availableEventTypes: [
        {
          text: 'Alle',
          value: 'all'
        },
        {
          text: 'Briefe',
          value: 'letter'
        },
        {
          text: 'Zahlungen',
          value: 'payment'
        }
      ],
      isCreatePaymentEntryDialogOpen: false,
      paymentEntryMarkedForDeletion: null,
      isCancelDonationReceiptDialogOpen: false,
      donationReceiptPaymentMarkedForCancellation: null,
      createSingleDonationReceiptFor: null,
      newPayment: {},
      formattedPaymentDate: '',
      availablePaymentTypes: [
        {
          text: 'Bar',
          value: 'cash'
        },
        {
          text: 'Gemeindekonto',
          value: 'parish_account'
        }
      ],
      mdiBankPlus,
      mdiFileDocumentPlus,
      mdiFileDownload,
      mdiFileCancelOutline,
      mdiDelete,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['enums']),
    events () {
      const events = [
        ...this.payments.map(payment => {
          let eventIcon = null
          let eventIconTooltip = null
          let eventDescription = null
          let eventClass = []
          let isDeletable = false
          const assignedDonationReceipt = payment.donation_receipts.find(item => !item.archived)

          if (payment.payment_type === 'CAMTPayment') {
            eventIcon = mdiCash
            eventIconTooltip = 'Kirchgeldkonto'
            eventDescription = payment.purpose

            if (payment.target_purpose) {
              eventDescription += ` (${payment.target_purpose})`
            }
          } else if (payment.payment_type === 'ContextKPayment') {
            eventIcon = mdiHistory
            eventIconTooltip = "ContextK-Import"
            eventDescription = payment.purpose

            if (payment.target_purpose) {
              eventDescription += ` (${payment.target_purpose})`
            }
          } else {
            if (payment.type === 'cash') {
              eventIcon = mdiCircleMultipleOutline
              eventIconTooltip = 'Bar'
            } else if (payment.type === 'parish_account') {
              eventIcon = OrgGemeinde
              eventIconTooltip = 'Gemeindekonto'
            }

            eventDescription = ''

            if (payment.purpose && !payment.target_purpose) {
              eventDescription = payment.purpose
            } else if (!payment.purpose && payment.target_purpose) {
              eventDescription = payment.target_purpose
            } else if (payment.purpose && payment.target_purpose) {
              eventDescription += `${payment.target_purpose} (${payment.purpose})`
            }
          }

          if (payment?.state === "cancelled") {
            eventIcon = mdiCashRemove
            eventIconTooltip = "Storniert"
            eventClass = ["grey--text"]
          } else if (payment?.state === "cancellation") {
            eventIcon = mdiCashRefund
            eventIconTooltip = "Stornobuchung"
            eventClass = ["grey--text"]
          }

          if (
            payment.payment_type === 'ManualEntryPayment' &&
            !['cancelled', 'cancellation'].includes(payment?.state) &&
            !assignedDonationReceipt
          ) {
            isDeletable = true
          }

          return {
            ...payment,
            eventDate: payment.booking_date,
            eventDescription,
            eventType: 'payment',
            eventIcon,
            eventIconTooltip,
            eventClass,
            assignedDonationReceipt,
            isDeletable
          }
        }),
        ...this.letters
          .filter(letter => letter.state === 'sent')
          .map(letter => {
            return {
              ...letter,
              // TODO: Replace with sent date as soon kdbackend #47 was tackled
              eventDate: letter.created_at,
              eventDescription: letter.name,
              eventType: 'letter',
              eventIcon: mdiFileDocumentOutline
            }
          }),
        ...this.payments.map(
          payment => payment.donation_receipts.filter(item => item.archived)
          // FIXME: archived batch receipts will appear multiple times
        ).reduce((a, b) => [...a, ...b], []).map(item => ({
          ...item,
          eventDate: item.created_at,
          eventDescription: `${item.file_name} (storniert)`,
          eventType: 'donation_receipt',
          eventIcon: mdiFileDocumentRemoveOutline,
          eventIconTooltip: "Zuwendungsbestätigung storniert",
        })
        )
      ].filter(event => {
        if (this.selectedEventType === 'all') {
          return true
        }

        return event.eventType === this.selectedEventType
      })

      return sortAlphaNumeric(events, 'eventDate', false)
    },
    isPaymentCreationFormValid () {
      // eslint-disable-next-line camelcase
      return this.newPayment?.booking_date &&
        this.newPayment?.amount &&
        // eslint-disable-next-line camelcase
        this.newPayment?.type
    }
  },
  methods: {
    onPaymentDatePicked () {
      this.formattedPaymentDate = new Date(this.newPayment.booking_date).toLocaleDateString('de-DE')
    },
    onPaymentDateInputChange () {
      const newDate = moment(this.formattedPaymentDate, 'L')
      this.newPayment.booking_date = newDate.format('YYYY-MM-DD')
    },
    cancelPaymentCreation () {
      this.newPayment = {}
      this.isCreatePaymentEntryDialogOpen = false
      this.formattedPaymentDate = null
    },
    getAvailableEnumChoices (propertyChoices) {
      const enumProperty = (this.enums ?? []).find(item => item.name === propertyChoices) ?? {}
      return Object.entries(enumProperty.values ?? {}).map(([name, description]) => ({
        value: name,
        text: description,
      })) ?? []
    },
    async createPayment () {
      const payment = {
        ...this.newPayment,
        state: "completed",
        // eslint-disable-next-line camelcase
        special_purpose: this.newPayment?.special_purpose || null,
        person: this.personUrl
      }

      await this.$store.dispatch('kirchgeld/' + SAVE_PAYMENT, payment)
      this.newPayment = {}
      this.formattedPaymentDate = null
      this.isCreatePaymentEntryDialogOpen = false
      this.$emit('payment-was-created')
    },
    async deletePayment (payment) {
      await this.$store.dispatch('kirchgeld/' + DELETE_PAYMENT, { paymentId: payment.id })
      this.$emit('refresh-payments')
      this.paymentEntryMarkedForDeletion = null
    },
    async downloadDonationReceipt (donationReceipt) {
      const response = await loadingStateWrapper(this.$store, async () => {
        return await this.$store.getters['kirchgeld/api'].get(`${donationReceipt.url}download/`, { responseType: 'blob' })
      })
      downloadBlob(response.data, donationReceipt.file_name, response.headers["content-type"])
    },
    requestDonationReceiptCancellation (payment) {
      this.donationReceiptPaymentMarkedForCancellation = payment
      this.isCancelDonationReceiptDialogOpen = true
    },
    async cancelDonationReceipt () {
      const donationReceipt = this.donationReceiptPaymentMarkedForCancellation.donation_receipts.find(item => !item.archived)
      await this.$store.dispatch('kirchgeld/' + CANCEL_DONATION_RECEIPT, { donationReceipt })
      this.closeDonationReceiptCancellationDialog()
      this.$emit('refresh-payments')
    },
    closeDonationReceiptCancellationDialog () {
      this.isCancelDonationReceiptDialogOpen = false
      this.donationReceiptPaymentMarkedForCancellation = null
    },
    closePaymentDeletionDialog () {
      this.paymentEntryMarkedForDeletion = null
    },
  }
}
</script>
