<template>
  <v-card>
    <v-card-title>
      <h5>Rechnungskonfiguration {{ contract.id }} <span class="text-uppercase">({{ scope }})</span></h5>
    </v-card-title>
    <v-card-text v-if="contract">
      <v-row>
        <v-col
          v-if="contract.billingPlanContractAssociation"
          cols="12"
          class="font-weight-bold"
        >
          <!-- TODO: Show time range of selected billing plan association, not contract -->
          <template v-if="contract.billingPlanContractAssociation.valid_from">
            {{ momentize(contract.billingPlanContractAssociation.valid_from) }}
          </template>
          <template v-if="contract.billingPlanContractAssociation.valid_from || contract.billingPlanContractAssociation.valid_notafter">
            &ndash;
          </template>
          <template v-if="contract.billingPlanContractAssociation.valid_notafter">
            {{ momentize(contract.billingPlanContractAssociation.valid_notafter) }}
          </template>
        </v-col>
      </v-row>
      <v-form>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="billingPlans"
              item-value="url"
              item-text="name"
              v-model="selectedBillingPlanUrl"
              placeholder="Rechnungskonfiguration wählen"
              :disabled="!isSuperuser"
            />
          </v-col>
        </v-row>
        <template v-if="selectedBillingPlanUrl">
          <v-row
            v-for="(priceDefinitions, priceDefinitionScope) in selectedBillingPlan.conditions[scope]"
            :key="priceDefinitionScope"
          >
            <v-col
              cols="12"
              md="3"
            >{{ getPriceDefinitionScopeLabel(priceDefinitionScope) }}
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-row>
                <template
                  v-for="(priceDefinition, priceDefinitionKey) in priceDefinitions"
                >
                  <v-col
                    v-if="Array.isArray(priceDefinition)"
                    :key="priceDefinitionKey"
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      :label="'ab ' + priceDefinition[0]"
                      :value="priceDefinition[1]"
                      class="mx-4"
                      :append-icon="mdiCurrencyEur"
                      disabled
                    />
                  </v-col>
                  <template v-else>
                    <template v-for="transactionPriceDefinitions in priceDefinition">
                      <v-col
                        v-for="(transactionPriceDefinition, transactionPriceDefinitionKey) in transactionPriceDefinitions"
                        :key="transactionPriceDefinitionKey"
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          :label="'ab ' + transactionPriceDefinition[0]"
                          :value="transactionPriceDefinition[1]"
                          class="mx-4"
                          :append-icon="mdiCurrencyEur"
                          disabled
                        />
                      </v-col>
                    </template>
                  </template>
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="my-4"
            >
              Rechnungslegung
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-radio-group
                :value="selectedBillingPlan.type"
                row
                disabled
                class="mx-4"
              >
                <v-radio
                  label="monatlich"
                  value="monthly"
                />
                <v-radio
                  label="jährlich"
                  value="yearly"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="my-4"
          >
            SEPA-Lastschrift-mandat erteilt?
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-radio-group
              row
              class="mx-4"
              :disabled="!isSuperuser"
              v-model="selectedSepaDdActive"
            >
              <v-radio
                label="Ja"
                :value="true"
              />
              <v-radio
                label="Nein"
                :value="false"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        text
        color="primary"
        @click="saveContractBilling"
        :disabled="!hasChange"
      >
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DEVICE_CLASSES } from "@/lib/device-db"
import { mdiCurrencyEur } from "@mdi/js"
import { mapGetters } from "vuex"
import {
  CREATE_BILLING_PLAN_CONTRACT_ASSOCIATION,
  UPDATE_BILLING_PLAN_CONTRACT_ASSOCIATION, UPDATE_CONTRACT
} from "@/store/action-types"
import moment from "moment/moment"
import { CONTRACT_SCOPE_ECOMMERCE, CONTRACT_SCOPE_KNB } from "@/store/contracts"

export default {
  name: 'OrganizationContractBillingCard',
  emits: ['update'],
  props: {
    organization: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      initialBillingPlanUrl: null,
      selectedBillingPlanUrl: null,
      initialSepaDdActive: null,
      selectedSepaDdActive: null,
      mdiCurrencyEur
    }
  },
  computed: {
    ...mapGetters('billing', ['billingPlans', 'billingPlanByUrl']),
    ...mapGetters('user', ['isSuperuser']),
    selectedBillingPlan () {
      return this.billingPlanByUrl[this.selectedBillingPlanUrl]
    },
    hasChange () {
      if (this.selectedSepaDdActive !== this.initialSepaDdActive) {
        return true
      }
      return this.selectedBillingPlanUrl !== this.initialBillingPlanUrl
    },
    scope () {
      if (this.contract.type === 'op') {
        return CONTRACT_SCOPE_ECOMMERCE
      }

      if (this.contract.type === 'ps') {
        return CONTRACT_SCOPE_KNB
      }

      return this.contract.type
    }
  },
  methods: {
    momentize (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getPriceDefinitionScopeLabel (scope) {
      const labelMapping = {
        ...DEVICE_CLASSES,
        per_tx: 'Kosten pro Transaktion',
        per_device: 'Kosten pro Gerät'
      }
      return labelMapping[scope] || scope
    },
    async saveContractBilling () {
      if (this.contract.billingPlanContractAssociation) {
        await this.updateContractBilling()
      } else {
        await this.createContractBilling()
      }
      this.$emit('update')
      this.initialBillingPlanUrl = this.selectedBillingPlanUrl
      this.initialSepaDdActive = this.selectedSepaDdActive
    },
    async createContractBilling () {
      const billingPlanCreate = this.$store.dispatch('contracts/' + CREATE_BILLING_PLAN_CONTRACT_ASSOCIATION, {
        contract: this.contract,
        billingPlanUrl: this.selectedBillingPlanUrl
      })

      const actions = [billingPlanCreate]

      if (this.selectedSepaDdActive !== this.initialSepaDdActive) {
        actions.push(this.updateContractSepaDdActive())
      }

      return await Promise.allSettled(actions)
    },
    async updateContractBilling () {
      const billingPlanUpdate = this.$store.dispatch('contracts/' + UPDATE_BILLING_PLAN_CONTRACT_ASSOCIATION, {
        associationId: this.contract.billingPlanContractAssociation.id,
        contractId: this.contract.id,
        billingPlanUrl: this.selectedBillingPlanUrl
      })

      const actions = [billingPlanUpdate]

      if (this.selectedSepaDdActive !== this.initialSepaDdActive) {
        actions.push(this.updateContractSepaDdActive())
      }

      return await Promise.allSettled(actions)
    },
    async updateContractSepaDdActive () {
      return this.$store.dispatch('contracts/' + UPDATE_CONTRACT, {
        contract: this.contract,
        sepaDdActive: this.selectedSepaDdActive
      })
    },
  },
  watch: {
    contract: {
      immediate: true,
      handler (contract) {
        this.initialBillingPlanUrl = contract.billingPlan?.url
        this.selectedBillingPlanUrl = contract.billingPlan?.url

        if (this.initialSepaDdActive === null) {
          this.initialSepaDdActive = contract.sepadd_active
          this.selectedSepaDdActive = contract.sepadd_active
        }
      }
    }
  }
}
</script>
