export const LETTER_FILTER_ONE_LETTER_PER_PERSON = 'oneLetterPerPerson'
export const LETTER_FILTER_ONE_LETTER_PER_COUPLE = 'oneLetterPerCouple'

export const additionalLetterOptions = [
  {
    label: 'ein Brief pro Person',
    value: LETTER_FILTER_ONE_LETTER_PER_PERSON
  },
  {
    label: 'ein Brief pro Ehepaar',
    value: LETTER_FILTER_ONE_LETTER_PER_COUPLE
  }
]
