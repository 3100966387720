import { isCurrentlyValid } from "@/lib/sort-utils"
import {
  CREATE_BILLING_PLAN_CONTRACT_ASSOCIATION,
  FETCH_BILLING_PLAN_CONTRACT_ASSOCIATION,
  FETCH_CONTRACTS,
  UPDATE_BILLING_PLAN_CONTRACT_ASSOCIATION, UPDATE_CONTRACT
} from "@/store/action-types"
import { cachedActionWrapper, loadingStateWrapper } from "@/modules/common/store/tools"
import { SET_CONTRACTS } from "@/store/mutation-types"
import { CACHE_CONTRACTS } from "@/store/cache-types"
import { SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"

export const CONTRACT_SCOPE_KNB = 'knb'
export const CONTRACT_SCOPE_ECOMMERCE = 'ecommerce'

export default {
  namespaced: true,
  state: () => {
    return {
      contracts: []
    }
  },
  getters: {
    contractsById (state) {
      const result = {}

      state.contracts.forEach(contract => {
        result[contract.id] = contract
      })
      return result
    },
    contractsByOrganization: (state) => {
      const result = {}
      state.contracts.forEach(contract => {
        if (!result[contract.organization]) {
          result[contract.organization] = {
            allContracts: [],
            validContracts: [],
            hasValidContract: false
          }
        }
        result[contract.organization].allContracts.push(contract)
        if (isCurrentlyValid(contract)) {
          result[contract.organization].validContracts.push(contract)
          result[contract.organization].hasValidContract = true
        }
      })

      return result
    },
    contractsByOrganizationAndDevices: (_state, getters, rootState) => {
      const result = {}
      rootState.device.devices.forEach(device => {
        if (!device.contracts.length) {
          return
        }

        device.contracts.forEach(deviceContract => {
          if (!result[deviceContract.contract.organization]) {
            result[deviceContract.contract.organization] = {
              allContracts: [],
              validContracts: [],
              hasValidContract: false,
              deviceClasses: []
            }
          }
          const contract = getters.contractsById[deviceContract.contract.id]
          result[deviceContract.contract.organization].deviceClasses.push(device.device_class)

          const contractFound = result[deviceContract.contract.organization].allContracts.find(c => c.id === contract.id)
          if (contractFound) {
            return
          }

          result[deviceContract.contract.organization].allContracts.push(contract)

          if (isCurrentlyValid(contract)) {
            result[deviceContract.contract.organization].validContracts.push(contract)
            result[deviceContract.contract.organization].hasValidContract = true
          }
        })
      })
      return result
    }
  },
  mutations: {
    [SET_CONTRACTS]: (state, { data }) => {
      state.contracts = data
    }
  },
  actions: {
    [FETCH_CONTRACTS]: async (context) => {
      return cachedActionWrapper(context, CACHE_CONTRACTS, async () => {
        const response = await context.rootGetters.restApi.get('/contract/')
        context.commit(SET_CONTRACTS, { data: response.data })
      }, async (context) => {
        context.commit(SET_CONTRACTS, { data: [] })
      })
    },
    [FETCH_BILLING_PLAN_CONTRACT_ASSOCIATION]: async (context, { contractId }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.get(`/contract/${contractId}/billing/`)
        return response.data
      }, async (context) => {
        return false
      })
    },
    [CREATE_BILLING_PLAN_CONTRACT_ASSOCIATION]: async (context, {
      contract,
      billingPlanUrl
    }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.post(`/contract/${contract.id}/billing/`, {
          billing_plan: billingPlanUrl,
          valid_from: context.rootState.now,
          valid_notafter: context.rootState.now.clone().add(4, 'year').subtract(1, 'day'),
          // valid_notafter: null,
        })
        context.commit(SET_SNACKBAR_MESSAGE, { message: 'Rechnungskonfiguration erstellt' }, { root: true })
        return response.data
      }, async (context) => {
        return false
      })
    },
    [UPDATE_BILLING_PLAN_CONTRACT_ASSOCIATION]: async (context, {
      contractId,
      billingPlanUrl,
      associationId
    }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(`/contract/${contractId}/billing/`, {
          id: associationId,
          billing_plan: billingPlanUrl
        })
        context.commit(SET_SNACKBAR_MESSAGE, { message: 'Rechnungskonfiguration aktualisiert' }, { root: true })
        return response.data
      }, async (context) => {
        return false
      })
    },
    [UPDATE_CONTRACT]: async (context, {
      contract,
      sepaDdActive
    }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(contract.url, {
          sepadd_active: sepaDdActive
        })
        context.commit(SET_SNACKBAR_MESSAGE, { message: 'Vertrag aktualisiert' }, { root: true })
        return response.data
      }, async (context) => {
        return false
      })
    }
  }
}
