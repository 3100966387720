<template>
  <v-card-text>
    <div>
      <v-select
        label="Typ"
        v-model="personType"
        :items="getAvailableEnumChoices('PersonType')"
        @blur="choosePersonType"
      />
    </div>
    <div
      v-for="property in availablePersonProperties"
      :key="property.value"
    >
      <div v-if="property.format === 'date'">

        <div class="text-h6">{{ property.text }}</div>
        <v-menu
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="onDateInputChange(property.value)"
              v-model="formattedData[property.value]"
              placeholder="Datum auswählen"
              v-bind="attrs"
              v-on="on"
              prepend-icon="mdi-calendar"
              :persistent-hint="hasErrors[property.value] === false"
              :hint="hasErrors[property.value] === false ? 'Das eingegebene Datum ist ungültig' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            @change="onDatePicked(property.value)"
            v-model="personData[property.value]"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-select
        v-else-if="property.format === 'enum' || property.format === 'enumSet'"
        :multiple="property.format === 'enumSet'"
        :label="getLabel(property)"
        v-model="personData[property.value]"
        :items="getAvailableEnumChoices(property.choices)"
        :error="property.isMandatory && !personData[property.value]"
      />
      <div
        v-else-if="property.value === 'relations' && allowRelationsModification">
        <div class="text-h6">{{ property.text }}</div>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="relation in personData[property.value]"
              :key="relation.side_b.id"
            >
              <v-select
                style="max-width: 300px"
                v-model="relation.type"
                :items="getAvailableEnumChoices('RelationType')"
                placeholder="Verwandschaftsbeziehung auswählen"
              ></v-select>
              <template v-if="relation.side_b.current_data">
                {{ relation.side_b.current_data.vornamen }}
                {{ relation.side_b.current_data.familienname }}
              </template>
              <template v-else>
                {{ getFormattedRelation(relation.side_b).vornamen }}
                {{ getFormattedRelation(relation.side_b).familienname }}
              </template>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="gray"
                    @click="removeRelation(relation.side_b)"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
                Verwandschaftsbeziehung entfernen
              </v-tooltip>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn
          color="primary"
          @click="showRelationsDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
          hinzufügen</v-btn>
        <base-dialog
          max-width="none"
          :is-open="showRelationsDialog"
          @close="showRelationsDialog = false"
        >
          <template #dialog-title>Verwandte Datensätze für {{ personData.vornamen }} {{ personData.familienname }} auswählen</template>
          <template #dialog-content>
            <v-card-text>
              <person-lookup
                :is-external-selection-allowed="true"
                @external-selection-confirmed="onExternalSelectionConfirmed"
              />
            </v-card-text>
          </template>
        </base-dialog>
      </div>
      <v-switch
        v-else-if="property.value === 'betreuerstatus'"
        v-model="personData[property.value]"
        :label="getLabel(property)"
        :false-value="null"
        :true-value="'B'"
      />
      <v-autocomplete
        v-else-if="property.value === 'org_by_kro'"
        v-model="selectedOrganization"
        :items="availableOrganizations"
        :label="getLabel(property)"></v-autocomplete>
      <v-text-field
        v-else-if="personEdit && property.value === 'bemerkungen'"
        v-model.trim="personData[property.value]"
        :label="getLabel(property)"
        :error="property.isMandatory && !personData[property.value]"
        :disabled="property.isReadonly"
      />
      <v-text-field
        v-else
        v-model.trim="personData[property.value]"
        :label="getLabel(property)"
        :error="property.isMandatory && !personData[property.value]"
        :disabled="property.isReadonly"
      />
    </div>
  </v-card-text>
</template>

<script>
import BaseDialog from "@/components/UI/BaseDialog"
import PersonLookup from "@/modules/kirchgeld/pages/PersonLookup"
import { mapGetters } from "vuex"
import {
  COMPANY_EXCLUDE,
  FORM_EXCLUDE, FORM_READONLY,
  personProperties,
  VISIBLE_DETAIL
} from "@/modules/kirchgeld/config/person.properties"
import moment from "moment"
import { FEATURE_FLAG_KD_285_ENABLE_RELATION_MODIFICATION } from "@/feature-flags"
import { sortAlphaNumeric } from "@/lib/sort-utils"
import { shortenParish } from "@/lib/regex-tools"
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"

export default {
  name: "PersonEditForm",
  components: {
    BaseDialog,
    PersonLookup
  },
  emits: ['person-data-changed'],
  mixins: [
    PersonDataMixin
  ],
  props: ['personDataInput', 'personEdit', 'personUrlLink'],
  data () {
    return {
      personData: {},
      personType: 'individual',
      formattedData: {},
      hasErrors: {},
      showRelationsDialog: false,
      selectedOrganization: null,
      newPersonData: {},
      allowRelationsModification: FEATURE_FLAG_KD_285_ENABLE_RELATION_MODIFICATION
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['enums', 'organizations', 'api']),
    availablePersonProperties () {
      return personProperties.filter(property => {
        if (property.value === 'relations' && this.personType === 'individual' && this.personType === 'company') {
          return true
        }
        if (this.personType === 'company' && property.flags.includes(COMPANY_EXCLUDE)) {
          return false
        }
        return property.flags.includes(VISIBLE_DETAIL) && !property.flags.includes(FORM_EXCLUDE)
      }).map(property => {
        let isMandatory = false
        if (this.personType === 'individual' && ['vornamen', 'geschlecht'].includes(property.value)) {
          isMandatory = true
        }

        if (property.value === 'vornamen' && this.personType === 'individual') {
          isMandatory = true
        }

        if (property.value === 'familienname') {
          isMandatory = true
        }

        return {
          ...property,
          isMandatory,
          isReadonly: property.flags.includes(FORM_READONLY)
        }
      })
    },
    isValid () {
      if (!this.personData?.vornamen && this.personType === 'individual') {
        return false
      }

      if (!this.personData?.familienname) {
        return false
      }
      return true
    },
    availableOrganizations () {
      return sortAlphaNumeric(this.organizations, 'name')
        .map(org => {
          const parishShort = shortenParish(org.name)
          return {
            value: org,
            text: `${org.kro_nr} (${parishShort})`
          }
        })
    },
  },
  mounted () {
    this.personData = this.personDataInput
    this.setSalutations()
  },
  methods: {
    getLabel (property) {
      if (this.personType === 'company') {
        // eslint-disable-next-line camelcase
        return property?.text_alt ?? property.text
      }

      if (property?.isMandatory) {
        return property.text + '*'
      }
      return property.text
    },
    onExternalSelectionConfirmed (relatedPerson) {
      const formattedRelations = this.formattedData?.relations ?? []
      const formattedRelationExists = formattedRelations.find(relation => relation.personUrl === relatedPerson.personUrl) ?? false
      if (!formattedRelationExists) {
        formattedRelations.push(relatedPerson)
        this.formattedData.relations = formattedRelations
      }

      const relations = this.personData?.relations ?? []
      const relationExists = relations.find(relation => relation.personUrl === relatedPerson.personUrl) ?? false
      if (!relationExists) {
        relations.push({
          side_b: relatedPerson.personUrl,
          type: null
        })
        this.personData.relations = relations
      }

      this.showRelationsDialog = false
    },
    getFormattedRelation (relatedPersonUrl) {
      return this.formattedData?.relations?.find(relation => relation?.personUrl === relatedPersonUrl)
    },
    onDatePicked (propertyValue) {
      this.formattedData[propertyValue] = new Date(this.personData[propertyValue]).toLocaleDateString('de-DE')
      this.hasErrors[propertyValue] = true
    },
    onDateInputChange (propertyValue) {
      const newDate = moment(this.formattedData[propertyValue], 'L')

      if (newDate.isValid()) {
        this.personData[propertyValue] = newDate.format('YYYY-MM-DD')
        this.hasErrors[propertyValue] = true
      } else {
        this.hasErrors[propertyValue] = false
      }
    },
    removeRelation (relatedPersonUrl) {
      const formattedRelationIndex = this.formattedData?.relations.findIndex(relation => relation.personUrl === relatedPersonUrl)
      const relationIndex = this.personData?.relations.findIndex(relation => relation.side_b === relatedPersonUrl)

      this.formattedData.relations.splice(formattedRelationIndex, 1)
      this.personData.relations.splice(relationIndex, 1)
    },
    getAvailableEnumChoices (propertyChoices) {
      const enumProperty = (this.enums ?? []).find(item => item.name === propertyChoices) ?? {}
      if (this.personType === 'company' && propertyChoices === 'RelationType') {
        return [{ value: 'part_of', text: 'Teil von' }]
      }
      return Object.entries(enumProperty.values ?? {}).map(([name, description]) => ({
        value: name,
        text: description,
      })) ?? []
    },
    setSalutations () {
      switch (this.personData?.geschlecht) {
        case 'w':
          this.personData.langanrede = 'Sehr geehrte Frau'
          this.personData.anrede = 'Frau'
          break
        case 'm':
          this.personData.langanrede = 'Sehr geehrter Herr'
          this.personData.anrede = 'Herr'
          break
        case 'd':
        case 'x':
        default:
          this.personData.langanrede = 'Hallo'
          this.personData.anrede = 'Hallo'
          break
      }
    },
    choosePersonType () {
      this.$emit('selectedPersonType', this.personType)
    }
  },
  watch: {
    personType (newValue) {
      if (newValue === 'company') {
        this.personData.langanrede = 'Sehr geehrte Damen und Herren'
        this.personData.anrede = ''
        this.personData.geschlecht = null
        this.personData.vornamen = ''
      } else {
        this.setSalutations()
      }
    },
    'personData.geschlecht': function (_newValue) {
      this.setSalutations()
    },
    'personData.vornamen': function (_newValue) {
      this.setSalutations()
    },
    'personData.familienname': function (_newValue) {
      this.setSalutations()
    },
    'selectedOrganization.id': function (_newValue) {
      this.personData.kro_nr = this.selectedOrganization.kro_nr
    },
    'personData.id': async function (_newValue) {
      if (!this.personEdit) {
        return
      }
      if (this.personData.relations) {
        this.allowRelationsModification = true
      }
      this.personType = this.personData.type

      this.formattedData.relations = this.personData?.relations.map(relation => relation.side_b.current_data) ?? []

      if (this.personData.geburtsdatum) {
        this.formattedData.geburtsdatum = new Date(this.personData.geburtsdatum).toLocaleDateString('de-DE')
      }

      if (this.personData.auszug_datum) {
        this.formattedData.auszug_datum = new Date(this.personData.auszug_datum).toLocaleDateString('de-DE')
      }
      if (this.personData.einzug_datum) {
        this.formattedData.einzug_datum = new Date(this.personData.einzug_datum).toLocaleDateString('de-DE')
      }
    },
    personData: {
      deep: true,
      handler (newValue) {
        this.$emit('person-data-changed', newValue)
      }
    },
    personDataInput: {
      deep: true,
      handler (newValue) {
        this.personData = newValue
      }
    }
  }
}
</script>

<style scoped>

</style>
