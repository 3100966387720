<template>
  <v-card
    outlined
    class="pa-3"
  >
    <v-container>
      <v-row>
        <v-col class="pa-0 ma-0 minimal-flex">
        </v-col>
        <v-col class="pa-0 ma-0 minimal-flex">
          <v-btn-toggle>
            <v-btn x-small class="pa-1 ma-0" min-width="6em" tile v-model="isNot['Default']" raised>
              {{ notOperator.text }}<v-spacer></v-spacer><v-icon v-if="isNot['Default']" x-small>{{ mdiCheck }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="10">
          <filter-criteria-input
            :ref="'filterCriteriaInputDefault'"
            :filter-id="'Default'"
            :is-deletion-allowed="false"
          ></filter-criteria-input>
        </v-col>
      </v-row>

      <v-row
        v-for="filterId in filterIds"
        :key="filterId"
      >
        <v-col class="pa-0 ma-0 minimal-flex" v-if="nestedFilterGroupIds.length > 0 || filterIds.length > 0">
          <v-btn-toggle v-model="selectedOperatorIndex" mandatory class="set-operators" tile>
            <v-btn v-for="item in availableOperators" :key="item.value" x-small class="pa-1 ma-0" min-width="5.5em">
              {{ item.text }}<v-spacer></v-spacer><v-icon v-if="selectedOperator === item.value" x-small>{{ mdiCheck }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="pa-0 ma-0 minimal-flex">
          <v-btn-toggle>
            <v-btn x-small class="pa-1 ma-0" min-width="6em" tile v-model="isNot[filterId]" raised>
              {{ notOperator.text }}<v-spacer></v-spacer><v-icon v-if="isNot[filterId]" x-small>{{ mdiCheck }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="10">
          <filter-criteria-input
            class="mt-1"
            :ref="'filterCriteriaInput' + filterId"
            :filter-id="filterId"
            @delete-filter="deleteFilter"
          >
          </filter-criteria-input>
        </v-col>

      </v-row>

      <v-row
        v-for="nestedFilterGroupId in nestedFilterGroupIds"
        :key="nestedFilterGroupId"
      >
        <v-col class="pa-0 ma-0 minimal-flex" v-if="nestedFilterGroupIds.length > 0 || filterIds.length > 0">
          <v-btn-toggle v-model="selectedOperatorIndex" mandatory class="set-operators" tile>
            <v-btn v-for="item in availableOperators" :key="item.value" x-small class="pa-1 ma-0" min-width="5.5em">
              {{ item.text }}<v-spacer></v-spacer><v-icon v-if="selectedOperator === item.value" x-small>{{ mdiCheck }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="pa-0 ma-0 minimal-flex">
          <v-btn-toggle>
            <v-btn x-small class="pa-1 ma-0" min-width="6em" tile v-model="isNot[nestedFilterGroupId]" raised>
              {{ notOperator.text }}<v-spacer></v-spacer><v-icon v-if="isNot[nestedFilterGroupId]" x-small>{{ mdiCheck }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="10">
          <filter-criteria-group
            :ref="'filterCriteriaGroup' + nestedFilterGroupId"
            :filter-group-id="nestedFilterGroupId"
            @delete-filter-group="deleteFilterGroup"
          >
          </filter-criteria-group>
        </v-col>
      </v-row>

      <v-row
        justify="space-between"
        class="mt-3">
        <div>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                @click="addFilter"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ mdiPlusCircleOutline }}
                </v-icon>
                <!--            -->
              </v-btn>
            </template>
            <span>
              Kriterium hinzufügen
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                @click="addFilterGroup"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ mdiTextBoxPlusOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Filtergruppe hinzufügen</span>
          </v-tooltip>
        </div>
        <div v-if="isDeletionAllowed">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                @click="requestFilterGroupDeletion"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ mdiTextBoxMinusOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Filtergruppe löschen</span>
          </v-tooltip>
        </div>
      </v-row>

    </v-container>
  </v-card>
</template>

<script>
import { availableOperators, notOperator } from '@/modules/kirchgeld/config/filter-lookups'
import { mdiCheck, mdiPlusCircleOutline, mdiTextBoxMinusOutline, mdiTextBoxPlusOutline } from '@mdi/js'

import FilterCriteriaInput from '@/modules/kirchgeld/components/FilterCriteriaInput'

export default {
  name: 'FilterCriteriaGroup',
  components: {
    FilterCriteriaInput
  },
  emits: ['delete-filter-group'],
  props: {
    filterGroupId: {
      type: [String, Number],
      required: true
    },
    isDeletionAllowed: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data () {
    return {
      selectedOperatorIndex: 0,
      isNot: {},
      notOperator,
      availableOperators,
      filterIds: [],
      nestedFilterGroupIds: [],
      mdiPlusCircleOutline,
      mdiTextBoxPlusOutline,
      mdiTextBoxMinusOutline,
      mdiCheck,
    }
  },
  computed: {
    selectedOperator: {
      get () {
        return this.availableOperators[this.selectedOperatorIndex ?? 0]?.value ?? 'and'
      },
      set (value) {
        for (const [i, item] of availableOperators.entries()) {
          if (item?.value === value) {
            this.selectedOperatorIndex = i
          }
        }
      }
    }
  },
  methods: {
    generateRandomId () {
      return Math.random().toString(36).slice(2, 7)
    },
    addFilter () {
      this.filterIds.push(this.generateRandomId())
    },
    addFilterGroup () {
      this.nestedFilterGroupIds.push(this.generateRandomId())
    },
    deleteFilter (filterIdToDelete) {
      this.filterIds = this.filterIds.filter(filterId => filterId !== filterIdToDelete)
    },
    requestFilterGroupDeletion () {
      this.$emit('delete-filter-group', this.filterGroupId)
    },
    deleteFilterGroup (filterGroupIdToDelete) {
      this.nestedFilterGroupIds = this.nestedFilterGroupIds.filter(nestedFilterGroupId => nestedFilterGroupId !== filterGroupIdToDelete)
    },
    getFilterGroup () {
      return [
        { op: this.selectedOperator },
        ...this.getFilterList(),
        ...this.getNestedFilterGroups(),
      ]
    },
    getNestedFilterGroups () {
      return this.nestedFilterGroupIds.map(nestedFilterGroupId => {
        let item = this.$refs['filterCriteriaGroup' + nestedFilterGroupId][0].getFilterGroup()
        if (this.isNot[nestedFilterGroupId]) {
          item = [
            { op: "not" },
            item
          ]
        }
        return item
      })
    },
    getFilterList () {
      return ['Default', ...this.filterIds].map(filterId => {
        const ref = 'filterCriteriaInput' + filterId
        let item = (this.$refs[ref][0] ?? this.$refs[ref]).getFilter()
        if (this.isNot[filterId]) {
          item = [
            { op: "not" },
            item
          ]
        }
        return item
      })
    },
    resetFilterGroup () {
      this.filterIds = []
      this.nestedFilterGroupIds = []
      this.$refs.filterCriteriaInputDefault.clearInput()
    }
  }
}
</script>
<style scoped>
div.v-card {
  border-right: 0;
}
.v-btn-toggle {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.minimal-flex {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 3.5em;
  flex-basis: content;
}

</style>
