<template>
  <v-container id="qr-code">
    <v-row>
      <v-col cols="12">
        <h3>QR-Code</h3>
        <v-slider
          v-model="qrwidth"
          label="Breite"
          thumb-color="#554193"
          thumb-label="always"
          max="1000"
          :min="100"
        ></v-slider>
        <v-text-field
          v-model="qrtarget"
          label="URL"
          placeholder="https://www.google.de"
          outlined
          dense
          hide-details="true"
        ></v-text-field>
        <div id="qrcode" class="mt-4">
          <qr-canvas :options="options"></qr-canvas>
        </div>
        <p>Zum Speichern: Rechte Maustaste -> Bild speichern unter</p>
        <v-color-picker
          v-model="qrforeground"
          dot-size="15"
          mode="rgba"
          show-swatches
          :swatches="swatches"
          swatches-max-height="113"
          elevation="1"
        ></v-color-picker>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { QRCanvas } from 'qrcanvas-vue'
export default {
  name: 'qr-code',
  components: {
    QrCanvas: QRCanvas,
  },
  data () {
    return {
      qrwidth: 512,
      qrtarget: 'https://www.google.de',
      qrforeground: '#000000',
      swatches: [
        ['#ffffff'], ['#000000'], ['#554193'], ['#ae364b'], ['#D7C548'], ['#078f6f'], ['#005F8C']
      ],
    }
  },
  computed: {
    options () {
      return {
        size: this.qrwidth,
        data: this.qrtarget,
        foreground: this.qrforeground,
        padding: 8,
      }
    },
  },
  created () {
  },
  mounted () {

  },
  methods: {

  },
  validations: {

  }
}
</script>

<style lang="stylus">
</style>
