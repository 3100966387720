<template>
  <v-row>
    <v-col cols="12">
      <filter-builder
        @data-changed="applyFilteredData"
      ></filter-builder>
    </v-col>
    <v-col cols="12">
      <filter-result-preview-table
        :persons="persons"
        :total-match-count="matchCount"
        :show-column-selection-input="false"
      />
    </v-col>
  </v-row>
</template>

<script>
import FilterBuilder from "@/modules/kirchgeld/components/FilterBuilder.vue"
import FilterResultPreviewTable from "@/modules/kirchgeld/components/FilterResultPreviewTable"

export default {
  components: {
    FilterBuilder,
    FilterResultPreviewTable
  },
  data () {
    return {
      persons: [],
      matchCount: 0
    }
  },
  methods: {
    applyFilteredData (personList, matchCount) {
      this.persons = personList
      this.matchCount = matchCount
    },
  }
}
</script>

<style lang="stylus">
.selection
  top: 5px
</style>
