<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Lastschriftdateien zum Herunterladen</h2>
        <div>
          <p>Damit die Zahlungen ausgeführt werden, müssen Sie die Lastschriftdateien bei Ihrer Bank einreichen.</p>
          <p>Befolgen Sie dafür folgende Schritte:</p>
          <p>
            <ol>
              <li>
                Download der Dateien
              </li>
              <li>
                Login im Onlinebanking
              </li>
              <li>
                Hochladen bei der Bank (Bei den Genossenschaftsbanken finden Sie die Funktion beispielsweise unter: Aufträge / Dateiverarbeitung / SEPA Datei hochladen Bei den Sparkassen finden Sie die Funktion unter Zahlungsverkehr / Sammelaufträge / Dateiübergabe)
              </li>
            </ol>
          </p>
          <p>Wenn Sie Fragen dazu haben, wenden Sie sich an Ihren Bankberater.</p>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="directPaymentPainFiles"
              item-key="url"
              sort-by="latestPaymentDate"
              sort-desc
            >
              <template #item.earliestPaymentDate="{ item }">
                {{ momentize(item.earliestPaymentDate) }}
              </template>
              <template #item.latestPaymentDate="{ item }">
                {{ momentize(item.latestPaymentDate) }}
              </template>
              <template #item.memoTexts="{ item }">
                <span v-if="item.memoTexts.length === 1">
                  {{ item.memoTexts[0] }}
                </span>
                <ul v-else>
                  <li
                    v-for="(memoText, index) in item.memoTexts"
                    :key="index"
                  >
                    {{ memoText }}
                  </li>
                </ul>
              </template>
              <template #item.processed="{ item }">
                <v-tooltip
                  v-if="item.processed"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                    >{{ mdiEmailOpenOutline }}</v-icon>
                  </template>
                  <span>Bereits heruntergeladen</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                    >{{ mdiEmailOutline }}</v-icon>
                  </template>
                  <span>Ungelesen</span>
                </v-tooltip>
              </template>
              <template #item._actions="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="download(item)"
                    >
                      <v-icon>{{ mdiDownloadOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Herunterladen</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment/moment"
import {
  mdiDownloadOutline,
  mdiEmailOutline,
  mdiEmailOpenOutline
} from '@mdi/js'
import { downloadBlob } from "@/lib/file-tools"
import { FETCH_DIRECT_PAYMENT_PAIN_FILES } from "@/store/action-types"

export default {
  name: 'EvalDirectDebits',
  data () {
    return {
      mdiDownloadOutline,
      mdiEmailOutline,
      mdiEmailOpenOutline
    }
  },
  computed: {
    ...mapGetters('organization', ['currentOrganization']),
    ...mapGetters('payment', ['directPaymentPainFilesByOrganization']),
    directPaymentPainFiles () {
      const files = this.directPaymentPainFilesByOrganization[this.currentOrganization.url] ?? []
      return files.map(file => {
        const uniqueMemoTexts = new Set()
        const paymentDates = []
        file.file_payments.forEach(payment => {
          uniqueMemoTexts.add(payment.memo_text)
          paymentDates.push(payment.created_at)
        })

        file.memoTexts = [...uniqueMemoTexts].sort()
        file.earliestPaymentDate = paymentDates.sort()[0]
        file.latestPaymentDate = paymentDates.sort().reverse()[0]
        file.filePaymentCount = file.file_payments.length

        return file
      })
    },
    headers () {
      return [
        {
          text: 'Früheste Zahlung',
          value: 'earliestPaymentDate'
        },
        {
          text: 'Letzte Zahlung',
          value: 'latestPaymentDate'
        },
        {
          text: 'Anzahl Zahlungen',
          value: 'filePaymentCount'
        },
        {
          text: 'Spendenzwecke',
          value: 'memoTexts'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Status',
          value: 'processed'
        },
        {
          text: '',
          value: '_actions'
        }
      ]
    }
  },
  methods: {
    momentize (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    async setProcessed (dppFile) {
      await this.$store.getters.restApi.patch(dppFile.url, { processed: true })
    },
    async download (dppFile) {
      const timestamp = moment(dppFile.latestPaymentDate).format('YYYY-MM-DD_HH-mm')
      const filename = `${timestamp}_Lastschriften ${this.currentOrganization.name}.xml`
      const response = await this.$store.getters.restApi.get(dppFile.url + 'download', { responseType: 'blob' })
      downloadBlob(response.data, filename, response.headers['content-type'])

      dppFile.processed = true
      await this.setProcessed(dppFile)
      await this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES)
    },
  }
}
</script>
