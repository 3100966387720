<template>
  <v-card flat>
    <v-card-title>Neuen Datensatz anlegen</v-card-title>
    <person-edit-form
      :person-data-input.sync="personData"
      @selectedPersonType="getPersonType"
      @person-data-changed="setPersonData"
    />
    <v-card-actions>
      <v-spacer/>
      <v-btn
        :disabled="!isValid"
        color="primary"
        @click="createPerson"
      >Person erstellen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SAVE_PERSON } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import { FEATURE_FLAG_KD_285_ENABLE_RELATION_MODIFICATION } from "@/feature-flags"
import PersonEditForm from "@/modules/kirchgeld/components/PersonEditForm"
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"

export default {
  name: "PersonCreate",
  mixins: [
    PersonDataMixin
  ],
  components: {
    PersonEditForm
  },
  data () {
    return {
      personData: {},
      formattedData: {},
      hasErrors: {},
      showRelationsDialog: false,
      // TODO: Remove check when backend feature is implemented
      allowRelationsModification: FEATURE_FLAG_KD_285_ENABLE_RELATION_MODIFICATION,
      selectedOrganization: null
    }
  },
  computed: {
    isValid () {
      if (!this.personData?.vornamen && this.personType === 'individual') {
        return false
      }

      if (!this.personData?.geschlecht && this.personType === 'individual') {
        return false
      }

      if (!this.personData?.familienname) {
        return false
      }
      return true
    },
  },
  methods: {
    getPersonType (value) {
      this.personType = (value)
    },
    async createPerson () {
      const personPayload = {
        type: this.personType,
        data: this.personData
      }
      const newPerson = await this.$store.dispatch('kirchgeld/' + SAVE_PERSON, personPayload)

      const newPersonData = this.getLatestValidPersonData(newPerson)
      const dataId = newPersonData?.id
      await this.$router.push(
        {
          name: 'kige-person-detail',
          params: {
            personUrl: newPerson.url,
            dataId
          }
        }
      )
    },
    setPersonData (personData) {
      this.personData = personData
    }
  }
}
</script>
