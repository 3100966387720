<template>
  <div class="random-background" :style="{ backgroundImage: 'url(' + require('@/assets/geben.digital/bg.svg') + ')' }">
    <v-container class="landing-container ">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row class="my-1">
                <v-col cols="7" sm="5" lg="3">
                  <logo dark/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="module-cards">
        <v-col cols="12" lg="8" order="2" order-lg="1">
          <v-card>
            <v-card-title>Neuigkeiten</v-card-title>
            <v-card-text>
              <div v-for="(news, index) in [newsData.newsData[0]]" :key="index">
                <h4 style="color:grey">{{ momentize(news.date) }}</h4>
                <div
                  v-for="article in news.news"
                  :key="article.id"
                  style="margin-bottom:1em"
                >
                  <v-row>
                    <div
                      v-for="(tg, index) in article.targets"
                      :key="index"
                      style="display:flex;margin:1em;margin-right: 0.5em"
                    >
                      <device-icon size="20" class="mr-2" :device-class="tg"></device-icon>
                      {{ targetMap[tg] }}
                    </div>
                  </v-row>
                  <v-card-title style="color: black">
                    {{ article.title }}
                  </v-card-title>
                  <v-card-text><div v-html="article.message"/></v-card-text>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" order="1" order-lg="2">
          <v-card>
            <login-card class="card-center" :elevation="0"/>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="module-cards">
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text>
              <iframe src="https://spende.app/o/da360dc4-a5ed-43b9-a8e1-871f928fa864" style="border:0; width:100%; height:25em;margin-left:auto;margin-right:auto;display:block"></iframe><br>
              <h4 class="my-0">spende.app</h4>
              Onlinetool zum Spendensammeln
              <p>Mit spende.app konfigurieren Sie im Handumdrehen Ihre eigene Smartphone-App. Das Tool lässt sich zudem per Link, Button oder Widget auf Websites einbinden – inklusive automatischer Abrechnung und Spendenbescheinigungen.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                href="https://digitalespende.de/spende-app/"
                target="_blank"
                depressed
                style="color: white"
                color="var(--link-color)"
              >
                mehr
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text>
              <video autoplay="" loop="" muted="" playsinline="" style="width:100%;height:25em;margin-left:auto;margin-right:auto;display:block">
                <source type='video/webm; codecs="av01.0.00M.08"' src="@/assets/geben.digital/Spendendose-Turnaround_klein-av1.webm">
                <source type='video/mp4; codecs="hev1.1.6.L63.90"' src="@/assets/geben.digital/Spendendose-Turnaround_klein-h265.mp4">
                <source type='video/mp4; codecs="avc1.64001f"' src="@/assets/geben.digital/Spendendose-Turnaround_klein-h264.mp4">
              </video>
              <br>
              <h4 class="my-0">Digitale Spendendose</h4>
              Mobiles Gerät zum Spendensammeln
              <p>Mit der Digitalen Spendendose sammeln Sie bargeldlos Spenden bis 50 €, ohne PIN-Eingabe. Die Spendendose ist wetterfest und funktioniert überall, wo es Mobilfunkempfang gibt. Individuelles Branding ist möglich.</p>
              <br>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                href="https://digitalespende.de/digitale-spendendose/"
                target="_blank"
                depressed
                style="color: white"
                color="var(--link-color)"
              >
                mehr
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text>
              <div style="height:25em;overflow:hidden;margin-left:auto;margin-right:auto;display:block">
                <img src="@/assets/geben.digital/Digitale-Spendensaeule-mit-Beschriftung.png" style="height:50em; margin-left:auto;margin-right:auto;display:block;">
              </div>
              <br>
              <h4 class="my-0">Digitale Spendensäule</h4>
              Standgerät für höhere Spendensummen
              <p>Die Digitale Spendensäule eignet sich hervorragend für Orte mit Publikumsverkehr. Ihr schlankes, schlichtes Design lädt Besucherinnen und Besucher dazu ein, elektronische Spenden von 1 bis 999 Euro zu geben.</p>
              <br>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                href="https://digitalespende.de/digitale-spendensaeule/"
                target="_blank"
                depressed
                style="color: white"
                color="var(--link-color)"
              >
                mehr
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="8">
          <v-sheet
            color="white"
            elevation="1"
            class=""
          >
            <v-hover v-slot="{ hover }">
              <v-carousel
                :cycle="!hover"
                interval="6000"
                height="700"
                progress-color="var(--link-color)"
                class="shadow"
              >
                <v-carousel-item
                  v-for="(image, i) in images"
                  :key="i"
                  align="center"
                  justify="center"
                >
                  <div
                    class="image-slider-header mb-4 pt-2"
                    align="center"
                    justify="center"
                  >
                    {{ image.text }}
                  </div>
                  <div style="height: 100%; width: 100%;" class="px-4">
                    <v-img
                      contain
                      max-height="100%"
                      max-width="100%"
                      :src="image.path"/>
                  </div>
                </v-carousel-item>
              </v-carousel>
              </v-hover><!--
            <v-switch
              v-model="cycle"
              class="ml-4"
              color="var(--link-color)"
              label="Slideshow aktiv"
              inset
            ></v-switch>-->
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text>
              <h4 class="mt-0">Spendenportal</h4>
              <p>geben.digital ist das Online-Portal zur Verwaltung von Spenden und Kollekten aus Spendenterminals von <a href="https://www.digitalespende.de/"> digitalespende.de</a>. Spendengeräte und Apps lassen sich hier bequem in derselben Spendenplattform verwalten. Dabei agieren wir nicht nur als Anbieter von Geräten und Software, sondern gleichzeitig als kaufmännischer Netzbetreiber: Digitale Spende im Komplettpaket!</p>
              <h4>Automatische Buchung und Abrechnung</h4>
              <p>Mit geben.digital können Sie nicht nur online Spenden sammeln, diese werden auch automatisch gebucht und abgerechnet – ohne Umweg, direkt auf Ihr Spendenkonto. Sie können laufende Sammlungen auswerten, Buchungen nachvollziehen und elektronische Zuwendungsbestätigungen ausstellen. Auch Reports für Ihre Buchhaltung und Dankesmails für Spenderinnen und Spender sind automatisiert.</p>
              <h4>Tools aus der Praxis</h4>
              <p>Bereits über 100 gemeinnützige und kirchliche Einrichtungen nutzen geben.digital und liefern wertvolles Feedback. Dies ermöglicht uns die laufende Weiterentwicklung der Software. Ob kirchliche Verwaltungsstrukuren, Denkmalschutz oder Datenschutzauflagen: Wir setzen Ihre Sonderwünsche um.</p>
              <p>Sie interessieren sich für geben.digital, sind aber noch kein Kunde? Fordern Sie einen <a href="https://digitalespende.de/demozugang-zu-kollekte-digital/"> Demozugang</a> an!</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Logo from '@/components/icons/Logo'
import LoginCard from '@/components/LoginCard'
import { mapState } from 'vuex'
import moment from "moment"
import DeviceIcon from "@/components/icons/DeviceIcon"
import config from '@/config'

export default {
  name: "GebenLanding",
  components: { LoginCard, Logo, DeviceIcon },
  data () {
    return {
      config,
      iconMap: {
        WA: require("@/assets/icons/device_App.svg"),
        WS: require("@/assets/icons/device_App.svg"),
        KB: require("@/assets/icons/device_Klingelbeutel.svg"),
        SP: require("@/assets/icons/device_Spendensäule.svg"),
        unknown: require("@/assets/icons/device_Klingelbeutel.svg"),
      },
      targetMap: {
        WA: 'kollekte.app',
        WS: 'spende.app',
        KB: 'Digitaler Klingelbeutel',
        SP: 'Digitale Spendensäule',
        unknown: ''
      },
      images: [
        { path: require("@/assets/geben.digital/geben.digital-monatsdetails.png"), text: 'Übersicht der Spendeneinnahmen' },
        { path: require("@/assets/geben.digital/geben.digital-collection-stats.png"), text: 'Auswertung einer Sammlung' },
        { path: require("@/assets/geben.digital/geben.digital-devices.png"), text: 'Übersicht der Geräte' },
        { path: require("@/assets/geben.digital/geben.digital-collection-planing.png"), text: 'Planung einer Sammlung' }
      ]
    }
  },
  computed: {
    ...mapState(["newsData"])
  },
  methods: {
    momentize (date) {
      return moment(date).lang("de").format('ll')
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.getElementById('app-container').style.setProperty('--link-color', '#03867B')
    })
  }
}
</script>

<style lang="stylus" scoped>
.shadow
  -moz-box-shadow: inset 0 0 10px #000000
  -webkit-box-shadow: inset 0 0 10px #000000
  box-shadow:  inset 0 0 10px #000000
.image-slider-header
  left: 0px
  top: 0px
  height: 40px
  width: 100%
  background-color: var(--link-color)
.random-background
  width: 100%
  height: 100%
  position: absolute
  overflow: auto
  top: 0
  left: 0
  background: repeat
.landing-container
  --geben-color: $clr-primary

  .card-center
    margin-left: auto
    margin-right: auto

  .module-cards
    .v-card
      height: 100%

</style>
