export const READ_CPLAN_INHERITED = "READ_CPLAN_INHERITED"
export const READ_NONSENSITIVE = "READ_NONSENSITIVE"
export const READ_CPLAN = "READ_CPLAN"
export const WRITE_CPLAN = "WRITE_CPLAN"
export const WRITE_EXTRA_FIELDS = "WRITE_EXTRA_FIELDS"
export const WRITE_WEBSITE = "WRITE_WEBSITE"
export const READ_ORG_DATA = "READ_ORG_DATA"
export const WRITE_ORG_DATA = "WRITE_ORG_DATA"
export const READ_ACCOUNTS = "READ_ACCOUNTS"
export const WRITE_ACCOUNTS = "WRITE_ACCOUNTS"
export const MANAGE_USERS = "MANAGE_USERS"
export const READ = "READ"
export const WRITE = "WRITE"
export const MEMBER = "MEMBER"
export const DISBURSEMENT_ORDER = "DISBURSEMENT_ORDER"

export const PERM_TYPE_INTERACT_GENERAL = "interactGeneral"
export const PERM_TYPE_INTERACT_COLLECTION_PLAN = "interactCollectionPlan"
export const PERM_TYPE_INTERACT_WRITE_ANY = "interactWriteAny"
export const PERM_TYPE_READ_OTHER = "readOther"

export const MINOR_RIGHTS = new Set([READ_CPLAN_INHERITED, READ_NONSENSITIVE])

export const FIELD_NAMES = {
  PLZ: 'PLZ',
  BANK: "Bank",
  IBAN: "IBAN",
  MAIL: "Mail",
  CITY: "Ort",
  STREET: "Straße",
  PHONE: "Telefon",
  ACCOUNT_HOLDER: "Kontoinhaber",
  ORGANIZATION: "Organisation",
  CONTACT_PERSON: "Kontaktperson",
  PURPOSE_OF_USE: "Verwendungszweck",
  WEBSITE: "www",
  ACCOUNTING_FIELDS: 'accounting_fields'

}

export const FIELD_PERMISSIONS = {
  [FIELD_NAMES.PLZ]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.BANK]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.IBAN]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.MAIL]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.CITY]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.STREET]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.PHONE]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.ACCOUNT_HOLDER]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.ORGANIZATION]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.CONTACT_PERSON]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.PURPOSE_OF_USE]: [WRITE_EXTRA_FIELDS],
  [FIELD_NAMES.WEBSITE]: [WRITE_WEBSITE],
  [FIELD_NAMES.ACCOUNTING_FIELDS]: [WRITE_EXTRA_FIELDS],
}
