<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            label="Zeitraum"
            v-model="selectedTimeRangeType"
            :items="availableTimeRangeTypes"
          ></v-select>
        </v-col>
        <v-col
          v-show="selectedTimeRangeType !== 'all'"
          cols="12" md="4"
        >
          <v-select
            :label="timeRangeLabel"
            :items="availableTimeRanges[selectedTimeRangeType]"
            v-model="selectedTimeRange"
            item-value="id"
            return-object
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment"

export default {
  name: 'TimeRangeSelect',
  emits: ['change'],
  props: {
    earliestDate: {
      // moment
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedTimeRangeType: 'all',
      selectedTimeRange: {
        since: this.earliestDate,
        before: new Date().toDateString()
      },
      availableTimeRangeTypes: [
        {
          text: 'Gesamt',
          value: 'all'
        },
        {
          text: 'ganzes Jahr',
          value: 'years'
        },
        {
          text: 'Halbjahr',
          value: 'semesters'
        },
        {
          text: 'Quartal',
          value: 'quarters'
        },
        {
          text: 'Monat',
          value: 'months'
        }
      ]
    }
  },
  computed: {
    timeRangeLabel () {
      return this.availableTimeRangeTypes.find(timeRangeType => timeRangeType.value === this.selectedTimeRangeType)?.text
    },
    availableTimeRanges () {
      const today = moment()

      const years = []
      const semesters = []
      const quarters = []
      const months = []

      let yearIterator = this.earliestDate.year()
      while (yearIterator <= today.year()) {
        const year = moment(yearIterator + '-01-01')
        const fromYear = year.startOf('year')

        years.push({
          text: year.year(),
          value: {
            since: this.earliestDate.isAfter(fromYear) ? this.earliestDate.toISOString() : fromYear.toISOString(),
            before: year.endOf('year').toISOString()
          },
          id: `Y${year.year()}`
        })

        for (let month = 0; month < 12; month++) {
          if ((month % 3 === 0)) {
            const fromQuarter = year.month(month).startOf('month')
            const toQuarter = fromQuarter.clone().add(2, 'months').endOf('quarter')
            if (
              this.earliestDate.isAfter(fromQuarter) &&
              this.earliestDate.year() === fromQuarter.year() &&
              this.earliestDate.quarter() === fromQuarter.quarter()
            ) {
              quarters.push({
                text: `Q${fromQuarter.quarter()} ${year.year()}`,
                value: {
                  since: this.earliestDate.toISOString(),
                  before: toQuarter.toISOString()
                },
                id: `Q${fromQuarter}_${year.year()}`
              })
            } else if (fromQuarter.isAfter(this.earliestDate) && fromQuarter.isBefore(today)) {
              quarters.push({
                text: `Q${fromQuarter.quarter()} ${year.year()}`,
                value: {
                  since: fromQuarter.toISOString(),
                  before: toQuarter.toISOString()
                },
                id: `Q${fromQuarter}_${year.year()}`
              })
            }
          }

          if ((month % 6 === 0)) {
            const fromSemester = year.month(month).startOf('month')
            const toSemester = fromSemester.clone().add(5, 'months').endOf('month')
            const semester = (fromSemester.quarter() > 2) ? 2 : 1
            if (
              this.earliestDate.isAfter(fromSemester) &&
              this.earliestDate.year() === fromSemester.year() &&
              (this.earliestDate.quarter() === fromSemester.quarter() || this.earliestDate.quarter() === toSemester.quarter())
            ) {
              semesters.push({
                text: `Halbjahr ${semester} - ${year.year()}`,
                value: {
                  since: this.earliestDate.startOf('day').toISOString(),
                  before: toSemester.toISOString()
                },
                id: `S${semester}_${year.year()}`
              })
            } else if (fromSemester.isAfter(this.earliestDate) && fromSemester.isBefore(today)) {
              semesters.push({
                text: `Halbjahr ${semester} - ${year.year()}`,
                value: {
                  since: fromSemester.toISOString(),
                  before: toSemester.toISOString()
                },
                id: `S${semester}_${year.year()}`
              })
            }
          }

          const fromMonth = year.month(month).startOf('month')
          const toMonth = fromMonth.clone().endOf('month')
          if (
            this.earliestDate.isAfter(fromMonth) &&
            this.earliestDate.year() === fromMonth.year() &&
            this.earliestDate.month() === fromMonth.month()
          ) {
            months.push({
              text: fromMonth.format('MMMM YYYY'),
              value: {
                since: this.earliestDate.toISOString(),
                before: toMonth.toISOString()
              },
              id: `M${month}_${year.year()}`
            })
          } else if (fromMonth.isAfter(this.earliestDate) && fromMonth.isBefore(today)) {
            months.push({
              text: fromMonth.format('MMMM YYYY'),
              value: {
                since: fromMonth.toISOString(),
                before: toMonth.toISOString()
              },
              id: `M${month}_${year.year()}`
            })
          }
        }
        yearIterator += 1
      }

      return {
        years,
        semesters,
        quarters,
        months
      }
    }
  },
  watch: {
    selectedTimeRangeType (newValue) {
      if (newValue === 'all') {
        this.selectedTimeRange = {
          text: 'Gesamt',
          value: {
            since: this.earliestDate.toISOString(),
            before: moment().toISOString()
          },
          id: 'all'
        }
      } else {
        this.selectedTimeRange = Object.values(this.availableTimeRanges[newValue])[0]
      }
    },
    selectedTimeRange (newTimeRange) {
      this.$emit('change', newTimeRange.value)
    }
  }
}
</script>
