const getBrowscapData = () => import(/* webpackChunkName: "browscap-data" */ './browscap-data')

export async function getGetBrowser () {
  const { BROWSCAP_DATA, BROWSCAP_ORDER } = await getBrowscapData()
  function getBrowser (userAgent) {
    for (const key of BROWSCAP_ORDER) {
      if (key.test(userAgent)) {
        let current = key
        const sectionList = [key]
        while (BROWSCAP_DATA[current]?.Parent) {
          current = BROWSCAP_DATA[current].Parent
          sectionList.push(current)
        }
        let result = {}
        for (current of sectionList.reverse()) {
          result = { ...result, ...BROWSCAP_DATA[current] }
        }
        return result
      }
    }
    return {}
  }
  return getBrowser
}
