import { SET_RECURSIVE, SET_ORGA_PANEL_STATE, CLEAR_ORGA_PANEL_STATE, SET_ALL_ORGA_PANEL_STATE, SET_ORGA_PANEL_FILTER } from '../mutation-types'

export async function toggleRecursive ({ state, dispatch }) {
  await dispatch('updateRecursive', { recursive: !state.recursive })
}

export async function updateRecursive ({ commit, dispatch, rootState, rootGetters }, { recursive }) {
  await commit(SET_RECURSIVE, { recursive })
}

export async function clearOrgaPanelState ({ commit, dispatch, rootState, rootGetters }) {
  await commit(CLEAR_ORGA_PANEL_STATE)
}

export async function setOrgaPanelState ({ commit, dispatch, rootState, rootGetters }, { orga, active }) {
  await commit(SET_ORGA_PANEL_STATE, { orga, active })
}

export async function setAllOrgaPanelState ({ commit, dispatch, rootState, rootGetters }, { allOrgas }) {
  await commit(SET_ALL_ORGA_PANEL_STATE, { allOrgas })
}

export async function setOrgaPanelFilter ({ commit, dispatch, rootState, rootGetters }, { orgafilter }) {
  await commit(SET_ORGA_PANEL_FILTER, { orgafilter })
}
