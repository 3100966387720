const DISBURSEMENT_STATE = {
  IN_PROGRESS: "in_progress",
  READY: "ready",
  ORDERED: "ordered",
  CONFIRMED: "confirmed",
  TRANSFERRED: "transferred",
  CANCELLED: "cancelled"
}

export default DISBURSEMENT_STATE
