<template>
  <v-data-table
    :headers="credentialHeaders"
    :items="credentials"
    disable-pagination
    hide-default-footer
  >
    <template #item.last_seen="{ item }">{{ item.last_seen | isoFromNow }}</template>
    <template #item.created_at="{ item }">{{ item.created_at | isoToHuman }}</template>
    <template #item.name="{ item }">
      <v-text-field
        v-model.trim="dataModel[item.url].name"
        placeholder="Unbenanntes Gerät"
        dense
        hide-details
        @change="updateName(item)"
        :loading="dataModel[item.url]._loading"
        :ref="'name/' + item.url"
      >
        <template #prepend>
          <v-icon v-if="item.data.credential_device_type === 'single_device'">{{ mdiMonitor }}</v-icon>
          <v-icon v-else-if="item.data.credential_device_type === 'multi_device'">{{ mdiMonitorMultiple }}</v-icon>
        </template>
        <template #append>
          <template v-if="dataModel[item.url]._change">
            <v-fade-transition leave-absolute>
              <v-icon :color="dataModel[item.url]._ok ? 'primary' : 'error'">{{ dataModel[item.url]._ok ? mdiCheck : mdiAlertCircle }}</v-icon>
            </v-fade-transition>
          </template>
        </template>
      </v-text-field>
    </template>
    <template #item._actions="{ item }"><v-btn small @click="unregisterCredential(item)"><v-icon>{{ mdiDeleteVariant }}</v-icon>Löschen</v-btn></template>
  </v-data-table>
</template>
<script>
import { mdiCheck, mdiAlertCircle, mdiDeleteVariant, mdiMonitor, mdiMonitorMultiple } from '@mdi/js'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { SET_CREDENTIALS } from '@/store/mutation-types'

const credentialHeaders = [
  { text: "Angelegt", value: "created_at", width: "1%", cellClass: "text-no-wrap" },
  { text: "Zuletzt benutzt", value: "last_seen", width: "1%", cellClass: "text-no-wrap" },
  { text: "", value: "_actions", width: "1%", cellClass: "text-no-wrap", sort: false },
  { text: "Name", value: "name" },
]

const CHANGE_TIMEOUT = 5000

export default {
  name: 'credential-table',
  props: {
    credentials: {
      required: true,
      type: Array,
    }
  },
  data () {
    return {
      dataModel: {},
      credentialHeaders,
      mdiDeleteVariant,
      mdiCheck,
      mdiAlertCircle,
      mdiMonitor,
      mdiMonitorMultiple,
    }
  },
  methods: {
    focus (credentialUrl) {
      const ref = 'name/' + credentialUrl
      if (this.$refs[ref]) {
        this.$refs[ref].focus()
      }
    },
    async unregisterCredential (credential) {
      await loadingStateWrapper(this.$store, async () => {
        await this.$store.getters.restApi.delete(credential.url)
        await this.$store.commit('user/' + SET_CREDENTIALS, [{ ...credential, _delete: true }])
      })
    },
    async updateName (credential) {
      await loadingStateWrapper(this.$store, async () => {
        this.dataModel[credential.url]._loading = true
        let result = null
        try {
          result = (await this.$store.getters.restApi.patch(credential.url, {
            name: this.dataModel[credential.url].name,
          }))?.data ?? null
          if (result) {
            await this.$store.commit('user/' + SET_CREDENTIALS, [result])
          }
        } finally {
          this.dataModel[credential.url]._ok = !!result
          this.dataModel[credential.url]._change = Date.now()
          this.dataModel[credential.url]._loading = false
          if (result) {
            setTimeout(this.clearChange, CHANGE_TIMEOUT + 100)
          }
        }
      })
    },
    clearChange () {
      const cutoff = Date.now() - CHANGE_TIMEOUT
      for (const item of Object.values(this.dataModel)) {
        if (item._change && item._change < cutoff) {
          item._change = null
        }
      }
    },
  },
  watch: {
    credentials: {
      immediate: true,
      handler (newVal) {
        const newDataModel = {}
        for (const credential of newVal) {
          newDataModel[credential.url] = {
            ...(this.dataModel[credential.url] ?? {}),
            name: credential.name,
          }
        }
        this.dataModel = newDataModel
      }
    }
  },
}
</script>
