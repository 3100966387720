<template>
  <v-dialog :value="value"
   max-width="600">
   <v-form v-model="validForm">
      <v-card>
        <v-container fluid>
        <v-card-title>
          Spendenbarometer {{ collection.name }}
        </v-card-title>
        <v-card-subtitle>
          Hier können Sie das Spendenbarometer für Ihre Organisation konfigurieren.   </v-card-subtitle>
        <v-card-text class="d-flex align-end ">
          <span class="mr-3 text-center">
            Was ist das Spendenziel?
          </span>
          <CurrencyInput
          class="my-0 py-0"
          style="max-width:130px"
          hide-details
          dense
            placeholder="10.000"
            v-model="form.donation_goal"
          />
        </v-card-text>
        <v-card-text>
          Auf welcher Domain möchten Sie das Spendenbarometer einsetzen? Zum Beispiel auf der Website Ihrer Organisation oder eines Projektes:
        <v-combobox
      v-model="form.allowed_origins"
      placeholder="z.B. www.meine-domain.de"
      multiple
      :rules="[
        value => {
      if (!value?.length) return true
      const domainRegex = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
      return value.every(domain => domainRegex.test(domain)) || 'Please enter valid domain names only'
    }
      ]"
    >
      <template v-slot:selection="{ item }">
        <v-chip label
        >
          <span>{{ item }}</span>
        </v-chip>

      </template>
        </v-combobox>
  </v-card-text>
    <v-card-actions>
          <v-btn color="red" text @click="confirm_delete_dialog= true"> löschen </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')"> Abbrechen </v-btn>
          <v-btn text @click="save" :disabled="!validForm"> Speichern </v-btn>
        </v-card-actions>
      </v-container>
      </v-card>
    </v-form>
      <v-dialog
      v-model="confirm_delete_dialog"
      max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this donation meter?
          </v-card-title>
          <v-card-text>if you delete the donation meter the donation page will not be accessible in future</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color=" darken-1"
              text
              @click="confirm_delete_dialog = false"
            >
            Abbrechen
            </v-btn>
            <v-btn
              color="red"
              text
              @click="deleteDonationMeter"
            >
            löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-dialog>
</template>
<script>
import CurrencyInput from '@/components/CurrencyInput'
import {
  ADD_DONATION_METER, DELETE_DONATION_METER
} from '@/store/action-types'
export default {
  emits: ['close'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    collection: {
      required: true,
    },
  },
  components: {
    CurrencyInput,
  },
  data: () => ({
    form: {
      donation_goal: 0,
      allowed_origins: [],
      secret_token: null,

    },
    validForm: false,
    confirm_delete_dialog: false,
    donationMeterDialog: false,
    selectedWhiteListDomains: [],
    whiteListDomains: [
      'https://spende.app',
      'https://digitalespende.de',
    ],
  }),
  methods: {
    async save () {
      await this.$store.dispatch('collectionPlan/' + ADD_DONATION_METER, {
        collectionId: this.collection.id,
        collectionUrl: this.collection.url,
        collectionName: this.collection.name,
        meta: {
          donation_meter: {
            ...this.form,
            allowed_origins: [...this.form.allowed_origins, ...this.whiteListDomains],
          }
        },
      })
      this.$emit('close')
    },
    async deleteDonationMeter () {
      await this.$store.dispatch('collectionPlan/' + DELETE_DONATION_METER, {
        collectionId: this.collection.id,
        collectionUrl: this.collection.url,
        collectionName: this.collection.name,
        token: this.collection?.meta?.donation_meter?.secret_token
      })
      this.form = {
        donation_goal: 0,
        allowed_origins: [],
        secret_token: null,
      }
      this.$emit('close')
    },
  },
  mounted () {
    this.form = {
      donation_goal: this.collection?.meta?.donation_meter?.donation_goal || 0,
      allowed_origins: this.collection?.meta?.donation_meter?.allowed_origins?.filter(domain => !this.whiteListDomains.includes(domain)) || [],
      secret_token: this.collection?.meta?.donation_meter?.secret_token
    }
  },

}
</script>
<style lang="">

</style>
