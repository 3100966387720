<template>
  <div style="overflow: hidden; width: 100%; height: 100%">
    <div class="random-background">
      <v-overlay
        opacity="0.6"
        z-index="0"
        color="white"
      ></v-overlay>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RandomBackground'
}
</script>

<style lang="stylus" scoped>
.random-background
  width: 100%
  height: 100%
  position: absolute
  overflow: auto
  top: 0
  left: 0
  background: url( 'https://api.kollekte.digital/rest/public/v2/content/image/random/bg_kollektedigital/?width=1000&redirect') no-repeat center center
  background-size: cover
  background-color: white
</style>
