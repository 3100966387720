<template>
  <random-background><!-- FIXME Background on geben.digital -->
    <v-card
      class="d-flex align-content-center justify-center flex-wrap"
      style="height: calc(100vh - 31px); background-color: unset; overflow: auto;"
      elevation="0"
    >
      <login-card>
        <template v-slot:logo><logo dark class="ma-5 mb-2"/></template>
        <template v-slot:more>
          <div class="mt-3 pt-2" style="border-top: 1px solid #eeeeee">
            Mehr Informationen auf <a href="https://digitalespende.de">digitalespende.de</a>
            <br>
            <v-icon>mdi-arrow-right</v-icon>
            <a href="https://digitalespende.de/demozugang-zu-kollekte-digitale/">Demozugang</a>
          </div>
        </template>
      </login-card>
    </v-card>
  </random-background>
</template>
<script>
import RandomBackground from '@/components/RandomBackground'
import { CLEAR_DATA, SET_OAUTH_STATE } from '@/store/mutation-types'
import LoginCard from '@/components/LoginCard'
import Logo from '@/components/icons/Logo'
import { AUTHORIZE_OAUTH } from '@/store/action-types'

export default {
  name: 'login',
  components: { RandomBackground, LoginCard, Logo },
  async mounted () {
    await this.$nextTick()
    if (this.$route?.meta?.oauthMode) {
      await this.$store.commit('user/' + SET_OAUTH_STATE, { ...this.$route?.query })
      if (this.$store.getters['user/userAuthToken']) {
        await this.$store.dispatch('user/' + AUTHORIZE_OAUTH)
      }
    } else {
      await this.$store.dispatch(CLEAR_DATA)
    }
  },
}
</script>

<style lang="stylus" scoped>
</style>
