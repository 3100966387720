<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Admin Mails</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="12"
        :md="6"
      >
        <v-select
          v-model="selectedTypes"
          :items="contactTypeItems"
          item-value="key"
          item-text="label"
          label="Typ"
          multiple
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col
        :cols="12"
        :md="6"
        justify="end"
      >
        <v-row>
          <v-checkbox
            v-model="showDataTable"
            label="Details anzeigen"
            class="mx-2"
          />
          <v-checkbox
            v-model="includeInactiveContacts"
            label="Inaktive Kontakte anzeigen"
            class="mx-2"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="showDataTable">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="selectedContacts"
          class="elevation-1"
        >
          <template #item.mappedTypes="{ item }">
            <v-chip
              @click="toggleType(type.key)"
              v-for="type in item.mappedTypes"
              x-small
              :outlined="!selectedTypes.includes(type.key)"
              :key="type.key"
              color="primary"
              dark
              class="ma-1"
            >
              {{ type.label }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h5>E-Mail-Adressen für Auswahl</h5>
          </v-card-title>
          <v-card-text>
            {{ selectedEmails }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="copyContacts"
            >
              <v-icon left>{{ mdiContentCopy }}</v-icon>
              E-Mail-Adressen kopieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {
  FETCH_CONTACTS,
  FETCH_GLOBAL_DEVICES,
  FETCH_ORGANIZATIONS,
  FETCH_STATIC_CONFIGURATION
} from "@/store/action-types"
import { mapGetters, mapState } from "vuex"
import { mdiContentCopy } from "@mdi/js"
import { SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"

export default {
  name: 'AdminMails',
  data () {
    return {
      mdiContentCopy,
      selectedTypes: [],
      showDataTable: false,
      includeInactiveContacts: false,
    }
  },
  computed: {
    ...mapState('staticConfiguration', ['enumsByName']),
    ...mapGetters('admin', ['contacts']),
    ...mapGetters('organization', ['allOrganizations']),
    ...mapGetters('contracts', ['contractsByOrganizationAndDevices']),
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Organisation',
          value: 'organizationName'
        },
        {
          text: 'Typ',
          value: 'mappedTypes'
        },
      ]
    },
    availableContacts () {
      if (!this.contacts || !Object.keys(this.allOrganizations).length || !Object.keys(this.contractsByOrganizationAndDevices).length) {
        return []
      }

      const contacts = this.contacts.filter(contact => {
        if (this.includeInactiveContacts) {
          return true
        }
        return this.contractsByOrganizationAndDevices[contact.organization]?.hasValidContract
      })

      return contacts.map(contact => {
        return {
          ...contact,
          organizationName: this.allOrganizations[contact.organization].name,
          mappedTypes: [...new Set(contact.types.map(type => {
            return this.contactTypeItems.find(availableType => availableType.values.includes(type))
          }))],
        }
      })
    },
    selectedContacts () {
      if (!this.availableContacts.length) {
        return []
      }

      if (!this.selectedTypes.length) {
        return this.availableContacts
      }

      return this.availableContacts.filter(contact => {
        return contact.mappedTypes.some(mappedType => {
          return this.selectedTypes.includes(mappedType.key)
        })
      })
    },
    selectedEmails () {
      return this.selectedContacts
        .map(contact => contact.email)
        .filter(email => !!email)
        .join('; ')
    },
    contactTypeItems () {
      const items = []
      for (const [value, label] of Object.entries(this.enumsByName.ContactType ?? {})) {
        if (['booking_report_available', 'invoice_available'].includes(value)) {
          continue
        }
        items.push({
          key: value,
          label,
          values: [value]
        })
      }

      items.unshift({
        key: 'finance',
        label: "Rechnungs- und/oder Buchungsbericht",
        values: ["invoice_available", "booking_report_available"]
      })
      return items
    },
  },
  methods: {
    toggleType (type) {
      if (this.selectedTypes.includes(type)) {
        this.selectedTypes = this.selectedTypes.filter(selectedType => selectedType !== type)
      } else {
        this.selectedTypes.push(type)
      }
    },
    copyContacts () {
      navigator.clipboard.writeText(this.selectedEmails)
      this.$store.commit(SET_SNACKBAR_MESSAGE, {
        message: 'E-Mail-Adressen kopiert',
      })
    }
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
      this.$store.dispatch('admin/' + FETCH_CONTACTS),
      this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES)
    ])
  },
}
</script>
