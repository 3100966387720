<template>
  <mqtt-connection
    :devices="devices"
    :extendedDevicesMqttIds="extendedDevicesMqttIds"
    :showWifiDeviceMqttId="showWifiDeviceMqttId"
    v-slot="{ mqttState, mqttVpnState, mqttConnected, wifiList }"
    @disconnected="showWifiDeviceMqttId=false"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <template>
            <v-sheet
              elevation="0"
              outlined
              max-width="100%"
            >
              <v-slide-group
                v-model="devicefilter"
                mandatory
                center-active
                show-arrows
              >
                <v-spacer></v-spacer>
                <v-slide-item
                  v-for="(displayName, deviceClass) in devicesChooser"
                  :key="deviceClass"
                  v-slot="{ active, toggle }"
                  :value="deviceClass"
                  class="ma-3"
                >
                  <v-btn
                    color="primary"
                    @click="toggle"
                    text
                    tile
                    :style="active ? 'border-bottom: 2px solid' : '2px solid transparent'"
                  >{{ displayName }}
                  </v-btn>
                </v-slide-item>
                <v-spacer></v-spacer>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-col>
        <v-col cols="12" v-if="devices && devices.length" id="contentActive">
          <pulse-loader :size="10" color="var(--clr-environment, #554193)" id="mqtt-loading" :loading="!mqttConnected"></pulse-loader>
          <h3>Aktive Spendengeräte<span v-if="activeDevices.length"> (zeige {{ activeDevicesToShow.length }} von {{ activeDevices.length }})</span></h3>
          <v-switch
            class="mt-2 mr-3 pt-0 d-inline-block"
            v-model="showWidgets"
            label="Live-Vorschau für Apps aktivieren"
          ></v-switch>

          <v-switch
            v-if="hasChildOrganizations"
            class="mt-0 ml-3 pt-0 d-inline-block"
            v-model="recursive"
          >
            <template #label>
              <v-icon
                dense
                class="mr-1"
              >
                {{ mdiFileTree }}
              </v-icon>
              Unterorganisationen einschließen
            </template>
          </v-switch>
          <template v-if="!activeDevicesToShow.length">
            <p>Keine Geräte vorhanden</p>
            <p v-if="devicefilter.includes('webApps')">Wenn Ihre kollekte.app oder spende.app in dieser Liste nicht
              auftauchen, gehen Sie bitte auf
              <router-link :to="{name: 'management.org.details', params: {id: organization.id}}">Organisation
              </router-link>
              und dann links im Menü auf das gewünschte Produkt.
            </p>
          </template>
          <v-row>
            <Device
              v-for="device in activeDevicesToShow"
              :key="device.url"
              :is-active="true"
              :device="device"
              :mqtt-state="mqttState[device.mqtt_identity]"
              :mqtt-vpn-state="mqttVpnState[device.bcmserno]"
              :show-widgets="showWidgets"
              :current-organization="organization"
              @show-wifi-list="showWifiDeviceMqttId=device.mqtt_identity"
              @expand="extendedDevicesMqttIds=new Set([...Array.from(extendedDevicesMqttIds || []), device.mqtt_identity])"
              @unexpand="extendedDevicesMqttIds.delete(device.mqtt_identity)"
            >
            </Device>
          </v-row>
          <device-wifi-list :wifi-list="wifiList" :is-open="!!showWifiDeviceMqttId" @close="showWifiDeviceMqttId = null"></device-wifi-list>
        </v-col>
        <v-col cols="12" v-else-if="devices && devices.length === 0 && !loading">
          <p>
            Aktuell sind keine aktiven Spendengeräte vorhanden.
            Bitte kontaktieren Sie uns, wenn Sie Fragen oder Probleme haben!
          </p>
          <p>
            <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a>
          </p>
        </v-col>
        <v-col v-else>
          <v-layout justify-center>
            <v-progress-circular color="primary" indeterminate :size="100"></v-progress-circular>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </mqtt-connection>
</template>
<script>
import { PulseLoader } from '@saeris/vue-spinners'
import Device from '../../components/device/Device'
import { mapGetters, mapState } from 'vuex'
import {
  FETCH_DEVICES,
  FETCH_GLOBAL_DEVICES,
  FETCH_LOCATIONS,
  FETCH_ORGANIZATIONS,
  FETCH_USER_DATA, UPDATE_RECURSIVE
} from '@/store/action-types'
import MqttConnection from '@/components/device/MqttConnection'
import { DEVICE_CLASSES, DEVICE_TYPES } from '@/lib/device-db'
import DeviceWifiList from '@/components/device/DeviceWifiList'
import { mdiFileTree } from '@mdi/js'
import { getLatestVersion } from "@/lib/sort-utils"

export default {
  name: 'organization-devices',
  components: {
    DeviceWifiList,
    Device,
    PulseLoader,
    MqttConnection
  },
  data () {
    return {
      extendedDevicesMqttIds: new Set(),
      showWifiDeviceMqttId: null,
      devicefilter: [],
      showWidgets: false,
      mdiFileTree
    }
  },
  filters: {
    deviceLocation: function (device) {
      if (device.current_location__name) {
        return '(' + (device.current_location__organization__name ? (device.current_location__organization__name + ' / ') : '') +
          device.current_location__name + ')'
      }
    }
  },
  computed: {
    ...mapState(['now']),
    ...mapGetters('organization', {
      allOrganizations: 'allOrganizations',
      organization: 'currentOrganization',
      organizationRelevantDevicesRawByUrl: 'organizationRelevantDevicesRawByUrl',
      orgtree: 'orgtree',
      orgParents: 'parents',
    }),
    ...mapState('location', ['locations']),
    ...mapGetters('location', ['locationsLookup']),
    ...mapState(['loading']),
    relevantDeviceTypes () {
      if (!this.organizationRelevantDevicesRawByUrl) {
        return new Set()
      }
      return this.organizationRelevantDevicesRawByUrl[this.organization.url]
    },
    devices () {
      const devicesFromStore = this.$store.state.device.devicesByOrganization[this.organization?.url] ?? null
      if (!devicesFromStore) {
        return []
      }
      const baseFakeDevice = {
        active: true,
        bcmserno: null,
        collection_member_state: null,
        current_location: null,
        mqtt_identity: null,
        sticky_location: false,
        url: null
      };
      /* Inject fake devices for devices that are present in a parent but can be configured here */
      [{ deviceType: 'john', overrideFlag: 'enableWA' }, { deviceType: 'bart', overrideFlag: 'enableWS' }].forEach(
        injectionDevice => {
          if (
            this.relevantDeviceTypes.has(injectionDevice.deviceType) &&
            !devicesFromStore.some(item => item.type === injectionDevice.deviceType) &&
            this.effectiveSettings?.override?.[injectionDevice.overrideFlag]
          ) {
            devicesFromStore.unshift({
              ...baseFakeDevice,
              device_class: DEVICE_TYPES[injectionDevice.deviceType].deviceClass,
              id: `TEMP_ID_FOR_${injectionDevice.deviceType}`,
              name: DEVICE_CLASSES[DEVICE_TYPES[injectionDevice.deviceType].deviceClass],
              type: injectionDevice.deviceType,
            })
          }
        }
      )
      return devicesFromStore
    },
    devicesChooser () {
      // Return a mapping between a chooser key and display name, used for filtering devices
      const result = { all: 'Alle Geräte' }
      if (!this.devices) {
        return result
      }
      if (this.devices.some(device => DEVICE_TYPES[device.type]?.webApp)) {
        result.webApps = "Web Apps"
      }
      this.devices.forEach(device => {
        // Do *not* list web app type devices individually
        if (!DEVICE_TYPES[device.type]?.webApp) {
          // Get name from device-db
          result[device.device_class] = DEVICE_CLASSES[device.device_class]
        }
      })
      return result
    },
    filteredDevices () {
      if (!this.devices) {
        return []
      }
      if (this.devicefilter.includes('all')) {
        return this.devices
      }
      return this.devices.filter(device => {
        const isWebAppActive = this.devicefilter.includes(device.device_class) || (this.devicefilter.includes('webApps') && DEVICE_TYPES[device.type]?.webApp)

        if (this.recursive) {
          return isWebAppActive
        }

        const ownerOrgUrl = this.getOwnerOrganization(device)?.url ?? null
        return isWebAppActive && (ownerOrgUrl === this.organization.url || (this.orgParents?.[this.organization.url] ?? []).includes(ownerOrgUrl) || device.url === null)
      })
    },
    activeDevicesToShow () {
      return this.activeDevices.filter(device => {
        if (this.recursive) {
          return true
        }

        const ownerOrgUrl = this.getOwnerOrganization(device)?.url ?? null

        // If the owner cannot be determined, it is likely that the current organization owns the device or it is injected on the spot.
        // In this case, we want to show the device.
        if (!ownerOrgUrl) {
          return true
        }
        return ownerOrgUrl === this.organization.url || (this.orgParents?.[this.organization.url] ?? []).includes(ownerOrgUrl) || device.url === null
      })
    },
    activeDevices () {
      if (!this.filteredDevices) return []
      return this.filteredDevices.filter((device) => device?.active).filter(device => {
        /*
          FIXME There was this line, not sure what it does/did?
        if (tmp[deviceId].current_location === null ||
          this.allOrganizations[this.locationsLookup[tmp[deviceId].current_location].organization].id === this.organization.id
        ) {
         */
        return true
      })
    },
    inactiveDevices () {
      if (!this.filteredDevices) return []
      return this.devices.filter((device) => !(device?.active ?? false))
    },
    recursive: {
      get () {
        return this.$store.state.userPrefs.recursive
      },
      async set (value) {
        await this.$store.dispatch("userPrefs/" + UPDATE_RECURSIVE, { recursive: value })
      }
    },
    hasChildOrganizations () {
      return this.orgtree[this.organization.url]?.length > 0
    },
    effectiveSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.organization.url)
    }
  },
  methods: {
    getOwnerOrganization (device) {
      const latestValidContract = getLatestVersion(device?.contracts ?? []) ?? null

      if (latestValidContract) {
        return this.allOrganizations[latestValidContract?.contract?.organization ?? null] ?? null
      }

      // If no owner by contract could be determined, try to determine the holder
      // eslint-disable-next-line camelcase
      if (!device?.current_location) {
        return null
      }

      // eslint-disable-next-line camelcase
      return this.allOrganizations[this.locationsLookup[device?.current_location ?? null]?.organization ?? null] ?? null
    },
  },
  mounted () {
    this.$nextTick(async () => {
      await Promise.allSettled([
        await this.$store.dispatch('user/' + FETCH_USER_DATA),
        await this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        await this.$store.dispatch('location/' + FETCH_LOCATIONS),
        await this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES)
      ])
    })
  },
  watch: {
    organization: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.url) {
          await this.$store.dispatch('device/' + FETCH_DEVICES, newVal?.url)
        }
      }
    }
  },
}
</script>

<style lang="stylus">
h3
  color: $clr-secondary-text-light

#mqtt-loading
  position: absolute
  top: 40px
  right: 50px

.wifiListSpinner
  height: 150px
  flex: 0 0 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
</style>
