<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Neue Zuwendungsbestätigungen erstellen</v-card-title>
          <v-card-text>
            <v-form v-model="createForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selector.organizations"
                      :items="organizationsHumanName"
                      item-text="name"
                      item-value="url"
                      placeholder="Für Organisation"
                      multiple
                      clearable
                      chips
                      :rules="[!!selector.organizations.length || 'Verpflichtend']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="d-flex align-baseline">
                    Von: <v-text-field type="date" v-model="selector.date_from" :rules="[!!selector.date_from || 'Verpflichtend']" class="ml-1" style="max-width: 9em" hint="(einschliesslich)" persistent-hint/>
                  </v-col>
                  <v-col cols="3" class="d-flex align-baseline">
                    Bis: <v-text-field type="date" v-model="selector.date_before" :rules="[!!selector.date_before || 'Verpflichtend']" class="ml-1" style="max-width: 9em" hint="(ausschliesslich)" persistent-hint/>
                  </v-col>
                  <v-col cols="2" class="d-flex align-baseline">
                    Ab:
                    <v-text-field
                      type="currency"
                      v-model="selector.sum_min_amount"
                      clearable
                      placeholder="Mindestsumme"
                      hint="(optional)"
                      persistent-hint
                      class="ml-1"
                      :append-icon="mdiCurrencyEur"
                      :rules="[!!(this.selector.sum_min_amount ?? '').match(/^[0-9]*$/) || 'Nur ganze Zahlenwerte erlaubt']"
                    />
                  </v-col>
                  <v-col cols="2" offset="2">
                    <v-btn :disabled="!createForm" color="primary" @click="activeSelector = selector" class="mt-4">Erstellen</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>Letzte Vorgänge</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="actionHistoryEntries"
              hide-default-footer
              disable-pagination
              sort-by="created_at"
              :sort-desc="true"
            >
              <template #item.created_at="{ item }">
                {{ item.created_at | isoToHuman("LLL") }}
              </template>
              <template #item.user="{ item }">
                {{ item.user?.display_name ?? "--" }}
              </template>
              <template #item.numberFiles="{ item }">
                <span v-if="item.summary.type === 'single'">Einzelne Zuwendungsbestätigung</span>
                <span v-else>
                  <span v-if="item.summary.organizations"> {{ item.summary.organizations }}, </span>
                  <span v-if="item.data_a.parameters.date_from">ab {{ item.data_a.parameters.date_from | isoToHuman('L') }} </span>
                  <span v-if="item.data_a.parameters.date_before">bis {{ item.data_a.parameters.date_before | isoToHuman('L') }}, </span>
                  <span v-if="item.data_a.parameters.min_amount">ab mindestens {{ item.data_a.parameters.min_amount | amount }} €</span>
                  <span v-else-if="item.data_a.parameters.sum_min_amount">ab mindestens {{ item.data_a.parameters.sum_min_amount | amount }} € Gesamtsumme</span>

                </span>
                ({{ (item.data_a?.donation_receipts ?? item.data_a?.receipts ?? []).length }})
              </template>
              <!-- FIXME add description based on parameters -->
              <template #item._actions="{ item }">
                <v-tooltip bottom v-if="(item.data_a?.donation_receipts ?? item.data_a?.receipts ?? []).length > 0">
                  <template #activator="{ on, attrs }">
                    <v-btn fab x-small color="primary" @click="download(item)"><v-icon
                      v-on="on"
                      v-bind="attrs"
                    >{{ (item.data_a?.donation_receipts ?? item.data_a?.receipts ?? []).length > 1 ? mdiFolderZip : mdiFileDownload }}</v-icon></v-btn>
                  </template>
                  Download
                </v-tooltip>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <base-dialog
      :is-open="!!activeSelector"
       @close="activeSelector = null"
    >
      <template #dialog-title>Zuwendungsbestätigung erstellen</template>
      <template #dialog-content>
        <create-donation-receipt
          v-if="activeSelector"
          :selector="activeSelector"
          :zip-name="now.format('YYYY-MM-DD_HH-mm-ss') + '_Zuwendungsbestätigungen.zip'"
          @done="activeSelector=null; fetchRecentActions()"
        />
      </template>
    </base-dialog>
  </v-container>
</template>

<script>

import { GET_ORGANIZATIONS, GET_TENANTS } from '@/modules/kirchgeld/store/kirchgeld/action-types'
import { mapGetters, mapState } from 'vuex'
import BaseDialog from '@/components/UI/BaseDialog.vue'
import CreateDonationReceipt from '@/modules/kirchgeld/components/CreateDonationReceipt.vue'
import { mdiCurrencyEur, mdiFolderZip, mdiFileDownload } from '@mdi/js'
import { downloadBlob } from '@/lib/file-tools'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import moment from 'moment'
import { shortenParish } from "@/lib/regex-tools"

export default {
  name: 'Certificates',
  components: { CreateDonationReceipt, BaseDialog },
  data () {
    return {
      headers: [
        {
          text: 'Zeitpunkt',
          value: 'created_at'
        },
        {
          text: "Benutzer",
          value: "user"
        },
        /* {
          text: 'Kriterien',
          value: 'criteria'
        }, */
        {
          text: "# Zuwendungsbestätigungen",
          value: "numberFiles"
        },
        {
          text: "",
          value: "_actions",
        },
      ],
      items: [],
      selector: {
        organizations: [],
        date_from: null,
        date_before: null,
        sum_min_amount: null,
      },
      createForm: false,
      activeSelector: null,
      mdiCurrencyEur,
      mdiFileDownload,
      mdiFolderZip,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['organizationsHumanName']),
    ...mapState(['now']),
    actionHistoryEntries () {
      if (!this.items) {
        return []
      }
      return this.items.map(item => {
        // eslint-disable-next-line camelcase
        const isBulkOperation = Object.entries(item.data_a?.parameters ?? {}).some(([key, val]) => {
          if (key === 'payments') {
            return false
          }
          return val !== null
        })
        item.summary = {}
        if (isBulkOperation) {
          // The current action was a bulk operation that generated multiple donation receipts for given parameters
          // eslint-disable-next-line camelcase
          item.summary.organizations = item.data_a.parameters.organizations.map(orgEntry => {
            const organization = this.organizationsHumanName.find(org => org.id === orgEntry._ref.pk)
            return shortenParish(organization.name)
          }).join(", ")
          item.summary.type = 'bulk'
        } else {
          // Else it just was a single payment which a donatoin receipt was generated for
          item.summary.type = 'single'
        }

        return item
      })
    }
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_TENANTS)
    await this.$store.dispatch('kirchgeld/' + GET_ORGANIZATIONS)
    await this.fetchRecentActions()
  },
  methods: {
    async fetchRecentActions () {
      this.items = (await this.$store.getters['kirchgeld/api'].get(`${this.$store.state.kirchgeld.tenant.url}audit/?ordering=-created_at&include_action=DonationReceiptAPIAction.GENERATED`))?.data?.results ?? []
    },
    async download (auditItem) {
      // eslint-disable-next-line camelcase
      const urls = (auditItem.data_a?.donation_receipts ?? auditItem.data_a?.receipts ?? []).map(item => `${this.$store.state.kirchgeld.tenant.url}donation_receipt/${item._ref.pk}/`)

      if (urls.length > 1) {
        const response = await this.$store.getters['kirchgeld/api'].post(`${this.$store.state.kirchgeld.tenant.url}donation_receipt/multi_download/`, { donation_receipts: urls }, { responseType: 'blob' })
        downloadBlob(response.data, `${moment(auditItem.created_at).format('YYYY-MM-DD_HH-mm')}_Zuwendungsbestätigungen.zip`, "application/zip")
      } else if (urls.length === 1) {
        const url = urls[0]
        const informationResponse = await loadingStateWrapper(this.$store, async () => {
          return (await this.$store.getters['kirchgeld/api'].get(url)).data
        })
        const downloadResponse = await loadingStateWrapper(this.$store, async () => {
          return await this.$store.getters['kirchgeld/api'].get(`${url}download/`, { responseType: 'blob' })
        })
        downloadBlob(downloadResponse.data, informationResponse.file_name, informationResponse.mime_type)
      }
    }
  }
}
</script>

<style
  lang="stylus"
  scoped
>
.archived
  color: #BDBDBD
</style>
