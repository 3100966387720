export const SET_TENANT = 'setTenants'
export const SET_STATIC_CONFIGURATION = 'setEnums'
export const SET_FILTERS = 'setFilters'
export const SET_AUTH_TOKEN = 'setAuthToken'
export const SET_LETTERS = 'setLetters'
export const SET_ORGANIZATIONS = 'setOrganizations'
export const SET_EDITABLE_PERSONS = 'setEditablePersons'
export const SET_BANK_ACCOUNTS = 'setBankAccounts'
export const SET_BANK_STATEMENTS = 'setBankStatements'
export const SET_SPECIAL_PURPOSES = 'setSpecialPurposes'
