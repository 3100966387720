<template>
  <v-icon :color="color" v-bind="$attrs">{{ iconPath }}</v-icon>
</template>

<script>
import { DEVICE_ICONS } from '@/lib/device-db'

export default {
  name: 'DeviceIcon',
  props: {
    deviceClass: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    }
  },
  computed: {
    iconPath () {
      return DEVICE_ICONS[this.deviceClass] ?? DEVICE_ICONS.KB
    }
  }
}
</script>

<style scoped>

</style>
