import { INC_LOADING, DEC_LOADING, SET_ERROR_MESSAGE } from '@/modules/common/store/mutation-types'
import { ADD_COLLECTION_PLAN_EXTENSION, DELETE_COLLECTION_PLAN_EXTENSION, PATCH_COLLECTION_PLAN_EXTENSION } from './action-types'

export default {
  namespaced: true,
  state: function () {
    return {
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    [ADD_COLLECTION_PLAN_EXTENSION]: async ({ state, commit, rootGetters }, data) => {
      // await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.post('collection_plan_extension/', data)
        if (response.status === 201 && response.data?.id) {
          return true
        } else {
          await commit(SET_ERROR_MESSAGE, { message: `Speichern nicht erfolgreich. Bitte versuchen Sie es erneut.` }, { root: true })
          return false
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        // FIXME Error handling
        // this.error = error.detail
      } finally {
        // await commit(DEC_LOADING, null, { root: true })
      }
    },
    [PATCH_COLLECTION_PLAN_EXTENSION]: async ({ commit, rootGetters }, { id, data }) => {
      // await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.patch('collection_plan_extension/' + id + '/', data)
        if (response.status === 200 && response.data?.id) {
          return true
        } else {
          await commit(SET_ERROR_MESSAGE, { message: `Speichern nicht erfolgreich. Bitte versuchen Sie es erneut.` }, { root: true })
          return false
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        // FIXME Error handling
        // this.error = error.detail
      } finally {
        // await commit(DEC_LOADING, null, { root: true })
      }
    },
    [DELETE_COLLECTION_PLAN_EXTENSION]: async ({ commit, rootGetters }, collectionPlanExtensionId) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.delete('collection_plan_extension/' + collectionPlanExtensionId + '/')
        if (response.status === 204) {
          return true
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    }
  }
}
