<template>
  <v-list-group :value="true" append-icon="" color="text">
    <template #activator>
      <slot name="activator"></slot>
    </template>
    <v-list-item
      v-for="[name, icon, text, params] of links"
      :key="name + (params ? Object.values(params).join(',') : '')"
      :to="{name: name, id: currentOrganization.id, params}"
    >
      <v-list-item-icon><v-icon v-if="icon">{{ icon }}</v-icon></v-list-item-icon>
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrganizationNavListGroup',
  components: {
  },
  props: {
    links: {
      type: Array,
    }
  },
  computed: {
    ...mapGetters('organization', ['currentOrganization']),
  },
}
</script>

<style scoped>

</style>
