<template>
  <v-form ref="changeForm" :value="isFormValid" @submit.prevent="changePassword">
    <v-card-text
      class="mb-0 pb-0"
      id="auth-container"
    >
      <v-alert
        v-if="error"
        dense
        outlined
        type="error"
        icon="mdi mdi-alert-circle"
        color="red"
      >
        <span v-if="error.length">{{ error }}</span>
        <span v-else>Die Passwortänderung wurde vom Server abgelehnt.</span>
      </v-alert>
      <password-input
        ref="passwordInput"
        @input="setPasswordValidity"></password-input>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="showCancelOption"
        @click="cancel"
        elevation="2"
      >
        abbrechen
      </v-btn>
      <v-btn
        @click="changePassword"
        :disabled="!isFormValid"
        color="primary"
        elevation="2"
      ><!--:loading="waiting"-->
        speichern
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import PasswordInput from "@/components/PasswordInput"
import rest from "@/lib/rest"
export default {
  name: "PasswordForm",
  components: {
    PasswordInput
  },
  props: {
    showCancelOption: {
      type: Boolean,
      required: false,
      default: true
    },
    passwordResetAuthToken: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [
    'cancel-password-change',
    'password-change-succeeded',
    'password-refused-by-policy'
  ],
  data () {
    return {
      error: null,
      password: null,
      isFormValid: false
    }
  },
  computed: {
    shouldPerformPasswordReset () {
      return this.passwordResetAuthToken !== null && this.passwordResetAuthToken.length > 0
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel-password-change')
    },
    setPasswordValidity (password, isPasswordValid) {
      this.password = password
      this.isFormValid = isPasswordValid
    },
    async changePassword () {
      try {
        let response = {}

        if (this.shouldPerformPasswordReset) {
          response = await rest.fetch(
            'user/change_password/',
            'POST',
            {
              password: this.password,
              token: this.passwordResetAuthToken
            }, {
              headers: { Authorization: null }
            })
        } else {
          response = await rest.fetch(
            'user/change_password/',
            'POST',
            {
              password: this.password
            }
          )
        }

        if (response.valid) {
          this.$emit('password-change-succeeded')
        } else {
          // FIXME Pass through server error if there is one
          if (response.includes('New password disallowed by policy')) {
            this.$emit('password-refused-by-policy')
          } else {
            this.error = 'Die Änderung das Passworts wurde vom Server abgelehnt. Möglicherweise ist das neue Passwort nicht zulässig.'
          }
        }
        this.$refs.passwordInput.resetInputs()
      } catch (e) {
        this.error = 'Die Änderung ist leider fehlgeschlagen. Bitte versuchen Sie es später noch einmal.'
        this.$refs.passwordInput.resetInputs()
      }
    }
  }
}
</script>

<style scoped>

</style>
