<template>
  <transition name="flip"><!-- FIXME Figure out how to make this work -->
    <v-card
      id="login"
      class="px-6 py-2"
      :outlined="elevation > 0"
      :elevation="elevation"
      max-width="450"
      min-width="400"
      z-index="9999"
      v-if="mode==='login'"
      key="login"
    >
      <slot name="logo"/>
      <v-alert
        v-if="errorMsg"
        dense
        outlined
        type="error"
        icon="mdi mdi-alert-circle"
        color="red"
      >
        <span v-if="errorMsg.length">{{ errorMsg }}</span>
        <span v-else>Die eingegebenen Anmeldedaten sind fehlerhaft.</span>
      </v-alert>

      <h4>Zugang</h4>
      <v-form ref="loginForm" v-model="loginFormValid" @submit.prevent="login">
        <v-text-field id="input-normal" v-model="username" :rules="[rules.required, rules.minLength2]"
                      :autocomplete="useConditionalUi ? 'username webauthn' : 'username'"
                      label="Nutzername" @change="fetchAuthInformation"/>
        <v-text-field id="input-password" v-model="password" :rules="[rules.required]" autocomplete="current-password"
                      label="Passwort" name="password" type="password"
                      v-on:keyup.enter.native="(!otpRequired) && login()"/>
        <v-expand-transition>
          <v-text-field v-if="otpRequired" id="input-otp" v-model="otp" autocomplete="one-time-code"
                        label="Einmalcode" :rules="[otpRequiredCheck]"
                        name="otp" type="otp" v-on:keyup.enter.native="login"/>
        </v-expand-transition>
        <v-container fluid>
          <v-row>
            <v-col cols="5" order="2">
              <v-btn @click="login" :loading="waiting" block :disabled="!loginFormValid" color="primary">
                Anmelden
              </v-btn>
            </v-col>
            <v-col cols="7" order="1">
              <v-btn @click="mode = 'reset'" text small class="mt-1">Passwort vergessen?</v-btn>
            </v-col>
          </v-row>
          <template v-if="showPasskeyButton">
            <v-row align="center">
              <v-divider/><span class="px-1">oder</span><v-divider/>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn class="grow" color="primary" @click="webauthnLogin"><v-icon>{{PasskeyLogo}}</v-icon> Passwortlose Anmeldung</v-btn>
              </v-col>
              <v-col cols="1">
                <v-dialog max-width="60em">
                  <v-card>
                    <v-card-title><h3>Passwortlose Anmeldung &mdash; Was ist das?</h3></v-card-title>
                    <v-card-text>
                      <p>Auf manchen Computern können Sie Sich komfortabel und sicher ohne Passwort anmelden.
                        Sie müssen diese Funktion zunächst in ihrem Benutzerprofil registrieren.</p>
                    </v-card-text>
                  </v-card>
                  <template #activator="{on, attrs}">
                    <v-btn icon v-bind="attrs" v-on="on"><v-icon>{{ mdiHelp }}</v-icon></v-btn>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
      <slot name="more"/>
    </v-card>
    <v-card
      id="reset"
      class="px-6 py-2"
      :outlined="elevation > 0"
      :elevation="elevation"
      max-width="450"
      min-width="400"
      z-index="9999"
      v-if="mode==='reset'"
      key="reset"
    >
      <slot name="logo"/>
      <h4>Passwort zurücksetzen</h4>
      <div>Hier können Sie Ihr Passwort zurücksetzen. Geben Sie hierfür Ihre E-Mail-Adresse ein, und falls diese im
        System bekannt ist, erhalten Sie eine E-Mail mit weiteren Schritten.
      </div>

      <v-form ref="resetForm" v-model="resetFormValid" @submit.prevent="reset">
        <v-text-field id="input-email" v-model="email" :rules="[rules.email]" autocomplete="email" label="E-Mail"
                      name="email"
                      v-on:keyup.enter.native="reset"/>
        <v-btn @click="reset" :loading="waiting" class="mt-1" block color="primary" :disabled="!resetFormValid">
          Passwort zurücksetzen
        </v-btn>
      </v-form>
      <v-btn @click="mode = 'login'" text small style="margin-top: 1em">Zurück zum Login</v-btn>
    </v-card>
  </transition>

</template>

<script>
import rest from 'lib/rest'
import { mapState } from 'vuex'
import { browserSupportsWebAuthn, browserSupportsWebAuthnAutofill, startAuthentication } from '@simplewebauthn/browser'
import { mdiHelp } from '@mdi/js'
import { PasskeyLogo } from '@/lib/icons'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { FINISH_LOGIN, LOGIN } from '@/store/action-types'
import { ENABLE_WEBAUTHN } from '@/feature-flags'

export default {
  name: 'LoginCard',
  props: {
    elevation: {
      default: 8,
      type: Number
    }
  },
  data () {
    return {
      username: null,
      password: null,
      email: null,
      otp: null,
      authInformation: null,
      mode: 'login',
      errorText: null,
      loginFormValid: false,
      resetFormValid: false,
      opportunisticAuth: null,
      hasConditionalUi: false,
      conditionalUiChecked: false,
      rules: {
        required: value => !!value || 'Eingabe erforderlich.',
        minLength2: value => (value?.length ?? 0) >= 2 || 'Eingabe erforderlich.',
        email: value => (value ?? '').match(/^[^@]+@[^@]{2,}/) || 'Gültige E-Mail-Adresse eingeben.',
      },
      mdiHelp,
      PasskeyLogo,
      ENABLE_WEBAUTHN,
    }
  },
  computed: {
    ...mapState(['loading']),
    otpRequired () {
      // eslint-disable-next-line camelcase
      return this.authInformation?.has_otp ?? false
    },
    flipped () {
      return this.mode === 'reset'
    },
    error () {
      return this.$store.state.user.auth.error
    },
    errorMsg () {
      return this.$store?.state?.user?.auth?.error ?? this.errorText ?? null
    },
    waiting () {
      return this.loading > 0
    },
    showPasskeyButton () {
      return ENABLE_WEBAUTHN && browserSupportsWebAuthn() && !this.hasConditionalUi && this.conditionalUiChecked
    },
    useConditionalUi () {
      return ENABLE_WEBAUTHN && browserSupportsWebAuthn() && this.hasConditionalUi && this.conditionalUiChecked
    }
  },
  async created () {
    this.hasConditionalUi = ENABLE_WEBAUTHN && browserSupportsWebAuthn() && (await browserSupportsWebAuthnAutofill())
    this.conditionalUiChecked = true
  },
  async beforeDestroy () {
    if (this.opportunisticAuth?.url) {
      const abortUrl = this.opportunisticAuth?.url + 'abandon/'
      this.opportunisticAuth = null
      try {
        await fetch(abortUrl, { method: "POST" })
      } catch (e) {
        // Swallow exception
      }
    }
  },
  methods: {
    async doOpportunisticWebauth () {
      if (this.opportunisticAuth?.options) {
        const authenticationResponse = await startAuthentication(this.opportunisticAuth.options, true)
        const data = await loadingStateWrapper(this.$store, async () => {
          return (await this.$store.getters.restApi.post(this.opportunisticAuth.url + "complete/", authenticationResponse))?.data ?? null
        })

        await this.$store.dispatch('user/' + FINISH_LOGIN, { data })
      }
    },
    otpRequiredCheck (value) {
      return ((!this?.otpRequired ?? false) || (value?.length ?? 0) >= 6) || 'Mindestens 6 Zeichen.'
    },
    async login () {
      if (!this.$refs.loginForm.validate()) {
        this.errorText = 'Bitte geben Sie einen Nutzernamen und ein Passwort ein.'
        return
      }
      this.errorText = null
      await this.$store.dispatch('user/' + LOGIN, {
        username: this.username,
        password: this.password,
        otp: this.otpRequired ? this.otp : undefined
      })
      this.otp = null
      if (this.$refs.loginForm) {
        this.$refs.loginForm.resetValidation()
      }
    },
    async reset () {
      if (!this.$refs.resetForm.validate()) {
        this.errorText = 'Bitte geben Sie eine E-Mail-Adresse ein.'
        return
      }
      this.errorText = null
      try {
        await rest.fetch('user/reset_password/', 'POST', { email: this.email })
      } catch (e) {
        /* Ignore */
      }
      this.errorText = 'Bitte prüfen Sie Ihre E-Mails.'
      this.mode = 'login'
      this.email = null
      this.$refs.resetForm.reset()
    },
    async fetchAuthInformation () {
      if (!this.username) {
        this.authInformation = null
      } else {
        this.authInformation = await rest.fetch('user/auth_information/', 'POST', { user: this.username })
      }
    },
    async webauthnLogin () {
      const authentication = await loadingStateWrapper(this.$store, async () => {
        return (await this.$store.getters.restApi.post('auth/authentication/'))?.data ?? null
      })
      // FIXME Error handling. Catch and display error
      if (authentication?.options) {
        const authenticationResponse = await startAuthentication(authentication.options)
        const data = await loadingStateWrapper(this.$store, async () => {
          return (await this.$store.getters.restApi.post(authentication.url + "complete/", authenticationResponse))?.data ?? null
        })

        await this.$store.dispatch('user/' + FINISH_LOGIN, { data })
      }
    },
  },
  watch: {
    useConditionalUi: {
      immediate: true,
      async handler (newVal, oldVal) {
        if (newVal && !oldVal) {
          this.opportunisticAuth = (await this.$store.getters.restApi.post('auth/authentication/?opportunistic=true'))?.data ?? null
          const task = this.doOpportunisticWebauth()
          setTimeout(async () => await task, 1)
        }
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
#login, #reset
  text-align: center

/*
.flip-enter-active
  transition: all 0.4s ease

.flip-leave-active
  display: none

.flip-enter, .flip-leave
  transform: rotateY(180deg)
  opacity: 0
*/

</style>
