<template>
  <v-card
    outlined
    v-if="person"
    class="mx-auto"
  >
    <v-card-title class="d-flex align-center justify-center" >
      <v-row class="justify-end">
      <v-col cols="4" class="align-center">
        <h4>
          <person-name :person-data="currentPersonData"></person-name>
        </h4>
        <router-link
          v-if="person.is_editable"
          :to="{ name: 'kige-person-edit', params: { url: personUrl }}"
        >
          <v-btn icon color="grey">
            <v-icon>{{mdiPencil}}</v-icon>
          </v-btn>
        </router-link>
      </v-col>
      <v-col class="d-flex align-center" cols="4">
        <v-card class="mx-auto d-flex align-center" v-if="isInactive">
          <v-icon class="mr-2" size="80">{{ mdiAccountOff }}</v-icon>
          <v-card-text color="primary"  class="text-center font-weight-medium text-lg-2 text-md-center text-sm-left inactive"><span style="color: #ae364b"><strong>INAKTIV</strong></span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        <template v-if="personData.length > 1">
          <div>
            <v-btn
              v-if="effectiveDataIndex > 0"
              icon
              color="grey"
              @click="setDataIndex(effectiveDataIndex-1)"
            >
              <v-icon>{{ mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-btn disabled icon v-else><v-icon>&nbsp;</v-icon></v-btn>
          </div>
          <div class="text-overline">
            Version
            {{ effectiveDataIndex+1 }}/{{ personData.length }}
          </div>
          <div>
            <v-btn
              v-if="effectiveDataIndex < personData.length - 1"
              @click="setDataIndex(effectiveDataIndex+1)"
              icon
              color="grey"
            >
              <v-icon>{{ mdiChevronRight }}</v-icon>
            </v-btn>
            <v-btn disabled icon v-else><v-icon>&nbsp;</v-icon></v-btn>
          </div>
        </template>
        <template v-else>
          <div class="text-overline">
            Version
            {{ effectiveDataIndex+1 }}/{{ personData.length }}
          </div>
        </template>
      </v-col>
        <v-col class="d-flex justify-end" cols="3">
          <v-btn
            v-if="person.is_editable && !isInactive"
            color="primary"
            @click="setPersonInactive"
            class="justify-start"
            text
          >Person inaktiv setzen</v-btn>
        <v-spacer></v-spacer>
        </v-col>
      </v-row>
    </v-card-title>
    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="propertyGroupView in propertyGroupViews"
            :key="propertyGroupView.group"
            cols="12"
            md="6"
            lg="4"
          >
            <v-item>
              <component
                v-if="propertyGroupView.component"
                :is="propertyGroupView.component"
                :person="person"
                :person-data="currentPersonData"
                :property-group="propertyGroupView.group"
                :title="propertyGroupView.title"
              ></component>
              <person-detail-property-group
                v-else
                :person="person"
                :person-data="currentPersonData"
                :property-group="propertyGroupView.group"
                :title="propertyGroupView.title"
              />
            </v-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <person-detail-events
              :person-url="personUrl"
              :payments="payments"
              :letters="letters"
              :current-person-data="currentPersonData"
              @payment-was-created="onPaymentCreate"
              @refresh-payments="onPaymentCreate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </v-card>
</template>

<script>
import PersonDetailPropertyGroup from '../components/PersonDetailPropertyGroup.vue'
import PersonName from '../components/PersonName'
import PersonAddressView from '../components/PersonAddressView'
import { GET_PERSON_LETTERS, GET_PERSON_PAYMENTS, UPDATE_PERSON } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import amount from "@/filters/amount"
import PersonDetailEvents from "@/modules/kirchgeld/components/PersonDetailEvents"
import moment from "moment"
import { mdiAccountOff, mdiChevronLeft, mdiChevronRight, mdiPencil } from "@mdi/js"
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PersonDataDetail',
  emits: [
    'data-id-changed',
  ],
  props: {
    personUrl: {
      type: String,
      required: true
    },
    dataId: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  filters: {
    amount
  },
  components: {
    PersonDetailPropertyGroup,
    PersonName,
    PersonAddressView,
    PersonDetailEvents
  },
  data () {
    return {
      person: null,
      payments: [],
      letters: [],
      propertyGroupViews: [
        {
          group: 'address',
          title: 'Adresse',
          component: PersonAddressView,
        },
        {
          group: 'personal',
          title: 'Persönliche Details'
        },
        {
          group: 'related',
          title: 'Verwandte Datensätze'
        },
        {
          group: 'other',
          title: 'Weitere Felder'
        },
        {
          group: 'general',
          title: 'Allgemein'
        },
        {
          group: 'remark',
          title: 'Bemerkungen'
        }
      ],
      currentIndex: null,
      mdiAccountOff,
      mdiChevronLeft,
      mdiChevronRight,
      mdiPencil,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['api']),
    ...mapState(['now']),
    personData () {
      // Return sorted list of person data
      if (!this?.person?.data) {
        return []
      }
      const retval = Array.from(this.person.data)
      retval.sort((a, b) => (
        // eslint-disable-next-line camelcase,multiline-ternary
        !a?.valid_from ? -1 : (
          // eslint-disable-next-line camelcase
          !b?.valid_from
            ? 1
            : (
                a.valid_from.localeCompare(b.valid_from)
              )
        )
      ))

      return retval
    },
    effectiveDataIndex () {
      // Default to last entry, can be overriden by currentIndex
      if (this.currentIndex === null) {
        return this.personData.length - 1
      }
      // In theory, we could check for personData[].valid_from with regards to now, but we won't do that for simplicity
      return this.currentIndex
    },
    currentPersonData () {
      // Return currently display person data
      if (!this.personData.length || this.effectiveDataIndex < 0 || (this.effectiveDataIndex + 1 > this.personData.length)) {
        return {}
      }
      return {
        ...this.personData[this.effectiveDataIndex],
        person_id: this.person.id,
      }
    },

    title () {
      if (!this.currentPersonData) {
        return ''
      }

      if (this.person.type === 'company') {
        return this.currentPersonData.familienname
      }

      return `${this.currentPersonData.rufname} ${this.currentPersonData.familienname}`
    },
    isInactive () {
      if (!this.currentPersonData) {
        return false
      }
      // FIXME Should this be "person inactive" or "currently displayed data inactive"?
      return moment(this.currentPersonData.valid_before).isBefore(this.now)
    },
  },
  methods: {
    async onPaymentCreate () {
      this.payments = await this.$store.dispatch('kirchgeld/' + GET_PERSON_PAYMENTS, this.person.id)
    },
    async setPersonInactive () {
      // FIXME Should this set person inactive or currently displayed data?
      const currentTime = moment().toISOString()
      const payload = { valid_before: currentTime }
      this.person = (await this.$store.dispatch('kirchgeld/' + UPDATE_PERSON,
        {
          url: this.personUrl,
          data: { data: payload, target: this.currentPersonData.id }
        })
      ).data
    },
    setDataIndex (newIndex) {
      if (newIndex >= 0 && newIndex < this.personData.length) {
        this.$emit("data-id-changed", this.personData[newIndex].id)
      }
      // If dataId is provided, outer component is responsible for changing dataId prop which
      // will trigger dataId watcher
      if (!this.dataId) {
        // Otherwise just set currentIndex directly
        this.currentIndex = newIndex
      }
    }
  },
  watch: {
    personUrl: {
      immediate: true,
      async handler (newValue) {
        if (this?.person?.url !== newValue) {
          // Clear index when changing person
          this.currentIndex = null
        }
        const personResponse = await this.$store.getters['kirchgeld/api'].get(newValue)
        this.person = personResponse.data
        const [personLettersResponse, personPaymentsResponse] = await Promise.allSettled([
          this.$store.dispatch('kirchgeld/' + GET_PERSON_LETTERS, this.person.id),
          this.$store.dispatch('kirchgeld/' + GET_PERSON_PAYMENTS, this.person.id)
        ])
        this.letters = personLettersResponse.value
        this.payments = personPaymentsResponse.value
      }
    },
    dataId: {
      immediate: true,
      handler (newValue) {
        if (newValue && this?.personData?.length) {
          for (const [i, personData] of this.personData.entries()) {
            if (personData.id === newValue) {
              this.currentIndex = i
            }
          }
        }
      }
    },
    personData: {
      immediate: true,
      handler (newValue) {
        if (newValue?.length && this.dataId) {
          for (const [i, personData] of newValue.entries()) {
            if (personData.id === this.dataId) {
              this.currentIndex = i
            }
          }
        }
      }
    },
  }
}
</script>
<style scoped lang="stylus">
.inactive
  font-size 1.5rem
  @media (min-width: 1264px)
    font-size  4.5rem
</style>
