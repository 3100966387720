<template>
  <v-card
    v-if="hasRelevantData"
    class="pa-2"
    outlined
  >
    <h6
      v-if="title"
      class="pt-0"
    >{{ title }}</h6>

    <div
      v-for="property in relevantProperties"
      :key="property.value"
    >
      <template v-if="personData[property.value] || showEmptyFields">
        <div class="text-caption text-grey mt-2">
          {{ property.text }}
        </div>
        <div v-if="property.format === 'date' && property.value === 'geburtsdatum' && personData[property.value]">
          {{ personData[property.value] | isoToHuman('DD.MM.YYYY') }} ({{ getAge(personData[property.value])}})
        </div>
        <div v-else-if="property.format === 'date' && personData[property.value]">
          {{ personData[property.value] | isoToHuman('DD.MM.YYYY') }}
        </div>
        <div v-else-if="property.format === 'enumSet' && personData[property.value] && enumsByName[property.choices]">
          <ul>
            <li
              v-for="(enumKey, enumIndex) in personData[property.value]"
              :key="enumIndex"
            >
              {{ enumKey }}: {{ enumsByName[property.choices].values[enumKey] }}
            </li>
          </ul>
        </div>
        <div v-else-if="property.format === 'enum' && personData[property.value] && enumsByName[property.choices]">
          {{ enumsByName[property.choices].values[personData[property.value]] }}
        </div>
        <div v-else-if="property.value === 'relations' && (personData[property.value].length || reverseRelations.length)">
          <ul>
            <li v-for="relation in personData[property.value]" :key="relation.id">
              <template v-if="relation.type === 'ehe'">&#9901; Verheiratet mit:</template>
              <template v-else-if="relation.type === 'part_of'"><v-icon small>mdi-domain</v-icon> Teil von:</template>
              <router-link :to="{name: 'kige-person-detail', params: { personUrl: relation.side_b.url }}" v-if="relation && relation.side_b && relation.side_b.current_data">
                <person-name :person-data="relation.side_b.current_data"></person-name>
                <template v-if="relation.side_b.current_data.geburtsdatum">
                  (* {{ relation.side_b.current_data.geburtsdatum | isoToHuman('DD.MM.YYYY') }})
                </template>
              </router-link>
            </li>
            <li v-for="relation in reverseRelations" :key="relation.id">
              <template v-if="relation.type === 'ehe'">&#9901; Verheiratet mit:</template>
              <template v-else-if="relation.type === 'part_of'"><v-icon small>mdi-domain</v-icon> Zugehörig:</template>
              <router-link :to="{name: 'kige-person-detail', params: { personUrl: relation.side_a.person }}" v-if="relation && relation.side_a">
                {{ relation.side_a.vornamen }} {{ relation.side_a.familienname }}
                <template v-if="relation.side_a.geburtsdatum">
                  (* {{ relation.side_a.geburtsdatum | isoToHuman('DD.MM.YYYY') }})
                </template>
              </router-link>

            </li>
          </ul>
        </div>
        <div v-else-if="property.value === 'source' && personData[property.value]">
          <span v-if="dataImportsById[personData[property.value]]">MEWIS-Import {{ dataImportsById[personData[property.value]].created_at | isoToHuman("LLL")}}</span>
          <span v-else>MEWIS-Import</span>
        </div>
        <div v-else-if="property.value === 'kro_nr' && personData[property.value]">
          {{ personData[property.value] }}
          <span v-if="activeOrganization.name">({{ activeOrganization.name | shortenParish }})</span>
          <span v-if="activeOrganization.navision_client_id">[{{ activeOrganization.navision_client_id }}]</span>
        </div>
        <div v-else-if="personData[property.value]">
          {{ personData[property.value] }}
        </div>
        <div v-else>
          --
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import { personProperties, VISIBLE_DETAIL } from "@/modules/kirchgeld/config/person.properties"
import moment from "moment"
import { mapGetters } from "vuex"
import PersonName from './PersonName'
import { shortenParish } from "@/lib/regex-tools"
import { loadingStateWrapper } from '@/modules/common/store/tools'

export default {
  components: {
    PersonName,
  },
  filters: {
    shortenParish,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    propertyGroup: {
      type: String,
      required: true
    },
    person: {
      type: Object,
      required: true
    },
    personData: {
      type: Object,
      required: true
    },
    showEmptyFields: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data () {
    return {
      // FIXME This belongs in the store
      dataImportsById: {},
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['enumsByName', 'organizations']),
    relevantProperties () {
      return personProperties.filter(property => property.group === this.propertyGroup && property.flags.includes(VISIBLE_DETAIL))
    },
    hasRelevantData () {
      if (this.propertyGroup === 'related') {
        return !!this.personData.relations?.length || !!this.reverseRelations.length
      }
      return !!this.relevantProperties
        .map(property => this.personData[property.value] ?? null)
        .filter(property => property !== null)
        .filter(property => property?.length !== 0)
        .length
    },
    activeOrganization () {
      const org = this.organizations.find(org => org.kro_nr === this.personData.kro_nr) ?? null

      return {
        ...org
      }
    },
    reverseRelations () {
      const now = moment()
      return this.person.reverse_relations?.filter(relation => {
        const validFrom = moment(relation.side_a.valid_from)
        const validBefore = moment(relation.side_a.valid_before)
        return (!relation.side_a.valid_from || now.isSameOrAfter(validFrom)) &&
          (!relation.side_a.valid_before || now.isSameOrBefore(validBefore))
      }) ?? []
    },
    FIXMEdataImportUrl () {
      for (const prop of this.relevantProperties) {
        if (prop.value === 'source' && this.personData[prop.value]) {
          if (!this.dataImportsById[this.personData[prop.value]]) {
            return `${this.$store.state.kirchgeld.tenant.url}data_import/${this.personData[prop.value]}/`
          }
        }
      }
      return null
    }
  },
  watch: {
    FIXMEdataImportUrl: {
      immediate: true,
      async handler (newVal) {
        if (newVal) {
          const importData = await loadingStateWrapper(this.$store, async () => {
            const response = await this.$store.getters['kirchgeld/api'].get(newVal)
            return response?.data ?? null
          })
          if (importData) {
            this.$set(this.dataImportsById, importData.id, importData)
          }
        }
      }
    }
  },
  methods: {
    getAge (date) {
      const now = moment()
      const mDate = moment(date)

      return now.diff(mDate, 'years')
    },
    extractPersonId (url) {
      // This is a hack, sorry
      const match = url.match(/.*\/person\/([^/]+)\/?$/)
      return match[1]
    },
    getOrganizationByKroNr (kroNr) {
      return this.organizations.find(org => org.kro_nr === kroNr) ?? ''
    }
  }
}
</script>
