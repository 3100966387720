import { getLatestVersion } from "@/lib/sort-utils"

export default {
  methods: {
    getLatestValidPersonData: (person) => {
      const personDataVersions = person.data

      return getLatestVersion(personDataVersions)
    }
  }
}
