export const GET_TENANTS = 'getTenants'
export const GET_STATIC_CONFIGURATION = 'getEnums'
export const GET_FILTERS = 'getFilters'
export const GET_FILTER_BY_ID = 'getFilterById'
export const GET_FILTER_PREVIEW = 'getFilterPreview'
export const GET_FILTER_PREVIEW_WILDCARD = 'getFilterPreviewWithWildcard'
export const GET_PERSON_BY_ID = 'getPersonById'
export const GET_PERSONS_BY_IDS = 'getPersonsByIds'
export const GET_PERSON_LETTERS = 'getPersonLetters'
export const GET_PERSON_PAYMENTS = 'getPersonPayments'
export const GET_IMPORT_HISTORY = 'getImportHistory'
export const GET_ORGANIZATIONS = 'getOrganizations'
export const GET_EDITABLE_PERSONS = 'getEditablePersons'
export const GET_BANK_ACCOUNTS = 'getBankAccounts'
export const GET_BANK_STATEMENTS = 'getBankStatements'
export const GET_SPECIAL_PURPOSES = 'getSpecialPurposes'

export const SAVE_FILTER = 'saveFilter'
export const GET_LETTERS = 'getLetters'
export const GET_LETTER = 'getLetter'
export const GET_LETTER_FROM_AUDIT = 'getLetterFromAudit'
export const SAVE_LETTER = 'saveLetter'
export const RENDER_CSV = 'renderCsv'
export const IMPORT_DATA = 'importData'
export const OAUTH_LOGIN = 'oauthLogin'
export const SAVE_PERSON = 'savePerson'
export const UPDATE_PERSON = 'updatePerson'
export const SAVE_PAYMENT = 'savePayment'
export const DELETE_PAYMENT = 'deletePayment'

export const CANCEL_DONATION_RECEIPT = 'cancelDonationReceipt'

export const LOGOUT = 'logout'
