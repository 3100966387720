<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
  extends: Line,
  // mixins: [mixins.chartData],
  props: {
    stats: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  computed: {
    data: function () {
      return {
        currency: this.currency,
        datasets: [
          { name: 'Hauptkollekte', type: 'collection', variant: 'inner', color: 'rgba(85, 65, 147, 1)' },
          { name: 'Nebenkollekte', type: 'collection', variant: 'outer', color: 'rgba(187,177,221, 1)' },
          { name: 'Freie Kollekte', type: 'collection', variant: 'x', color: 'rgba(215,198,73, 1)' },
          { name: 'Spende', type: 'donation', variant: null, color: 'rgba(7,143,111, 1)' },
        ].map(item => {
          return {
            label: item.name,
            data: this.extractData(this.stats, item.type, item.variant),
            borderColor: item.color,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: item.color,
            lineTension: 0.01,
            currency: this.currency
          }
        }).filter(item => item.data.length)
      }
    }
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return tooltipItem.yLabel.toLocaleString("de-DE", {
                style: "currency",
                currency: this.currency
              })
            }
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              tooltipFormat: 'D. MMM YYYY',
              offset: true,
              stepSize: 1,
              minUnit: 'month',
              unit: 'month',
              displayFormats: {
                month: 'MMM YYYY'
              }
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Monate'
            },
            ticks: {
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000'
              }
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Einnahmen'
            },
            ticks: {
              callback: (value) => {
                return value + ' ' + this.$options.filters.currencySymbol(this.currency)
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    extractData: (stats, collectionType, collectionVariant = null) => {
      return stats.filter(
        x => (x.collection_type === collectionType && (collectionVariant === null || x.collection_variant === collectionVariant))
      ).map(
        x => ({ x: moment(x.date), y: x.amount })
      ).sort((a, b) => a.x.diff(b.x))
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  watch: {
    stats: function (val) {
      this.renderChart(this.data, this.options)
    }
  }
}

</script>
