import { mdiSetAll, mdiSetCenter, mdiSetLeft } from '@mdi/js'

export const FORMAT_TEXT = 't'
export const FORMAT_ENUM = 'e'
export const FORMAT_DATE = 'd'
export const FORMAT_KRO = 'k'

export const filterLookups = [
  {
    value: 'gt',
    text: 'ist größer als',
    suitable: '',
  },
  {
    value: 'gte',
    text: 'ist am oder nach',
    suitable: FORMAT_DATE,
  },
  {
    value: 'lte',
    text: 'ist gleich oder kleiner als',
    suitable: '',
  },
  {
    value: 'lt',
    text: 'ist vor',
    suitable: FORMAT_DATE,
  },
  {
    value: 'iexact',
    text: 'ist genau',
    suitable: FORMAT_TEXT + FORMAT_KRO,
  },
  {
    value: 'exact',
    text: 'ist',
    suitable: FORMAT_ENUM,
  },
  {
    value: 'icontains',
    text: 'enthält',
    suitable: FORMAT_TEXT,
  },
  {
    value: 'istartswith',
    text: 'beginnt mit',
    suitable: FORMAT_TEXT,
  },
  {
    value: 'iendswith',
    text: 'endet mit',
    suitable: FORMAT_TEXT,
  }
]

export const availableOperators = [
  {
    value: 'and',
    text: 'und',
    icon: mdiSetCenter,
  },
  {
    value: 'or',
    text: 'oder',
    icon: mdiSetAll,
  },
]

export const notOperator = {
  value: 'not',
  text: 'nicht',
  icon: mdiSetLeft,
}
