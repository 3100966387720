<template>

  <v-card
    outlined
    class="pa-3"
  >
    <v-container>
      <v-row style="min-width: 60em">
        <v-select
          v-model="selectedProperty"
          :items="availableProperties"
          :label="(selectedProperty && selectedProperty === 'ref') ? '' : 'Feld auswählen ...'"
          dense
          style="max-width: 14em"
        >
        </v-select>

        <v-icon>
          {{ mdiChevronRight }}
        </v-icon>

        <template v-if="selectedProperty === 'ref'">
          <v-autocomplete
            v-model="selectedFilterId"
            :items="filters"
            label="Zielgruppe auswählen ..."
            item-text="name"
            item-value="id"
            dense
            style="max-width: 20em;"
          ></v-autocomplete>
        </template>
        <template v-else>
        <v-select
          :disabled="!selectedProperty"
          v-model="selectedFilterLookup"
          :items="availableFilterLookups"
          label="Filter auswählen ..."
          dense
          style="max-width: 10em"
        >
        </v-select>

        <v-icon>
          {{ mdiChevronRight }}
        </v-icon>

        <div>
          <v-menu
            v-if="selectedFilterProperty && selectedFilterProperty.format === 'date'"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterInputDateFormatted"
                placeholder="Datum auswählen"
                readonly
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterInput"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-else-if="selectedFilterProperty && selectedFilterProperty.format === 'enum'"
            v-model="filterInput"
            :label="filterInputLabel"
            :items="availableFilterInputItems"
            dense
          ></v-select>
          <v-autocomplete
            v-else-if="selectedProperty === 'kro_nr'"
            v-model="filterInput"
            :items="availableOrganizations"
            dense
          ></v-autocomplete>
          <v-text-field
            v-else
            :disabled="!selectedFilterLookup && !selectedProperty"
            v-model.trim="filterInput"
            placeholder="Wert eingeben ..."
            dense
            style="min-width: 15em; max-width: 25em"
          >

          </v-text-field>
        </div>
        </template>

        <v-tooltip
          bottom
          v-if="isDeletionAllowed"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              @click="requestFilterDeletion"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ mdiMinusCircleOutline }}</v-icon>
            </v-btn>
          </template>
          <span>
            Filter entfernen
          </span>
        </v-tooltip>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { personProperties, SEARCH_DETAIL } from "@/modules/kirchgeld/config/person.properties"
import {
  filterLookups,
  availableOperators,
  FORMAT_ENUM,
  FORMAT_TEXT,
  FORMAT_DATE,
  FORMAT_KRO
} from "@/modules/kirchgeld/config/filter-lookups"
import { mdiChevronRight, mdiMinusCircleOutline } from "@mdi/js"
import { mapGetters } from 'vuex'
import { sortAlphaNumeric } from "@/lib/sort-utils"
import { shortenParish } from "@/lib/regex-tools"
import { GET_FILTERS } from '../store/kirchgeld/action-types'

export default {
  name: 'FilterCriteriaInput',
  emits: ['delete-filter'],
  props: {
    filterId: {
      type: [String, Number],
      required: true
    },
    isDeletionAllowed: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data () {
    return {
      selectedProperty: null,
      selectedFilterLookup: null,
      filterInput: null,
      lastFilterPropertyFormat: null,
      selectedFilterId: null,
      personProperties,
      filterLookups,
      availableOperators,
      mdiChevronRight,
      mdiMinusCircleOutline
    }
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_FILTERS)
  },
  computed: {
    ...mapGetters('kirchgeld', ['enums', 'organizations', 'filters']),
    availableProperties () {
      return [
        {
          text: "Existierende Zielgruppe",
          value: "ref",
        },
        ...this.personProperties.filter(item => item.flags.includes(SEARCH_DETAIL))
          .map(item => {
            if (item.text_alt) {
              return {
                ...item,
                text: `${item.text} / ${item.text_alt}`
              }
            }

            return item
          })]
    },
    availableOrganizations () {
      return sortAlphaNumeric(this.organizations, 'name')
        .map(org => {
          const parishShort = shortenParish(org.name)
          return {
            value: org.kro_nr,
            text: `${org.kro_nr} (${parishShort})`
          }
        })
    },
    selectedFilterProperty () {
      return this.personProperties.find(item => item.value === this.selectedProperty)
    },
    availableFilterLookups () {
      if (!this.selectedFilterProperty) {
        return []
      }
      if (this.selectedProperty === 'kro_nr') {
        return this.filterLookups.filter(item => item.suitable.includes(FORMAT_KRO))
      }
      if (this.selectedFilterProperty?.format === 'enum') {
        return this.filterLookups.filter(item => item.suitable.includes(FORMAT_ENUM))
      } else if (this.selectedFilterProperty?.format === 'date') {
        return this.filterLookups.filter(item => item.suitable.includes(FORMAT_DATE))
      }
      return this.filterLookups.filter(item => item.suitable.includes(FORMAT_TEXT))
    },
    filterInputDateFormatted () {
      if (this.selectedFilterProperty?.format !== 'date') {
        return ""
      }
      return new Date(this.filterInput).toLocaleDateString('de-DE')
    },
    selectedFilterInputEnum () {
      if (this.selectedFilterProperty?.format !== 'enum') {
        return {}
      }
      return (this.enums ?? []).find(item => item.name === this.selectedFilterProperty?.choices)
    },
    filterInputLabel () {
      return `${this.selectedFilterInputEnum?.name} wählen`
    },
    availableFilterInputItems () {
      return Object.entries(this.selectedFilterInputEnum?.values ?? {}).map(([name, description]) => ({
        value: name,
        text: description,
      }))
    }
  },
  methods: {
    getFilter () {
      if (this.selectedProperty === 'ref') {
        return {
          ref: this.selectedFilterId
        }
      } else {
        return {
          field: this.selectedProperty,
          [this.selectedFilterLookup]: this.filterInput
        }
      }
    },
    requestFilterDeletion () {
      this.$emit('delete-filter', this.filterId)
    },
    clearInput () {
      this.selectedProperty = null
      this.selectedFilterLookup = null
      this.filterInput = null
      this.selectedFilterId = null
    }
  },
  watch: {
    selectedProperty: {
      immediate: true,
      async handler (value) {
        await this.$nextTick()
        if (!this.selectedFilterProperty || !value) {
          return
        }
        if (this.selectedFilterProperty?.format === 'date') {
          const x = new Date(this.filterInput)
          if (isNaN(x)) {
            this.filterInput = null
          }
        }
        if (!this.selectedFilterLookup || this.lastFilterPropertyFormat !== this.selectedFilterProperty?.format) {
          if (this.selectedFilterProperty?.format === 'date') {
            this.selectedFilterLookup = 'gte'
          } else if (this.selectedFilterProperty?.format === 'enum') {
            this.selectedFilterLookup = 'exact'
          } else {
            this.selectedFilterLookup = 'istartswith'
          }
          this.lastFilterPropertyFormat = this.selectedFilterProperty?.format
        }
      }
    }
  }
}
</script>
