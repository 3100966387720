<template>
  <section>
    <add-cash-collection-dialog
      v-if="isReady"
      :value="true"
      :anonymous-organization-name="organizationName"
      :anonymous-collection-name="collectionName"
      @save-cash-collection="saveCashPayment"
    ></add-cash-collection-dialog>
    <base-dialog
      :is-open="showSuccessDialog"
      @close="showSuccessDialog = false"
    >
      <template #dialog-title>
        Barsammlung erfolgreich eingetragen
      </template>
      <template #dialog-text>
        {{ successMessage }}
      </template>
    </base-dialog>
  </section>
</template>

<script>
import AddCashCollectionDialog from "@/components/collection/AddCashCollectionDialog.vue"
import { SET_ERROR_MESSAGE } from "@/modules/common/store/mutation-types"
import BaseDialog from "@/components/UI/BaseDialog.vue"

export default {
  name: "CashPaymentSubmission",
  components: { BaseDialog, AddCashCollectionDialog },
  data () {
    return {
      organizationName: null,
      collectionName: null,
      successMessage: null,
      showSuccessDialog: false
    }
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    isReady () {
      return !!this.organizationName && !!this.collectionName
    },

  },
  async mounted () {
    const res = await this.$store.getters.restApi.get(`collection_plan/cash_payment/${this.token}/`)
    this.organizationName = res.data.organization_name
    this.collectionName = res.data.name
    // TODO - FIXME - Add currency
  },
  methods: {
    async saveCashPayment ({ amount }) {
      try {
        const response = await this.$store.getters.restApi.post(`collection_plan/cash_payment/${this.token}/`, {
          amount,
          currency: 'EUR'
        })
        const createdAt = new Date(response.data.created_at)
        const messageAmount = this.$options.filters.currency(amount * 100)
        this.successMessage = `Sie haben erfolgreich eine Barsammlung in der Höhe von ${messageAmount} am ${createdAt.toLocaleString()} eingetragen. Vielen Dank`
        this.showSuccessDialog = true
        // await this.$store.commit(
        //   SET_SNACKBAR_MESSAGE,
        //   { message },
        //   { root: true }
        // )
      } catch (e) {
        await this.$store.commit(
          SET_ERROR_MESSAGE,
          { message: e.message },
          { root: true }
        )
      }
    }
  }
}
</script>
