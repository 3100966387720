<template>
  <span class="organization-level-icon" :style="fontStyle">
    <v-icon :color="color" :size="size" v-bind="$attrs">{{ icons[organization.level] }}</v-icon>
  </span>
</template>

<script>
import { mdiStarOutline, mdiWeb } from '@mdi/js'
import { OrgBundeskirche, OrgGemeinde, OrgKirchenkreis, OrgLandeskirche } from '@/lib/icons'

export default {
  name: 'OrganizationIcon',
  props: {
    organization: null,
    size: {
      type: String,
      default: "36"
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  data () {
    return {
      icons: {
        international: mdiWeb,
        country: OrgBundeskirche,
        region: OrgLandeskirche,
        district: OrgKirchenkreis,
        parish: OrgGemeinde,
        other: mdiStarOutline,
      }
    }
  },
  computed: {
    fontStyle () {
      return {
        'font-size': `${this.size}px`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.organization-level-icon
  padding: 8px;
  white-space: nowrap
  position: relative
  top: 0
  left: 0
  height: 36px
  img
    height: 36px
</style>
