export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      color: 'white',
      // opacity: '0.8',
      formatter: (value, ctx) => {
        let sum = 0
        const dataArr = ctx.chart.data.datasets[0].data
        dataArr.forEach(data => {
          sum += parseFloat(data)
        })
        return (value * 100 / sum).toFixed(2) + "%"
      }
    }
  }
}
