import moment from 'moment'

export function isoToHuman (value, format = 'LLLL') {
  if (!value) {
    return ""
  }
  const d = moment(value)
  return d.format(format)
}

export function isoFromNow (value) {
  if (!value) {
    return ""
  }
  const d = moment(value)
  return d.fromNow()
}
