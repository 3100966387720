export const LETTER_FILTER_HAS_DONATED_THIS_YEAR = 'hasDonatedThisYear'
export const LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR = 'hasNotDonatedThisYear'
export const LETTER_FILTER_DONATION_STATUS_UNIMPORTANT = 'donationStatusIsUnimportant'

export const additionalLetterFilters = [
  {
    label: 'hat im Jahr gespendet',
    value: LETTER_FILTER_HAS_DONATED_THIS_YEAR
  },
  {
    label: 'hat im Jahr NICHT gespendet',
    value: LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
  }
]
