<template >
        <StopCashDialog
        v-if="disbursementOrder.disbursement"
        persistent
          :value="true"
          :disbursementOrder="disbursementOrder"
          hide-cancel
          :token="token"
           />
</template>
<script>

import StopCashDialog from '@/components/evaluation/StopCashDialog.vue'
export default {
  components: {
    StopCashDialog
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      organizationName: null,
      collectionName: null,
      successMessage: null,
      disbursementOrder: {

      }
    }
  },

  async mounted () {
    const res = await this.$store.getters.restApi.get(`disbursementorder/secure_link/${this.token}/`)
    this.disbursementOrder = {
      name: res.data.collection_name,
      organization_name: res.data.organization_name,
      currency_symbol: res.data.currency_symbol,
      disbursement: {
        total_payments: res.data.total_payments,
        total_app_amount: res.data.total_online_amount,
        total_device_amount: res.data.total_device_amount,
        total_cash_amount: res.data.total_cash_amount,
        stop_cash_payments: res.data.stop_cash_payments,
        total_amount: res.data.total_amount,
      }
    }
  },
}
</script>
