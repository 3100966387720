<template>
<span>
  <organization-details-address-add :organization="organization"/>
  <org-details-address-edit :organization="organization"/>
</span>
</template>

<script>

import OrganizationDetailsAddressAdd from "@/components/OrganizationDetailsAddressAdd"
import OrgDetailsAddressEdit from "@/components/OrgDetailsAddressEdit"

export default {
  name: "OrganizationComponent",
  components: {
    OrganizationDetailsAddressAdd,
    OrgDetailsAddressEdit
  },
  props: {
    organization: {
      type: Object,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
