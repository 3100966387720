import { SET_RECURSIVE, SET_ORGA_PANEL_STATE, CLEAR_ORGA_PANEL_STATE, SET_ALL_ORGA_PANEL_STATE, SET_ORGA_PANEL_FILTER } from '@/store/mutation-types'

export default {
  [SET_RECURSIVE] (state, { recursive }) {
    state.recursive = recursive
  },
  [SET_ORGA_PANEL_STATE] (state, { orga, active }) {
    if (state.orgapanels.includes(orga)) {
      if (active) {
        // Nothing to do here. Flies away
      } else {
        const newPanelsArray = state.orgapanels
        const index = newPanelsArray.indexOf(orga)
        if (index !== -1) {
          newPanelsArray.splice(index, 1)
        }
        state.orgapanels = newPanelsArray
        delete state.orgapanels[orga]
      }
    } else {
      const tmpPanelsArray = state.orgapanels
      tmpPanelsArray.push(orga)
      state.orgapanels = tmpPanelsArray
    }
  },
  [CLEAR_ORGA_PANEL_STATE] (state) {
    state.orgapanels = []
  },
  [SET_ALL_ORGA_PANEL_STATE] (state, { allOrgas }) {
    // console.log(state.orgapanels)
    state.orgapanels = allOrgas
  },
  [SET_ORGA_PANEL_FILTER] (state, { orgafilter }) {
    state.orgafilter = orgafilter
  }
}
