<script>
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      default: null,
      validator: function (value) {
        return (!!value?.labels && !!value?.datasets)
      }
    },
    options: {
      type: Object,
      default: null
    },
    legendPosition: {
      type: String,
      default: 'top',
      required: false
    }
  },
  computed: {
    chartOptions () {
      return {
        ...this.options,
        legend: {
          position: this.legendPosition
        }
      }
    }
  },
  mounted () {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartData: {
      deep: true,
      handler: function () {
        this.renderChart(this.chartData, this.chartOptions)
      }
    }
  }
}
</script>
