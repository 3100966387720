import UnionReplacer from 'union-replacer'

const parishReplacer = new UnionReplacer([
  [/Evangelischer?/, 'Ev.'],
  [/Evangelisch-Lutherischer?/, 'Ev.-Luth.'],
  [/Evangelisch-methodistische?/, 'Ev.-meth.'],
  [/Evangelisch-Reformierter?/, 'Ev.-Ref.'],
  [/Protestantischer?/, 'Prot.'],
  [/Kirchenkreis/, 'KK'],
  [/Kirchengemeinde/, 'KG'],
  [/Landeskirche/, 'LK'],
  [/Verwaltungszweckverband/, 'VZV'],
  [/Kirchengemeindeverband/, 'KGV'],
  [/Regionalverband/, 'RV'],
])

export function shortenParish (value) {
  return parishReplacer.replace(value)
}

const PREPOSITION_RE = /^([Ff]ür\s(?:d..\s)?)(.*)$/s

export function splitPurpose (value) {
  if (!value) {
    return {
      preposition: 'Für die',
      purpose: '---'
    }
  }
  const matches = PREPOSITION_RE.exec(value)
  if (!matches) {
    return {
      preposition: '',
      purpose: value.trim()
    }
  }
  return {
    preposition: matches[1].trim(),
    purpose: matches[2].trim()
  }
}

export function extractDisplayPurpose (collectionPlan, deviceType = 'john') {
  const display = collectionPlan?.display
  if (!display) {
    return ''
  }

  return display[deviceType] ?? (display?.default ?? '')
}
