<template>
  <v-col cols="12" md="6" lg="4" xl="3" >
      <v-card
        class="mx-auto pt-1"
        max-width="320"
        min-width="320"
        :color="isActive ? '' : 'grey lighten-1'"
      >
        <v-img
          v-if="telemetryConnected && mqttState['screen/idle_state']"
          v-bind:src="'data:image/jpeg;base64,' + mqttState['screen/idle_state']"
          max-width="320"
          max-height="240"
          contain
          class="mt-2"
          :transition="false"
        ></v-img>
        <div
          v-else-if="showWidgets && isActive && this.appUrl"
          style="width: 100%;"
        >
          <iframe
            :src="this.appUrl + '?mode=embed'"
            frameBorder="0"
            :style="{ width: '320px', height: (this.device.type === 'bart' ? '285px' : '355px') }">
          </iframe>
        </div>
        <device-icon
          v-else
          size="240"
          class="mt-2"
          style="display: block; margin-left: auto; margin-right: auto"
          :device-class="safeClass"
        ></device-icon>
        <v-btn class="float-right mr-3 mt-3" fab color="primary" small @click="openDeviceSettings()"><v-icon dark>mdi-cog</v-icon></v-btn>
        <v-card-title>{{ deviceClassName }}<template v-if="!isActive"> (inaktiv)</template></v-card-title>
        <v-card-subtitle>
          <div>
            {{ device.name }}
          </div>
          <div v-if="contractInformation" class="ma-0 text-uppercase text-caption">
            {{ contractInformation }}
          </div>
        </v-card-subtitle>
        <v-card-text>
          <!-- FIXME Device state overview -->
          <v-row
            align="center"
            class="mx-0"
            v-if="isActive && safeClass !== 'WA' && safeClass !== 'WS'"
          >
            <div :class="['device-icon', stateType, {active: isActive, inactive: !isActive}]">
              <device-icon :device-class="safeClass" color="inherit" size="24" :class="['state-icon', stateType]"></device-icon>
              <template v-if="deviceHasEthernet">
                <span class="wifi-icon mdi mdi-ethernet-cable" v-if="vpnConnected"></span>
                <span class="wifi-icon mdi mdi-ethernet-cable-off" v-else></span>
              </template>
              <span v-if="(deviceNeedsWifi || wifiConnected) && (deviceHasWifi && wifiConnected)">
                <span :class="['wifi-icon', 'mdi', 'mdi-wifi-strength-' + wifiIconStrength]" v-if="telemetryConnected"></span>
                <span class="wifi-icon mdi mdi-wifi-strength-off-outline" v-else></span>
              </span>
              <span :class="['battery-icon', 'mdi', batteryIconClass]" v-if="deviceHasBattery" :title="(mqttState && mqttState.battery_percent) ? mqttState.battery_percent + '%' : ''"></span>
            </div>
          </v-row>
          <v-row v-if="wwanState" class="mx-1 mb-2">
            <v-progress-linear
              height="25"
              top
              :value="wwanState.value"
              :color="wwanState.color"
            >{{ wwanState.text }}</v-progress-linear>
          </v-row>
          <v-row v-if="ddbEntry.hardware && (location || locationName)" class="pb-0 ma-0">
            <span>
              <v-icon class="mr-1">mdi-crosshairs-gps</v-icon>{{ location ? location.name : locationName }}
              <v-btn
                color="primary"
                text
                fab
                x-small
                @click="openDeviceSettings('location')"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </span>
          </v-row>
          <v-row v-if="holderOrganization" class="ma-0">
            {{ holderOrganization.name }}
          </v-row>
          <v-row v-if="isActive" style="min-height: 1.5em" class="ma-0">
            <!-- FIXME Short settings overview -->
            <span v-if="ddbEntry.display.maxCollections > 1" class="pr-1">Max. {{ purposeCount }} Zweck{{ purposeCount > 1 ? 'e' : '' }}, </span>
            <span v-if="ddbEntry.ledRing">
              LED-Farbe:
              <span
                :style="{ marginBottom: '-5px', height: '20px', width: '20px', backgroundColor: ledRingColor, borderRadius: '50%', display: 'inline-block' }"
              ></span>
            </span>
          </v-row>
          <v-row
            align="center"
            class="pb-0 mb-0"
            no-gutters
            v-if="isActive && safeClass !== 'WA' && safeClass !== 'WS'"
          >
            <div :class="['device-information', {'text-warning': deviceHasWarning}]">{{ stateText }}</div>
            <v-col cols="12">
              <v-btn
                outlined
                color="primary"
                @click="showCloseOutModal = true"
              >Kassenschnitt</v-btn>
            </v-col>
              <v-expansion-panels v-model="expansionState" flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    mehr
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="lastHandshake.valueOf() > 0">
                      <span class="font-weight-bold">VPN-Verbindung zuletzt gesehen:</span>
                      <span :title="lastHandshake.format('LLLL')">{{ lastHandshake.from(this.$store.state.now) }}</span>
                    </div>
                    <div><span class="font-weight-bold">Interner Name: </span>{{ device.mqtt_identity }}</div>
                    <div><span class="font-weight-bold">Softwarestand: </span><tt>{{ softwareRevision }}</tt>
                      <template v-if="firmwareInformationByIdentifier['KB/' + softwareRevision]">
                        (Herausgegeben {{ firmwareInformationByIdentifier['KB/' + softwareRevision].created_at | isoToHuman('LL') }}, {{ firmwareInformationByIdentifier['KB/' + softwareRevision].created_at | isoFromNow }})
                      </template>
                    </div>
                    <template v-if="telemetryConnected">
                      <div v-if="deviceHasWifi"><!-- FIXME remove bunt -->
                        <span class="font-weight-bold">WLAN:</span> {{ mqttState['wifi/status/ssid']}}
                        <meter min="0" max="65" low="20" high="40" optimum="50" v-bind:value="wifiStrength"></meter>
                        <v-btn @click.stop="emitShowWifiList" rounded><v-icon>{{ mdiDotsHorizontalCircleOutline }}</v-icon></v-btn>
                      </div>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-row>
        </v-card-text>

        <template v-if="isActive && appUrl">
          <v-divider class="mx-4"></v-divider>

          <v-card-actions v-if="isActive">
            <template v-if="appUrl">
              <v-dialog
                v-model="qrDialog"
                scrollable
                max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    color="primary"
                  ><v-icon>{{ mdiQrcode }}</v-icon>QR-Code</v-btn>
                </template>
                <v-card>
                  <v-card-title class="">
                    QR-Code für {{ deviceClassName }}
                    <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      fab
                      text
                      @click="qrDialog = false"
                    >
                      <span class="font-weight-bold" style="font-size: 1.2em;">X</span>
                    </v-btn>
                  </v-card-title>

                  <v-card-text class="mt-4">
                    <h4>Ihr Direktlink</h4>
                    <p>Unter diesem Link finden Sie Ihre {{ deviceClassName }}:</p>
                    <p><a :href="appUrl" target="_blank">{{ appUrl }}</a></p>
                    <p v-if="safeClass === 'WS'">Fügen Sie Ihn in Ihre Webseite, auf SocialMedia in Videostreams, etc. ein. Eine Anleitung zum Einbinden per iFrame <a href="https://digitalespende.de/Spende-app-auf-der-eigenen-seite-einbinden/" target="_blanc">finden Sie hier</a>.</p>
                    <p v-else>Fügen Sie Ihn in Ihre Gemeindeseite, auf SocialMedia in Videostreams, etc. ein. Eine Anleitung zum Einbinden per iFrame <a href="https://digitalespende.de/Spende-app-auf-der-eigenen-seite-einbinden/" target="_blanc">finden Sie hier</a>.</p>
                    <h4>QR-Code</h4>
                    <p v-if="safeClass === 'WS'">Um spende.app bei Veranstaltungen zu verwenden, drucken Sie bitte den folgenden QR-Code aus, dann kann die App direkt mit dem Smartphone geöffnet werden. <a href="https://digitalespende.de/kollekte-app-in-ihrer-gemeinde-bewerben/" target="_blanc">Mehr Informationen</a></p>
                    <p v-else>Um kollekte.app im Gottesdienst, auf Liedzetteln, Plakaten und Schaukästen zu benutzen, fügen Sie bitte den
                      folgenden QR-Code ein, er kann mit dem Smartphone geöffnet werden. <a href="https://digitalespende.de/kollekte-app-in-ihrer-gemeinde-bewerben/" target="_blanc">Mehr Informationen</a></p>
                    <div class="my-2">
                      <v-btn
                        color="primary"
                        @click="downloadQrCode"
                      >
                        <v-icon>{{ mdiDownload }}</v-icon>
                        QR-Code herunterladen
                      </v-btn>
                    </div>
                    <qr-canvas
                      class="qrsquare"
                      :options="qrAppOptions"
                      ref="qrcanvas"
                    >
                    </qr-canvas>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="qrDialog = false"
                    >
                      schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogEmbed"
                scrollable
                persistent
                max-width="800"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    color="primary"
                  ><v-icon>{{ mdiApplicationArrayOutline }}</v-icon>Einbinden</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Einbinden von {{ deviceClassName }}
                    <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      fab
                      text
                      @click="dialogEmbed = false"
                    >
                      <span class="font-weight-bold" style="font-size: 1.2em;">X</span>
                    </v-btn>
                  </v-card-title>

                  <v-card-text class="">
                    <spendeappEmbed v-if="safeClass === 'WS'"></spendeappEmbed>
                    <kollekteappEmbed v-else></kollekteappEmbed>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialogEmbed = false"
                    >
                      schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-card-actions>
        </template>
      </v-card>

    <v-dialog
      v-model="deviceSettingsDialog"
      scrollable
      persistent
      max-width="80vw"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          Einstellungen für {{ deviceClassName }}
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            fab
            text
            @click="abortSettings"
          >
            <span class="font-weight-bold" style="font-size: 1.2em;">X</span>
          </v-btn>
        </v-card-title>

        <v-card-text class="">
          <device-settings
            ref="deviceSettings"
            :device="device"
            :ddb-entry="ddbEntry"
            v-model="settingsChanged"
          ></device-settings>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="abortSettings"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveSettings"
            :disabled="!settingsChanged"
          ><!-- disable save unless things are changed -->
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-dialog
      :is-open="showCloseOutModal"
      @close="showCloseOutModal = false"
    >
      <template #dialog-title>
        Kassenschnitt durchführen? (kostenpflichtig)
      </template>

      <template #dialog-content>
        <v-card-text class="pt-2">
          <p>
            Damit die erfolgten Zahlungen an den Zahlungsdienstleister übermittelt werden, muss nach einer Sammlung (spätestens am Monatsende) ein Kassenschnitt ausgelöst werden. Dies passiert automatisch nach 24-48 Stunden, WENN das Gerät eingeschaltet ist.
          </p>
          <p>
            Soll das Gerät jetzt abgerechnet und ein kostenpflichtiger Kassenschnitt ausgelöst werden? Dann schalten Sie das Gerät jetzt ein und klicken erst auf Ja, wenn es hochgefahren ist.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="performCloseOut"
          >Ja</v-btn>
          <v-btn
            text
            color="primary"
            @click="showCloseOutModal = false"
          >Nein</v-btn>

        </v-card-actions>

      </template>
    </base-dialog>
  </v-col>
</template>

<script>
import moment from 'moment'
import config from '@/config'
import { QRCanvas } from 'qrcanvas-vue'
import { mdiApplicationArrayOutline, mdiDotsHorizontalCircleOutline, mdiQrcode, mdiDownload, mdiFileDocumentOutline } from '@mdi/js'
import KollekteappEmbed from './KollekteappEmbed'
import SpendeappEmbed from './SpendeappEmbed'
import { deviceTypeToClassName, entryForDeviceType } from '@/lib/device-db'
import { mapGetters } from 'vuex'
import DeviceIcon from '@/components/icons/DeviceIcon'
import DeviceSettings from '@/components/device/DeviceSettings'
import BaseDialog from "@/components/UI/BaseDialog.vue"
import { POST_DEVICE_CLOSEOUT, SEARCH_FIRMWARE_INFORMATION } from '@/store/action-types'
import { SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"
import { getLatestVersion } from "@/lib/sort-utils"
import { shortenParish } from "../../lib/regex-tools"

function locationFromDevice (device) {
  if (device.current_location__name) {
    return '(' + (device.current_location__organization__name ? (device.current_location__organization__name + ' / ') : '') +
      device.current_location__name + ')'
  }
}

export default {
  name: 'Device',
  props: {
    isActive: {
      default: true,
      type: Boolean
    },
    device: null,
    mqttState: {},
    mqttVpnState: {},
    currentOrganization: null,
    showWidgets: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      mdiQrcode,
      mdiDotsHorizontalCircleOutline,
      mdiApplicationArrayOutline,
      mdiDownload,
      mdiFileDocumentOutline,
      qrDialog: false,
      deviceSettingsDialog: false,
      dialogEmbed: false,
      expansionState: undefined,
      settingsChanged: false,
      showCloseOutModal: false
    }
  },
  components: {
    BaseDialog,
    DeviceSettings,
    QrCanvas: QRCanvas,
    KollekteappEmbed,
    SpendeappEmbed,
    DeviceIcon,
  },
  computed: {
    ...mapGetters('organization', ['allOrganizations']),
    ...mapGetters('location', ['locationsLookup']),
    ...mapGetters('firmware', ['firmwareInformationByIdentifier']),
    collectionMemberState () {
      // eslint-disable-next-line camelcase
      return this.device?.collection_member_state ?? null
    },
    holderOrganization () {
      // eslint-disable-next-line camelcase
      return this.device.current_location === null ? null : this.allOrganizations[this.locationsLookup[this.device?.current_location ?? null]?.organization ?? null] ?? null
    },
    locationName () {
      return locationFromDevice(this.device) /* FIXME is this still necessary? */
    },
    location () {
      // eslint-disable-next-line camelcase
      return this.locationsLookup[this.device?.current_location ?? null] ?? null
    },
    stateType () {
      if (!this.mqttState || !this.mqttVpnState) {
        return 'unknown'
      }
      const vpnLastSeen = this.vpnLastSeenDiff
      if (this.vpnConnected) {
        if (this.telemetryConnected) {
          return 'online'
        } else if (vpnLastSeen < 60) {
          return 'booting'
        } else {
          return 'telemetryFailed'
        }
      } else {
        return 'offline'
      }
    },
    ddbEntry () {
      return entryForDeviceType(this?.device?.type ?? 'unknown')
    },
    ledRingColor () {
      // eslint-disable-next-line camelcase
      if (!this.device?.settings_inherit || !this.ddbEntry?.ledRing) {
        return null
      }
      // FIXME inheritance, also next one
      return (this.device.settings_inherit[this.safeClass] ?? {})?.ledring?.data?.color ?? '#331493'
    },
    purposeCount () {
      // eslint-disable-next-line camelcase
      if (!this.device?.settings_inherit || !this.ddbEntry?.display?.maxCollections > 1) {
        return null
      }
      // eslint-disable-next-line camelcase
      return (this.device.settings_inherit[this.safeClass] ?? {})?.display?.collection_count ?? 1
    },
    appUrl () {
      if (!this.currentOrganization) {
        return false
      } else if (this?.device?.type === 'bart') { // spende.app
        return config.spendeApp.url + '/o/' + this.currentOrganization.id
      } else if (this?.device?.type === 'john') { // kollekte.app
        return config.kollekteApp.url + '/p/' + this.currentOrganization.id
      } else {
        return false
      }
    },
    qrAppOptions () {
      return {
        size: "400",
        data: this.appUrl,
      }
    },
    safeClass () {
      // eslint-disable-next-line camelcase
      return this?.device?.device_class ?? "unknown"
    },
    deviceClassName () {
      return deviceTypeToClassName(this?.device?.type ?? null)
    },
    stateText () {
      const retval = []

      if (this.stateType === 'unknown') {
        retval.push("Verbindungszustand unbekannt")
      } else if (this.stateType === 'online') {
        retval.push("Verbunden")
      } else if (this.stateType === 'booting') {
        retval.push("Verbunden, Gerät startet")
      } else if (this.stateType === 'telemetryFailed') {
        retval.push("Verbunden, technischer Fehler in der Telemetriedaten-Verbindung")
      } else if (this.stateType === 'offline') {
        retval.push("Gerät offline")
      }

      if (this.collectionMemberState === 'open') {
        retval.push("Kollekte/Sammlung offen")
      } else if (this.collectionMemberState === 'closing') {
        retval.push("Kollekte/Sammlung im Abschlußprozess")
      } else {
        retval.push("keine Kollekte/Sammlung aktiv")
      }

      return retval.join(", ")
    },
    vpnLastSeenDiff () {
      return this.$store.state.now.diff(this.lastHandshake, 'seconds')
    },
    vpnConnected () {
      return this.vpnLastSeenDiff < 3 * 60
    },
    telemetryConnected () {
      return this.mqttState?.status === 'online'
    },
    lastHandshake () {
      // eslint-disable-next-line camelcase
      return moment.unix(this.mqttVpnState?.last_handshake ?? 0)
    },
    deviceHasWarning () {
      if (this.isActive) {
        if (this.stateType === 'telemetryFailed') {
          return true
        }
      } else {
        if (this.collectionMemberState === 'open' || this.collectionMemberState === 'closing') {
          return true
        }
      }
      return false
    },
    deviceHasBattery () {
      return this.ddbEntry?.battery ?? false
    },
    deviceNeedsWifi () {
      return (this.ddbEntry?.connection?.wifi ?? false) === true
    },
    deviceHasWifi () {
      return ((this.ddbEntry?.connection?.wifi ?? false) !== false) &&
        ((this.ddbEntry?.connection?.wifi ?? false) !== "hidden")
    },
    deviceHasEthernet () {
      return !!(this.ddbEntry?.connection?.ethernet ?? false)
    },
    deviceInformation () {
      if (!this.mqttState || !this.mqttState.device_information) {
        return {}
      }
      return JSON.parse(this.mqttState.device_information)
    },
    wwanState () {
      if (!this.mqttState || !this.mqttState['wwan/signal/network_type']) {
        return null
      }
      const floorV = -90
      const ceilV = -60
      const rssiDbm = parseFloat(this.mqttState['wwan/signal/RSSI'] || '-90')
      const value = (Math.max(floorV, Math.min(rssiDbm, ceilV)) - floorV) / (ceilV - floorV) * 100
      let text = this.mqttState['wwan/signal/network_type']
      let color = "green"
      if (value < 20) {
        color = "red"
        text = text + " (sehr schwach)"
      } else if (value < 50) {
        color = "yellow"
        text = text + " (schwach)"
      }
      return {
        color, value, text
      }
    },
    softwareRevision () {
      // eslint-disable-next-line camelcase
      return this.deviceInformation?.software_revision || ""
    },
    wifiStrength () {
      if (!this.mqttState['wifi/signal/RSSI']) {
        return 2
      }
      return 95 + parseInt(this.mqttState['wifi/signal/RSSI'])
    },
    wifiIconStrength () {
      if (!this.mqttState['wifi/signal/RSSI']) {
        return 'outline'
      }
      const strength = parseInt(this.mqttState['wifi/signal/RSSI'])
      if (strength >= -40) {
        return '4'
      }
      if (strength >= -45) {
        return '3'
      }
      if (strength >= -55) {
        return '2'
      }
      if (strength >= -75) {
        return '1'
      }
      return 'outline'
    },
    wifiConnected () {
      if (!this.mqttState) {
        return false
      }
      return !!(this.mqttState['wifi/signal/RSSI'] ?? false)
    },
    batteryIconClass () {
      if (!this.mqttState || !this.mqttState.battery_percent) {
        return 'mdi-battery-unknown'
      }
      const batpct = parseInt(this.mqttState.battery_percent)
      if (batpct >= 100) {
        return 'mdi-battery'
      } else if (batpct <= 0) {
        return 'mdi-battery-outline'
      } else {
        const iconIdx = Math.round(batpct / 10)
        const result = ['mdi-battery-' + iconIdx + '0']
        if (iconIdx < 5) {
          result.push('warning')
        }
        return result
      }
    },
    inheritanceOrder () {
      return this.$store.getters['settings/inheritanceOrderForUrl'](this?.device?.url ?? null)
    },
    contractInformation () {
      if (!this.device.contracts) {
        return null
      }
      const contract = getLatestVersion(this.device.contracts)?.contract
      const contractOrganization = this.allOrganizations[contract?.organization ?? null]
      const contractOrganizationName = shortenParish(contractOrganization?.name ?? null)

      return `(${contract.id} ${contractOrganizationName})`
    }
  },
  filters: {
    locationFromDevice
  },
  methods: {
    shortenParish,
    openDeviceSettings (whereTo = "top") {
      this.deviceSettingsDialog = true
      // FIXME scroll to target
    },
    emitShowWifiList (event) {
      this.$emit('show-wifi-list', this.device.mqtt_identity)
    },
    saveDefaultAmount () {
      // Dummy, to prevent errors
    },
    abortSettings () {
      this.deviceSettingsDialog = false
      this.$refs.deviceSettings.reset()
    },
    async saveSettings () {
      const result = await this.$refs.deviceSettings.save()
      if (result) {
        this.deviceSettingsDialog = false
      }
    },
    async performCloseOut () {
      await this.$store.dispatch('device/' + POST_DEVICE_CLOSEOUT, { contextUrl: this.device.url })
      await this.$store.commit(SET_SNACKBAR_MESSAGE, { message: 'Danke. Ihr Gerät sollte in den nächsten Minuten einen Kassenschnitt auslösen.' })
      this.showCloseOutModal = false
    },
    downloadQrCode () {
      const canvasEl = this.$refs.qrcanvas.$el

      const downloadLink = document.createElement('a')

      downloadLink.download = this.deviceClassName + '_' + this.currentOrganization.name + '.png'
      downloadLink.href = canvasEl.toDataURL()
      downloadLink.click()
      downloadLink.remove()
    }
  },
  watch: {
    expansionState (newValue) {
      if (newValue !== undefined) {
        this.$emit('expand')
      } else {
        this.$emit('unexpand')
      }
    },
    softwareRevision: {
      immediate: true,
      async handler (newValue) {
        if (newValue && newValue.length > 2) {
          if (!this.firmwareInformationByIdentifier['KB/' + newValue]) {
            await this.$store.dispatch('firmware/' + SEARCH_FIRMWARE_INFORMATION, { kind: "KB", identifier: newValue })
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .open-wifi-list-button
    icon-button-style()

  .device-icon
    margin-top: 10px
    min-width: 130px
    height:54px
    display: flex
    align-items: center
    white-space: nowrap

    > *
      margin-right: 8px

    .wifi-icon, .battery-icon
      font-size: 22px

    .battery-icon.warning
      animation: blinker 1.5s ease-in-out infinite;
    @keyframes blinker
      50%
        opacity: 0.2

    &.offline
      opacity: 0.7

  .device-information
    min-height: 3.7em
    .text-warning
      color: $clr-orange-500
.myButtons
  button-style(color: $clr-primary)

.v-expansion-panel-header
  padding: 0px!important
.v-application--is-ltr .v-expansion-panel-header__icon
  margin-left: 0px!important
.v-expansion-panel-content__wrap
  padding-left: 0px!important
</style>
