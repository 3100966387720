<template>
  <v-container v-if="organization">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <template v-if="relevantDevices.has('bart') && (hasSpendeAppOverride === undefined || hasSpendeAppOverride === true)">
          <v-card>
            <v-card-title class="">
              Einbinden als iFrame
            </v-card-title>

            <v-card-text>
              <p>Unter diesem Link finden Sie Ihre spende.app: <a :href="spendeAppUrl" target="_blanc">{{ spendeAppUrl }}</a></p>
              <p>Eine Anleitung zum Einbinden per iFrame <a href="https://digitalespende.de/Spende-app-auf-der-eigenen-seite-einbinden/" target="_blanc">finden Sie hier</a>.</p>
              <div id="iframegenerator">
                <v-container>
                <v-row>
                  <v-col cols="12">

                    <h4 class="pt-0 mt-0">Größe</h4>
                    <v-checkbox v-model="frameFullWidth" hide-details="auto" class="mb-10">
                      <template v-slot:label>
                        <div>
                          Breite 100%
                        </div>
                      </template>
                    </v-checkbox>
                    <v-slider
                      v-model="frameWidth"
                      label="Breite"
                      thumb-color="#554193"
                      thumb-label="always"
                      max="650"
                      :min="385"
                      :disabled="frameFullWidth"
                    ></v-slider>
                    <v-slider
                      v-model="frameHeight"
                      label="Höhe"
                      thumb-color="#554193"
                      thumb-label="always"
                      max="500"
                      :min="285"
                    ></v-slider>
                    <h4>Ausrichtung</h4>
                    <v-tabs
                      v-model="alignmentTab"
                      align-with-title
                    >
                      <v-tabs-slider></v-tabs-slider>

                      <v-tab
                        v-for="item in iframealignment"
                        :key="item.textalign"
                      >
                        {{ item.label }}
                      </v-tab>
                    </v-tabs>
                    <keep-alive>
                      <v-tabs-items v-model="alignmentTab" elevation="2">
                        <v-tab-item
                          v-for="item in iframealignment"
                          :key="item.textalign"
                        >
                          <v-card elevation="0">
                            <div class="iframe-container" :style="{height: (frameHeight + 20) + 'px'}">
                              <div v-bind:style='{textAlign: item.textalign, width: "100%"}'><iframe ref="spendeapp-iframe" :src="spendeAppUrl+'?remoteControl'" frameBorder="0" v-bind:style="{ width: (frameFullWidth ? '100%' : frameWidth + 'px'), height: frameHeight + 'px' }"></iframe></div>
                            </div>
                            <h4>Ergebnis</h4>
                            <p>Fügen Sie den folgenden HTML-Code in Ihre Website ein:</p>
                            <div>
                              <code class="codeblock">&#x3C;div style=&#x22;text-align: {{ item.textalign }}; width: 100%;&#x22;&#x3E;&#x3C;iframe src=&#x22;{{ spendeAppUrl }}&#x22; frameBorder=&#x22;0&#x22; style=&#x22;width:{{ frameFullWidth ? '100%' : frameWidth + 'px' }}; height:{{ frameHeight  }}px;&#x22;&#x3E;&#x3C;/iframe&#x3E;&#x3C;/div&#x3E;
                              </code>
                            </div>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </keep-alive>
                  </v-col>
                </v-row>
                </v-container>
              </div>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <p>Für Ihre Organisation ist noch keine spende.app konfiguriert. Bitte schreiben Sie uns eine Mail an <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a> wenn Sie denken, dass uns ein Fehler unterlaufen ist oder um einen Vertrag abzuschließen.</p>
        </template>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="snacktimeout"
    >
      {{ this.snacktext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { FETCH_ORGANIZATIONS } from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
import config from '@/config'

export default {
  name: 'org-spendeapp',
  props: {
  },
  data () {
    return {
      creating: false,
      error: null,
      alignmentTab: null,
      parishSelection: false,
      iframeSmall: false,
      iframealignment: [
        { label: 'links', textalign: 'left' },
        { label: 'mittig', textalign: 'center' },
        { label: 'rechts', textalign: 'right' },
      ],
      frameWidth: 385,
      frameHeight: 285,
      frameFullWidth: false,
      snackbar: false,
      snacktext: '',
      snacktimeout: 1500,
      embedDialog: false,
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organization: 'currentOrganization', organizationRelevantDevicesRawByUrl: 'organizationRelevantDevicesRawByUrl' }),
    isDev () {
      return config.deployment === 'development'
    },
    hasSpendeAppOverride () {
      if (!this.organizationSettings) {
        return undefined
      }
      return this.organizationSettings?.override?.enableWS === undefined
        ? undefined
        : this.organizationSettings?.override?.enableWS
    },
    relevantDevices () {
      if (!this.organizationRelevantDevicesRawByUrl) {
        return {}
      }
      return this.organizationRelevantDevicesRawByUrl[this.organization.url]
    },
    spendeAppUrl () {
      return config.spendeApp.url + '/o/' + this.organization.id
    },
    options () {
      return {
        size: 512,
        data: this.spendeAppUrl,
      }
    }
  },
  watch: {
    iframeSmall: function () {
      if (this.iframeSmall) {
        this.parishSelection = false
      }
    },
  },
  methods: {
    snack (text) {
      this.snacktext = text
      this.snackbar = true
    },
    postMessageIframe: async function (message) {
      if (this.$refs['spendeapp-iframe']) {
        this.$refs['spendeapp-iframe'].forEach(f => f.contentWindow.postMessage(message, this.spendeAppUrl))
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
      ])
    })
  }
}
</script>
<style lang="stylus">
.activeborder
  border: 4px solid $clr-primary!important
.inactiveborder
  border: 4px solid transparent!important
#iframegenerator
  .iframe-container
    padding-top: 10px
    padding-bottom: 10px
    border-bottom: 1px solid black
    height: 310px

  .codeblock
    display: block
    margin-top: 10px
    width: 100%
    min-width: 400px
    min-height: 90px
    border: 1px solid grey
    padding: 10px
</style>
