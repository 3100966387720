<template>
 <div>
   <v-select
     v-if="showColumnSelection"
     v-model="selectedColumns"
     :items="availableColumns"
     label="Sichtbare Spalten"
     multiple
     outlined
     return-object
     class="selection"
   ></v-select>
   <div v-else>
     <v-tooltip bottom>
       <template v-slot:activator="{ on, attrs }">
         <v-btn
           v-bind="attrs"
           v-on="on"
           icon
           @click="showColumnSelection = true">
           <v-icon>mdi-dots-horizontal</v-icon>
         </v-btn>
       </template>
       Spaltenauswahl anzeigen
     </v-tooltip>
   </div>
   <div
     v-if="totalMatchCount > 0"
     class="py-2 text-overline"
   >
     {{ matchCountText }}
   </div>
   <v-data-table
     :headers="visibleColumns"
     :items="personData"
     :search="searchInput"
     :items-per-page="-1"
     :disable-pagination="true"
     :disable-items-per-page="true"
     :footer-props="{
       itemsPerPageOptions: [-1],
       pageText: matchCountText,
       prevIcon: '',
       nextIcon: ''
     }"
     :loading="loading > 0"
      :class="['kige-search-results', 'elevation-1', loading > 0 ? 'enable-row-blur' : null]"
      d-flex
      justify-center
    >
      <template #item.geburtsdatum="{ item }">
        {{ momentize(item.geburtsdatum) }}
      </template>
      <template #item.einzug_datum="{ item }">
        {{ momentize(item.einzug_datum) }}
      </template>
      <template #item.auszugsdatum="{ item }">
        {{ momentize(item.auszug_datum) }}
      </template>
      <template #item.familienstand="{ item }">
        <span v-if="item.familienstand === 'vh'" title="Verheiratet">&#9901;</span>
        <span v-else-if="item.familienstand === 'nv'" title="Nicht verheiratet">&ndash;</span>
        <span v-else>{{ item.familienstand }}</span>
      </template>
      <template #item.relations="{ item }">
        {{ item.relations ? item.relations.length : '--' }}
      </template>
      <template #item.sperre="{ item }">
          <span v-if="item.sperre">
          <span
            v-for="(key, index) in item.sperre"
            :key="index"
          >
            <v-tooltip
              :open-on-click="false"
              :open-on-hover="true"
              color="primary"
              top>
            <template v-slot:activator="{ on, attrs }">
             <span
               v-bind="attrs"
               v-on="on">
               {{ key }}
             </span>
            </template>
                <span>{{ getEnumValue('Sperre', key) }}</span>
              </v-tooltip>
            <span v-if="index +1 < item.sperre.length">, </span>
            </span>
          </span>
        <span v-else>--</span>
      </template>
     <template #item._selection="{ item }">
       <v-radio-group v-if="adjustmentPassing" v-model="personMarkedForExternalSelection">
         <v-radio
           :key="item.id"
           :value="item"
         ></v-radio>
       </v-radio-group>
       <v-checkbox v-if="!adjustmentPassing"
                   v-model="personMarkedForExternalSelection"
                   :value="item"
       />
     </template>
      <template #item._actions="{ item }">
        <v-btn
          color="primary"
          fab
          small
          @click="openDetailsDialog(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="item.isEditable"
          color="primary"
          fab
          small
          width="2.5rem"
          @click="editPersonPage(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template #item.org_by_kro="{ item }">
        {{ getOrganizationByKroNr(item.kro_nr).name }}
      </template>
      <template #item.eigenerfasst="{ item }">
        <v-icon v-if="item.isEditable">mdi-check</v-icon>
        <v-icon v-else-if="item.type === 'company'">mdi-domain</v-icon>
        <span v-else>--</span>
      </template>
     <template #item.inaktiv="{ item }">
       <v-icon v-if="item.inaktiv">{{ mdiAccountOff }}</v-icon>
       <span v-else>--</span>
     </template>
    </v-data-table>
    <div
      v-if="isExternalSelectionAllowed"
      class="my-2 d-flex justify-end">
      <v-btn
        :disabled="!personMarkedForExternalSelection"
        color="primary"
        @click="confirmExternalSelection"
      >Auswahl bestätigen
      </v-btn>
    </div>
    <person-detail-dialog
      v-if="selectedPerson"
      :is-open="showPersonDetailsDialog"
      :person-url="selectedPerson.personUrl"
      @close="onPersonDetailDialogClose"
    ></person-detail-dialog>
  </div>
</template>

<script>
import moment from "moment"
import { personProperties, VISIBLE_LIST } from "@/modules/kirchgeld/config/person.properties"
import { mapGetters, mapState } from 'vuex'
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"
import PersonDetailDialog from "@/modules/kirchgeld/components/PersonDetailDialog"
import { mdiAccountOff } from "@mdi/js"

export default {
  name: 'FilterResultPreviewTable',
  components: {
    PersonDetailDialog,
  },
  mixins: [
    PersonDataMixin
  ],
  props: {
    persons: {
      type: Array,
      required: true
    },
    totalMatchCount: {
      type: Number,
      required: false,
      default: 0
    },
    isExternalSelectionAllowed: {
      type: Boolean,
      required: false,
      default: false
    },
    searchInput: {
      type: String,
      required: false,
      default: ''
    },
    adjustmentPassing: {
      type: Boolean,
      required: false,
      default: false
    },
    showColumnSelectionInput: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['external-selection-confirmed'],
  data () {
    return {
      selectedColumns: [],
      availableColumns: personProperties,
      selectedPerson: null,
      showPersonDetailsDialog: false,
      personMarkedForExternalSelection: null,
      showColumnSelection: true,
      mdiAccountOff
    }
  },
  mounted () {
    this.showColumnSelection = this.showColumnSelectionInput
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('kirchgeld', ['enums', 'organizations']),
    visibleColumns () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.selectedColumns
        .sort((columnA, columnB) => columnA.priority - columnB.priority)
        .map(column => {
          if (column.text_alt) {
            return {
              ...column,
              text: `${column.text} / ${column.text_alt}`
            }
          }
          return column
        })
    },
    personData () {
      return this.persons.map(person => {
        const personData = this.getLatestValidPersonData(person)
        const isInactive = moment(personData.valid_before).isBefore(moment().format())
        return {
          personUrl: person.url,
          type: person.type,
          isEditable: person.is_editable,
          inaktiv: isInactive,
          ...personData
        }
      })
    },
    matchCountText () {
      return `Vorschau: Zeige erste ${this.persons.length} von insg. ${this.totalMatchCount} Einträgen`
    }
  },
  methods: {
    momentize (date) {
      if (!date) {
        return ''
      }
      return moment(date).format("DD.MM.YYYY")
    },
    getEnumGroupByName (groupName) {
      return this.enums.find(enumGroup => enumGroup.name === groupName) ?? null
    },
    getEnumValue (groupName, key) {
      return this.getEnumGroupByName(groupName)?.values[key] ?? null
    },
    openDetailsDialog (value) {
      this.selectedPerson = value
      this.showPersonDetailsDialog = true
    },
    confirmExternalSelection () {
      this.$emit('external-selection-confirmed', this.personMarkedForExternalSelection)
    },
    getOrganizationByKroNr (kroNr) {
      return this.organizations.find(org => org.kro_nr === kroNr) ?? ''
    },
    async editPersonPage (person) {
      await this.$router.push({
        name: 'kige-person-edit',
        params: {
          url: person.personUrl
        }
      })
    },
    onPersonDetailDialogClose () {
      this.showPersonDetailsDialog = false
      this.selectedPerson = null
    }
  },
  created () {
    if (localStorage.filterResultPreviewTableSelectedColumns?.length) {
      this.selectedColumns = this.availableColumns
        .filter(column => localStorage.filterResultPreviewTableSelectedColumns.includes(column.value))
    } else {
      this.selectedColumns = this.availableColumns.filter(column => column.flags.includes(VISIBLE_LIST))
    }
    if (this.isExternalSelectionAllowed) {
      const selectableColumn = {
        text: 'Auswahl',
        value: '_selection',
        priority: -1,
        group: 'actions',
      }
      this.selectedColumns.push(selectableColumn)
    }
    return this.selectedColumns
  },
  watch: {
    selectedColumns (newValue) {
      localStorage.filterResultPreviewTableSelectedColumns = newValue.map(column => column.value)
    }
  }
}
</script>

<style lang="stylus">
.kige-search-results
  .v-data-footer__icons-before,
  .v-data-footer__icons-after
    display: none

  .v-data-footer__pagination
    padding: 1em 0

.kige-search-results.enable-row-blur
  td
    opacity: 0.8
    filter: blur(3px)
    -webkit-filter: blur(3px)

</style>
