import { mdiStarOutline, mdiWeb } from "@mdi/js"
import { OrgBundeskirche, OrgGemeinde, OrgKirchenkreis, OrgLandeskirche } from "@/lib/icons"

export const LEVEL_OPTIONS = {
  INTERNATIONAL: 'international',
  COUNTRY: 'country',
  REGION: 'region',
  DISTRICT: 'district',
  PARISH: 'parish',
  OTHER: 'other'
}
export const levelOptions = [
  {
    label: 'International',
    value: LEVEL_OPTIONS.INTERNATIONAL,
    icon: mdiWeb,
  },
  {
    label: 'Land',
    value: LEVEL_OPTIONS.COUNTRY,
    icon: OrgBundeskirche,
  },
  {
    label: 'Region',
    value: LEVEL_OPTIONS.REGION,
    icon: OrgLandeskirche,
  },
  {
    label: 'Bezirk',
    value: LEVEL_OPTIONS.DISTRICT,
    icon: OrgKirchenkreis,
  },
  {
    label: 'Gemeinde',
    value: LEVEL_OPTIONS.PARISH,
    icon: OrgGemeinde,
  },
  {
    label: 'Andere',
    value: LEVEL_OPTIONS.OTHER,
    icon: mdiStarOutline,
  }
]
