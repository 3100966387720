import { ADD_LOCATION, FETCH_LOCATIONS, FETCH_QR_CODE } from './action-types'
import {
  APPEND_LOCATION,
  SET_LOCATIONS,
  SET_QR_CODE
} from './mutation-types'
import { INC_LOADING, DEC_LOADING, SET_ERROR_MESSAGE } from '@/modules/common/store/mutation-types'
import { cachedActionWrapper } from '@/modules/common/store/tools'
import { CACHE_LOCATIONS } from '@/store/cache-types'

export default {
  namespaced: true,
  state: function () {
    return {
      locations: [],
      qrCodes: {}
    }
  },
  getters: {
    locationsLookup: (state) => {
      if (!state.locations) return {}
      const result = {}
      state.locations.forEach(loc => { result[loc.url] = loc })
      return result
    }
  },
  mutations: {
    [SET_LOCATIONS]: (state, payload) => {
      if (!payload) {
        payload = []
      }
      state.locations = payload
    },
    [APPEND_LOCATION]: (state, payload) => {
      state.locations = state.locations.concat([payload])
    },
    [SET_QR_CODE]: (state, { url, data }) => {
      state.qrCodes = {
        ...state.qrCodes,
        [url]: data
      }
    }
  },
  actions: {
    [FETCH_LOCATIONS]: async (context) => {
      return cachedActionWrapper(context, CACHE_LOCATIONS, async () => {
        const response = await context.rootGetters.restApi.get('location/')
        await context.commit(SET_LOCATIONS, response.data)
      }, async () => {
        await context.commit(SET_LOCATIONS, [])
      })
    },
    [FETCH_QR_CODE]: async ({ commit, rootGetters }, locationUrl) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get(locationUrl + 'qr_code/')
        await commit(SET_QR_CODE, { url: locationUrl, data: response.data })
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        await commit(DEC_LOADING, null, { root: true })
      }
    },
    [ADD_LOCATION]: async ({ state, commit, rootGetters }, data) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.post('location/', data)
        await commit(APPEND_LOCATION, response.data)
      } catch (error) {
        // FIXME Error handling
        // this.error = error.detail
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        await commit(DEC_LOADING, null, { root: true })
      }
    }
  }
}
