import Vuex from "vuex"
import commonStore from '@/modules/common/store'

export default new Vuex.Store({
  state: () => {
    return {
      ...commonStore.state(),
    }
  },
  modules: {
  },
  actions: {
    ...commonStore.actions,
  },
  mutations: {
    ...commonStore.mutations,
  }
})
