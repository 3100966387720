<template>
  <v-dialog
    :value="isOpen"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>
        <v-toolbar
          color="primary"
          dark
          fixed
          flat
        >
          <v-btn
            dark
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <router-link
            v-if="personUrl"
            :to="{ name: 'kige-person-detail', params: { personUrl, dataId }}"
          >
            <v-btn
              color:
              elevation="0"
              primary
              right
              text
              @click="closeDialog"
            >
                  <span
                    class="font-weight-bold"
                    style="font-size: 0.9em;"
                  >Vollbildansicht</span>
            </v-btn>
          </router-link>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <person-data-detail
          v-if="personUrl"
          :person-url="personUrl"
          @data-id-changed="dataIdChanged"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonDataDetail from "@/modules/kirchgeld/components/PersonDataDetail"
export default {
  name: 'PersonDetailDialog',
  components: { PersonDataDetail },
  emits: ['close'],
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    personUrl: {
      type: String,
      required: false,
      default: () => null
    }
  },
  data: function () {
    return {
      dataId: null,
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    dataIdChanged (dataId) {
      this.dataId = dataId
    }
  }
}
</script>
