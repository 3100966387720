
import Vue from 'vue'

import DatetimePicker from 'vuetify-datetime-picker'
import 'vuetify-datetime-picker/src/stylus/main.styl'
import Vuelidate from 'vuelidate'
import contenteditable from 'vue-contenteditable'
import SweetModal from 'sweet-modal-vue/src/plugin'
import Fitty from 'vue-fitty'
import PortalVue from 'portal-vue'

import App from './App.vue'

import router from './router'
import store from './store'
import config from './config'

import zubeRouter from './modules/spendenbescheinigung/router'
import zubeStore from './modules/spendenbescheinigung/store'

import kigeRouter from './modules/kirchgeld/router'
import kigeStore from './modules/kirchgeld/store'

import { sync } from 'vuex-router-sync'

import amount from './filters/amount'
import truncate from './filters/truncate'
import currency from './filters/currency'
import currencySymbol from "@/filters/currency-symbol"

import '@fontsource/roboto'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import 'styles/main.styl'
// import '@babel/polyfill'  // FIXME Do we need this?

import * as Sentry from "@sentry/vue"

import { isoFromNow, isoToHuman } from '@/filters/datetime'

import i18n from './i18n'

if (config.sentry.dsn) {
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: config.sentry.release,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [config.backend.rest, config.kirchgeldApi.rest, /^\//],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  })
}

Vue.config.productionTip = false
Vue.use(DatetimePicker)
Vue.use(contenteditable)
Vue.use(Vuelidate)
Vue.use(SweetModal)
Vue.use(Fitty)
Vue.use(PortalVue)
Vue.filter('amount', amount)
Vue.filter('truncate', truncate)
Vue.filter('currency', currency)
Vue.filter('currencySymbol', currencySymbol)
Vue.filter('isoToHuman', isoToHuman)
Vue.filter('isoFromNow', isoFromNow)

sync(store, router)

let variantRouter = router
let variantStore = store

if (config.variant === 'spendenbescheinigung.digital') {
  variantRouter = zubeRouter
  variantStore = zubeStore
} else if (config.variant === 'kirchgeld.digital') {
  variantRouter = kigeRouter
  variantStore = kigeStore
}

new Vue({
  router: variantRouter,
  store: variantStore,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
