<template>
  <v-container id="user-admin">
    <div
      v-if="error"
      class="error"
    >{{ error }}
    </div>

    <v-text-field
      v-if="!specificOrganization"
      v-model="searchField"
      block
      clearable
      label="Filter"
      outlined
      placeholder="Name, Benutzername, E-Mail"
    ></v-text-field>
    <edit-user-dialog
      :user="selectedUser"
      :is-open="isEditing"
      :available-organizations="formattedOrganizations"
      @close="onEditFinalize"
      @update-user="onUserUpdated"
    ></edit-user-dialog>
    <v-dialog
      v-model="newUser"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="isEntry"
          class="mx-1"
          color="primary"
          fab
          small
          @click="openNewUser"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>{{ mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Benutzer hinzufügen</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editUserName"
            :rules="[rules.required]"
            label="Name"
            name="name"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="editUserUsername"
            :rules="[rules.required]"
            label="Benutzername"
            name="username"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="editUserEmail"
            :rules="[rules.email]"
            label="E-Mail-Adresse"
            name="email"
            outlined
          ></v-text-field>
          <span v-if="!specificOrganization">
                <user-admin-org-selection-template
                  :org-list="formattedOrganizations"
                  @organization-selection="selectedOrg"
                ></user-admin-org-selection-template>
              </span>
          <span v-if="newUserRandomPassword">
                <v-text-field
                  :disabled="newUserRandomPassword"
                  label="Passwort(Link per Mail)"
                  name="password"
                  outlined
                ></v-text-field>
            </span>
          <v-text-field
            v-if="!newUserRandomPassword"
            v-model="editUserPassword"
            label="Passwort"
            name="password"
            outlined
          ></v-text-field>
          <password
            v-if="false"
            v-model="editUserPassword"
            :strength-meter-only="true"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!checkFormValidity"
            :loading="creating"
            class="create-button"
            color="primary"
            @click="createUser"
          >Anlegen&hellip;
          </v-btn>
          <v-btn
            plain
            @click="onEditFinalize"
          >Abbr.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      id="organization-body"
      class="content"
    >
      <v-data-table
        :headers="headers"
        :items="userListToShow"
        :footer-props="{ 'items-per-page-options': [20, 50, 100, 200, -1] }"
        :items-per-page="itemsPerPage"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            :disabled="isEditing"
            class="mx-1"
            color="primary"
            fab
            x-small
            @click="editUser(item)"
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
          <v-btn
            v-if="isSuperuser"
            class="mx-1"
            color="brown"
            fab
            title="Als dieser Benutzer anmelden"
            x-small
            @click="impersonate(item.url)"
          >
            <v-icon dark>{{ mdiAccountSwitchOutline }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.pw_check_status="{item}">
          <v-icon
            :color="passwordStatus(item).color"
            :title="passwordStatus(item).title"
          >
            {{ passwordStatus(item).icon }}
          </v-icon>
          <v-icon
            :color="otpStatus(item).color"
            :title="otpStatus(item).title"
          >
            {{ otpStatus(item).icon }}
          </v-icon>
          <v-icon
            :color="authStatus(item).color"
            :title="authStatus(item).title"
          >
            {{ authStatus(item).icon }}
          </v-icon>
          <v-icon
            :color="superUserStatus(item).color"
            :title="superUserStatus(item).title"
          >
            {{ superUserStatus(item).icon }}
          </v-icon>
        </template>
        <template v-slot:item.permissions="{ item }">
          <!--          <user-admin-org-selection-template-->
          <!--            :org-list="formattedOrganizations"-->
          <!--            @organization-selection="selectedOrg"-->
          <!--            v-if="item.id === editUserItem.id"-->
          <!--          ></user-admin-org-selection-template>-->
          <v-list>
            <v-list-item
              v-for="(permission, items) in item.permissions"
              :key="items.id+'-'+items"
            >
              <v-list-item-content>
                {{ allOrganizations[permission.organization] | getName }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-slot:item.roles="{ item }">
          <v-list>
            <v-list-item
              v-for="(permission, items) in item.permissions"
              :key="items.id+'-'+items"
            >
              <v-list-item-content>
                <span v-if="enumsByName && enumsByName.RoleType && enumsByName.RoleType[permission.role]">
                    {{ enumsByName.RoleType[permission.role] }}
                  </span>
                <span v-else>{{ permission.role }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-slot:item.has_usable_password="{}">
          <!--          <v-text-field-->
          <!--            v-model="editUserItem.password"-->
          <!--            label="Passwort"-->
          <!--            name="password"-->
          <!--            outlined-->
          <!--          ></v-text-field>-->
          <password
            v-if="false"
            v-model="selectedUser.password"
            :strength-meter-only="true"
          />
        </template>
      </v-data-table>
      <v-card-text v-if="!specificOrganization">
        E-Mail Liste (gefiltert):
        <span
          v-for="user in userListToShow"
          v-bind:key="user.key"
        >
          <template v-if="isValidExportEmail(user.email)">{{ user.email }}; </template>
        </span>
      </v-card-text>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  ADD_USER,
  FETCH_ORGANIZATIONS,
  FETCH_USER_LIST,
  IMPERSONATE,
  UPDATE_CURRENT_USER
} from '@/store/action-types'
import Password from 'vue-password-strength-meter'
import { shortenParish } from "@/lib/regex-tools"
import { levelOptions } from "@/lib/level-options"
import UserAdminOrgSelectionTemplate from "@/components/management/UserAdminOrgSelectionTemplate"
import { INVALIDATE_CACHE } from "@/modules/common/store/action-types"
import { CACHE_USER_LIST } from "@/store/cache-types"
import {
  mdiPencil,
  mdiPlus,
  mdiAccountSwitchOutline
} from '@mdi/js'
import EditUserDialog from "@/components/user/EditUserDialog.vue"

export default {
  name: 'user-admin',
  components: {
    EditUserDialog,
    Password,
    UserAdminOrgSelectionTemplate
  },
  props: {
    specificOrganization: {
      required: false,
      default: null
    }
  },
  filters: {
    getName: (val) => {
      return (val ?? {})?.name ?? null
    }
  },
  data () {
    return {
      creating: false,
      updating: false,
      newUser: false,
      newUserRandomPassword: true,
      editUserName: null,
      editUserUsername: null,
      editUserEmail: null,
      editUserOrganization: null,
      editUserPassword: null,
      error: null,
      searchField: '',
      filteredUserList: [],
      rules: {
        required: value => !!value || 'Dieses Feld darf nicht leer sein.',
        email: value => {
          return ((!value) ? 'Dieses Feld darf nicht leer sein.' : ((/^[^@]+@[^@]{2,}\.[a-zA-Z0-9]+/).test(value) ?? '') || 'Gültige E-Mail-Adresse eingeben.')
        },
      },
      selectedUser: {
        id: 0,
        name: '',
        username: '',
        email: '',
        password: ''
      },
      isEditing: false,
      itemsPerPage: 20,
      mdiPencil,
      mdiPlus,
      mdiAccountSwitchOutline
    }
  },
  computed: {
    ...mapState('organization', ['organizations']),
    ...mapState('user', ['userList']),
    ...mapState('staticConfiguration', ['enumsByName', 'permissionParts']),
    ...mapGetters('organization', ['allOrganizations']),
    ...mapGetters('user', ['allUsers', 'isSuperuser']),
    isEntry: function () {
      return !!(this.newUser || this.isEditing)
    },
    checkFormValidity () {
      return !!(this.editUserEmail && this.editUserName && this.editUserUsername && (/^[^@]+@[^@]{2,}\.[a-zA-Z0-9]+/).test(this.editUserEmail))
    },
    formattedOrganizations () {
      return Object.values(this.allOrganizations).map(org => {
        const icon = levelOptions.find(lvl => lvl.value === org.level)
        return {
          ...org,
          name: shortenParish(org.name),
          id: org.id,
          url: org.url,
          icon: icon ? icon.icon : ''
        }
      }).sort((org1, org2) => {
        if (org1.name > org2.name) {
          return 1
        }
        if (org1.name < org2.name) {
          return -1
        }
        return 0
      })
    },
    userListToShow () {
      const newProp = { roles: '' }

      return this.filteredUserList.map(obj => Object.assign({}, obj, newProp))
    },
    headers () {
      const headers = [
        {
          text: "",
          value: "action"
        },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Benutzername",
          value: "username"
        },
        {
          text: "E-Mail-Adresse",
          value: "email"
        },
      ]
      if (!this.specificOrganization) {
        headers.push({
          text: "Sicherheit",
          value: "pw_check_status"
        })
        headers.push({
          text: "Organisation(en)",
          value: "permissions"
        })
      }
      if (!this.newUser && !this.specificOrganization) {
        headers.push({
          text: "Rolle",
          value: "roles"
        })
      }
      return headers
    },
    passwordStatus () {
      // eslint-disable-next-line camelcase
      return (item) => {
        if (!item.has_usable_password && !item.has_webauthn) {
          return {
            color: 'red',
            icon: 'mdi-alert',
            title: 'Weder Passwort- noch Passwortlose Anmeldung möglich'
          }
        } else if (!item.has_usable_password) {
          return {
            color: '',
            icon: 'mdi-circle-small',
            title: 'Passwort-Anmeldung deaktiviert'
          }
        } else if (item.pw_check_status === 'not_checked') {
          return {
            color: '',
            icon: 'mdi-lock-question',
            title: 'Passwort (noch) nicht geprüft'
          }
        } else if (item.pw_check_status === 'error') {
          return {
            color: 'red',
            icon: 'mdi-lock-question',
            title: 'Fehler bei der Prüfung des Passworts'
          }
        } else if (item.pw_check_status === 'ok') {
          return {
            color: '',
            icon: 'mdi-lock-check-outline',
            title: 'Passwort-Qualität ok'
          }
        } else if (item.pw_check_status === 'problematic') {
          return {
            color: 'yellow',
            icon: 'mdi-lock-alert-outline',
            title: 'Warnung: Schwaches Passwort'
          }
        } else if (item.pw_check_status === 'insecure') {
          return {
            color: 'red',
            icon: 'mdi-lock-alert',
            title: 'Warnung: Passwort unsicher, Login nicht möglich'
          }
        }
      }
    },
    otpStatus () {
      return (item) => {
        if (item.has_otp) {
          return {
            color: '',
            icon: 'mdi-two-factor-authentication',
            title: '2-Faktor-Authentifizierung aktiv'
          }
        } else {
          return {
            color: '',
            icon: 'mdi-circle-small',
            title: ''
          }
        }
      }
    },
    authStatus () {
      return (item) => {
        if (item.has_webauthn) {
          return {
            color: '',
            icon: 'mdi-key-variant',
            title: 'Passwortlose Anmeldung konfiguriert'
          }
        } else {
          return {
            color: '',
            icon: 'mdi-circle-small',
            title: ''
          }
        }
      }
    },
    superUserStatus () {
      return (item) => {
        if (item.is_superuser) {
          return {
            color: '',
            icon: 'mdi-shield-account',
            title: 'Admin'
          }
        } else {
          return {
            color: '',
            icon: 'mdi-circle-small',
            title: ''
          }
        }
      }
    },

  },
  methods: {
    impersonate: function (userUrl) {
      this.$store.dispatch('user/' + IMPERSONATE, { userUrl })
    },
    editUser (item) {
      this.isEditing = true
      this.selectedUser = Object.assign({}, item)
    },
    isValidExportEmail: function (email) {
      if (!email || email === '') {
        return false
      }
      if (email.endsWith('@digitalwolff.de')) {
        return false
      } else {
        return true
      }
    },
    selectedOrg (value) {
      this.editUserOrganization = value
    },
    openNewUser: function () {
      this.newUser = true
      this.newUserRandomPassword = true
      this.editUserName = this.editUserUsername = this.editUserEmail = this.editUserOrganization = null
      this.editUserPassword = null
    },
    async createUser () {
      this.creating = true
      try {
        await this.$store.dispatch('user/' + ADD_USER, {
          name: this.editUserName,
          username: this.editUserUsername,
          email: this.editUserEmail,
          permissions: [
            { organization: this.specificOrganization ?? this.editUserOrganization }
          ],
        })
        this.newUser = false
        await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_USER_LIST })
        await this.$store.dispatch('user/' + FETCH_USER_LIST)
      } finally {
        this.creating = false
      }
    },
    async updateUser (url) {
      this.updating = true
      const permissions = (this.editUserOrganization) ? new Set([...this.allUsers[url].permissions, { organization: this.editUserOrganization }]) : new Set(this.allUsers[url].permissions)
      try {
        await this.$store.dispatch('user/' + UPDATE_CURRENT_USER, {
          url,
          name: this.selectedUser.name,
          username: this.selectedUser.username,
          password: this.selectedUser.password || null,
          email: this.selectedUser.email,
          permissions: [...permissions]
        })
        this.selectedUser = Object.assign({}, {})
        await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_USER_LIST })
        await this.$store.dispatch('user/' + FETCH_USER_LIST)
      } finally {
        this.updating = false
        this.isEditing = false
      }
    },
    onEditFinalize () {
      this.newUser = false
      this.selectedUser = Object.assign({}, {})
      this.isEditing = false
    },
    async onUserUpdated () {
      this.onEditFinalize()
      await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_USER_LIST })
      await Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('user/' + FETCH_USER_LIST)
      ])
    },
    checkSpecificPermissions (usr) {
      for (let i = 0; i < usr.permissions.length; i++) {
        if (usr.permissions[i].organization === this.specificOrganization) {
          return true
        }
      }
      return false
    },
    isSuperuser () {
      // eslint-disable-next-line camelcase
      return this.user?.is_superuser || false
    }
  },

  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('user/' + FETCH_USER_LIST)
      ])
    })
  },
  watch: {
    searchField (newValue) {
      const allOrgas = this.allOrganizations
      if (!newValue || newValue.trim() === '') {
        this.filteredUserList = this.userList
      } else {
        this.filteredUserList = this.userList.filter(function (el) {
          let orgaValid = false
          for (const per of (el.permissions ? el.permissions : [])) {
            if (allOrgas[per.organization].name.toLowerCase().indexOf(newValue.toLowerCase()) > -1) {
              orgaValid = true
              break
            }
          }
          return el.email.toLowerCase().indexOf(newValue.toLowerCase()) > -1 ||
            el.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1 ||
            el.username.toLowerCase().indexOf(newValue.toLowerCase()) > -1 ||
            orgaValid
        })
      }
    },
    userList:
      {
        immediate: true,
        handler (newValue) {
          if (newValue) {
            if (this.specificOrganization) {
              this.filteredUserList = newValue.filter(usr => this.checkSpecificPermissions(usr)).filter(user => !!user?.is_active)
            } else {
              this.filteredUserList = newValue.filter(user => !!user?.is_active)
            }
          }
        }
      }
  },
}
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
