<script>
import mqtt from 'mqtt'
import config from '@/config'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    devices: {
      type: Array,
      required: true,
    },
    extendedDevicesMqttIds: {
      type: Set,
      default: () => { return new Set() },
    },
    showWifiDeviceMqttId: {
      validator: value => typeof value === 'string' || value === false,
      default: null,
    }
  },
  computed: {
    ...mapState(['now']),
  },
  render () {
    // Renderless component
    return this.$scopedSlots.default({
      mqttState: this.mqttState,
      mqttVpnState: this.mqttVpnState,
      mqttConnected: this.mqttConnected,
      wifiList: this.wifiList,
    })
  },
  data () {
    return {
      mqttState: {},
      mqttVpnState: {},
      mqttClient: null,
      mqttConnected: false,
      wifiList: [],
    }
  },
  beforeDestroy () {
    if (this?.mqttClient) {
      this.mqttClient.end()
    }
  },
  mounted () {
    this.$nextTick(() => {
      const username = this.$store.state.user.user?.username ?? null
      const password = this.$store.state.user.auth?.token ?? null
      if (!(username && password)) { return }

      const mqttClient = mqtt.connect(config.telemetry.url, {
        username,
        password
      })

      this.$set(this, "mqttClient", mqttClient)
      mqttClient.on('connect', (connack) => {
        this.mqttConnected = true
        // Listen to the connected event on other pages an implement subscriber / handler logic there
        this.$emit("connected", mqttClient)
      })
      mqttClient.on('close', () => {
        this.mqttConnected = false
        this.mqttState = {}
        this.mqttVpnState = {}
        this.wifiList = []
        this.$emit("disconnected")
      })
      mqttClient.on('message', (topic, message) => {
        const parts = topic.split('/')
        if (parts[0] === 'kb' || parts[0] === 'vpn') {
          let target = {}
          if (parts[0] === 'kb') {
            target = this.mqttState
          } else {
            target = this.mqttVpnState
          }
          if (!this.mqttState[parts[1]]) {
            this.$set(target, parts[1], {})
          }
          const subpath = parts.slice(2).join('/')
          if (subpath === 'wifi/scan_results') {
            const wifiList = JSON.parse(message.toString())
            wifiList.sort((a, b) => parseInt(b['signal level']) - parseInt(a['signal level']))
            wifiList.forEach((item, index) => {
              item.wifiStrength = 2
              if (item['signal level']) {
                const level = parseInt(item['signal level'])
                if (level) {
                  item.wifiStrength = 95 + level
                }
              }
            })
            this.wifiList = wifiList
          } else {
            this.$set(target[parts[1]], subpath, message.toString())
          }
        }
      })
    })
  },
  watch: {
    devices: {
      immediate: true,
      handler (newVal, oldVal) {
        this.updateRegistrations(oldVal, newVal)
      }
    },
    extendedDevicesMqttIds: {
      handler (newVal) {
        this.$nextTick(() => this.updateDataRequest())
      }
    },
    mqttClient: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.updateRegistrations([], this.devices)
        }
      }
    },
    now: {
      handler (newVal) {
        this.$nextTick(() => this.updateDataRequest())
      }
    },
    showWifiDeviceMqttId: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        if (oldValue) {
          this.mqttClient.unsubscribe('kb/' + oldValue + '/wifi/scan_results')
        }
        if (newValue) {
          this.wifiList = []
          this.mqttClient.subscribe('kb/' + newValue + '/wifi/scan_results')
        }
      }
    }
  },
  methods: {
    updateDataRequest: function () {
      const now_ = moment().unix()
      if (this?.mqttClient) {
        (this.devices ?? []).forEach(device => {
          // eslint-disable-next-line camelcase
          if (device?.mqtt_identity) {
            this.mqttClient.publish(`kb/${device.mqtt_identity}/_data/request`, `${now_}`)
          }
        })
        for (const extendedDeviceMqttId of (this.extendedDevicesMqttIds || new Set())) {
          this.mqttClient.publish(`kb/${extendedDeviceMqttId}/_data/request_extended`, `${now_}`)
        }
        if (this.showWifiDeviceMqttId && !(this.extendedDevicesMqttIds || new Set()).has(this.showWifiDeviceMqttId)) {
          this.mqttClient.publish(`kb/${this.showWifiDeviceMqttId}/_data/request_extended`, `${now_}`)
        }
      }
    },
    updateRegistrations: function (oldVal, newVal) {
      function getTopics (device) {
        // eslint-disable-next-line camelcase
        if (!device?.mqtt_identity || !device?.bcmserno) {
          return []
        }
        return [
          `kb/${device.mqtt_identity}/status`,
          `kb/${device.mqtt_identity}/battery_percent`,
          `kb/${device.mqtt_identity}/device_information`,
          `kb/${device.mqtt_identity}/screen/+`,
          `kb/${device.mqtt_identity}/net/gateways`,
          `kb/${device.mqtt_identity}/net/+/status`,
          `kb/${device.mqtt_identity}/wifi/status/+`,
          `kb/${device.mqtt_identity}/wifi/signal/RSSI`,
          `kb/${device.mqtt_identity}/wwan/signal/network_type`,
          `kb/${device.mqtt_identity}/wwan/signal/RSSI`,
          `vpn/${device.bcmserno}/last_handshake`
        ]
      }

      if (this?.mqttClient) {
        (oldVal ?? []).forEach(device => {
          const topics = getTopics(device)
          if (topics.length) { this.mqttClient.unsubscribe(topics) }
        });
        (newVal ?? []).forEach(device => {
          const topics = getTopics(device)
          if (topics.length) { this.mqttClient.subscribe(topics) }
        })
        this.updateDataRequest(newVal)
      }
    },
  }
}
</script>
