import {
  ADD_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  PATCH_ORGANIZATION,
  ADD_ORGANIZATION_CONTACT,
  PATCH_ORGANIZATION_CONTACT,
  DELETE_ORGANIZATION_CONTACT,
  PATCH_ORGANIZATION_ADDRESS,
  ADD_ORGANIZATION_ADDRESS,
  UPDATE_ORGANIZATION_ADDRESS,
  UPDATE_LOCAL_ORGANIZATION_SETTING,
  UPDATE_INHERIT_ORGANIZATION_SETTING,
  UPDATE_ORGANIZATION_SETTING,
  INVALIDATE_ORGANIZATION_ADDRESS
} from './action-types'
import {
  APPEND_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
} from './mutation-types'
import { merge as deepMerge } from 'lodash'
import { DEVICE_TYPES } from '@/lib/device-db'
import { cachedActionWrapper, loadingStateWrapper } from '@/modules/common/store/tools'
import { CACHE_ORGANIZATIONS } from '@/store/cache-types'
import { SET_ERROR_MESSAGE, SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"
import moment from "moment"
import { isCurrentlyValid } from "@/lib/sort-utils"

export default {
  namespaced: true,
  state: function () {
    return {
      organizations: [],
    }
  },
  getters: {
    writableOrganizations: (state) => {
      return (state.organizations ?? []).filter((val) => val.is_writable)
    },
    idMap: (state) => {
      const result = {}
      if (!state.organizations) {
        return {}
      }
      state.organizations.forEach((val) => {
        result[val.id] = val.url
      })
      return result
    },
    currentOrganization: (state, getters, rootState) => {
      if (!rootState.route || !rootState.route || !rootState.route.params || !rootState.route.params.id) {
        return null
      }
      if (!getters.allOrganizations || !getters.idMap) {
        return null
      }

      return getters.allOrganizations[
        getters.idMap[
          rootState.route.params.id
        ]
      ]
    },
    allOrganizations: (state) => {
      const result = {}
      if (!state.organizations) {
        return {}
      }
      state.organizations.forEach((val) => {
        result[val.url] = val
      })
      return result
    },
    parents: (state, getters) => {
      const result = {}
      if (!getters.allOrganizations) {
        return {}
      }
      const findParents = (org) => {
        const result = []
        while (org.parent) {
          result.push(org.parent)
          org = getters.allOrganizations[org.parent]
        }
        return result
      }
      state.organizations.forEach((val) => { result[val.url] = findParents(val) })
      return result
    },
    orgtree: (state, getters) => {
      const adjacency = {}
      if (!state.organizations) {
        return {}
      }
      state.organizations.forEach((val) => {
        let parentUrl = val.parent
        if (!parentUrl || !getters.allOrganizations[parentUrl]) {
          parentUrl = 'NONE'
        }
        if (!(parentUrl in adjacency)) {
          adjacency[parentUrl] = []
        }
        adjacency[parentUrl].push(val.url)
      })
      return adjacency
    },
    organizationRelevantDevicesRawByUrl: (state, getters) => { /* FIXME Should be renamed to organizationRelevantDeviceTypesRawByUrl */
      const orgtree = getters.orgtree
      const orgs = getters.allOrganizations
      const result = {}
      const INHERIT_DEVICE_TYPES = ['john', 'bart']

      function walkTree (currentNodeUrl, inheritDevices, parentUrls) {
        // eslint-disable-next-line camelcase
        const current = new Set(orgs[currentNodeUrl]?.device_types ?? [])

        // Inherit down only some device types
        inheritDevices = new Set([...inheritDevices, ...[...current].filter(val => INHERIT_DEVICE_TYPES.includes(val))])

        result[currentNodeUrl] = new Set([...current, ...inheritDevices]);

        (orgtree[currentNodeUrl] ?? []).forEach(childNodeUrl => {
          walkTree(childNodeUrl, inheritDevices, [...parentUrls, currentNodeUrl])
        })
      }

      (orgtree?.NONE ?? []).forEach(childNodeUrl => {
        walkTree(childNodeUrl, new Set(), [])
      })

      return result
    },
    organizationRelevantDeviceTypesForUrl: (state, getters, rootState, rootGetters) => (url) => {
      const rawDeviceTypes = getters.organizationRelevantDevicesRawByUrl[url] ?? new Set()
      const effectiveSettings = rootGetters['settings/effectiveSettingsForUrl'](url) ?? {}
      return new Set([
        // Start with rawDeviceTypes,
        // remove device types if their class is override deactivated for this organization
        ...Array.from(rawDeviceTypes).filter(deviceType => {
          if (!(deviceType in DEVICE_TYPES)) {
            throw new Error(`unknown device type: ${deviceType}. Did you forget to add that to src/lib/device-db.js?`)
          }
          return (effectiveSettings?.override ?? {})[`enable${DEVICE_TYPES[deviceType].deviceClass}`] ?? true
        }),
        // Add device types if their class is override activated for this organization
        ...Array.from(Object.keys(DEVICE_TYPES)).filter(deviceType => {
          if (!(deviceType in DEVICE_TYPES)) {
            throw new Error(`unknown device type: ${deviceType}. Did you forget to add that to src/lib/device-db.js?`)
          }
          return (effectiveSettings?.override ?? {})[`enable${DEVICE_TYPES[deviceType].deviceClass}`] ?? false
        }),
      ])
    },
    organizationSettingsByUrl: (state, getters) => {
      if (!state.organizations) {
        return {}
      }
      const orgsByUrl = getters.allOrganizations
      const parentCache = {}
      const defaultSettings = {
        branding: '_default'
      }
      function fillData (orgUrl) {
        if (!Object.prototype.hasOwnProperty.call(parentCache, orgUrl)) {
          if (orgsByUrl[orgUrl].parent) {
            fillData(orgsByUrl[orgUrl].parent)
          }
          parentCache[orgUrl] = deepMerge(
            {},
            defaultSettings,
            parentCache[orgsByUrl[orgUrl].parent] ?? {},
            // eslint-disable-next-line camelcase
            (orgsByUrl[orgUrl]?.settings_inherit ?? {})
          )
        }
      }
      state.organizations.forEach((org) => fillData(org.url))
      state.organizations.forEach((org) => {
        parentCache[org.url] = deepMerge(
          {},
          parentCache[org.url],
          // eslint-disable-next-line camelcase
          (orgsByUrl[org.url]?.settings_local ?? {})
        )
      })
      return parentCache
    },
    effectiveOrganizationFeaturesByUrl: (state, getters) => {
      const orgaFeatureMap = {}
      const organizations = state.organizations
      const orgtree = getters.orgtree
      if (!organizations || Object.entries(orgtree).length === 0) {
        return {}
      }

      organizations.forEach((org) => {
        const effectiveFeatures = new Set()

        const parents = getters.parents[org.url] ?? []

        parents.forEach((parentOrgUrl) => {
          const parentOrg = getters.allOrganizations[parentOrgUrl]
          parentOrg.features.forEach((feature) => {
            if (parentOrg.url !== org.url && feature.inherit && isCurrentlyValid(feature)) {
              effectiveFeatures.add(feature.type)
            }
          })
        })

        org.features.forEach((feature) => {
          if (isCurrentlyValid(feature)) {
            effectiveFeatures.add(feature.type)
          }
        })

        orgaFeatureMap[org.url] = effectiveFeatures
      })
      return orgaFeatureMap
    }
  },
  mutations: {
    [SET_ORGANIZATIONS]: (state, { data }) => {
      state.organizations = data ?? []
    },
    [SET_ORGANIZATION]: (state, { data }) => {
      const organizations = [...(state.organizations ?? [])]
      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].id === data.id) {
          organizations[i] = data
        }
      }
      state.organizations = organizations
    },
    [APPEND_ORGANIZATION]: (state, payload) => {
      state.organizations = state.organizations.concat([payload])
    }
  },
  actions: {
    [PATCH_ORGANIZATION]: async (context, { url, data }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(url, data)
        if (response.status === 200) {
          await context.commit(SET_ORGANIZATION, { data: response.data })
          return true
        }
        return false
      }, async () => {
        return false
      })
    },
    [FETCH_ORGANIZATIONS]: async (context) => {
      return cachedActionWrapper(context, CACHE_ORGANIZATIONS, async () => {
        const response = await context.rootGetters.restApi.get('organization/')
        await context.commit(SET_ORGANIZATIONS, { data: response.data })
      }, async () => {
        await context.commit(SET_ORGANIZATIONS, { data: [] })
      })
    },
    [ADD_ORGANIZATION]: async (context, data) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.post('organization/', data)
        await context.commit(APPEND_ORGANIZATION, response.data)
        context.commit(SET_SNACKBAR_MESSAGE, { message: 'Organisation wurde erfolgreich gespeichert.' }, { root: true })
        return response.data
      }, async () => {
        return false
      })
    },
    [ADD_ORGANIZATION_CONTACT]: async (context, contact) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.post('contact/', contact)
        if ([200, 201].includes(response.status)) {
          const organizationResponse = await context.rootGetters.restApi.get(contact.organization)
          await context.commit(SET_ORGANIZATION, { data: organizationResponse.data })
          context.commit(SET_SNACKBAR_MESSAGE, { message: 'Kontakt wurde erfolgreich gespeichert.' }, { root: true })
          return organizationResponse.data
        }
        throw Error('Fehler beim Speichern des Kontakts')
      }
      , async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [PATCH_ORGANIZATION_CONTACT]: async (context, contact) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(`contact/${contact.id}/`, contact)
        if ([200, 201].includes(response.status)) {
          const organizationResponse = await context.rootGetters.restApi.get(contact.organization)
          await context.commit(SET_ORGANIZATION, { data: organizationResponse.data })
          context.commit(SET_SNACKBAR_MESSAGE, { message: 'Kontakt wurde erfolgreich aktualisiert.' }, { root: true })
          return organizationResponse.data
        }
        throw Error('Fehler beim Speichern des Kontakts')
      }
      , async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [DELETE_ORGANIZATION_CONTACT]: async (context, contact) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.delete(`contact/${contact.id}/`)
        if ([200, 204].includes(response.status)) {
          const organizationResponse = await context.rootGetters.restApi.get(contact.organization)
          await context.commit(SET_ORGANIZATION, { data: organizationResponse.data })
          context.commit(SET_SNACKBAR_MESSAGE, { message: 'Kontakt wurde erfolgreich gelöscht.' }, { root: true })
          return true
        }
        throw Error('Fehler beim Löschen des Kontakts')
      }
      , async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [PATCH_ORGANIZATION_ADDRESS]: async (context, address) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(`address/${address.id}/`, address)
        if ([200, 201].includes(response.status)) {
          const organizationResponse = await context.rootGetters.restApi.get(address.organization)
          await context.commit(SET_ORGANIZATION, { data: organizationResponse.data })
          context.commit(SET_SNACKBAR_MESSAGE, { message: 'Adresse wurde erfolgreich aktualisiert.' }, { root: true })
          return organizationResponse.data
        }
        throw Error('Fehler beim Speichern der Adresse')
      }
      , async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [ADD_ORGANIZATION_ADDRESS]: async (context, address) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.post('address/', address)
        if ([200, 201].includes(response.status)) {
          const organizationResponse = await context.rootGetters.restApi.get(address.organization)
          await context.commit(SET_ORGANIZATION, { data: organizationResponse.data })
          context.commit(SET_SNACKBAR_MESSAGE, { message: 'Adresse wurde erfolgreich gespeichert.' }, { root: true })
          return organizationResponse.data
        }
        throw Error('Fehler beim Speichern der Adresse')
      }
      , async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [UPDATE_ORGANIZATION_ADDRESS]: async ({ dispatch }, payload) => {
      const currentTime = moment().format()
      const patchPayload = { ...payload.patchPayload, valid_notafter: currentTime, organization: payload.organization }
      const addPayload = { ...payload.addPayload, valid_from: currentTime, organization: payload.organization }
      await dispatch(PATCH_ORGANIZATION_ADDRESS, patchPayload)
      const response = await dispatch(ADD_ORGANIZATION_ADDRESS, addPayload)
      return response.data
    },
    [INVALIDATE_ORGANIZATION_ADDRESS]: async ({ dispatch }, payload) => {
      const currentTime = moment().format()
      const patchPayload = {
        ...payload.address,
        valid_notafter: currentTime,
        organization: payload.organization
      }
      const response = await dispatch(PATCH_ORGANIZATION_ADDRESS, patchPayload)
      return response.data
    },
    [UPDATE_ORGANIZATION_SETTING]: async (context, { organization, setting, scope, subscope }) => {
      return loadingStateWrapper(context, async () => {
        if (!['settings_local', 'settings_inherit'].includes(scope)) {
          throw new Error('Invalid scope, use either "settings_local" or "settings_inherit"')
        }
        if (!['features', 'override'].includes(subscope)) {
          throw new Error('Invalid subscope, use either "features" or "override"')
        }
        const payload = {
          [scope]: {
            [subscope]: {
              [setting.id]: setting.value
            }
          }
        }

        const response = await context.rootGetters.restApi.patch(organization.url, payload)
        await context.commit(SET_ORGANIZATION, { data: response.data })
        return response.data
      }, async (error) => {
        context.commit(SET_ERROR_MESSAGE, { message: error.message }, { root: true })
        return false
      })
    },
    [UPDATE_LOCAL_ORGANIZATION_SETTING]: async (context, { organization, setting, subscope = 'features' }) => {
      return await context.dispatch(UPDATE_ORGANIZATION_SETTING, { organization, setting, scope: 'settings_local', subscope })
    },
    [UPDATE_INHERIT_ORGANIZATION_SETTING]: async (context, { organization, setting, subscope = 'features' }) => {
      return await context.dispatch(UPDATE_ORGANIZATION_SETTING, { organization, setting, scope: 'settings_inherit', subscope })
    },
  }
}
