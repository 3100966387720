import { DEC_LOADING, INC_LOADING, SET_ERROR_MESSAGE, SET_WAITING_PROMISE } from './mutation-types'
import { UPDATE_TIME } from './action-types'
import * as Sentry from "@sentry/browser"

export async function loadingStateWrapper (context, successCase, errorCase = null) {
  context.commit(INC_LOADING, null, { root: true })
  try {
    return await successCase()
  } catch (error) {
    if (error?.response?.status !== 401) {
      await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
    }
    Sentry.captureException(error)
    if (errorCase) {
      await errorCase(error)
    }
  } finally {
    await context.commit(DEC_LOADING, null, { root: true })
    await context.dispatch(UPDATE_TIME, null, { root: true })
  }
}

export async function cachedActionWrapper (context, key, successCase, errorCase = null) {
  /* See if we're already doing the process requested */
  const cachedPromise = context.rootState.waitingPromises[key]
  if (cachedPromise) {
    return cachedPromise
  }

  /* Create the promise and store it in the root state */
  const promise = loadingStateWrapper(context, successCase, errorCase)
  context.commit(SET_WAITING_PROMISE, { key, promise }, { root: true })
  return promise
}
