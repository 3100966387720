<template>
  <span class="hyphenate">
    <template v-if="personData.akad_grad">{{personData.akad_grad}}</template>
    {{ fname_prefix }}
    <u>{{ personData.rufname }}</u>
    {{ fname_suffix }}
    {{ personData.familienname }}
    <template v-if="personData.familienname_zusatz">{{ personData.familienname_zusatz }}</template>
    <template v-if="personData.geburtsname">geb. {{ personData.geburtsname }}</template>
  </span>
</template>

<script>
export default {
  name: 'PersonName',
  props: {
    personData: {
      required: true,
    }
  },
  computed: {
    fname_prefix () {
      if (!this.personData.vornamen) {
        return ''
      }
      const parts = this.personData.vornamen.split(this.personData.rufname, 2)
      return parts[0]
    },
    fname_suffix () {
      if (!this.personData.vornamen) {
        return ''
      }
      const parts = this.personData?.vornamen.split(this.personData.rufname, 2)
      return parts[1]
    }
  }
}
</script>

<style lang="stylus" scoped>
.hyphenate
  hyphens auto
  word-break break-word
</style>
