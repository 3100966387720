<template>
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="56px" height="64px" viewBox="0 0 56 64">
    <g class="cash-collection-icon">
      <g>
        <path d="M1.5,63h19c0.28,0,0.5-0.22,0.5-0.5v-6c0-0.28-0.22-0.5-0.5-0.5h-19C1.22,56,1,56.22,1,56.5v6C1,62.78,1.22,63,1.5,63z" />
        <path d="M3.5,56h19c0.28,0,0.5-0.22,0.5-0.5v-6c0-0.28-0.22-0.5-0.5-0.5h-19C3.22,49,3,49.22,3,49.5v6C3,55.78,3.22,56,3.5,56z" />
        <path d="M1.5,49h19c0.28,0,0.5-0.22,0.5-0.5v-6c0-0.28-0.22-0.5-0.5-0.5h-19C1.22,42,1,42.22,1,42.5v6C1,48.78,1.22,49,1.5,49z" />
        <path d="M49.5,63h-19c-0.28,0-0.5-0.22-0.5-0.5v-6c0-0.28,0.22-0.5,0.5-0.5h19c0.28,0,0.5,0.22,0.5,0.5v6C50,62.78,49.78,63,49.5,63z" />
        <path d="M51.5,56h-19c-0.28,0-0.5-0.22-0.5-0.5v-6c0-0.28,0.22-0.5,0.5-0.5h19c0.28,0,0.5,0.22,0.5,0.5v6C52,55.78,51.78,56,51.5,56z" />
        <path d="M22,28H5.5C5.22,28,5,28.22,5,28.5v6C5,34.78,5.22,35,5.5,35H22" />
      </g>
      <g>
        <polyline points="55,20 49,20 49,1 55,1" />
        <path d="M10,28V17.36c0-0.88,0.29-1.74,0.83-2.44l7.67-9.97C19.45,3.72,20.91,3,22.46,3H49" />
        <path d="M17,28v-8l4-5h6c1.1,0,2,0.9,2,2v4l-7.28,8.26c-1.41,1.69-1.19,4.22,0.49,5.64l0,0c1.69,1.41,4.22,1.19,5.64-0.49L36,25c3,0,8-2,10-8h3" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CashCollectionIcon"
}
</script>

<style scoped>
  .cash-collection-icon {
    fill: none;
    stroke: currentColor;
    stroke-width: 3.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
</style>
