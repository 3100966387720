<template>
  <v-container fluid>
    <legacy-context-k-assign ref="importDialog" @imported="importDone"/>
    <v-row>
      <v-col cols=""><h2>Altdaten-Import anzeigen</h2></v-col>
      <v-col cols="3" class="mt-3" v-if="availablePlz && availablePlz.length"><v-select multiple v-model="query.selectedPlz" :items="availablePlz" label="Filter: Datensätze mit Adresse in PLZ"></v-select></v-col>
      <v-col cols="3" class="mt-3"><v-select multiple v-model="query.selectedYears" :items="AVAILABLE_YEARS" label="Filter: Datensätze mit Buchungen in Jahr"></v-select></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
          :headers="TABLE_HEADERS"
          :items="stammdatenForTable"
          :server-items-length="stammdaten.count"
          :loading="loading > 0"
          :options.sync="options"
          disable-sort
          disable-filtering
          :class="(loading > 0 && stammdatenForTable.length > 0) ? 'legacy-contextk-enable-row-blur' : ''"
        >
          <template #item.Geburtsdatum="{ item }">{{ item.Geburtsdatum | isoToHuman("DD.MM.YYYY") }}</template>
          <template #item._action="{ item }">
            <v-btn color="primary" small @click="confirmImport(item._orig)">
              <v-icon class="mr-2">{{ mdiAccountPlus }}</v-icon> übernehmen
            </v-btn>
          </template>
          <template #item.buchungenSum="{ item }">
            {{ item.buchungenSum | amount}} €
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { pick } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import Vue from 'vue'
import { GET_TENANTS } from '@/modules/kirchgeld/store/kirchgeld/action-types'
import { mdiAccountPlus } from '@mdi/js'
import LegacyContextKAssign from '@/modules/kirchgeld/components/LegacyContextKAssign.vue'

const TABLE_HEADERS = [
  { text: "ID", value: "ID" },
  { text: "", value: "_action" },
  { text: "Buch#", value: 'buchungenCount', align: "right" },
  { text: "BuchΣ", value: 'buchungenSum', align: "right" },
  ...[
    "Vorname",
    "Nachname",
    "Geburtsdatum",
    "Straße",
    "Hausnr",
    "PLZ",
    "Ort"
  ].map(item => ({ text: item, value: item })),
]

const AVAILABLE_YEARS = [
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
]

export default {
  name: "LegacyContextK",
  components: { LegacyContextKAssign },
  data () {
    return {
      TABLE_HEADERS,
      AVAILABLE_YEARS,
      mdiAccountPlus,

      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [true],
      },
      query: {
        selectedYears: [
          "2021",
          "2022",
        ],
        selectedPlz: [],
      },
      epoch: 0,

      rawPlz: [],
      stammdaten: {
        data: [],
        count: null,
        cacheKey: null,
        ts: null,
      },
      aktionenByUrl: {},
      zielgruppenByUrl: {},
      segmenteByUrl: {},
      mandantenByUrl: {},

      missingDataSerializeFetch: null,
    }
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_TENANTS)
    this.rawPlz = await loadingStateWrapper(this.$store, async () => {
      const response = await this.api.get(`${this.$store.state.kirchgeld.tenant.url}legacy/contextk/stammdaten/plz/`)
      return response?.data ?? []
    })
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('kirchgeld', ['api']),
    optionsForFetch () {
      if (!this.$store.state.kirchgeld?.tenant?.url) {
        return null
      }
      return {
        ...pick(this.options, ["page", 'itemsPerPage', 'sortBy', 'sortDesc']),
        ...pick(this.query, ['selectedYears', 'selectedPlz']),
        epoch: this.epoch,
      }
    },
    availablePlz () {
      const result = [...this.rawPlz]
      result.sort()
      return result
    },
    missingData () {
      const retval = {}
      retval.mandanten = Array.from(new Set([
        ...this.stammdaten.data.map(item => item?.MandantNr).filter(item => !!item),
        ...Object.values(this.zielgruppenByUrl).map(item => item?.MandantNr).filter(item => !!item),
        ...Object.values(this.aktionenByUrl).map(item => item?.MandantNr).filter(item => !!item),
        ...Object.values(this.segmenteByUrl).map(item => item?.MandantNr).filter(item => !!item),
      ])).filter(item => !this.mandantenByUrl[item])
      retval.zielgruppen = Array.from(new Set([
        ...this.stammdaten.data.map(item => (item?.zielgruppen ?? [])).flat()
      ])).filter(item => !this.zielgruppenByUrl[item])
      retval.aktionen = Array.from(new Set([
        ...this.stammdaten.data.map(item => (item?.aktionen ?? [])).flat()
      ])).filter(item => !this.aktionenByUrl[item])
      retval.segmente = Array.from(new Set([
        ...this.stammdaten.data.map(item => (item?.segmente ?? [])).flat()
      ])).filter(item => !this.segmenteByUrl[item])
      return retval
    },
    stammdatenForTable () {
      return this.stammdaten.data.map(item => ({
        ID: item.ID,
        buchungenCount: item.buchungen.length,
        // eslint-disable-next-line camelcase
        buchungenSum: item.buchungen.map(item => parseFloat(item?.raw_data?.Betrag ?? '0')).reduce((acc, cur) => acc + cur, 0),
        ...pick(item.raw_data, [
          "Vorname",
          "Nachname",
          "Geburtsdatum",
        ]),
        // eslint-disable-next-line camelcase
        ...pick(item?.AdrID?.raw_data, [
          "PLZ",
          "Ort",
          "Straße",
          "Hausnr",
        ]),
        _orig: item,
      }))
    },
  },
  watch: {
    optionsForFetch: {
      immediate: true,
      async handler (newVal) {
        if (!newVal) {
          return
        }

        await loadingStateWrapper(this.$store, async () => {
          const ts = Date.now()
          const years = this.query.selectedYears.length ? `&buchung_year=${this.query.selectedYears.join(",")}` : ""
          const plz = this.query.selectedPlz.length ? `&address_plz=${this.query.selectedPlz.join(",")}` : ""
          const cacheKey = `${plz}|${years}|${this.options.itemsPerPage}|${this.options.page}|${this.epoch}`
          if (this.stammdaten.cacheKey !== cacheKey && (!this.stammdaten.ts || this.stammdaten.ts < ts)) {
            const response = await this.api.get(`${this.$store.state.kirchgeld.tenant.url}legacy/contextk/stammdaten/?offset=${this.options.itemsPerPage * this.options.page}&limit=${this.options.itemsPerPage}${years}${plz}`)
            if (response?.data) {
              if (this.stammdaten.ts < ts) {
                this.stammdaten = {
                  count: response.data.count,
                  data: response.data.results,
                  cacheKey,
                  ts,
                }
              }
            }
          }
        })
      }
    },
    missingData: {
      immediate: true,
      async handler (newVal) {
        const mdsf = this.missingDataSerializeFetch
        this.missingDataSerializeFetch = loadingStateWrapper(this.$store, async () => {
          if (mdsf) {
            await mdsf
          }

          const tasks = []
          for (const [name, items] of Object.entries(newVal)) {
            for (const item of items) {
              if (!this[`${name}ByUrl`][item]) {
                tasks.push((async () => {
                  const response = await this.api.get(item)
                  if (response?.data) {
                    Vue.set(this[`${name}ByUrl`], item, response.data)
                  }
                })())
              }
            }
          }

          await Promise.allSettled(tasks)
        })

        await this.missingDataSerializeFetch
      }
    }
  },
  methods: {
    async confirmImport (item) {
      this.$refs.importDialog.import(item)
    },
    async importDone (person) {
      this.epoch += 1
      const personDetailUrl = this.$router.resolve(
        { name: 'kige-person-detail', params: { personUrl: person.url, dataId: person?.data[0]?.id ?? null } }
      ).href
      window.open(personDetailUrl, '_blank')
    }
  },
}
</script>

<!-- scoped doesn't work *into* a v-data-table -->
<style>
.legacy-contextk-enable-row-blur td {
  opacity: 0.8;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
</style>
