<template>
  <v-container id="user-profile">
    <v-snackbar :timeout="4000" :value="showPasswordChangeToast">
      Sie haben Ihr Passwort erfolgreich geändert.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showPasswordChangeToast = false"
        >
          Meldung schliessen {{ attrs }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar :timeout="4000" :value="showNameChangeToast">
      Sie haben Ihren Namen erfolgreich geändert.
      <template>
        <v-btn
          color="white"
          text
          @click="showNameChangeToast = false">
          Meldung schliessen
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar :timeout="4000" :value="showEmailChangeToast">
      Sie haben Ihre E-Mail Adresse erfolgreich geändert.
      <template>
        <v-btn
          color="white"
          text
          @click="showEmailChangeToast = false">
          Meldung schliessen
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row justify="center">
        <v-col cols="2">
          <h2>
            <menu-icon class="mr-2" icon="profile" scale="0.9"></menu-icon>
            Profil
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-card-title>
        <h3>Kontodaten</h3>
      </v-card-title>
      <v-card-text>
        <v-simple-table align="left" dense style="border: 0">
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Name:</td>
              <td>{{ user.name }} (
                <a v-if="!isNameChangeRequested"
                   @click="(isNameChangeRequested = true),(isEmailChangeRequested = false), (isPasswordChangeRequested = false) ">ändern</a>
                <a v-else @click="isNameChangeRequested = false">abbrechen</a>
                )
              </td>
            </tr>
            <tr>
              <td>Benutzername:</td>
              <td>{{ user.username }}</td>
            </tr>
            <tr>
              <td>E-Mail-Adresse:</td>
              <td>{{ user.email }} (
                <a v-if="!isEmailChangeRequested"
                   @click="(isEmailChangeRequested = true),(isNameChangeRequested = false), (isPasswordChangeRequested = false)">ändern</a>
                <a v-else @click="isEmailChangeRequested = false">abbrechen</a>
                )
              </td>
            </tr>
            <tr>
              <td>Passwort:</td>
              <td>********* (
                <a v-if="!isPasswordChangeRequested"
                   @click="(isPasswordChangeRequested = true),(isEmailChangeRequested = false),(isNameChangeRequested = false)">ändern</a>
                <a v-else @click="isPasswordChangeRequested = false">abbrechen</a>
                )
              </td>
            </tr>
            <tr>
              <td>Einmalcodes:</td>
              <td>
                <span v-if="user.has_otp">Aktiviert</span>
                <span v-else>Nicht aktiviert</span>
                <!-- FIXME: Fetch user information on load, allow enabling OTP -->
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card
      v-if="isPasswordChangeRequested"
      elevation="0"
    >
      <v-card-title
        class="mb-0 pb-0"
      >
        <h3>Passwort ändern</h3>
      </v-card-title>
      <password-form @password-change-succeeded="onPasswordChangeSucceeded"
                     @cancel-password-change="isPasswordChangeRequested = false"></password-form>
    </v-card>
    <v-card
      v-if="isNameChangeRequested"
      elevation="0"
    >
      <v-card-title
        class="mb-0 pb-0"
      >
        <h3>Name ändern</h3>
      </v-card-title>
      <name-form @name-change-succeeded="onNameChangeSucceeded"
                 @cancel-name-change="isNameChangeRequested = false"></name-form>
    </v-card>
    <v-card
      v-if="isEmailChangeRequested"
      elevation="0"
    >
      <v-card-title
        class="mb-0 pb-0"
      >
        <h3>E-Mail-Adresse ändern</h3>
      </v-card-title>
      <email-form @email-change-succeeded="onEmailChangeSucceeded"
                  @cancel-email-change="isEmailChangeRequested = false"></email-form>
    </v-card>
    <v-card v-if="(ownCredentials.length || browserSupportsWebAuthn) && ENABLE_WEBAUTHN">
      <v-card-title>
        <h3>Passwortlose Anmeldung</h3>
      </v-card-title>
      <v-card-text>
        <template v-if="ownCredentials.length">
          <p>Die folgenden Geräte sind für die passwortlose Anmeldung eingerichtet:</p>
          <credential-table :credentials="ownCredentials" ref="credentialTable"/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" v-if="browserSupportsWebAuthn" class="text-right" @click="registerNewCredential">
          Passwortlose Anmeldung <template v-if="!ownCredentials.length">einrichten</template>
          <template v-else>hinzufügen</template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-title>
        <h3>Zugeordnete Rollen</h3>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="[{value: 'organization', text: 'Organisation'}, {value: 'role', text: 'Rolle'}]"
          :items="user.permissions"
          disable-pagination
          disable-sort hide-default-footer
        >
          <template v-slot:item.organization="{ item }">
            <template v-if="allOrganizations[item.organization]">
              <!-- FIXME Show parents as short breadcrumbs, mouseover full parents -->
              {{ allOrganizations[item.organization].name}}
            </template>
            <template v-else>Unbekannte Organization</template>
          </template>
          <template v-slot:item.role="{ item }">
            <template v-if="enumsByName && enumsByName.RoleType && enumsByName.RoleType[item.role]">
              {{ enumsByName.RoleType[item.role] }}
            </template>
            <template v-else>{{ item.role }}</template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title><h3>Effektive Berechtigungen</h3></v-card-title>
      <v-card-text>
        <v-treeview
          :items="permissionTreeView.items"
          open-on-click
        >
          <template #append="{ item }">
            <span v-for="(permission, index) in permissionTreeView.permissions" :key="index" style="min-width: 1.2em; display: inline-block">
              <template v-if="Array.from(item.permissions).some(item => permissionParts[item].has(permission))">{{ index+1 }}</template>
              <template v-else>&nbsp;</template>
            </span>
          </template>
        </v-treeview>
        <div v-for="(permission, index) in permissionTreeView.permissions" :key="index">
          <span style="min-width: 1.2em; display: inline-block">{{ index+1 }}</span>
          <span>
            <template v-if="enumsByName && enumsByName.Permission && enumsByName.Permission[permission]">
              {{ enumsByName.Permission[permission] }}
              <div v-if="permissionTreeView.subsumed[permission] && permissionTreeView.subsumed[permission].length" class="pl-7">
                Enthält: <span v-for="(subPermission, subIndex) in permissionTreeView.subsumed[permission]" :key="subIndex"><template v-if="subIndex > 0">, </template>{{ enumsByName.Permission[subPermission] }}</span>
              </div>
            </template>
            <template v-else>{{ permission }}</template>
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <h3>Ihre Anmeldungen</h3>
      </v-card-title>
      <v-card-subtitle>
        <p>Sie haben folgende aktive Sessions:
          <br>
          <a @click="infoDialog = true;">Was bedeutet das?</a>
        </p>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table :headers="[{value: 'last_seen'}, {value:'created_at'}, {value: 'details', sortable: false}]" :items="sessions" :sort-by="'last_seen'" :sort-desc="true">
          <template v-slot:header.last_seen>Letzter Zugriff</template>
          <template v-slot:header.created_at>Erstanmeldung</template>
          <template v-slot:header.details>Browser</template>
          <template v-slot:item.last_seen="{ item }"><span style="white-space: nowrap">{{ item.last_seen | isoToHuman }}</span></template>
          <template v-slot:item.created_at="{ item }"><span style="white-space: nowrap">{{ item.created_at | isoToHuman }}</span></template>
          <template v-slot:item.details="{ item }"><span :title="item.details['User-Agent']">{{ mapUA(item.details['User-Agent']) }}</span></template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="infoDialog"
      max-width="50vw"
      scrollable
    >
      <v-card>
        <v-card-title class="">
          Ihre Anmeldungen
        </v-card-title>

        <v-card-text>
          Jedes Mal wenn Sie sich an unterschiedlichen Geräten und Browsern bei kollekte.digital anmelden erzeugen
          Sie dabei eine sogenannte "Session". Diese Session sorgt dafür das sie angemeldet bleiben
          und somit nicht jedes Mal Ihren Nutzernamen und Passwort eingeben müssen.
        </v-card-text>
        <v-card-text>
          In dieser Übersicht können Sie sehen wann Sie sich angemeldet haben und wann Sie zuletzt aktiv waren.
          Außerdem können Sie Details des verwendeten Browsers sehen.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="infoDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import MenuIcon from '../components/icons/MenuIcon'
import rest from 'lib/rest'

import PasswordForm from '@/components/PasswordForm'
import { getGetBrowser } from '@/lib/browscap'
import NameForm from "@/components/NameForm"
import EmailForm from "@/components/EmailForm"
import {
  FETCH_CREDENTIAL_LIST,
  FETCH_DIRECT_PAYMENT_PAIN_FILES,
  FETCH_ORGANIZATIONS,
  FETCH_STATIC_CONFIGURATION
} from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
import { browserSupportsWebAuthn, startRegistration } from '@simplewebauthn/browser'
import { mdiPlus } from '@mdi/js'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { ENABLE_WEBAUTHN } from '@/feature-flags'
import CredentialTable from '@/components/user/CredentialTable.vue'
import { SET_CREDENTIALS } from '@/store/mutation-types'

export default {
  name: 'user-profile',
  components: {
    CredentialTable,
    EmailForm,
    NameForm,
    PasswordForm,
    MenuIcon,
  },
  data () {
    return {
      waiting: false,
      isPasswordChangeRequested: false,
      isNameChangeRequested: false,
      isEmailChangeRequested: false,
      infoDialog: false,
      sessions: [],
      showPasswordChangeToast: false,
      showNameChangeToast: false,
      showEmailChangeToast: false,
      getBrowser: null,
      ENABLE_WEBAUTHN,
      mdiPlus,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['effectivePermissionsByUrl', 'ownCredentials']),
    ...mapState('staticConfiguration', ['enumsByName', 'permissionParts']),
    ...mapGetters('organization', ['allOrganizations', 'orgtree']),
    ...mapGetters('staticConfiguration', ['permissionsOrdered']),
    permissionTreeView () {
      const usedPermissions = new Set()
      const self = this
      function walk (parentUrl) {
        const retval = []
        for (const nodeUrl of (self.orgtree[parentUrl] ?? [])) {
          const node = self.allOrganizations[nodeUrl]
          const minimizedPermissions = self.minimizePermissions(self.effectivePermissionsByUrl[nodeUrl])
          const children = walk(nodeUrl)
          if (children.length || minimizedPermissions.size) {
            for (const perm of minimizedPermissions) {
              usedPermissions.add(perm)
            }
            retval.push({
              id: node.url,
              name: node.name,
              children,
              permissions: minimizedPermissions,
            })
          }
        }
        return retval
      }
      const items = walk("NONE")
      const permissions = this.permissionsOrdered.filter(item => usedPermissions.has(item))
      const subsumed = {}
      for (const permission of permissions) {
        subsumed[permission] = permissions.reduce(
          (acc, other) => acc.filter(
            item => !Array.from(this.permissionParts[item] ?? []).includes(other)
          ), Array.from(this.permissionParts[permission] ?? [])
        )
      }
      return { items, permissions, subsumed }
    },
    browserSupportsWebAuthn,
  },
  async created () {
    this.getBrowser = await getGetBrowser()
  },
  async mounted () {
    await this.$nextTick()
    await Promise.allSettled([
      this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
      this.$store.dispatch('user/' + FETCH_CREDENTIAL_LIST),
      this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES),
      this.fetchSessions(),
    ])
  },
  methods: {
    minimizePermissions (permissions) {
      const resultSet = new Set()
      const inputSet = new Set(permissions)
      for (const permission of this.permissionsOrdered) {
        if ([...this.permissionParts[permission]].every(item => inputSet.has(item))) {
          resultSet.add(permission)
          for (const item of this.permissionParts[permission]) {
            inputSet.delete(item)
          }
        }
      }
      return resultSet
    },
    async fetchSessions () {
      if (!this.user) {
        this.sessions = []
      } else {
        this.sessions = await rest.fetch('user/' + this.user.id + '/sessions/', 'GET')
      }
    },
    onPasswordChangeSucceeded () {
      this.isPasswordChangeRequested = false
      this.showPasswordChangeToast = true
    },
    onNameChangeSucceeded () {
      this.isNameChangeRequested = false
      this.showNameChangeToast = true
    },
    onEmailChangeSucceeded () {
      this.isEmailChangeRequested = false
      this.showEmailChangeToast = true
    },
    mapUA (val) {
      if (!this.getBrowser) {
        return val
      }
      const browscapEntry = this.getBrowser(val)
      // eslint-disable-next-line camelcase
      if (!browscapEntry?.Device_Type || browscapEntry.Device_Type === 'unknown') {
        return val
      }
      if (!browscapEntry?.Comment) {
        return val
      }
      let retval = browscapEntry.Comment
      if (browscapEntry.Platform) {
        retval = `${retval} (${browscapEntry.Platform})`
      }
      return retval
    },
    async registerNewCredential () {
      const registration = await loadingStateWrapper(this.$store, async () => {
        return (await this.$store.getters.restApi.post('auth/registration/'))?.data ?? null
      })
      // FIXME Error handling. Catch and display error
      if (registration?.options) {
        const registrationResponse = await startRegistration(registration.options)
        const credential = await loadingStateWrapper(this.$store, async () => {
          return (await this.$store.getters.restApi.post(registration.url + "complete/", registrationResponse))?.data ?? null
        })
        if (credential?.url) {
          await this.$store.commit('user/' + SET_CREDENTIALS, [credential])
          this.$refs.credentialTable.focus(credential.url)
        }
      }
    },
  }
}
</script>

<style lang="stylus" >
</style>
