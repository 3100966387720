import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import commonStore from '@/modules/common/store'

import {
  CLEAR_DATA,
  SET_AUTHENTICATION_DATA,
  SET_ORGANIZATIONS,
  SET_USER_DATA,
  SET_RECURSIVE,
  CLEAR_ORGA_PANEL_STATE,
  SET_ORGA_PANEL_FILTER, CLEAR_CREDENTIALS
} from './mutation-types'
import user from './user'
import config from '../config'
import organization from './organization'
import location from './location'
import firmware from './firmware'
import collectionPlan from './collection_plan'
import disbursement from './disbursement'
import date from './date'
import device from './device'
import dashboard from './dashboard'
import userPrefs from './userPrefs'
import settings from './settings'
import contentimage from './contentimage'
import collectionplanextension from './collectionplanextension'
import newsData from './newsData'
import staticConfiguration from './staticConfiguration'
import admin from './admin'
import contracts from './contracts'
import billing from './billing'
import payment from './payment'
import { CLEAR_WAITING_PROMISES } from '@/modules/common/store/action-types'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: "kollekte.digital-state",
  storage: window.localStorage,
  reducer: state => ({ user: { user: state.user.user, auth: state.user.auth }, userPrefs: state.userPrefs }),
  filter: mutation => ["user/" + SET_USER_DATA, 'user/' + SET_AUTHENTICATION_DATA, 'userPrefs/' + SET_RECURSIVE].includes(mutation.type)
})

export default new Vuex.Store({
  state () {
    return {
      ...commonStore.state(),
    }
  },
  modules: {
    admin,
    billing,
    collectionplanextension,
    collectionPlan,
    disbursement,
    contentimage,
    contracts,
    dashboard,
    date,
    device,
    firmware,
    location,
    newsData,
    organization,
    payment,
    settings,
    staticConfiguration,
    user,
    userPrefs,
  },
  plugins: [
    vuexLocal.plugin
  ],
  getters: {
    restApi: (state, getters, rootState, rootGetters) => {
      return axios.create({
        baseURL: config.backend.rest,
        headers: {
          Authorization: rootGetters['user/userAuthToken'] ? 'Token ' + rootGetters['user/userAuthToken'] : null
        }
      })
    }
  },
  mutations: {
    ...commonStore.mutations,
  },
  actions: {
    ...commonStore.actions,
    [CLEAR_DATA]: async function ({ commit, rootState, rootGetters }, params) {
      if (params?.logout ?? true) {
        if (rootState?.user?.auth?.token) {
          try {
            await rootGetters.restApi.post('user/logout/')
          } catch (e) {
            // console.exception("Error during logout", e)
          }
        }
        await commit('user/' + SET_AUTHENTICATION_DATA, {})
      }
      await Promise.allSettled([
        commit("user/" + SET_USER_DATA, null),
        commit("user/" + CLEAR_CREDENTIALS),
        commit("userPrefs/" + SET_RECURSIVE, { recursive: false }),
        commit("userPrefs/" + CLEAR_ORGA_PANEL_STATE),
        commit('userPrefs/' + SET_ORGA_PANEL_FILTER, { orgafilter: ['all'] }),
        commit('organization/' + SET_ORGANIZATIONS, { data: null, now_: Date.now() }),
        commit(CLEAR_WAITING_PROMISES),
      ])
    }
  }
})
