<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th colspan="2" class="text-right">
          Anzahl
        </th>
        <th class="text-right">
          Summe
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(line, cplanUrl) in entries"
          :key="cplanUrl"
      >
        <td style="cursor: pointer;" @click.stop.prevent="$emit('open-evaluation-dialog', cplanUrl)" class="firstcol">{{ mapCollectionPlan(cplanUrl)
          }}
        </td>
        <td style="white-space: nowrap; text-align: right;">{{ line.count }}</td>
        <td style="white-space: nowrap; text-align: right;">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                          <span
                              v-bind="attrs"
                              v-on="on"
                          >
                            {{ line.value|amount }} €
                          </span>
            </template>
            <span>{{ line.count }} Zahlungen, im Schnitt {{ line.average | amount }} €</span>
          </v-tooltip>
        </td>
      </tr>
      <tr>
        <td class="firstcol"><b>Summe</b></td>
        <td style="white-space: nowrap; text-align: right;">
          <b>{{ entries | totalCount }}</b>
        </td>
        <td style="white-space: nowrap; text-align: right;">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                          <span
                              v-bind="attrs"
                              v-on="on"
                          >
                            <b>{{ entries | totalSum | amount }} €</b>
                          </span>
            </template>
            <span>{{ entries | totalCount }} Zahlungen, im Schnitt {{ entries | totalAvg | amount }} €</span>
          </v-tooltip>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  name: 'EvalTableInner',
  props: {
    entries: {},
    collectionPlans: {},
  },
  filters: {
    totalSum (v) {
      return Object.values(v).reduce((accumulator, item) => accumulator + item.value, 0)
    },
    totalCount (v) {
      return Object.values(v).reduce((accumulator, item) => accumulator + item.count, 0)
    },
    totalAvg (v) {
      return Object.values(v).reduce((accumulator, item) => accumulator + item.value, 0) / Object.values(v).reduce((item, accumulator) => accumulator + item.count, 0)
    },
  },
  methods: {
    mapCollectionPlan (v) {
      return this.collectionPlans[v]?.name ?? "Unbekannter Sammlungsplan"
    },
  }
}
</script>
<style lang="stylus" scoped>
.firstcol
  padding: 0px !important

@media print
  .page-break
    display: block
    page-break-before: always
</style>
