<template>
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
<g id="XMLID_1_"></g>
<g id="Ebene_2_00000124879312048221188730000001349115435980895875_">
<ellipse class="st0" cx="3.3" cy="21" rx="2.1" ry="2"/>
<path class="st0" d="M49-9c-0.7,0.6-2.1,0.6-3.3,0c-1.4-0.7-2.2-2-1.9-3l-2.4-1.4L31.5,4.1L41.8,10l9.9-17.4L49-9z"/>
<g>
<path class="st1" d="M10.3,28.7h3c0.7,0,1.4-0.4,1.9-1.1l4.7-6.6c0.5-0.6,1.7-2.5,2.4-2.7l2.8-0.6"/>
<polyline class="st1" points="5.8,26.9 10.3,26.9 10.3,47.9 5.8,47.9 "/>
<path class="st1" d="M25,26.9h17.7c2.3,0.1,5.2,0.6,5.4,2.5c0.7,5.2-4.4,15.2-8.4,18c-2.5,1.8-4,2-6.2,2.2c-2,0.2-4.2,0.1-5.7-0.3
c-2.5-0.7-9.1-4.2-9.1-4.2c-0.2,0-8.4,0-8.4,0"/>
<path class="st1" d="M48.6,14.1c-0.7,0.7-2.1,0.7-3.3,0c-1.4-0.8-2.2-2.1-1.9-3.2L41,9.5l-4.1,7.7v9.7h5.8c0.8,0,1.7,0.1,2.5,0.3
l6.1-11.5L48.6,14.1z"/>
<path class="st1" d="M34.2,7.7c-0.3,1-1.5,1.8-3.1,1.8s-2.8-0.8-3.1-1.8H25v19.2h11.9V7.7H34.2z"/>
</g>
</g>
<g id="Rahmen_für_Benutzer_2_">
<path class="st2" d="M0,0v56.7h56.7V0H0z M53,53H3.7V3.7H53V53z"/>
</g>
<ellipse class="st2" cx="44.9" cy="18.9" rx="1.2" ry="1.2"/>
<ellipse class="st2" cx="41.2" cy="17.3" rx="1.2" ry="1.2"/>
<ellipse class="st2" cx="33" cy="14.5" rx="1.2" ry="1.2"/>
<ellipse class="st2" cx="29" cy="14.5" rx="1.2" ry="1.2"/>
</svg>
</template>
<script>
export default {
  name: "VeranstaltungIcon"
}
</script>
<style type="text/css" scoped>
.st0{display:none;fill:none;stroke:currentColor;stroke-width:2;stroke-miterlimit:10;}
.st1{fill:none;stroke:currentColor;stroke-width:2;stroke-miterlimit:10;}
.st2{fill:currentColor;}
</style>
