<template>
  <img
    id="logo"
    :src="schriftzug"
    :title="variant"
    :alt="variant"
    class="d-print-none"
  />
</template>

<script>
import config from '@/config'

const LOGOS = {
  'geben.digital/development': require('@/assets/schriftzug_gebendigital_development.svg'),
  'geben.digital/production': require('@/assets/schriftzug_gebendigital_production.svg'),
  'geben.digital/dark': require('@/assets/schriftzug_gebendigital_dark.svg'),
  'kollekte.digital/development': require('@/assets/schriftzug_development.svg'),
  'kollekte.digital/demo': require('@/assets/schriftzug_demo.svg'),
  'kollekte.digital/production': require('@/assets/schriftzug_production.svg'),
  'kollekte.digital/dark': require('@/assets/schriftzug_dark.svg'),
  'spendenbescheinigung.digital/development': require('@/assets/schriftzug_spendenbescheinigung_digital.png'),
  'spendenbescheinigung.digital/demo': require('@/assets/schriftzug_spendenbescheinigung_digital.png'),
  'spendenbescheinigung.digital/production': require('@/assets/schriftzug_spendenbescheinigung_digital.png'),
  'spendenbescheinigung.digital/dark': require('@/assets/schriftzug_spendenbescheinigung_digital.svg'),
  'kirchgeld.digital/development': require('@/assets/schriftzug_kirchgeld_digital.svg'),
  'kirchgeld.digital/demo': require('@/assets/schriftzug_kirchgeld_digital.svg'),
  'kirchgeld.digital/production': require('@/assets/schriftzug_kirchgeld_digital.svg'),
  'kirchgeld.digital/dark': require('@/assets/schriftzug_kirchgeld_digital_dark.svg')
}

export default {
  name: 'Logo',
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    variant: () => config.variant,
    schriftzug: function () {
      const mode = this.dark ? 'dark' : config.deployment
      return LOGOS[`${config.variant}/${mode}`] ?? LOGOS['kollekte.digital/development']
    },
    printVariant () {
      return LOGOS[`${config.variant}/dark`] ?? LOGOS['kollekte.digital/development']
    }
  },

}
</script>

<style scoped lang="stylus">
</style>
