<template>
  <div></div>
</template>

<script>
export default {
  name: 'KigeHome',
  mounted () {
    if (this.$store.getters['kirchgeld/isLoggedIn']) {
      this.$router.push({ name: 'kige-person-lookup' })
    }
  }
}
</script>
