// import Vue from 'vue'

import config from 'config'
import store from 'store'

const rest = {
  backend: config.backend.rest,
  fetch (url, method, body, options = { headers: {} }) {
    return fetch(rest.backend + url, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: (store.getters['user/userAuthToken']) ? 'Token ' + store.getters['user/userAuthToken'] : null,
        ...options.headers
      }
    }).then((response) => {
      return response.json()
    }).then((response) => {
      return response
    }).catch((response) => {
      return Promise.reject(response.error || response)
    })
  }
}
export default rest
