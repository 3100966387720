<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="4" md="2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="query.dateRange"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Datumsbereich auswählen"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="query.dateRange"
              :allowed-dates="allowedDates"
              :selected-items-text="datePickerTitleText"
              color="primary"
              locale="de-DE"
              range
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="query.dateRange.length > 1"
                color="primary"
                text
                @click="$refs.menu.save(query.dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="4" md="2"
        >
          <v-select
            v-model="query.status"
            :items="assignmentSelectionItems"
            label="Nach Status filtern"
          ></v-select>
        </v-col>
        <v-col cols="4" md="8">
          <v-autocomplete
            v-model="query.accounts"
            :clearable="true"
            :item-text="accountIbanName"
            :items="bankAccounts"
            item-value="iban"
            label="Nach Empfängerkonto filtern"
            multiple
          >
            <template #selection="{ index, item }">
              <template v-if="index !== 0">,</template>
              <span v-text="item.name || item.iban"></span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
      :headers="headers"
      :items="transactionsToShow"
      :loading="isLoading > 0"
      :options.sync="options"
      :server-items-length="transactionCount"
    >
      <template #item.booking_date="{ item }">
        {{ item.booking_date|isoToHuman("DD.MM.YYYY") }}
      </template>
      <template #item.amount="{ item }">
        <span v-if="item.amount < 0" class="amount-negative">{{ item.amount|amount }}</span>
        <span v-else class="amount-positive">{{ item.amount|amount }}</span>
      </template>
      <template #item.their_name="{ item }">
        {{ item.their_name }}<br>
        <span class="grey--text" v-html="formatIbanHtml(item.their_iban)"></span>
      </template>
      <template #item.konto="{ item }">
        <template v-if="bankAccountsByUrl[item.account]">
          <template v-if="bankAccountsByUrl[item.account].name">{{ bankAccountsByUrl[item.account].name }}<br>
          </template>
          <span class="grey--text" v-html="formatIbanHtml(bankAccountsByUrl[item.account].iban)"></span>
        </template>
      </template>
      <template #item.state="{ item }">
        <v-btn
          v-if="item.state === 'unassigned' || item.state === 'partial'"
          class="mx-2"
          color="primary"
          tile
          @click="openAssignmentDialog(item)">
          <v-icon left>
            {{ mdiPlus }}
          </v-icon>
          Zuordnung
        </v-btn>
        <v-btn
          v-if="item.state === 'unassigned'"
          color="primary"
          plain
          @click="showIgnoreConfirmationDialog = true; selectedStatement = item"
        >
          <v-icon left>{{ mdiMinus }}</v-icon>
          Ignorieren
        </v-btn>
        <div v-if="item.state === 'assigned' || item.state === 'partial'">
          <v-list-item v-for="(payment, index) in item.payments" :key="index">
            <v-list-item-action>
              <v-btn
                color="primary"
                icon
                @click="inspectPerson(payment)"
              >
                <v-icon>{{ mdiAccount }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <span v-if="lettersByUrl[payment.letter]">
           {{ showPersonName(payment?.person) }} {{ lettersByUrl[payment?.letter]?.name }} {{ payment?.amount | amount }}
         </span>
              <span v-else-if="payment.target_purpose">
            {{ showPersonName(payment?.person) }} {{ payment?.target_purpose }} {{ payment?.amount | amount }}
          </span>
              <span v-else>
                  {{ showPersonName(payment?.person) }}  {{ payment?.amount | amount }}
         </span>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-btn
          v-if="item.state === 'assigned' || item.state === 'partial'"
          color="grey"
          icon
          @click="requestUnassignment(item)"
        >
          <v-icon>{{ mdiLinkVariantRemove }}</v-icon>
        </v-btn>
      </template>
      <template #footer.page-text="{ pageStart, pageStop }">
        {{ pageStart }} - {{ pageStop }} von {{ transactionCount }}
        <template v-if="transactionsToShow.length !== options.itemsPerPage && !isLoading">
          <v-divider></v-divider>
          <span>{{ options.itemsPerPage - transactionsToShow.length }} Zeilen auf dieser Seite ausgeblendet</span>
        </template>
      </template>
      <template #footer.prepend>
        <template v-if="latestBankStatement">
          Letzter Kontoauszug empfangen am {{ latestBankStatement | isoToHuman("DD.MM.YYYY [um] HH:mm") }}
        </template>
      </template>
    </v-data-table>
    <base-dialog
      :is-open="showUnassignmentConfirmationDialog"
      @close="showUnassignmentConfirmationDialog = false"
    >
      <template #dialog-title>Zuordnung löschen</template>
      <template #dialog-content>
        <v-card
          v-if="selectedStatement"
          text
        >
          <v-card-text>
            Möchten Sie die Zuordnung der Zahlung zu {{ selectedStatement.their_name }} [{{
              selectedStatement.purpose
            }}]
            wirklich löschen?"
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="primary"
              @click="unassignTransaction(selectedStatement)">
              Ja, löschen
            </v-btn>
            <v-btn @click="showUnassignmentConfirmationDialog = false; selectedStatement = null">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </base-dialog>
    <base-dialog
      :is-open="showIgnoreConfirmationDialog"
      @close="showIgnoreConfirmationDialog = false"
    >
      <template #dialog-title>Ignorieren</template>
      <template #dialog-content>
        <v-card
          v-if="selectedStatement"
          text
        >
          <v-card-text>
            <!-- Translated from deepL -->
            Bestätigen Sie, dass Sie den Auftrag ignorieren?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="primary"
              @click="ignoreStatement(selectedStatement)">
              Ja, löschen
            </v-btn>
            <v-btn @click="showIgnoreConfirmationDialog = false; selectedStatement = null">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </base-dialog>
    <base-dialog
      :is-open="showAssignmentDialog"
      max-width="none"
      @close="showAssignmentDialog = false"
    >
      <template #dialog-title>Zuordnung zu Zahlung</template>
      <template #dialog-content>
        <v-card-text>
          <v-container v-if="selectedStatement">
            <v-row>
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-text>
                    <ul class="transaction-preview">
                      <li><b>Auftraggeber:</b> {{ selectedStatement.their_name }}</li>
                      <li><b>Auftraggeber-IBAN:</b> {{ selectedStatement.their_iban }}</li>
                      <li><b>Verwendungszweck:</b> {{ selectedStatement.purpose }}</li>
                      <li><b>Zielkonto:</b>
                        <template v-if="bankAccountsByUrl[selectedStatement.account]">
                          <template v-if="bankAccountsByUrl[selectedStatement.account].name">
                            {{ bankAccountsByUrl[selectedStatement.account].name }}
                          </template>
                          <span class="grey--text"
                                v-html="formatIbanHtml(bankAccountsByUrl[selectedStatement.account].iban)"></span>
                        </template>
                      </li>
                      <li><b>Betrag: </b>{{ selectedStatement.amount | amount }}</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-stepper
            v-model="step"
            vertical
          >
            <v-stepper-step
              :complete="Object.keys(personSelectedForAssignment).length > 0"
              step="1"
              @click="step = 1"
            >
              Person auswählen
            </v-stepper-step>

            <v-stepper-content step="1">
              <person-lookup
                :adjustment-passing="true"
                :default-query="selectedStatement ? selectedStatement.their_name : ''"
                :is-external-selection-allowed="true"
                :show-column-selection="false"
                @external-selection-confirmed="onExternalSelectionConfirmed"
              />
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              @click="step = 2"
            >
              Aktion zuordnen
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-container v-if="selectedStatement">
                <v-radio-group
                  v-model="selectedRadioForLetter"
                  mandatory
                >
                  <div style="float: left">
                    <v-card-text>
                      <v-radio
                        :value="KIRCHGELD_ALLGEMEIN"
                        label="Kirchgeld allgemein"
                      ></v-radio>
                      <v-radio
                        v-for="letter in letterList"
                        :key="letter.id"
                        :label="letter.state ? `${letter.name} vom ${isoToHuman(letter.created_at, 'DD.MM.YYYY')} (${letterStateText(letter.state)})`: selectedRadioForLetter.label"
                        :value="letter.url"
                      ></v-radio>
                      <v-radio
                        value="special-purpose"
                      >
                        <template #label>
                          <v-autocomplete
                            v-model="selectedSpecialPurpose"
                            :disabled="selectedRadioForLetter !== 'special-purpose'"
                            :items="specialPurposeValues"
                            :search-input.sync="search"
                            item-value="special_purpose"
                            label="Verwendungsschlüssel"
                            placeholder="Suche"
                            return-object
                            @blur="applySpecialPurpose"
                            @update:search-input="onSpecialPurposeInput"
                          >
                            <template #no-data>
                              <span class="pa-2">
                                Verwendungsschlüssel <b>{{ specialPurposeInputValue }}</b> neu anlegen
                              </span>
                            </template>
                          </v-autocomplete>
                          <!--                          <v-text-field-->
                          <!--                            v-show="!specialPurposeValues.length"-->
                          <!--                            v-model.trim="selectedSpecialPurpose"-->
                          <!--                            label="Verwendungsschlüssel"-->
                          <!--                            placeholder="Verwendungsschlüssel"-->
                          <!--                          >-->
                          <!--                          </v-text-field>-->
                        </template>
                      </v-radio>
                      <v-checkbox v-model="disallowReceipt"><template #label>Zuwendungsbestätigung&nbsp;<em>nicht</em>&nbsp;ausstellen</template></v-checkbox>
                    </v-card-text>
                  </div>
                </v-radio-group>
              </v-container>
            </v-stepper-content>
            <v-stepper-step
              step="3"
              @click="step = 3"
            >
              Betrag splitten
              <template v-if="isSplitAmountActive">
                : <strong>{{ assignAmount | amount }} €</strong>
              </template>
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card height="80" max-width="344">
                <v-card-text>
                  <v-text-field
                    v-model="assignAmount"
                    :max="assignableAmountString"
                    :min="0.0"
                    label="Betrag"
                    step=".01"
                    suffix="in €"
                    type="number"
                  />
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="selectedRadioForLetter === 'kirchgeld-allgemein' || !personSelectedForAssignment"
            color="primary"
            @click="assignTransaction"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
    <person-detail-dialog
      :is-open="showPersonDetailsDialog"
      :person-url="selectedPerson ? selectedPerson.personUrl : null"
      @close="onPersonDetailDialogClose"
    ></person-detail-dialog>
  </div>
</template>

<script>

import moment from "moment"
import { mapGetters } from "vuex"
import amount from "@/filters/amount"
import {
  GET_BANK_ACCOUNTS,
  GET_LETTER,
  GET_PERSON_LETTERS,
  GET_SPECIAL_PURPOSES,
  GET_TENANTS
} from "@/modules/kirchgeld/store/kirchgeld/action-types"
import BaseDialog from "@/components/UI/BaseDialog"
import PersonLookup from "@/modules/kirchgeld/pages/PersonLookup"
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"
import PersonDetailDialog from "@/modules/kirchgeld/components/PersonDetailDialog"
import { KIRCHGELD_ALLGEMEIN } from "@/modules/kirchgeld/config/letter-assignment-type"
import { loadingStateWrapper } from '../../common/store/tools'
import { mdiAccount, mdiLinkVariantRemove, mdiMinus, mdiPlus } from '@mdi/js'
import { pick } from 'lodash'
import { isoToHuman } from '@/filters/datetime'

export default {
  name: "Adjustments",
  filters: {
    amount
  },
  mixins: [
    PersonDataMixin
  ],
  components: {
    BaseDialog,
    PersonLookup,
    PersonDetailDialog
  },
  data () {
    return {
      headers: [
        {
          text: 'Datum',
          value: 'booking_date',
          sortable: true,
        },
        {
          text: 'Betrag in €',
          value: 'amount',
          align: 'end',
          sortable: false,
        },
        {
          text: 'Auftraggeber',
          value: 'their_name',
          sortable: false,
        },
        {
          text: 'Verwendungszweck',
          value: 'purpose',
          sortable: false,
          cellClass: 'purpose-column',
        },
        {
          text: 'Zuordnung',
          value: 'state',
          sortable: false,
          cellClass: 'pl-0'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          cellClass: 'pa-0',
        },
        {
          text: 'Zielkonto',
          value: 'konto',
          sortable: false,
        },
      ],
      assignmentSelectionItems: [
        {
          text: 'Alle',
          value: 'all'
        },
        {
          text: 'mit Zuordnung',
          value: 'has_assignment',
        },
        {
          text: 'keine Zuordnung',
          value: 'no_assignment',
        }
      ],
      menu: false,
      query: {
        status: 'all',
        accounts: [],
        dateRange: [moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      options: { // Table options (including pagination)
        page: 1, // IMPORTANT: Only those options that are already specified here will be part of reactive watching
        itemsPerPage: 10,
        sortBy: ["booking_date"],
        sortDesc: [true],
      },
      isLoading: 0,
      transactionCount: null, // Server-side transaction count
      txCache: {
        options: {}, // Options used to fetch this cache
        content: [], // List of transactions we cached. Any change in query or sort order invalidates cache
        start: 0, // Offset that our cache has from server start
      },
      latestBankStatement: null, // Latest bank statement (by created_at) for informative display
      personObject: {},
      showAssignmentDialog: false,
      showPersonDetailsDialog: false,
      showUnassignmentConfirmationDialog: false,
      selectedStatement: null,
      selectedPerson: null,
      step: 1,
      letterList: [],
      selectedRadioForLetter: KIRCHGELD_ALLGEMEIN,
      personSelectedForAssignment: '',
      selectedSpecialPurpose: null,
      localSpecialPurposes: [],
      specialPurposeInputValue: '',
      disallowReceipt: false,
      search: null,
      specialPurposeItems: [],
      KIRCHGELD_ALLGEMEIN,
      mdiPlus,
      mdiAccount,
      mdiLinkVariantRemove,
      mdiMinus,
      showIgnoreConfirmationDialog: false,
      assignAmount: 0,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['bankAccounts', 'bankAccountsByUrl', 'bankStatements', 'api', 'specialPurposes', 'lettersByUrl']),
    dateRangeText () {
      if (this.query.dateRange.length < 2) {
        return moment(this.query.dateRange[0]).format('DD.MM.YYYY')
      } else {
        let dates = this.orderDates(this.query.dateRange[0], this.query.dateRange[1])
        dates = dates.map(date => moment(date).format('DD.MM.YYYY'))
        return dates.join(' - ')
      }
    },
    datePickerTitleText () {
      if (this.query.dateRange.length < 2) {
        return ''
      } else {
        const dates = this.orderDates(this.query.dateRange[0], this.query.dateRange[1])
        return dates.map(date => moment(date).format("DD. MMM")).join(" - ")
      }
    },
    transactionsToShow () {
      const displayFrom = (this.options.page - 1) * this.options.itemsPerPage
      const displayTo = (this.options.page) * this.options.itemsPerPage
      let txs = this.txCache.content.slice(displayFrom - this.txCache.start, displayTo - this.txCache.start)
      txs = txs.filter(transaction => transaction.state !== 'ignored')
      if (this.query.status === 'no_assignment') {
        return txs.filter(transaction => transaction.state === 'unassigned')
      } else if (this.query.status === 'has_assignment') {
        return txs.filter(transaction => transaction.state === 'assigned')
      }

      return txs
    },
    specialPurposeValues () {
      return [
        ...this.specialPurposes.map(item => item.special_purpose),
        ...this.localSpecialPurposes
      ]
    },
    optionsForFetch () {
      // Helper object, to allow watching on all options relevant for data fetch
      return {
        ...pick(this.query, ['accounts', 'dateRange']),
        ...pick(this.options, ["page", 'itemsPerPage', 'sortBy', 'sortDesc'])
      }
    },
    optionsForCache () {
      // Helper object to allow checking cache
      return pick(this.optionsForFetch, ['accounts', 'dateRange', 'sortBy', 'sortDesc'])
    },
    assignedAmount () {
      if (this.selectedStatement?.payments?.length) {
        return this.selectedStatement?.payments?.reduce((total, currentValue) => total + currentValue.amount, 0)
      }
      return 0
    },
    assignableAmount () {
      return parseFloat(this.selectedStatement?.amount || "0") - this.assignedAmount
    },
    assignableAmountString () {
      return this.assignableAmount.toFixed(2)
    },
    isSplitAmountActive () {
      if (this.selectedStatement?.amount) {
        return parseFloat(this.assignAmount) !== parseFloat(this.selectedStatement.amount)
      }
      return false
    },
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_TENANTS)
    await this.$nextTick()
    await Promise.allSettled([
      this.fetchLatestBankStatement(),
      this.$store.dispatch('kirchgeld/' + GET_BANK_ACCOUNTS),
      this.$store.dispatch('kirchgeld/' + GET_SPECIAL_PURPOSES)
    ])
  },
  methods: {
    isoToHuman,
    accountIbanName (account) {
      if (account?.name) {
        return `${account.name} - ${account.iban}`
      } else {
        return account.iban
      }
    },
    isTxCacheValid (txCacheOptions) {
      return !Object.keys(txCacheOptions).some(key => JSON.stringify(txCacheOptions[key]) !== JSON.stringify(this.txCache.options[key]))
    },
    showPersonName (person) {
      // eslint-disable-next-line camelcase
      const personFirstName = person?.current_data?.vornamen ?? ''
      // eslint-disable-next-line camelcase
      const personLastName = person?.current_data?.familienname ?? ''
      return (personFirstName || personLastName) ? personFirstName + ' ' + personLastName : ''
    },
    async updateTable () {
      await this.$store.dispatch('kirchgeld/' + GET_TENANTS)

      // If any of these values change, completely invalidate the cache
      const txCacheOptions = this.optionsForCache
      if (!this.isTxCacheValid(txCacheOptions)) {
        this.txCache = {
          options: txCacheOptions,
          content: [],
          start: 0,
        }
      }

      // Figure out how cache and display relate. "from" is index, "to" is index+1
      const cacheFrom = this.txCache.start
      const cacheTo = this.txCache.start + this.txCache.content.length
      const displayFrom = (this.options.page - 1) * this.options.itemsPerPage
      const displayTo = (this.options.page) * this.options.itemsPerPage
      const fetchFrom = displayFrom
      const fetchTo = displayTo + this.options.itemsPerPage // Fetch one extra page

      /*
        Index:  0                                                                                            ...
        Cache:                        | [txCache.content.length items]   |
                                      ^ cacheFrom                         ^ cacheTo
        Five cases for fetchFrom/To:
        Case 1                              |===================|
        Case 2                                                        |==-------------------|
        Case 3     |-------------------======|
        Case 4                  |------==================================-------------|
        Case 5     a |--------|                     or                         b |--------------|

        In case 1, the new request is completely contained within the cache. No action needs to be taken
        In cases 2 and 3, the new request partially overlaps the end or start of the cache
             (a partial overlap of exactly 0 is allowed, meaning the request immediately follows
              or precedes the cache). In this case, fetch the new items ("-") and append/prepend to the
              cache. In prepend case: update txCache.start variable.
        In case 4, the new request is larger than the current cache. Handle as composite 2/3 case: append
              and prepend to the cache, in two server requests.
        In case 5 (shouldn't happen), the request is completely unrelated to the cache. DO NOT fetch
              inbetween values. Instead, drop the cache and fetch the items with no regard for caching.
       */

      const fetchActions = []
      if (fetchFrom <= cacheTo) {
        // Cases 1, 2, 3, 4, 5a
        if (fetchTo > cacheTo) {
          // Cases 2, 4
          fetchActions.push({
            offset: cacheTo,
            limit: fetchTo - cacheTo,
            action: "append"
          })
        }
        if (fetchFrom < cacheFrom) {
          // Cases 3, 4, 5a
          if (fetchTo >= cacheFrom) {
            // Cases 3, 4
            fetchActions.push({
              offset: fetchFrom,
              limit: fetchTo - cacheFrom,
              action: "prepend"
            })
          } else {
            // Case 5a
            fetchActions.push({
              offset: fetchFrom,
              limit: fetchTo - fetchFrom,
              action: "replace"
            })
          }
        }
      } else {
        // Case 5b
        fetchActions.push({
          offset: fetchFrom,
          limit: fetchTo - fetchFrom,
          action: "replace"
        })
      }

      if (fetchActions.length && txCacheOptions.dateRange.length) {
        const dates = this.orderDates(txCacheOptions.dateRange[0], txCacheOptions.dateRange[1] ?? txCacheOptions.dateRange[0])
        const commonParams = {
          start: dates[0],
          end: dates[1],
          ordering: txCacheOptions.sortBy.map((item, i) => txCacheOptions.sortDesc[i] ? `-${item}` : item).join(",")
        }
        try {
          this.isLoading++
          // Run all fetches in parallel
          await Promise.all(fetchActions.map(async ({
            limit,
            offset,
            action
          }) => {
            const url = new URL(this.$store.state.kirchgeld.tenant.url + "bank_statement/query/")
            const searchParams = new URLSearchParams({
              ...commonParams,
              limit,
              offset
            })
            for (const account of txCacheOptions.accounts) {
              searchParams.append("account", account)
            }
            url.search = searchParams.toString()

            const response = await this.api.get(url.toString())
            const data = response?.data
            if (data && this.isTxCacheValid(txCacheOptions)) {
              // Only handle response if the cache validator hasn't been changed in the meantime
              this.transactionCount = data?.count ?? null
              if (data?.results) {
                if (action === "replace") {
                  // Replace (cases 5a, 5b)
                  this.txCache.content = data.results
                  this.txCache.start = offset
                } else {
                  if (action === "prepend") {
                    // Prepend (cases 3, 4)
                    this.txCache.content = [...data.results, ...this.txCache.content.slice(offset + limit)]
                    this.txCache.start -= data.results.length
                  } else if (action === "append") {
                    // Append (cases 2, 4)
                    this.txCache.content = [...this.txCache.content.slice(undefined, offset), ...data.results]
                  }
                }
              }
            }
          }))
        } finally {
          this.isLoading--
        }
      }
    },
    orderDates (date1, date2) {
      if (moment(date1).isBefore(date2)) {
        return [date1, date2]
      }
      return [date2, date1]
    },
    allowedDates (val) {
      return val <= moment().format('YYYY-MM-DD')
    },
    async fetchLatestBankStatement () {
      await loadingStateWrapper(this.$store, async () => {
        const response = await this.api.get(`${this.$store.state.kirchgeld.tenant.url}bank_statement/?ordering=-created_at&limit=1`)
        if (response?.data?.results?.length > 0) {
          this.latestBankStatement = response.data.results[0]
        }
      })
    },
    async onExternalSelectionConfirmed (person) {
      this.step = 2
      this.personSelectedForAssignment = person
      const personId = this.getPersonId(person)
      this.letterList = await this.$store.dispatch('kirchgeld/' + GET_PERSON_LETTERS, personId)
    },
    openAssignmentDialog (statement) {
      this.showAssignmentDialog = true
      this.selectedStatement = statement
      this.assignAmount = this.assignableAmountString
    },
    requestUnassignment (statement) {
      this.selectedStatement = statement
      this.showUnassignmentConfirmationDialog = true
    },
    async unassignTransaction (statement) {
      const data = {
        bank_transaction: statement.url,
      }
      this.selectedStatement.state = 'unassigned'
      const response = await this.submitTransactionAssignmentChange(statement.camt_file, data)
      if (response?.data) {
        Object.assign(this.selectedStatement, response.data)
      }
      this.selectedStatement = null
      this.showUnassignmentConfirmationDialog = false
    },
    getPersonId (person) {
      const personUrl = person?.personUrl
      const startIndex = personUrl.indexOf('person/') + 7
      return personUrl.substring(startIndex, personUrl.length - 1)
    },
    onPersonDetailDialogClose () {
      this.selectedPerson = null
      this.showPersonDetailsDialog = false
    },
    async inspectPerson (transaction) {
      this.showAssignmentDialog = false
      const personResponse = await this.api.get(transaction.person.url)
      this.selectedPerson = {
        ...personResponse.data,
        personUrl: personResponse.data.url, // FIXME Get rid of `personUrl`
      }
      this.showPersonDetailsDialog = true
    },
    async assignTransaction () {
      const person = this.personSelectedForAssignment
      let specialPurpose
      if (this.selectedRadioForLetter === 'special-purpose' && this.selectedSpecialPurpose) {
        specialPurpose = this.selectedSpecialPurpose
      } else if (this.selectedRadioForLetter === 'special-purpose') {
        specialPurpose = this.specialPurposeInputValue
      }

      const data = {
        bank_transaction: this.selectedStatement.url,
        person: person.personUrl,
        disallow_receipt: this.disallowReceipt,
      }
      if (this.isSplitAmountActive) {
        data.amount = this.assignAmount.toString()
      }
      if (specialPurpose) {
        data.special_purpose = specialPurpose
      } else if (this.selectedRadioForLetter !== KIRCHGELD_ALLGEMEIN) {
        data.letter = this.selectedRadioForLetter
      }
      const response = await this.submitTransactionAssignmentChange(this.selectedStatement.camt_file, data)
      if (response?.data) {
        Object.assign(this.selectedStatement, response.data)
      }
      this.step = 1
      this.selectedRadioForLetter = KIRCHGELD_ALLGEMEIN
      this.selectedSpecialPurpose = null
      this.showAssignmentDialog = false
    },
    letterStateText (letterState) {
      if (letterState === 'created') {
        return 'offen'
      } else if (letterState === 'finalized') {
        return 'abgeschlossen'
      } else if (letterState === 'sent') {
        return 'gesendet'
      } else {
        return ''
      }
    },
    querySelections (_v) {
      setTimeout(() => {
        this.items = this.specialPurposes.filter(e => {
          return (e.special_purpose || '') > -1
        })
      }, 500)
    },
    onSpecialPurposeInput (input) {
      this.specialPurposeInputValue = input
    },
    applySpecialPurpose () {
      if (!this.specialPurposeValues.includes(this.specialPurposeInputValue) && this.specialPurposeInputValue) {
        this.localSpecialPurposes.push(this.specialPurposeInputValue)
        this.selectedSpecialPurpose = this.specialPurposeInputValue
      }
    },
    formatIbanHtml (v) {
      // Returns HTML(!) for the IBAN passed in, with a visual space every 4 characters,
      // while keeping the full IBAN searchable
      if (!v) {
        return ""
      }
      const safeIban = v.toUpperCase().replace(/[^0-9A-Z]/g, '')
      const parts = []
      for (let i = 0; i < safeIban.length; i += 4) {
        parts.push(safeIban.slice(i, i + 4))
      }
      return '<span>' + parts.join('</span><span class="pl-1">') + "</span>"
    },
    async submitTransactionAssignmentChange (statement, data) {
      return await this.api.post(`${statement}assign/`, data)
    },
    async ignoreStatement (statement) {
      const data = {
        bank_transaction: statement.url,
        state: 'ignored'
      }
      const response = await this.api.post(`${statement.camt_file}assign/`, data)
      this.selectedStatement.state = response.data.state
      this.selectedStatement = null
      this.showIgnoreConfirmationDialog = false
    }
  },
  watch: {
    search (value) {
      value && value !== this.selectedSpecialPurpose && this.querySelections(value)
    },
    optionsForFetch: {
      immediate: false, // Something changes already at start. With immediate true there would be a double fetch
      async handler () {
        // On any change to any of the relevant parameters, call the update method
        await this.updateTable()
      }
    },
    'selectedStatement.url': function () {
      this.disallowReceipt = false
    },
    'txCache.content': {
      immediate: true,
      async handler (newValue) {
        const letters = new Set()
        newValue.forEach(obj => {
          if (obj.payments) {
            obj?.payments.forEach(payment => {
              // eslint-disable-next-line no-unused-vars
              for (const key in payment) {
                if (!this.lettersByUrl[payment.letter]) {
                  letters.add(payment.letter)
                }
              }
            })
          }
        })
        const letterPromises = [...letters].map(async (letter) => {
          await this.$store.dispatch('kirchgeld/' + GET_LETTER, letter)
        })
        await Promise.allSettled(letterPromises)
      },
      deep: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.v-progress-circular
  margin: 1rem

.amount-negative
  font-weight: bolder
  color: #600

.transaction-preview
  list-style-type: none
  margin: 0
  padding: 0

  li
    list-style-type: none
</style>
