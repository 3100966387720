import {
  SET_ORGANIZATION_DEVICES,
  SET_GLOBAL_DEVICES,
  SET_DEVICE_PROVISIONING_REQUESTS
} from './mutation-types'
import {
  FETCH_DEVICE_PROVISIONING_REQUESTS,
  FETCH_DEVICES,
  FETCH_GLOBAL_DEVICES,
  PATCH_DEVICE,
  PATCH_DEVICE_PROVISIONING_REQUEST, POST_DEVICE_CLOSEOUT
} from './action-types'
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'
import { cachedActionWrapper, loadingStateWrapper } from '@/modules/common/store/tools'
import { CACHE_DEVICES, CACHE_DEVICES_BY_ORGANIZATION } from '@/store/cache-types'

export default {
  namespaced: true,
  state: function () {
    return {
      devicesByOrganization: {},
      devices: [],
      deviceProvisioningRequests: []
    }
  },
  getters: {
    devicesLookup: (state) => {
      if (!state.devices) return {}
      const result = {}
      state.devices.forEach(dev => { result[dev.url] = dev })
      return result
    }
  },
  mutations: {
    [SET_ORGANIZATION_DEVICES]: (state, { url, data }) => {
      state.devicesByOrganization = {
        ...state.devicesByOrganization,
        [url]: data ?? null
      }
    },
    [SET_GLOBAL_DEVICES]: (state, { data }) => { // FIXME: This naming
      state.devices = data ?? []
    },
    [SET_DEVICE_PROVISIONING_REQUESTS]: (state, { data }) => {
      // TODO - Add state
      state.deviceProvisioningRequests = data ?? []
    }
  },
  actions: {
    [PATCH_DEVICE]: async (context, { url, data }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.rootGetters.restApi.patch(url, data)
        // FIXME Update device in store

        // Nuclear option: Clear and refetch everything
        const clearPromises = [
          context.dispatch(INVALIDATE_CACHE, { key: CACHE_DEVICES }, { root: true })
        ]
        if (context.rootGetters['organization/currentOrganization']?.url) {
          clearPromises.push(context.dispatch(INVALIDATE_CACHE, { key: CACHE_DEVICES_BY_ORGANIZATION + context.rootGetters['organization/currentOrganization']?.url }, { root: true }))
        }
        await Promise.allSettled(clearPromises)

        const fetchPromises = [
          context.dispatch(FETCH_GLOBAL_DEVICES)
        ]
        if (context.rootGetters['organization/currentOrganization']?.url) {
          fetchPromises.push(context.dispatch(FETCH_DEVICES, context.rootGetters['organization/currentOrganization']?.url))
        }
        await Promise.allSettled(fetchPromises)

        return response.status === 200
      }, async () => {
        return false
      })
    },
    [FETCH_GLOBAL_DEVICES]: async (context) => {
      return cachedActionWrapper(context, CACHE_DEVICES, async () => {
        const response = await context.rootGetters.restApi.get('device/')
        await context.commit(SET_GLOBAL_DEVICES, { data: response.data })
      }, async () => {
        await context.commit(SET_GLOBAL_DEVICES, { data: null })
      })
    },
    [FETCH_DEVICES]: async (context, organizationUrl) => {
      return cachedActionWrapper(context, CACHE_DEVICES_BY_ORGANIZATION + organizationUrl, async () => {
        const response = await context.rootGetters.restApi.get(organizationUrl + 'devices/')
        await context.commit(SET_ORGANIZATION_DEVICES, { url: organizationUrl, data: response.data })
      }, async () => {
        await context.commit(SET_ORGANIZATION_DEVICES, { url: organizationUrl, data: null })
      })
    },
    [FETCH_DEVICE_PROVISIONING_REQUESTS]: async (context) => {
      try {
        const response = await context.rootGetters.restApi.get('devicemgr/provisioning_request/')
        await context.commit(SET_DEVICE_PROVISIONING_REQUESTS, { data: response.data })
      } catch (e) {
        await context.commit(SET_DEVICE_PROVISIONING_REQUESTS, { data: null })
      }
    },
    [PATCH_DEVICE_PROVISIONING_REQUEST]: async (context, { targetState, contextUrl, deviceType, deviceName }) => {
      const payload = {
        state: targetState
      }

      const info = {}

      if (deviceType) {
        // eslint-disable-next-line camelcase
        info.device_type = deviceType
      }

      if (deviceName) {
        info.name = deviceName
      }

      if (Object.keys(info).length) {
        payload.info = info
      }
      try {
        return await context.rootGetters.restApi.patch(contextUrl, payload)
      } catch (e) {
        return false
      }
    },
    [POST_DEVICE_CLOSEOUT]: async (context, { contextUrl }) => {
      try {
        return await context.rootGetters.restApi.post(`${contextUrl}close/`)
      } catch (e) {
        return false
      }
    }
  }
}
