import { SET_CONTENTIMAGE } from './mutation-types'
import { FETCH_CONTENTIMAGES } from './action-types'
import { cachedActionWrapper } from '@/modules/common/store/tools'
import { CACHE_CONTENTIMAGES } from '@/store/cache-types'

export default {
  namespaced: true,
  state: function () {
    return {
      images: [],
    }
  },
  getters: {
    imagesLookup: (state) => {
      if (!state.images) return {}
      const result = {}
      state.images.forEach(item => { result[item.url] = item })
      return result
    }
  },
  mutations: {
    [SET_CONTENTIMAGE]: (state, payload) => {
      if (!payload) {
        payload = []
      }
      state.images = payload
    },
  },
  actions: {
    [FETCH_CONTENTIMAGES]: async (context) => {
      return cachedActionWrapper(context, CACHE_CONTENTIMAGES, async () => {
        const response = await context.rootGetters.restApi.get('content/image/')
        await context.commit(SET_CONTENTIMAGE, response.data)
      }, async () => {
        await context.commit(SET_CONTENTIMAGE, [])
      })
    }
  }
}
