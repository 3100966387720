<template>
  <base-dialog
    :is-open="isOpen"
    @close="close"
  >
    <template #dialog-title>Benutzer bearbeiten</template>
    <template #dialog-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <!-- TODO: Name may stay empty -->
          <v-text-field
            v-model="name"
            label="Name"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="username"
            label="Benutzername"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="email"
            label="E-Mail-Adresse"
            outlined
            type="email"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="permissionTableHeaders"
            :items="permissions"
          >
            <template #item.organization="{ item }">
              <template v-if="item.isNew || !item.organization">
                <user-admin-org-selection-template
                  :org-list="availableOrganizations"
                  @organization-selection="item.organization = $event"
                ></user-admin-org-selection-template>
              </template>
              <template v-else>
                {{ allOrganizations[item.organization].name }}
              </template>
            </template>
            <template #item.role="{ item }">
              <div class="d-flex-justify-center">
                <v-autocomplete
                  v-model="item.role"
                  :items="availableRoles"
                  item-value="name"
                  item-text="label"
                  dense
                  outlined
                  :hide-details="true"
                />
              </div>
            </template>
            <template #item._actions="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="grey"
                    v-on="on"
                    :attrs="attrs"
                    @click="removeOrganizationPermission(item.organization)"
                  >
                    <v-icon>{{ mdiTrashCan }}</v-icon>
                  </v-btn>
                </template>
                <span>Entfernen</span>
              </v-tooltip>
            </template>
            <template #footer.prepend>
              <v-btn
                color="primary"
                @click="addPermission"
              >
                <v-icon>{{ mdiPlus }}</v-icon>
                Neue Berechtigung
              </v-btn>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </template>
    <template #dialog-actions>
      <v-btn
        color="primary"
        @click="updateUser"
      >
        Speichern
      </v-btn>
      <v-btn
        @click="close"
      >
        Schliessen
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/UI/BaseDialog.vue'
import { mapGetters, mapState } from "vuex"
import { mdiTrashCan, mdiPlus } from "@mdi/js"
import { UPDATE_OTHER_USER } from "@/store/action-types"
import UserAdminOrgSelectionTemplate from "@/components/management/UserAdminOrgSelectionTemplate.vue"

export default {
  name: "EditUserDialog",
  components: {
    UserAdminOrgSelectionTemplate,
    BaseDialog
  },
  emits: ['close', 'update-user'],
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    availableOrganizations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: '',
      username: '',
      email: '',
      permissions: [],
      mdiTrashCan,
      mdiPlus
    }
  },
  computed: {
    ...mapGetters('organization', ['allOrganizations']),
    ...mapState('staticConfiguration', ['enumsByName']),
    permissionTableHeaders () {
      return [
        {
          text: 'Organisation',
          value: 'organization'
        },
        {
          text: 'Rolle',
          value: 'role',
        },
        {
          value: '_actions',
        }
      ]
    },
    availableRoles () {
      return Object.entries(this.enumsByName.RoleType).map(([name, label]) => {
        return {
          name,
          label
        }
      })
    }
  },
  methods: {
    addPermission () {
      this.permissions.unshift({
        organization: null,
        role: 'user',
        isNew: true
      })
    },
    removeOrganizationPermission (organizationUrl) {
      const index = this.permissions.findIndex(permission => permission.organization === organizationUrl)
      this.permissions.splice(index, 1)
    },
    close () {
      this.$emit('close')
    },
    async updateUser () {
      // We want to filter the flag for identifying new permissions
      const permissions = this.permissions.map(permission => {
        return {
          organization: permission.organization,
          role: permission.role
        }
      })
      const data = {
        name: this.name,
        username: this.username,
        email: this.email,
        permissions,
        url: this.user.url
      }

      await this.$store.dispatch('user/' + UPDATE_OTHER_USER, data)
      this.$emit('update-user')
      this.$emit('close')
    }
  },
  watch: {
    user: {
      deep: true,
      handler () {
        const user = JSON.parse(JSON.stringify(this.user))
        this.name = user.name
        this.username = user.username
        this.email = user.email
        this.permissions = user.permissions
      }
    }
  }
}
</script>
