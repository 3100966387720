export const ALIGNMENT = {
  monthly: { div: 1, off: 0, human: '10. eines Monats', name: 'monatlich' },
  quarterly: { div: 3, off: 0, human: '10. Januar, 10. April, 10. Juli, 10. Oktober', name: 'vierteljährlich' },
  halfyearly: { div: 6, off: 1, human: '10. Februar und 10. August', name: 'halbjährlich' },
  yearly: { div: 12, off: 2, human: '10. März', name: 'jährlich' }
}

export function nextValid (m, alignment) {
  if (m.date() >= 10) {
    m = m.clone().add({ months: 1 })
  }
  const inc = ((alignment.div - (m.month() % alignment.div)) % alignment.div) + alignment.off
  return m.clone().date(10).add({ months: inc })
}

export function availableOptions (start, end) {
  const options = Object.entries(ALIGNMENT).filter(
    ([codeA, objA]) => {
      if (!end) {
        return true
      }
      const x = nextValid(nextValid(start, objA), objA)
      return x.isSameOrBefore(end)
    }
  )
  options.sort(([codeA, objA], [codeB, objB]) => objA.div - objB.div)
  return options.map(([code, obj]) => ({ text: obj.name, value: code }))
}
