import { render, staticRenderFns } from "./CertificatesDataTable.vue?vue&type=template&id=62c0d743&scoped=true&"
import script from "./CertificatesDataTable.vue?vue&type=script&lang=js&"
export * from "./CertificatesDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c0d743",
  null
  
)

export default component.exports