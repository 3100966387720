export const SET_TIME = 'setTime'
export const INC_LOADING = 'incLoading'
export const DEC_LOADING = 'decLoading'
export const SET_ERROR_MESSAGE = 'setErrorMessage'

export const SET_SNACKBAR_MESSAGE = 'setSnackbarMessage'
export const SET_SNACKBAR_WARNING_MESSAGE = 'setSnackbarWarningMessage'

export const SET_WAITING_PROMISE = 'setWaitingPromise'
export const SET_VERSION = 'setVersion'
