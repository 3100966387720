<template>
  <v-autocomplete
    v-model="selectedOrgValue"
    :items="orgList"
    @change="selectedOrg"
    item-text="name"
    item-value="url"
    outlined
    dense
    clearable
    label="Organisation"
    hide-details
  >
    <template v-slot:item="data">
      <template v-if="!data.item">
        <v-list-item-content/>
      </template>
      <template v-else>
        <v-list-item-icon>
          <v-icon>{{ data.item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "UserAdminSelectionTemplate",
  props: ['orgList'],
  emits: ['organization-selection'],
  data () {
    return {
      selectedOrgValue: null
    }
  },
  methods: {
    selectedOrg () {
      this.$emit('organization-selection', this.selectedOrgValue)
    },
  }
}
</script>

<style scoped>

</style>
