<template>
  <div>
    <v-text-field
      v-model="password"
      :rules="[rules.password]"
      class="mt-4"
      label="Neues Passwort"
      placeholder="***"
      type="password"
      autocomplete="new-password"
      @input="inputChanged"
      :loading="password.length > 0"
    ><template v-slot:progress>
      <v-progress-linear
        absolute
        height="5"
        background-color="grey"
        :color="['red', 'red', 'orange', 'yellow', 'green'][passwordStrength.score]"
        :value="(passwordStrength.score >= 0 ? passwordStrength.score * 25 : 0)"
      ></v-progress-linear>
    </template>
    </v-text-field>
    <v-text-field
      v-model="repeat"
      :rules="[rules.repeat]"
      label="Neues Passwort (Bestätigung)"
      placeholder="***"
      type="password"
      name="password"
      autocomplete="new-password"
      @input="inputChanged"
      class="mt-3"
    ></v-text-field>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'
import { getIsCommonPassword } from '@/lib/password-tools'

export default {
  name: 'PasswordInput',
  data: function () {
    return {
      password: "",
      repeat: "",
      commonChecker: null,
      rules: {
        password: value => {
          if ((value?.length ?? 0) < 8) {
            return (value?.length ?? 0) >= 8 || 'Bitte geben Sie mindestens 8 Zeichen ein.'
          } else {
            if (this.commonChecker && this.commonChecker(value)) {
              return 'Das Passwort kann nicht verwendet werden, da es in der Liste häufiger Passwörter vorkommt.'
            }
            return this.passwordStrength.score >= 2 || 'Das Passwort ist nicht stark genug.'
          }
        },
        repeat: value => {
          return value === this.password || 'Beide Eingaben müssen übereinstimmen.'
        }
      },
    }
  },
  async created () {
    this.commonChecker = await getIsCommonPassword()
  },
  props: {
    value: {
      type: String,
      validator: prop => typeof prop === 'string' || prop === null,
    }
  },
  emits: ['input'],
  computed: {
    passwordStrength () {
      if (!this.password) {
        return { score: 0 }
      }
      return zxcvbn(this.password)
    }
  },
  methods: {
    inputChanged (event) {
      if (this.password === this.repeat && this.password?.length >= 8 && this.passwordStrength?.score >= 2 && !(this.commonChecker && this.commonChecker(this.password))) {
        this.$emit("input", this.password, true)
      } else {
        this.$emit("input", null, false)
      }
    },
    resetInputs () {
      this.password = ""
      this.repeat = ""
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        if (newVal === null) {
          this.password = this.repeat = ""
        } else if (typeof newVal === 'string') {
          this.password = this.repeat = newVal
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
