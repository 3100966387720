<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <span v-if="version?.frontend?.version">{{ version.frontend.version }}</span>
        <span v-else-if="version?.frontend?.buildId">#{{ version.frontend.buildId }}</span>
        <span v-else>&mdash;</span>
        /
        <span v-if="version?.backend?.version">{{ version.backend.version }}</span>
        <span v-else-if="version?.backend?.buildId">#{{ version.backend.buildId }}</span>
        <span v-else>&mdash;</span>
      </span>
    </template>
    <table style="min-width: 10em;" v-if="version?.frontend?.version || version?.frontend?.buildId || version?.backend?.version || version?.backend?.buildId">
      <tr v-if="version?.frontend?.version || version?.frontend?.buildId"><th class="text-left">Frontend</th><td><template v-if="version?.frontend?.version">{{ version?.frontend?.version }}</template><template v-if="version?.frontend?.buildId"> Build {{ version?.frontend?.buildId }}</template></td></tr>
      <tr v-if="version?.frontend?.buildDate"><th class="text-left">Frontend-Version</th><td><template v-if="version?.frontend?.buildDate">{{ version?.frontend?.buildDate | isoToHuman('LLL') }}</template></td></tr>
      <tr v-if="version?.backend?.version || version?.backend?.buildId"><th class="text-left">Server</th><td><template v-if="version?.backend?.version">{{ version?.backend?.version }}</template><template v-if="version?.backend?.buildId"> Build {{ version?.backend?.buildId }}</template></td></tr>
      <tr v-if="version?.backend?.buildDate"><th class="text-left">Server-Version</th><td><template v-if="version?.backend?.buildDate">{{ version?.backend?.buildDate | isoToHuman('LLL') }}</template></td></tr>
    </table>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VersionInfo',
  computed: {
    ...mapState(['version'])
  }
}
</script>

<style scoped>

</style>
