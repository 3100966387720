<template>
  <v-col
    cols="12"
    xs="12"
    sm="12"
    md="12"
    lg="12"
    class=""
  >
    <div class="d-flex flex-row-reverse mb-4" v-if="items.length">
      <v-btn
        color="primary"
        class="d-print-none"
        @click="$emit('exportDataMethod')"
      >
        <span>Download alle Daten</span>
        <v-icon>{{ mdiFileExcel }}</v-icon>
      </v-btn>
    </div>
    <template>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche nach Jahr (z. B. 2022), Monat (z. B.  August), Spendenzweck oder Name"
            hide-details
            single-line
          ></v-text-field>
        </v-card-title>
        <v-data-table
          id="certificate"
          :headers="headers"
          :items="items"
          :search="search"
          :custom-filter="customSearch"
          :sort-by="['date']"
          :sort-desc="[true]"
          item-key="id"
          :expanded.sync="expanded"
          show-select
          show-expand
          dense
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip bottom v-if="item.name">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.name.length > 22 ? item.name.substring(0, 22) + '...' : item.name }}
                </span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ item.date | isoToHuman('L') }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
            <span>{{ item.amount | amount }} € </span>
          </template>
          <template v-slot:item.receipt="{ item }">
            <span v-if="!item.receipt">-</span>
            <template v-else>
              <a @click.stop.prevent="$emit('downloadPdf', item.receipt.url)">Download (PDF)</a>
              <v-icon v-if="item.payments.length > 1"> {{ mdiTextBoxMultipleOutline }}</v-icon>
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip v-if="item.state === 'payment_pending'" bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >{{ mdiHelpBox }}
                </v-icon>
              </template>
              <span>Warte auf Zahlungseingang</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.state === 'payment_confirmed'" bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >{{ mdiProgressClock }}
                </v-icon>
              </template>
              <span>Zahlung eingegangen.<br>Zuwendungsbestätigung wird<br> demnächst automatisch erstellt</span>
            </v-tooltip>
            <v-tooltip
              v-else-if="item.receipt && item.receipt.first_download"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="archivedColor(item)"
                >{{ mdiEmailOpenOutline }}
                </v-icon>
              </template>
              <span>
                Heruntergeladen <br>
                am {{ item.receipt.first_download.date | isoToHuman("DD.MM.YYYY") }}<br>
                von {{ item.receipt.first_download.user }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-else-if="item.receipt"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="archivedColor(item)"
                >{{ mdiEmailOutline }}
                </v-icon>
              </template>
              <span>Ungeöffnet</span>
            </v-tooltip>
            <v-tooltip v-else>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >{{ mdiArchiveOutline }}
                </v-icon>
              </template>
              <span>Archiviert</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ul>
                <li :key="payment.id" v-for="payment in item.payments">
                  {{ payment.amount | amount }} € am {{ payment.created_at | isoToHuman("L") }}
                </li>
              </ul>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <!-- <p>* Grenze bis zu der Kontoauszug als Zuwendungsbestätigung reicht.</p> -->
  </v-col>
</template>

<script>
import { monthMappingDe, monthMappingEn } from "@/lib/search-by-month"
import { mdiEmailOpenOutline, mdiEmailOutline, mdiFileExcel, mdiHelpBox, mdiProgressClock, mdiArchiveOutline, mdiTextBoxMultipleOutline } from "@mdi/js"

export default {
  name: "CertificatesDataTable",
  props: {
    headers: { type: Array, required: true },
    items: { type: Array },
  },
  data () {
    return {
      mdiEmailOutline,
      mdiEmailOpenOutline,
      mdiFileExcel,
      mdiHelpBox,
      mdiProgressClock,
      mdiArchiveOutline,
      mdiTextBoxMultipleOutline,
      selected: [],
      expanded: [],
      search: "",
    }
  },
  methods: {
    customSearch (value, search, item) {
      search = search.toLowerCase()
      if (Object.keys(monthMappingEn).includes((search))) {
        search = "-" + monthMappingEn[search] + "-"
      }
      if (Object.keys(monthMappingDe).includes(search)) {
        search = "-" + monthMappingDe[search] + "-"
      }
      return Object.values(item).some(value => value && value.toString().toLowerCase().includes(search))
    },
    archivedColor (item) {
      return item?.archived ? 'grey lighten-1' : ''
    }
  }
}
</script>

<style scoped>

</style>
