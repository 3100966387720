<template>
  <div id="doc-book">
    <p>Bald werden Sie hier Ihr Kollektenbuch einsehen können!</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'doc-book',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organization: 'currentOrganization' }),
  },
  created () {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="stylus">
#doc-book p
  padding: 32px
</style>
