<template>
  <v-card flat>
    <v-card-title>Neuen Brief erstellen</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-stepper
            vertical
            v-model="step"
          >

            <v-stepper-step
              step="1"
              :complete="!!targetGroup"
              @click="step = 1"
            >Zielgruppe wählen
              <small v-if="targetGroup && step !== 1">{{ targetGroup.name }}</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card>
                <v-card-text>
                  <v-autocomplete
                    v-model="targetGroup"
                    :items="availableTargetGroups"
                    item-text="name"
                    label="Zielgruppe wählen"
                    return-object
                    @change="updatePreview"
                    :disabled="actionParam === 'followUp'"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="$router.push({ name: 'kige-target-groups-create' })"
                    color="primary"
                    outlined
                  >
                    <v-icon>mdi-plus</v-icon>
                    Neue Zielgruppe
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    text
                    color="primary"
                    @click="step = 2"
                    :disabled="!targetGroup"
                  >
                    Weiter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-step
              step="2"
              :complete="letterType && maxStep > 1"
              @click="step = 2"
            >
              Brieftyp wählen
              <small v-if="letterType && step > 1">
                {{ allLetterTypes[letterType] }}<template v-if="isNotKirchgeld">,
                kein Kirchgeld</template>
              </small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card flat>
                <v-card-text>
                  <v-radio-group
                    row
                    v-model="letterType"
                    class="letter-type"
                  >
                    <v-radio
                      v-for="[letterType, label] in availableLetterTypes"
                      :key="letterType"
                      :label="label"
                      :value="letterType"
                    ></v-radio>
                  </v-radio-group>
                  <v-checkbox
                    v-if="actionParam !== 'followUp'"
                    v-model="isNotKirchgeld"
                    label="kein Kirchgeld (abweichender HT Code)"
                    small
                  />
                  <v-text-field
                    v-show="isNotKirchgeld"
                    v-model.trim="specialPurpose"
                    label="Verwendungsschlüssel"
                    placeholder="Abweichenden HT Code eingeben"
                  >
                  </v-text-field>
                  <div class="text-overline mt-4">Anzahl Adressen</div>
                  <v-radio-group
                    v-model="separateRelationTypeFilter"
                    :disabled="actionParam === 'followUp'"
                  >
                    <v-radio
                      v-for="marriedLetterType in separateRelationTypeFilters"
                      :key="marriedLetterType.value"
                      :label="marriedLetterType.label"
                      :value="marriedLetterType.value"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    text
                    @click="step = 3"
                    color="primary"
                    :disabled="!letterType || (isNotKirchgeld && !specialPurpose)"
                  >
                    Weiter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              step="3"
              :complete="step > 3"
              @click="step = 3"
            >
              Filter hinzufügen
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card>
                <v-card-text>
                  <div class="text-overline">Spendenstatus</div>
                  <v-radio-group
                    v-model="donationStatusFilter"
                    :disabled="actionParam === 'followUp'"
                  >
                    <v-radio
                      v-for="donationStatus in donationStatusFilters"
                      :key="donationStatus.value"
                      :label="donationStatus.label"
                      :value="donationStatus.value"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-model="currentYear"
                    label="Jahr"
                    type="number"
                    min="0"
                    :max="(new Date()).getFullYear()"
                    v-if="[LETTER_FILTER_HAS_DONATED_THIS_YEAR, LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR].includes(this.donationStatusFilter)"
                    :disabled="actionParam === 'followUp'"
                  />
                  <v-divider/>
                  <div class="text-overline mt-4">Sperren</div>
                  <v-checkbox
                    v-for="sperre in availableSperren"
                    :key="sperre.value"
                    v-model="sperrenFilter"
                    :label="sperre.label"
                    :value="sperre.value"
                    :disabled="actionParam === 'followUp'"
                    hide-details="auto"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title>
              <v-text-field
                v-model.trim="letterName"
                label="Name des Briefes*"
                placeholder="Namen vergeben"
                :rules="[!!letterName || 'Name des Briefes eingeben']"
              ></v-text-field>
            </v-card-title>
            <template v-if="previewEntries !== null && previewCount !== null">
              <v-card-subtitle>
                <b v-if="targetGroup">{{ targetGroup.name }}</b><br>
                {{ targetGroup | filterDescription }}
              </v-card-subtitle>
              <v-card-text>
                <v-list
                  dense
                  v-if="additionalFilterDescriptions.length"
                >
                  <v-list-item
                    v-for="desc in additionalFilterDescriptions"
                    :key="desc"
                    style="min-height: initial"
                  >{{ desc }}
                  </v-list-item>
                </v-list>
                <v-divider/>
                <v-list
                  v-if="previewEntries.length"
                  dense
                >
                  <v-list-item class="text-overline">Vorschau ({{ previewCount }} Ergebnisse)</v-list-item>
                  <v-list-item
                    v-for="(previewEntry, index) in previewEntries"
                    :key="previewEntry.id"
                    style="min-height: initial"
                  >
                    <v-list-item-icon style="height: auto" class="ma-0">{{ index + 1 }}</v-list-item-icon>
                    {{ previewEntry.rufname }} {{ previewEntry.familienname }},
                    {{ previewEntry.strassenname }},
                    {{ previewEntry.kommune }}
                  </v-list-item>
                  <v-list-item v-if="previewEntries && previewEntries.length < previewCount" style="min-height: initial">
                    <v-list-item-icon style="height: auto" class="ma-0">&hellip;</v-list-item-icon>
                    <i>{{ previewCount - previewEntries.length }} weitere Einträge</i>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </template>
            <v-skeleton-loader v-else type="article,list-item@6" boilerplate
                               style="filter: blur(6px)"></v-skeleton-loader>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                :disabled="!areInputsValid"
                color="primary"
                @click="showConfirmationDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
                Erstellen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <base-dialog
      :is-open="showConfirmationDialog"
      @close="showConfirmationDialog = false"
    >
      <template #dialog-title>Neuen Brief anlegen</template>
      <template #dialog-content>
        <v-card-text>
          <div class="pt-2">
            Sind Sie sicher, dass Sie den Brief <b>{{ letterName }}</b> erstellen möchten?
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="saveLetter"
          >
            <v-icon>mdi-check</v-icon>
            Ja, erstellen
          </v-btn>
          <v-btn
            @click="showConfirmationDialog = false">
            <v-icon>mdi-cancel</v-icon>
            Nein, abbrechen
          </v-btn>
        </v-card-actions>
      </template>

    </base-dialog>
  </v-card>
</template>

<script>
import {
  additionalLetterFilters,
  LETTER_FILTER_DONATION_STATUS_UNIMPORTANT,
  LETTER_FILTER_HAS_DONATED_THIS_YEAR,
  LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
} from "@/modules/kirchgeld/config/letter-types"
import { mapGetters, mapState } from 'vuex'
import { GET_FILTER_PREVIEW, GET_FILTERS, SAVE_LETTER } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import BaseDialog from "@/components/UI/BaseDialog"
import { sortAlphaNumeric } from "@/lib/sort-utils"
import { shortenParish } from "@/lib/regex-tools"
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"
import {
  additionalLetterOptions, LETTER_FILTER_ONE_LETTER_PER_PERSON,
  LETTER_FILTER_ONE_LETTER_PER_COUPLE
} from "@/modules/kirchgeld/config/relation-letter-type"
import { describeFilter } from '../../utils'
import { pick } from 'lodash'

export default {
  name: "LetterCreate",
  components: {
    BaseDialog
  },
  mixins: [
    PersonDataMixin
  ],
  props: ["selectedLetter"],
  data () {
    return {
      step: 1,
      maxStep: 1,
      letterName: '',
      followUpLetter: false,
      letterType: null,
      targetGroup: null,
      showConfirmationDialog: false,
      previewEntries: null,
      previewCount: null,
      sperrenFilter: ["1010"],
      donationStatusFilter: LETTER_FILTER_DONATION_STATUS_UNIMPORTANT,
      isNotKirchgeld: false,
      specialPurpose: '',
      separateRelationTypeFilter: LETTER_FILTER_ONE_LETTER_PER_COUPLE,
      currentYear: (new Date()).getFullYear(),
      LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR,
      LETTER_FILTER_HAS_DONATED_THIS_YEAR,
    }
  },
  filters: {
    filterDescription (v) {
      return describeFilter(v?.filter || [])
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['enums', 'enumsByName', 'filters', 'api']),
    ...mapState(['now']),
    preselectedTargetGroup () {
      if (!this.availableTargetGroups || !this.availableTargetGroups.length) {
        return null
      }
      const groupId = this.$route?.query?.preselectedTargetGroupId ?? null
      if (groupId) {
        return this.availableTargetGroups.find(item => item.id === groupId)
      }
      return null
    },
    donationStatusFilters () {
      const replacement = (String(this.currentYear) === String(this.now.year()))
        ? 'This'
        : (
            (String(this.currentYear) === String(this.now.year() - 1)) ? 'Last' : 'Other'
          )
      return [...additionalLetterFilters, {
        label: 'alle',
        value: LETTER_FILTER_DONATION_STATUS_UNIMPORTANT
      }].map(item => ({
        ...item,
        label: this.$t(`kirchgeld.donationStatus.${item.value}`.replace('This', replacement), { year: this.currentYear })
      }))
    },
    separateRelationTypeFilters () {
      return [...additionalLetterOptions]
    },
    availableTargetGroups () {
      const filters = (this.filters || []).map(filter => {
        return {
          ...filter,
          name: shortenParish(filter.name)
        }
      }).filter(item => item.type !== "PersistedFilter")
      return sortAlphaNumeric(filters, 'name')
    },
    allLetterTypes () {
      return this.enumsByName?.LetterType?.values ?? {}
    },
    availableLetterTypes () {
      let retval = this.enumsByName?.LetterType?.values ?? {}
      if (this.actionParam === 'followUp') {
        retval = pick(retval, ["appreciation", "reminder"])
      }
      retval = Object.entries(retval).sort(([a, b], [c, d]) => (a !== 'donation'))
      return retval
    },
    availableSperren () {
      const sperren = this.enumsByName?.Sperre?.values ?? []
      return Object.entries(sperren).map(([sperreId, sperreLabel]) => {
        return {
          value: sperreId,
          label: `${sperreId}: ${sperreLabel}`
        }
      })
    },
    additionalFilter () {
      const additionalFilter = []

      if (this.sperrenFilter.length) {
        additionalFilter.push([
          { op: 'not' },
          {
            field: 'sperre',
            in: this.sperrenFilter
          },
        ])
      }

      if ([LETTER_FILTER_HAS_DONATED_THIS_YEAR, LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR].includes(this.donationStatusFilter)) {
        const hasPaidCurrentYear = (this.donationStatusFilter === LETTER_FILTER_HAS_DONATED_THIS_YEAR)

        const donationStatusFilter = {
          field: 'has_paid_current_year',
          exact: hasPaidCurrentYear
        }

        additionalFilter.push(donationStatusFilter)
      }

      if (additionalFilter.length) {
        additionalFilter.unshift({ op: 'and' })
      }
      return additionalFilter
    },
    additionalFilterDescriptions () {
      const retval = []
      if (this.sperrenFilter.length) {
        retval.push("Sperren: " + this.sperrenFilter.join(", "))
      }

      if ([LETTER_FILTER_HAS_DONATED_THIS_YEAR, LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR].includes(this.donationStatusFilter)) {
        retval.push("Spendenstatus: " + this.donationStatusFilters.find(item => item.value === this.donationStatusFilter).label)
      }

      if (this.isNotKirchgeld && this.specialPurpose) {
        retval.push("Verwendungsschlüssel: " + this.specialPurpose)
      }

      if (this.options.use_last_known_address) {
        retval.push("Inaktive Personen mit einschließen, letzte bekannte Adresse verwenden")
      }

      return retval
    },
    areInputsValid () {
      return !!this.letterName?.length && !!this.letterType && !!this.targetGroup && (!this.isNotKirchgeld || !!this.specialPurpose)
    },
    filterDict () {
      const filterObject = {}
      this.filters.forEach(filter => {
        filterObject[filter.id] = filter
      })
      return filterObject
    },
    actionParam () {
      return this.$route.query.action || null
    },
    originalLetterParam () {
      return this.$route.query.originalLetter || null
    },
    options () {
      const options = {}
      const selectedRelationType = this.separateRelationTypeFilter
      options.separate_relation_ehe = selectedRelationType !== LETTER_FILTER_ONE_LETTER_PER_COUPLE
      if ([LETTER_FILTER_HAS_DONATED_THIS_YEAR, LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR].includes(this.donationStatusFilter)) {
        options.current_year_for_payments = parseInt(this.currentYear)
      }
      if (this.letterType === 'appreciation') {
        // options.use_last_known_address = true
        // Don't use this anymore
      }
      return options
    }
  },
  methods: {
    async updatePreview () {
      if (!this.targetGroup || !this.targetGroup?.id) {
        return
      }
      const previewFilter = [
        { op: "and" },
        { ref: this.targetGroup.id },
      ]
      if (this.additionalFilter.length) {
        previewFilter.push(this.additionalFilter)
      }
      const result = await this.$store.dispatch('kirchgeld/' + GET_FILTER_PREVIEW, { filter: JSON.stringify(previewFilter), options: this.options })
      if (!result) {
        this.previewEntries = this.previewCount = null
      }
      let res
      [this.previewCount, res] = result

      this.previewEntries = res.map(personEntry => this.getLatestValidPersonData(personEntry))
    },
    async saveLetter () {
      const selectedFilterUrl = this.targetGroup?.url ?? null
      const payload = {
        name: this.letterName,
        filter: selectedFilterUrl,
        type: this.letterType,
        additional_filter: this.additionalFilter.length ? this.additionalFilter : null,
        options: this.options
      }

      if (this.isNotKirchgeld && this.specialPurpose.length) {
        payload.special_purpose = this.specialPurpose
      }

      // const response = await this.$store.dispatch('kirchgeld/' + SAVE_LETTER, payload)
      await this.$store.dispatch('kirchgeld/' + SAVE_LETTER, payload)
      // FIXME HACK HACK HACK  Immediately finalize this letter so that CSV download is available
      // await this.$store.getters['kirchgeld/api'].post(response.url + "finalize/")
      await this.$router.push({ name: 'kige-letter-index' })
    },
    showFilterName (filterUrl) {
      const startIndex = filterUrl.indexOf('filter/') + 7
      const filterId = filterUrl.substring(startIndex, filterUrl.length - 1)
      return this.filterDict[filterId]?.name || ''
    },
    checkAdditionalFilter (array, searchItem) {
      const additionalFilter = []
      array.forEach(function (eachObj) {
        for (const key in eachObj) {
          // eslint-disable-next-line no-prototype-builtins
          if (eachObj[key].hasOwnProperty(searchItem)) {
            additionalFilter.push(eachObj[key][searchItem])
          }
        }
      })
      return additionalFilter
    },
    async initializeFromExistingLetter (letter) {
      const filterResponse = await this.api.get(letter.filter)

      const filterContainer = filterResponse.data
      if (filterContainer.type === 'PersistedFilter') {
        this.applyPersistedFilterSettings(filterContainer.filter)
      } else if (filterContainer.type === 'LiveFilter') {
        this.targetGroup = filterContainer
        this.applyAdditionalSperrenFilter(letter.additional_filter)
        this.applyDonationStatus(letter.additional_filter)
      }
      // Disable use_last_known_address
      this.applyOptions({ ...letter.options, use_last_known_address: undefined })
      await this.updatePreview()
    },
    async initializeDuplicateLetter (letter) {
      this.letterType = letter.type
      this.letterName = letter.name

      if (letter.special_purpose) {
        this.isNotKirchgeld = true
        this.specialPurpose = letter.special_purpose
      }

      await this.initializeFromExistingLetter(letter)
    },
    async initializeFollowUpLetter (letter) {
      this.letterName = 'Folgebrief: ' + letter.name
      this.step = 2

      await this.initializeFromExistingLetter(letter)
    },
    applyPersistedFilterSettings (persistedFilter) {
      const [filterConfig, additionalFilter, liveFilter] = persistedFilter
      const hasExpandedAdditionalFilters = filterConfig['backend:expand_include_additional'] ?? false
      if (hasExpandedAdditionalFilters || additionalFilter.length) {
        this.applyAdditionalSperrenFilter(additionalFilter)
        this.applyDonationStatus(additionalFilter)
      }

      this.targetGroup = this.getFilterById(liveFilter[0]?.was) ?? null
    },
    applyAdditionalSperrenFilter (additionalFilter) {
      const additionalSperrenFilter = additionalFilter?.find(filter => {
        return filter[0]?.op === 'not' && filter[1]?.field === 'sperre'
      })

      if (additionalSperrenFilter) {
        this.sperrenFilter = additionalSperrenFilter[1].in
      }
    },
    getFilterById (filterId) {
      return this.availableTargetGroups.find(filter => filter.id === filterId)
    },
    applyDonationStatus (additionalFilter) {
      // eslint-disable-next-line no-unused-vars
      const donationStatus = additionalFilter?.find(filter => {
        return filter?.field === 'has_paid_current_year'
      })
      if (!donationStatus) {
        this.donationStatusFilter = LETTER_FILTER_DONATION_STATUS_UNIMPORTANT
      } else if (donationStatus) {
        if (donationStatus.exact === true) {
          this.donationStatusFilter = LETTER_FILTER_HAS_DONATED_THIS_YEAR
        } else {
          this.donationStatusFilter = LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
        }
      }
      return donationStatus
    },
    applyOptions (options) {
      if (options.separate_relation_ehe) {
        this.separateRelationTypeFilter = LETTER_FILTER_ONE_LETTER_PER_PERSON
      } else {
        this.separateRelationTypeFilter = LETTER_FILTER_ONE_LETTER_PER_COUPLE
      }
      // eslint-disable-next-line camelcase
      if (options?.current_year_for_payments !== undefined) {
        this.currentYear = parseInt(options.current_year_for_payments)
      }
    },
    resetLetter () {
      this.letterName = ''
      this.followUpLetter = false
      this.letterType = 'donation'
      this.targetGroup = null
      this.showConfirmationDialog = false
      this.previewEntries = null
      this.previewCount = null
      this.sperrenFilter = ["1010"]
      this.donationStatusFilter = LETTER_FILTER_DONATION_STATUS_UNIMPORTANT
      this.isNotKirchgeld = false
      this.specialPurpose = ''
    },

  },
  watch: {
    step (newValue) {
      this.maxStep = Math.max(newValue, this.maxStep)
    },
    async additionalFilter () {
      await this.updatePreview()
    },
    async options () {
      await this.updatePreview()
    },
    letterType (newValue) {
      if (newValue === 'reminder' && this.donationStatusFilter !== LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR) {
        this.donationStatusFilter = LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
      } else if (newValue === 'appreciation' && this.donationStatusFilter !== LETTER_FILTER_HAS_DONATED_THIS_YEAR) {
        this.donationStatusFilter = LETTER_FILTER_HAS_DONATED_THIS_YEAR
      }
    },
    preselectedTargetGroup: {
      immediate: true,
      handler (newValue) {
        if (newValue) {
          this.targetGroup = this.preselectedTargetGroup
        }
      }
    },
    originalLetterParam: {
      immediate: true,
      async handler (newValue) {
        const response = await this.api.get(newValue)
        const letter = response.data
        if (this.actionParam === 'duplicate') {
          await this.initializeDuplicateLetter(letter)
        } else if (this.actionParam === 'followUp') {
          await this.initializeFollowUpLetter(letter)
        }
      }
    },
    actionParam: {
      immediate: true,
      handler (newValue) {
        if (!(['duplicate', 'followUp'].includes(newValue))) {
          this.resetLetter()
        }
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_FILTERS)
  }
}
</script>

<style
  lang="stylus"
  scoped
>

</style>
