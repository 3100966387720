<template>
  <person-data-detail
    :person-url="$route.params.personUrl"
    :data-id="$route.params.dataId"
    @data-id-changed="dataIdChanged"
    ></person-data-detail>
</template>

<script>

import PersonDataDetail from '@/modules/kirchgeld/components/PersonDataDetail.vue'

export default {
  name: 'PersonDetail',
  components: {
    PersonDataDetail,
  },
  methods: {
    dataIdChanged (dataId) {
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          dataId,
        }
      })
    },
  },
}
</script>
<style scoped lang="stylus">
.inactive
  font-size 1.5rem
  @media (min-width: 1264px)
    font-size  4.5rem
</style>
