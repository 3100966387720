<template>
  <div>
    <template v-if="isLoggedIn">
      <menu-area></menu-area>
    </template>
    <template v-else>
      <kige-login></kige-login>
    </template>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { GET_STATIC_CONFIGURATION, GET_ORGANIZATIONS, GET_TENANTS } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import MenuArea from "@/modules/kirchgeld/layouts/MenuArea.vue"
import { mapGetters } from 'vuex'
import KigeLogin from "@/modules/kirchgeld/pages/KigeLogin"

export default {
  name: "KigeWrapper",
  components: {
    MenuArea,
    KigeLogin
  },
  computed: {
    ...mapGetters('kirchgeld', [
      'isLoggedIn',
    ])
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      async handler (newVal) {
        if (newVal) {
          await Promise.allSettled([
            this.$store.dispatch('kirchgeld/' + GET_STATIC_CONFIGURATION),
            this.$store.dispatch('kirchgeld/' + GET_TENANTS),
            this.$store.dispatch('kirchgeld/' + GET_ORGANIZATIONS),
          ])
        }
      }
    }
  }
}
</script>
