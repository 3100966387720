<template>
  <div>
      <div
        style="height:78px; width: 259px; padding: 7px 14px 7px 14px; border: 2px solid #03867b; display: flex;"
      >
        <div class="handout" style="height: 100%; width: 52px; padding-right: 12px; padding-top: 18px">

          <svg style="" id="spendeappsvg" xmlns="http://www.w3.org/2000/svg" width="9.84mm" height="5.35mm" viewBox="0 0 33.57 15.18"><g><polyline points="0 12.69 3.22 12.69 3.22 3.21 0 3.21" class="cls-1"></polyline><path d="M3.22,3.21A9.34,9.34,0,0,1,9.35.5c3.58,0,11.11,4.15,11.11,4.15,2.49,2.11.35,4.28-1.63,3.8-.58-.26-8.07-3.19-8.07-3.19" class="cls-1"></path><path d="M5.68,10.68s5.23,4.21,8.75,4S31.88,6.88,32.84,6.15,32,2.26,29.37,1.75c-2.3-.45-8.13,3-8.37,3" class="cls-1"></path></g></svg>
        </div>
        <div style="line-height: 1.8em; text-align: left; font-size: small; cursor: default;">
          <contenteditable
            tag="div"
            style="width: 100%; line-height: 1.6em; "
            id="display-simulation-kb--text"
            v-model="val"
            :contenteditable="selected && editable"
          ></contenteditable>

        </div>

      </div>
    <span>Max. 3 Zeilen Text</span>
    <!-- <span v-if="scope === 'loc'"><br>Nicht verfügbar an diesem Ort</span> -->
  </div>
</template>

<script>
export default {
  name: 'DisplayKindSpendeApp',
  props: ["value", "selected", "editable"],
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.val = newValue
      }
    },
    val (newVal) {
      this.$emit("input", newVal)
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
#spendeappsvg
  /* FIXME Use proper icon */
  .cls-1
    fill: none
    stroke: rgb(3, 134, 123)
    stroke-miterlimit: 10

</style>
