<template>
  <div>
    <div class="address-edit">
      <v-card
        v-if="isEditingAddr"
        elevation="0"
        class="pa-1 addresscard"
        outlined
      >
        <v-card-text>
          <org-details-address-common
            :organization="organization"
            :org-addr="editOrgAddr"
            @dataChanged="handleDataChanged"
          >
          </org-details-address-common>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="saveAddress"
            color="primary"
          >Speichern
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="isEditingAddr = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-list-item
        v-else
        v-for="address in addresses"
        :key="address.id"
        three-line
      >
        <v-list-item-content>
          <v-card
            elevation="0"
            class="pa-0 addresscard"
            outlined
          >
            <v-card-text>
              <v-list-item-title>
                <div class="d-flex align-center">
                  <h4>{{ (addressTypes[address.type] ? addressTypes[address.type] : address.type) }}</h4>
                  <div
                    v-if="address.isEditable"
                    class="d-flex justify-space-between address-button-container"
                  >
                    <v-btn
                      icon
                      color="grey"
                      @click="editOrgAddress(address)"
                    >
                      <v-icon>{{ mdiPencil }}</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="grey"
                      @click="requestAddressInvalidation(address)"
                    >
                      <v-icon>{{ mdiTrashCan }}</v-icon>
                    </v-btn>
                  </div>
                </div>
                  <div class="text-overline">
                    <span v-if="address.valid_from || address.valid_before">
                      <span v-if="address.valid_from">{{ address.valid_from | isoToHuman("LL") }}</span>
                      &mdash;
                      <span v-if="address.valid_notafter">{{ address.valid_notafter| isoToHuman("LL") }}</span>
                    </span>
                  </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ address.name !== null ? address.name : organization.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ address.address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ address.postcode }} {{ address.locality }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="address.region">
                {{ address.region }}
              </v-list-item-subtitle>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </div>
    <base-dialog
      :is-open="showConfirmInvalidateAddressDialog"
      @close="showConfirmInvalidateAddressDialog = false"
    >
      <template #dialog-title>Addressdatensatz löschen?</template>
      <template #dialog-content>
        <v-card text>
          <v-card-text>
            Möchten Sie den aktuellen Addressdatensatz wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="primary"
              @click="invalidateAddress">
              Ja, löschen
            </v-btn>
            <v-btn @click="cancelAddressInvalidation">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { mdiPencil, mdiTrashCan } from "@mdi/js"
import { mapGetters } from "vuex"
import { WRITE_ORG_DATA } from '@/lib/permission-tools'
import { INVALIDATE_ORGANIZATION_ADDRESS, UPDATE_ORGANIZATION_ADDRESS } from "@/store/action-types"
import OrgDetailsAddressCommon from "@/components/OrgDetailsAddressCommon"
import { mapOrder } from "@/lib/data-order"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import { isCurrentlyValid } from "@/lib/sort-utils"

export default {
  name: "OrgDetailsAddressEdit",
  components: {
    BaseDialog,
    OrgDetailsAddressCommon
  },
  props: {
    organization: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      showConfirmInvalidateAddressDialog: false,
      isEditingAddr: false,
      selectedAddress: null,
      editOrgAddr: {
        postcode: '',
        locality: '',
        type: []
      },
      addressTypes: {
        primary: 'Hauptadresse',
        billing: 'Rechnungsadresse',
        physical: 'Standort',
        other: 'Sonstige'
      },
      existingAddress: null,
      addressTypeGroup: {},
      updatedAddressList: [],
      addressesOrder: ['primary', 'physical', 'billing', 'other'],
      expirationData: {},
      mdiPencil,
      mdiTrashCan
    }
  },
  computed: {
    ...mapGetters('user', ['isSuperuser', 'hasPermission']),
    addresses () {
      if (!this.organization || !this.organization.addresses) {
        return []
      }
      const validAddresses = this.organization.addresses
        .filter(addr => isCurrentlyValid(addr))
        .map(addr => {
          const isEditable = (
            (addr.type !== 'billing' && this.hasEditingPermissions) ||
            (addr.type === 'billing' && this.isSuperuser)
          )

          return {
            ...addr,
            isEditable
          }
        })

      return mapOrder(validAddresses, this.addressesOrder, 'type')
    },
    hasEditingPermissions () {
      return this.hasPermission(this.organization.url, WRITE_ORG_DATA)
    }
  },
  methods: {
    editOrgAddress (address) {
      this.isEditingAddr = true
      this.editOrgAddr = { ...address }
      this.existingAddress = { ...address }
    },
    async saveAddress () {
      const updateAddressPayload = {
        ...this.existingAddress,
      }
      if (!this.editOrgAddr.type) {
        this.editOrgAddr.type = 'other'
      }
      const addressPayload = {
        ...this.editOrgAddr,
        organization: this.organization.url
      }
      const payload = {
        patchPayload: updateAddressPayload,
        addPayload: addressPayload,
        organization: this.organization.url
      }
      let updatedOrg
      if (this.editOrgAddr?.id) {
        updatedOrg = await this.$store.dispatch('organization/' + UPDATE_ORGANIZATION_ADDRESS, payload)
        this.isEditingAddr = false
      }
      if (updatedOrg) {
        this.isEditingAddr = false
        this.editOrgAddr = {}
        this.existingAddress = null
      }
    },
    handleDataChanged (newValue) {
      this.isEditingAddr = newValue
    },
    requestAddressInvalidation (address) {
      this.selectedAddress = address
      this.showConfirmInvalidateAddressDialog = true
    },
    invalidateAddress () {
      this.$store.dispatch('organization/' + INVALIDATE_ORGANIZATION_ADDRESS, {
        address: this.selectedAddress,
        organization: this.organization.url,
      })
    },
    cancelAddressInvalidation () {
      this.showConfirmInvalidateAddressDialog = false
      this.selectedAddress = null
    }
  }
}
</script>

<style
  lang="stylus"
  scoped
>
.addresscard
  .title
    text-overflow: unset
    white-space: unset

  .content
    text-overflow: unset
    white-space: unset

.address-edit
  margin-top: 20px

.address-button-container
  width: 100%
</style>
