<template>
  <v-container id="home">
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
        lg="8"
        v-if="allOrganizations"
      >
        <h1 class="mt-0">{{ $t('general.welcome') }}</h1>
        <p>
          Da Sie mehr als eine Organisation verwalten, müssen Sie hier eine auswählen um die eigentliche Startseite,
          das Dashboard, zu sehen. Der Link "Adminansicht" in der linken unteren Ecke führt Sie hierher zurück.
        </p>
        <v-toolbar
          dense
          elevation="0"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                @click="$refs.organizationTree.updateAll(true)"
                small
                style="min-width: 28px"
                plain
                v-on="on"
                v-bind="attrs"
                class="px-1"
              >
                <v-icon color="primary">{{ mdiExpandAll }}</v-icon>
              </v-btn>
            </template>
            <span>Alle ausklappen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                @click="$refs.organizationTree.updateAll(false)"
                small
                style="min-width: 28px"
                plain
                v-on="on"
                v-bind="attrs"
                class="px-1"
              >
                <v-icon color="primary">{{ mdiCollapseAll }}</v-icon>
              </v-btn>
            </template>
            <span>Alle einklappen</span>
          </v-tooltip>
          <v-text-field
            v-model="search"
            :prepend-inner-icon="mdiMagnify"
            :append-icon="mdiClose"
            hide-details
            :label="'Organisationsbaum durchsuchen' + (search && search.length > 2 ? '' : ' (min. 3 Zeichen)')"
            class="mx-3"
            @click:append="search=''"
          ></v-text-field>
          <template v-if="user.is_superuser">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="isNewRootOrgaCreationDialogOpen = true"
                  small
                  fab
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                >
                  <v-icon dark>{{ mdiPlusBox }}</v-icon>
                </v-btn>
              </template>
              <span>Neue Organisation anlegen ...</span>
            </v-tooltip>
          </template>
        </v-toolbar>
        <v-skeleton-loader
          :loading="!!loading && !(organizationTreeView && organizationTreeView.length)"
          type="list-item-avatar@7"
          color="primary"
        >
          <v-treeview
            :items="organizationTreeView"
            :open="orgsOpen"
            item-key="url"
            ref="organizationTree"
            :search="activeSearch"
            :filter="filteredSearch"
            hoverable
            open-on-click
            :open-all="!user.is_superuser"
          >
            <template #prepend="{ item }">
              <organization-icon :organization="allOrganizations[item.url]"></organization-icon>
            </template>
            <template #label="{ item }">
              <div>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <router-link
                      :to="{ name: 'organization.dashboard', params: { id: allOrganizations[item.url].id } }"
                      v-if="hasPermission(item.url, PERM_TYPE_INTERACT_GENERAL)"
                    >
                      <span
                        v-on="on"
                        v-bind="attrs"
                        class="text-h6"
                      ><text-highlight :queries="(activeSearch && activeSearch.length) ? [activeSearch] : []">{{
                          allOrganizations[item.url].name | shortenParish
                        }}</text-highlight></span>
                    </router-link>
                    <span v-else>{{ allOrganizations[item.url].name | shortenParish }}</span>
                  </template>
                  <span>{{ item.breadcrumbs }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #append="{ item }">
              <v-tooltip
                bottom
                v-if="hasPermission(item.url, READ)"
              >
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    outlined
                    @click.stop="openLocationsDialog(allOrganizations[item.url])"
                    class="mx-1"
                  >
                    <v-icon>{{ mdiMapMarker }}</v-icon>
                    {{ item.locationCount }}
                  </v-chip>
                </template>
                <span>{{ $t('nestedOrganization.showLocations') }}</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="hasPermission(item.url, MANAGE_USERS)"
              >
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    outlined
                    @click.stop="openUserDialog(allOrganizations[item.url])"
                    class="mx-1"
                  >
                    <v-icon>{{ mdiAccount }}</v-icon>
                    {{ item.userCount }}
                  </v-chip>
                </template>
                <span>{{ $t('nestedOrganization.showUsers') }}</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="user.is_superuser"
              >
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="mx-1"
                    @click.stop="openOrgaCreationDialog(allOrganizations[item.url])"
                  >
                    <v-icon>{{ mdiDomainPlus }}</v-icon>
                  </v-chip>
                </template>
                <span>{{ $t('nestedOrganization.addChildOrganization') }}</span>
              </v-tooltip>
            </template>
          </v-treeview>
        </v-skeleton-loader>

        <v-dialog
          v-if="user.is_superuser"
          v-model="isChildOrgaCreationDialogOpen"
          width="900"
          persistent
        >
          <v-card>
            <v-card-title class="">
              Kindorganisation zu {{ organization ? organization.name : '' }} hinzufügen
            </v-card-title>
            <v-stepper
              vertical
              v-model="step"
            >
              <v-stepper-step
                step="1"
                @click="step = 1"
                :complete="newOrgName.length > 0"
              >Name
                <small v-if="newOrgName">{{ newOrgName }}</small>
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="newOrgName"
                      label="Name"
                      name="Name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 2"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="2"
                @click="step = 2"
                :complete="Object.keys(orgAddr).length > 0"
              >
                Adresse hinzufügen
                <small v-if="addressPreview">{{ addressPreview }}</small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card
                  flat
                  outlined
                >
                  <v-card-text>
                    <org-details-address-common
                      :org-addr="orgAddr"
                      :hidden-fields="['name']"
                      @dataChanged="handleDataChanged"
                    ></org-details-address-common>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 3"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="3"
                :complete="newOrgLevel !== null"
                @click="step = 3"
              >
                Ebene
                <small v-if="newOrgLevel">{{ newOrgLevelPreview }}</small>
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-text>
                    <v-select
                      name="level"
                      label="Ebene"
                      v-model="newOrgLevel"
                      :items="levelOptions"
                      item-text="label"
                      outlined
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template
                        slot='selection'
                        slot-scope='{ item }'
                      >
                        <div class="org-option">
                          <div class="option-icon">
                  <span class="orga-type-selection">
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </span>
                          </div>
                          <div class="name">{{ item.label }}</div>
                        </div>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <div class="org-option">
                              <div class="option-icon">
                      <span class="orga-type-selection">
                        <v-icon color="primary">{{ item.icon }}</v-icon>
                      </span>
                              </div>
                              <div class="name">{{ item.label }}</div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 4"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="resetNewOrganization"
              >Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                id="createOrg"
                :loading="creating"
                color="primary"
                @click="createOrg"
              >Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="isNewRootOrgaCreationDialogOpen"
          width="900"
          persistent
        >
          <v-card>
            <v-card-title>
              Neue Organisation anlegen
            </v-card-title>

            <v-stepper
              vertical
              v-model="step"
            >
              <v-stepper-step
                step="1"
                @click="step = 1"
                :complete="newOrgName.length > 0"
              >Name
                <small v-if="newOrgName">{{ newOrgName }}</small>
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="newOrgName"
                      label="Name"
                      name="Name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 2"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="2"
                @click="step = 2"
                :complete="isNewOrgAddressValid"
              >
                Adresse hinzufügen
                <small v-if="Object.keys(orgAddr).length">
                  {{ addressPreview }}
                </small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card
                  flat
                  outlined
                >
                  <v-card-text>
                    <org-details-address-common
                      :org-addr="orgAddr"
                      :hidden-fields="['name']"
                      @dataChanged="handleDataChanged"
                    ></org-details-address-common>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 3"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="3"
                @click="step = 3"
                :complete="newOrgLevel !== null"
              >
                Ebene
                <small v-if="newOrgLevel">{{ newOrgLevelPreview }}</small>
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-text>
                    <v-select
                      name="level"
                      label="Ebene"
                      v-model="newOrgLevel"
                      :items="levelOptions"
                      item-text="label"
                      outlined
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template
                        slot='selection'
                        slot-scope='{ item }'
                      >
                        <div class="org-option">
                          <div class="option-icon">
                  <span class="orga-type-selection">
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </span>
                          </div>
                          <div class="name">{{ item.label }}</div>
                        </div>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <div class="org-option">
                              <div class="option-icon">
                      <span class="orga-type-selection">
                        <v-icon color="primary">{{ item.icon }}</v-icon>
                      </span>
                              </div>
                              <div class="name">{{ item.label }}</div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 4"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="4"
                @click="step = 4"
                :complete="newOrgCurrency !== null"
              >
                Währung
              </v-stepper-step>
              <v-stepper-content step="4">
                <v-card flat>
                  <v-card-text>
                    <v-autocomplete
                      name="currency"
                      label="Währung"
                      v-model="newOrgCurrency"
                      :items="availableCurrencies"
                      outlined
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-autocomplete>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      text
                      @click="step = 5"
                      color="primary"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-step
                step="5"
                @click="step = 5"
                :complete="newOrgParent !== null"
              >
                Übergeordnete Organisation (optional)
                <small v-if="newOrgParent">{{ allOrganizations[newOrgParent].name }}</small>
              </v-stepper-step>
              <v-stepper-content step="5">
                <v-card flat>
                  <v-card-text>
                    <v-autocomplete
                      name="parent"
                      label="Übergeordnete Organisation"
                      v-model="newOrgParent"
                      :items="sortedOrganizations"
                      item-text="name"
                      item-value="url"
                      outlined
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    />
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                id="cancel-orga-creation"
                @click="resetNewOrganization"
                text
                style="margin-right: 1em"
              >Abbrechen
              </v-btn>
              <v-spacer/>
              <v-btn
                :disabled="!isNewOrgValid"
                id="createOrg"
                @click="createOrg"
                :loading="creating"
                color="primary"
                depressed
              >Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="userDialog"
        >
          <v-card v-if="selectedOrganization && userDialog">
            <v-card-title>
              {{ selectedOrganization.name | shortenParish }} - Nutzer
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                fab
                text
                @click="userDialog = false"
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 1.2em;"
                >&#x2715;</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <UserAdmin :specificOrganization="selectedOrganization.url"/>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="locationsDialog"
        >
          <v-card v-if="selectedOrganization && locationsDialog">
            <v-card-title>
              {{ selectedOrganization.name | shortenParish }} - Orte
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                fab
                text
                @click="locationsDialog = false"
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 1.2em;"
                >&#x2715;</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <LocationAdmin :specificOrganization="selectedOrganization.url"/>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  FETCH_ORGANIZATIONS,
  FETCH_USER_DATA,
  FETCH_LOCATIONS,
  FETCH_USER_LIST, ADD_ORGANIZATION_ADDRESS, ADD_ORGANIZATION
} from '@/store/action-types'
import {
  SET_ORGA_PANEL_FILTER
} from '@/store/mutation-types'
import { mapGetters, mapState } from 'vuex'
import { OrgBundeskirche, OrgGemeinde, OrgKirchenkreis, OrgLandeskirche } from '@/lib/icons'
import TextHighlight from 'vue-text-highlight'
import {
  mdiWeb,
  mdiStarOutline,
  mdiMapMarker,
  mdiAccountPlus,
  mdiArrowRight,
  mdiArrowDown,
  mdiAccount,
  mdiDomainPlus,
  mdiCity,
  mdiDevices,
  mdiExpandAll,
  mdiCollapseAll,
  mdiPlusBox,
  mdiMagnify,
  mdiClose,
  mdiDotsVertical,
} from '@mdi/js'
import OrganizationIcon from '@/components/icons/OrganizationIcon'
import { shortenParish } from '@/lib/regex-tools'
import LocationAdmin from '@/views/admin/LocationAdmin.vue'
import UserAdmin from '@/views/admin/UserAdmin.vue'
import { MANAGE_USERS, PERM_TYPE_INTERACT_GENERAL, READ } from '@/lib/permission-tools'
import OrgDetailsAddressCommon from "@/components/OrgDetailsAddressCommon"
import moment from "moment"
import { sortAlphaNumeric } from "@/lib/sort-utils"

export default {
  name: 'home',
  components: {
    OrgDetailsAddressCommon,
    OrganizationIcon,
    UserAdmin,
    LocationAdmin,
    TextHighlight,
  },
  data () {
    return {
      PERM_TYPE_INTERACT_GENERAL,
      READ,
      MANAGE_USERS,
      orgsOpen: [],
      search: null,
      adminRoutes: [
        {
          router: { name: 'admin.locations' },
          label: 'Orte',
          icon: mdiCity,
        },
        {
          router: { name: 'admin.devices' },
          label: 'Geräte',
          icon: mdiDevices,
        },
        {
          router: { name: 'admin.users' },
          label: 'Benutzer',
          icon: mdiAccount,
        },

      ],
      userDropdownItems: [
        {
          to: { name: "auth.profile" },
          label: 'Profil',
          icon: 'profile'
        },
      ],
      isChildOrgaCreationDialogOpen: false,
      userDialog: false,
      locationsDialog: false,
      selectedOrganization: false,
      newOrgName: '',
      newOrgLevel: null,
      newOrgParent: null,
      newOrgCurrency: 'EUR',
      creating: false,
      levelOptions: [
        {
          label: 'International',
          value: 'international',
          icon: mdiWeb,
        },
        {
          label: 'Land',
          value: 'country',
          icon: OrgBundeskirche,
        },
        {
          label: 'Region',
          value: 'region',
          icon: OrgLandeskirche,
        },
        {
          label: 'Bezirk',
          value: 'district',
          icon: OrgKirchenkreis,
        },
        {
          label: 'Gemeinde',
          value: 'parish',
          icon: OrgGemeinde,
        },
        {
          label: 'Andere',
          value: 'other',
          icon: mdiStarOutline,
        }
      ],
      step: 1,
      orgAddr: {
        region: 'Deutschland',
        type: 'primary'
      },
      editOrga: null,
      orgafilter: 'all',
      isNewRootOrgaCreationDialogOpen: false,
      mdiAccountPlus,
      mdiArrowRight,
      mdiArrowDown,
      mdiMapMarker,
      mdiAccount,
      mdiDomainPlus,
      mdiExpandAll,
      mdiCollapseAll,
      mdiPlusBox,
      mdiMagnify,
      mdiClose,
    }
  },
  filters: {
    shortenParish,
  },
  watch: {
    writableOrganizations (newValue, oldValue) {
      if (newValue && newValue.length === 1) {
        this.$router.replace({
          name: 'organization.dashboard',
          params: { id: newValue[0].id }
        })
      }
    },
    activeSearch: {
      handler (newVal) {
        this.applySearchExpansion()
      }
    },
    allOrganizations: {
      immediate: true,
      handler (newVal) {
        if (this.hasMultipleOrgs || Object.keys(this.allOrganizations).length === 0) {
          return
        }

        const redirectOrganizationUrl = Object.keys(this.effectivePermissionsByUrl)[0]
        this.$router.replace({
          name: 'organization.dashboard',
          params: { id: this.allOrganizations[redirectOrganizationUrl].id }
        })
      }
    },
    orgafilter: {
      immediate: true,
      handler (newVal, oldVal) {
        if (oldVal) {
          this.$store.dispatch("userPrefs/" + SET_ORGA_PANEL_FILTER, { orgafilter: newVal })
        }
      }
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('user', ['user', 'userList']),
    ...mapState('organization', ['organizations']),
    ...mapState('location', ['locations',]),
    ...mapGetters('organization', ['allOrganizations', 'orgtree', 'idMap', 'writableOrganizations', 'organizationRelevantDevicesRawByUrl']),
    ...mapGetters('user', ['hasPermission', 'hasMultipleOrgs', 'effectivePermissionsByUrl']),
    ...mapGetters('location', ['locationsLookup']),
    availableCurrencies () {
      return [
        {
          text: 'Euro (EUR)',
          value: 'EUR'
        },
        {
          text: 'Schweizer Franken (CHF)',
          value: 'CHF'
        }
      ]
    },
    filteredSearch () {
      return (item, search, textKey) => item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1
    },
    activeSearch () {
      const search = this.search || ""
      if (search.length > 2) {
        return search
      }
      return null
    },
    organizationTreeView () {
      if (!(this.organizations?.length > 0) || (!this.orgtree || Object.entries(this.orgtree).length === 0) || !this.filteredOrgtree) {
        return []
      }

      const buildTree = (orgUrl, parents) => {
        const org = this.allOrganizations[orgUrl]
        const children = (this.orgtree[orgUrl] ?? []).map(item => buildTree(item, [...parents, org?.name ?? "U.O."]))
        const hasInteractionPermissions = this.hasPermission(orgUrl, PERM_TYPE_INTERACT_GENERAL)

        return {
          url: orgUrl,
          name: org?.name ?? "Unbekannte Organisation",
          children,
          locationCount: this.locations ? this.locations.filter(loc => loc.organization === org.url).length : 0,
          userCount: this.userList.filter(user => user.permissions.some(permission => permission.organization === orgUrl)).length,
          hasInteractionPermissions,
          breadcrumbs: parents.join(" > ")
        }
      }

      const treeResult = this.filteredOrgtree.map(item => buildTree(item, []))

      const finalResult = []
      const filterTreeForPermissions = (orgList) => {
        orgList.forEach(org => {
          if (org.hasInteractionPermissions && Object.entries(org.children).length > 0) {
            finalResult.push(org)
            return
          }

          if (org.hasInteractionPermissions && Object.entries(org.children).length === 0) {
            finalResult.push(org)
            return
          }

          filterTreeForPermissions(org.children)
        })
      }

      filterTreeForPermissions(treeResult)

      return finalResult
    },
    filteredOrgaLevels () {
      if (!this.orgtree.NONE) {
        return []
      }
      const resVal = []
      resVal.push({
        key: 'all',
        human: 'Alle',
        icon: mdiDotsVertical
      })
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.orgtree.NONE)) {
        let found = false
        for (let i = 0; i < resVal.length; i++) {
          if (resVal[i].key === this.allOrganizations[value].level) {
            found = true
            break
          }
        }
        if (!found) {
          resVal.push({
            key: this.allOrganizations[value].level,
            human: this.levelToHuman(this.allOrganizations[value].level)
          })
        }
      }
      resVal.sort(function (a, b) {
        let lvlA = -1
        let lvlB = -1

        for (let i = 0; i < this.levelOptions.length; i++) {
          if (this.levelOptions[i].value === a.key) {
            lvlA = i
          }
          if (this.levelOptions[i].value === b.key) {
            lvlB = i
          }
        }
        return lvlA > 0 && lvlB > 0 ? lvlA > lvlB ? 1 : -1 : 0
      }.bind(this))
      /* resVal.sort(function (a, b) {
        var keyA = new Date(a.key)
        var keyB = new Date(b.key)
        // Compare the 2 dates
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      }) */
      return resVal
    },
    username () {
      let retval = '?'
      if (!this.$store.state.user.user) {
        retval = '?'
      } else {
        retval = this.$store.state.user.user.name ? this.$store.state.user.user.name : this.$store.state.user.user.username
      }
      if (!retval) {
        retval = '?'
      }
      return retval
    },
    organization: function () {
      // return this.allOrganizations[this.orgId]
      return this.editOrga
    },
    filteredOrgtree () {
      if (this.orgtree.NONE) {
        if (this.$store.state.userPrefs.orgafilter === undefined || this.$store.state.userPrefs.orgafilter.includes('all')) {
          return this.orgtree.NONE
        }
        return this.orgtree.NONE.filter(orgId => this.$store.state.userPrefs.orgafilter.includes(this.allOrganizations[orgId].level))
      }
      return []
    },
    sortedOrganizations () {
      return sortAlphaNumeric(this.organizations, 'name')
    },
    addressPreview () {
      if (!Object.values(this.orgAddr).length) {
        return ''
      }

      const cityLine = (this.orgAddr?.postcode ?? '') + ' ' + (this.orgAddr?.locality ?? '')

      const addressElements = [
        this.orgAddr?.address ?? '',
        cityLine.trim(),
        this.orgAddr?.region ?? '',
        this.$tc('addressTypes.' + this.orgAddr?.type) ?? '',
      ]

      return addressElements.filter(element => !!element).join(', ')
    },
    newOrgLevelPreview () {
      if (!this.newOrgLevel) {
        return ''
      }

      return this.levelOptions.find(option => option.value === this.newOrgLevel).label
    },
    isNewOrgAddressValid () {
      return !!this.orgAddr.address && !!this.orgAddr.locality && !!this.orgAddr.postcode && !!this.orgAddr.region
    },
    isNewOrgValid () {
      return !!this.newOrgName && !!this.newOrgLevel && !!this.newOrgCurrency && !!this.isNewOrgAddressValid
    }
  },
  async mounted () {
    if (this.$store.state.userPrefs.orgafilter !== undefined) {
      this.orgafilter = this.$store.state.userPrefs.orgafilter
    }
    await Promise.allSettled([
      this.$store.dispatch('user/' + FETCH_USER_DATA),
      this.$store.dispatch('user/' + FETCH_USER_LIST),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
      this.$store.dispatch('location/' + FETCH_LOCATIONS)
    ])
  },
  methods: {
    applySearchExpansion (retry = 0) {
      if (!this.user.is_superuser) {
        return
      }
      // Make sure mounting is complete
      this.$nextTick(() => {
        if (this.$refs.organizationTree) {
          // Expand if search is active, collapse otherwise
          if (this.activeSearch && this.activeSearch.length) {
            this.$refs.organizationTree.updateAll(true)
          } else {
            this.$refs.organizationTree.updateAll(false)
          }
        } else if (retry < 1) {
          window.setTimeout(() => this.applySearchExpansion(1), 1000)
        }
      })
    },
    openLocationsDialog (organization) {
      this.selectedOrganization = organization
      this.locationsDialog = true
    },
    openUserDialog (organization) {
      this.selectedOrganization = organization
      this.userDialog = true
    },
    levelToHuman (level) {
      const leveltohuman = {
        country: 'Land',
        district: 'Bezirk',
        region: 'Region',
        parish: 'Gemeinde',
        international: 'International',
        other: 'Andere'
      }
      if (leveltohuman[level] !== undefined) {
        return leveltohuman[level]
      }
      return 'Sonstige'
    },
    async createOrg () {
      if (!this.user.is_superuser) {
        return
      }
      this.creating = true
      try {
        const response = await this.$store.dispatch('organization/' + ADD_ORGANIZATION, {
          name: this.newOrgName,
          parent: this.newOrgParent ?? this.editOrga ? this.editOrga?.url : null,
          level: this.newOrgLevel,
          currency: this.newOrgCurrency
        })
        const orgUrl = response?.url
        const currentTime = moment().format()
        const addressPayload = {
          ...this.orgAddr,
          valid_from: currentTime,
          organization: orgUrl
        }
        await this.$store.dispatch('organization/' + ADD_ORGANIZATION_ADDRESS, addressPayload)
      } finally {
        this.creating = false
        this.resetNewOrganization()
        await this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS)
      }
    },
    resetNewOrganization () {
      this.step = 1
      this.orgAddr = {
        region: 'Deutschland',
        type: 'primary'
      }
      this.newOrgName = ''
      this.newOrgLevel = null
      this.newOrgParent = null
      this.newOrgCurrency = 'EUR'
      this.isChildOrgaCreationDialogOpen = false
      this.isNewRootOrgaCreationDialogOpen = false
    },
    openOrgaCreationDialog (orga) {
      this.editOrga = orga
      if (orga) {
        this.isChildOrgaCreationDialogOpen = true
      }
    },
    levelIcon (key) {
      const optKey = this.levelOptions.find(el => el.value === key)
      return optKey ? optKey.icon : ''
    },
    handleDataChanged (newValue) {
    }
  }
}
</script>

<style lang="stylus">
#home
  #home-container
    flex-shrink: 1

  h3
    color: $clr-secondary-text-light

  .link-title
    margin-bottom: 16px;
    margin-top: 8px;

  .expand-button
    float: right
    margin-top: 1em
    margin-bottom: 1em

.org-option
  display: flex

.orga-filter
  img
    width: 1.5em
    height: 1.5em
    margin-right: 0.5em

  span
    font-size: 1.5em
    margin-right: 0.5em

.orga-type-selection
  img
    width: 1em
    height: 1em
    margin-right: 0.5em

  span
    font-size: 1em
    margin-right: 0.5em
</style>
