<template>
  <v-card
    outlined
    class="my-2"
  >
    <div>
      <v-card-title>{{ letter.name }}</v-card-title>
      <v-card-text>
        <div v-if="targetGroup">
          Zielgruppe: {{ targetGroup }}
        </div>
        <div v-if="letterFilter">
          {{ letterFilter | filterDescription }}
        </div>
        <div
          v-for="(additionalFilter, index) in additionalFilters"
          :key="index"
        >
          {{ additionalFilter }}
        </div>
        <div v-if="letter.special_purpose">
          Verwendungsschlüssel: {{ letter.special_purpose }}
        </div>
        <div>
          erstellt am: {{ letter.created_at | isoToHuman('LLL') }}
        </div>
        <div v-if="finalizedEvent">
          abgeschlossen am: {{ finalizedEvent.created_at | isoToHuman('LLL') }} ({{ finalizedEvent.user.display_name }})
        </div>
        <div v-if="sentEvent">
          gesendet am: {{ sentEvent.created_at | isoToHuman('LLL') }} ({{ sentEvent.user.display_name }})
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="letter.state === 'created'"
          class="ml-2 mt-3"
          right
          color="primary"
          @click="downloadCsv()"
        >.csv herunterladen
        </v-btn>
        <v-btn
          class="ml-2 mt-3"
          color="primary"
          right
          @click="duplicateLetter()"
        >Duplizieren
        </v-btn>
        <v-btn
          class="ml-2 mt-3"
          right color="primary"
          @click="followUpLetter()"
        >Folgebrief
        </v-btn>
        <v-btn
          v-if="letter.state==='created'"
          class="ml-2 mt-3" color="primary"
          right
          @click="confirmAction = 'finalize'"
        >Abschließen
        </v-btn>
        <v-btn
          v-if="letter.state==='finalized'"
          class="ml-2 mt-3" color="primary"
          right
          @click="confirmAction = 'send'"
        >Als gesendet markieren
        </v-btn>
        <v-btn
          v-if="letter.state==='sent'"
          class="ml-2 mt-3" color="primary"
          right
          :disabled="letter.state === 'sent'"
        >
          gesendet
        </v-btn>
      </v-card-actions>
    </div>
    <base-dialog
      :is-open="!!confirmAction"
      @close="confirmAction = null"
    >
      <template #dialog-title>Auswählen</template>
      <template #dialog-content>
        <v-card-text v-if="confirmAction && letter">
          <div
            class="pt-4"
            v-if="confirmAction === 'finalize'"
          >
            Möchten Sie den Kirchgeldspendenbrief vom {{ letter.created_at | isoToHuman('LLL') }} wirklich
            abschließen?
            Dann sind keine Änderungen mehr
            möglich.
          </div>
          <div
            class="pt-4"
            v-else-if="confirmAction === 'send'"
          >
            Möchten Sie {{ letter.name }} vom {{ letter.created_at | isoToHuman('LLL') }} jetzt als
            gesendet
            markieren? Dann wird das heutige
            Datum dafür in die Datenbank geschrieben.
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="confirmAction === 'finalize' ? handleFinalizeLetter() : handleSendLetter()"
          >
            <v-icon>mdi-check</v-icon>
            <p v-if="confirmAction === 'finalize'">Brief abschließen</p>
            <p v-else>Brief als gesendet markieren</p>
          </v-btn>
          <v-btn @click="confirmAction = null">
            <v-icon>mdi-cancel</v-icon>
            Abbrechen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
    <base-dialog :is-open="!!finalizeTaskId">
      <template #dialog-title>Schließe Brief ab</template>
      <template #dialog-content>
        <task-progress :task-id="finalizeTaskId" @finished="finishFinalizeLetter" v-if="!!finalizeTaskId">
          <template #title>Stapelverarbeitung</template>
          <template #state="{ progress }">
            <template v-if="['started'].includes(progress?.state)">
              Verknüpfe Brief mit Personen
            </template>
            <template v-if="['preparing'].includes(progress?.state)">
              Verknüpfe Briefe mit Personen ({{ progress.done_count }} Briefe)
            </template>
            <template v-if="['completing'].includes(progress?.state)">
              Schließe Brief ab
            </template>
            <template v-else-if="progress?.state === 'finished'">Fertig</template>
          </template>
        </task-progress>
      </template>
    </base-dialog>
  </v-card>
</template>

<script>
import {
  GET_FILTERS,
  GET_LETTER_FROM_AUDIT,
  GET_LETTERS,
  RENDER_CSV
} from '@/modules/kirchgeld/store/kirchgeld/action-types'
import { downloadBlobWithBOM } from '@/lib/file-tools'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { shortenParish } from '@/lib/regex-tools'
import { describeFilter } from '@/modules/kirchgeld/utils'
import {
  LETTER_FILTER_HAS_DONATED_THIS_YEAR,
  LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
} from '@/modules/kirchgeld/config/letter-types'
import BaseDialog from '@/components/UI/BaseDialog.vue'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import TaskProgress from '@/modules/kirchgeld/components/TaskProgress.vue'
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'
import { CACHE_KD_FILTER } from '@/modules/kirchgeld/store/kirchgeld/cache-types'

export default {
  name: 'LetterEntry',
  components: { TaskProgress, BaseDialog },
  props: {
    letter: {
      type: Object,
      require: true
    }
  },
  emits: [
    'send-letter',
    'finalize-letter'
  ],
  filters: {
    filterDescription (v) {
      return describeFilter(v?.filter || [])
    }
  },
  data () {
    return {
      auditEvents: [],
      confirmAction: null,
      finalizeTaskId: null,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', { availableFilters: 'filters' }),
    ...mapState(['now']),
    sentEvent () {
      return this.auditEvents.find(element => element.action === 'LetterAPIAction.SENT') ?? null
    },
    finalizedEvent () {
      return this.auditEvents.find(element => element.action === 'LetterAPIAction.FINALIZED') ?? null
    },
    letterFilter () {
      return this.availableFilters.find(filter => filter.url === this.letter.filter) ?? null
    },
    targetGroup () {
      return shortenParish(this.letterFilter?.name ?? '')
    },
    additionalFilters () {
      const retval = []
      // eslint-disable-next-line camelcase
      for (const additionalFilter of this.letter?.additional_filter ?? []) {
        if (Array.isArray(additionalFilter)) {
          const sperren = additionalFilter.flat().find(filter => filter?.field === 'sperre')?.in ?? null
          if (sperren) {
            retval.push('Sperren: ' + sperren.join(', '))
          }
        }

        if (additionalFilter?.field === 'has_paid_current_year') {
          let replacement = 'Other'
          let year = moment(this.letter.created_at).year()

          // eslint-disable-next-line camelcase
          if (this.letter?.options?.current_year_for_payments) {
            replacement = (String(this.letter.options.current_year_for_payments) === String(this.now.year()))
              ? 'This'
              : (
                  (String(this.letter.options.current_year_for_payments) === String(this.now.year() - 1)) ? 'Last' : 'Other'
                )
            year = this.letter.options.current_year_for_payments
          }
          const labelKey = additionalFilter.exact ? LETTER_FILTER_HAS_DONATED_THIS_YEAR : LETTER_FILTER_HAS_NOT_DONATED_THIS_YEAR
          const label = this.$t(`kirchgeld.donationStatus.${labelKey}`.replace('This', replacement), { year })
          retval.push('Spendenstatus: ' + label)
        }
      }

      if (this.letter?.options?.separate_relation_ehe !== undefined) {
        retval.push('Eheleute getrennt anschreiben: ' + (this.letter?.options?.separate_relation_ehe ? "Ja" : "Nein"))
      }

      if (this.letter?.options?.use_last_known_address !== undefined) {
        retval.push('Inaktive Personen mit einschließen, letzte bekannte Adresse verwenden: ' + (this.letter?.options?.use_last_known_address ? "Ja" : "Nein"))
      }

      return retval
    }
  },
  methods: {
    async duplicateLetter () {
      await this.$router.push({
        name: 'kige-letter-create',
        query: { action: 'duplicate', originalLetter: this.letter.url }
      })
    },
    async followUpLetter () {
      await this.$router.push({
        name: 'kige-letter-create',
        query: { action: 'followUp', originalLetter: this.letter.url }
      })
    },
    async downloadCsv () {
      const response = await this.$store.dispatch('kirchgeld/' + RENDER_CSV, this.letter.id)
      const fileExtension = (response.headers['content-type'].includes('text/csv')) ? 'csv' : 'xlsx'
      downloadBlobWithBOM(
        response.data,
        `${this.letter.name}.${fileExtension}`,
        response.headers['content-type']
      )
    },
    async handleFinalizeLetter () {
      this.finalizeTaskId = await loadingStateWrapper(this.$store, async () => {
        const response = await this.$store.getters['kirchgeld/api'].post(this.letter.url + 'finalize/?background=1')
        // eslint-disable-next-line camelcase
        return response?.data?.task_id
      })
      this.confirmAction = null
    },
    async finishFinalizeLetter () {
      this.finalizeTaskId = null
      await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_KD_FILTER })
      await Promise.allSettled([
        this.$store.dispatch('kirchgeld/' + GET_FILTERS),
        this.$store.dispatch('kirchgeld/' + GET_LETTERS)
      ])
    },
    async handleSendLetter () {
      const payload = {
        state: 'sent'
      }
      await this.$store.getters['kirchgeld/api'].patch(this.letter.url, payload)
      await this.$store.dispatch('kirchgeld/' + GET_LETTERS)
      this.confirmAction = null
    }
  },
  watch: {
    letter: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.id) {
          this.auditEvents = await this.$store.dispatch('kirchgeld/' + GET_LETTER_FROM_AUDIT, this.letter.id)
        }
      }
    }
  }
}
</script>
