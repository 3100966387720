<template>
  <v-container id="org-notification">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <h2>Benachrichtigungen</h2>

        <v-row>
          <v-col cols="12">
            <p>Hier finden Sie eine Übersicht über die hinterlegten Kontakt-Mailadressen für unterschiedliche Benachrichtigungen. Bitte beachten Sie, dass je nach Vertragskonfiguration nicht alle Typen verfügbar sind..</p>
          </v-col>
          <v-col
            v-if="hasEditingPermissions"
            cols="12"
            class="text-right "
          >
            <v-btn
              color="primary"
              @click="addContact"
              class="mr-2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
              Mailadresse hinzufügen
            </v-btn>
            <NotificationsExplanationDialog />

          </v-col>
          <v-col
            v-if="organization.contacts.length < 1"
            cols="12">
            <p class="text--disabled">Keine Kontakte hinterlegt</p>
          </v-col>
          <v-col cols="10" md="10" lg="6" v-for="(contact, index) in organization.contacts" :key="contact.id">
            <organization-notification-contact
              :contact="contact"
              :organization="organization"
              :position="index"
              @remove-contact-from-org="onRemoveContactFromOrg"
              @cancel="onNotificationContactEditCancel"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiPlus } from "@mdi/js"
import OrganizationNotificationContact from "@/components/OrganizationNotificationContact.vue"
import NotificationsExplanationDialog from '@/components/NotificationsExplanationDialog.vue'
import { WRITE_ORG_DATA } from '@/lib/permission-tools'
import { mapOrder } from "@/lib/data-order"

export default {
  name: 'org-notification',
  components: {
    OrganizationNotificationContact,
    NotificationsExplanationDialog
  },
  data () {
    return {
      addressesOrder: ['physical', 'billing', 'other'],
      mdiPlus
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapGetters('user', ['hasPermission']),
    addresses () {
      if (!this.organization || !this.organization.addresses) {
        return []
      }
      return mapOrder(this.organization.addresses, this.addressesOrder, 'type')
    },
    hasEditingPermissions () {
      return this.hasPermission(this.organization?.url, WRITE_ORG_DATA)
    }
  },
  methods: {
    addContact () {
      // eslint-disable-next-line no-unused-expressions
      this.organization?.contacts.unshift({})
    },
    onRemoveContactFromOrg (position) {
      // eslint-disable-next-line no-unused-expressions
      this.organization?.contacts.splice(position, 1)
    },
    onNotificationContactEditCancel (position, hasContactId) {
      if (!hasContactId) {
        // eslint-disable-next-line no-unused-expressions
        this.organization?.contacts.splice(position, 1)
      }
    },
  },
  mounted () {
  }
}
</script>
<style lang="stylus">
#orgorg-notification
  .v-list-item__subtitle
    font-size: 1em
    line-height: 1.6
    color: black
  .v-list-item
    padding: 0
.contactcard
  .title
    text-overflow: unset
    white-space: unset
  .content
    text-overflow: unset
    white-space: unset
</style>
