import { FETCH_DIRECT_PAYMENT_PAIN_FILES } from "@/store/action-types"
import { cachedActionWrapper } from "@/modules/common/store/tools"
import { CACHE_DIRECT_PAYMENT_PAIN_FILES } from "@/store/cache-types"
import { SET_DIRECT_PAYMENT_PAIN_FILES } from "@/store/mutation-types"

export default {
  namespaced: true,
  state: {
    directPaymentPainFiles: null
  },
  getters: {
    directPaymentPainFilesByOrganization (state) {
      if (!state.directPaymentPainFiles) {
        return {}
      }
      const map = {}
      state.directPaymentPainFiles.forEach(dppFile => {
        if (!map[dppFile.organization]) {
          map[dppFile.organization] = []
        }
        map[dppFile.organization].push(dppFile)
      })

      return map
    }
  },
  mutations: {
    [SET_DIRECT_PAYMENT_PAIN_FILES]: (state, { data }) => {
      state.directPaymentPainFiles = data
    }
  },
  actions: {
    [FETCH_DIRECT_PAYMENT_PAIN_FILES]: async (context) => {
      return cachedActionWrapper(context, CACHE_DIRECT_PAYMENT_PAIN_FILES, async () => {
        const response = await context.rootGetters.restApi.get('payment/direct_payment_pain_file/')
        await context.commit(SET_DIRECT_PAYMENT_PAIN_FILES, { data: response.data })
      }, async () => {
        await context.commit(SET_DIRECT_PAYMENT_PAIN_FILES, { data: [] })
      })
    }
  }
}
