<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          outlined
          label="Datentyp"
          v-model="selectedPersonTypeOption"
          :items="personTypeOptions"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <simple-filter-search
          v-if="selectedPersonTypeOption === 'all'"
          @data-changed="applyFilteredData"
          :default-query="defaultQuery"
        ></simple-filter-search>
        <v-text-field
          v-else
          outlined
          label="Suchen"
          v-model="tableSearchInput"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <router-link :to="{ name: 'kige-person-create' }">
          <v-btn
            color="primary"
            class="ml-3"
          >
            <v-icon>mdi-account-plus</v-icon>
            Datensatz erstellen
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12">
        <filter-result-preview-table
          :persons="persons"
          :total-match-count="matchCount"
          :is-external-selection-allowed="isExternalSelectionAllowed"
          @external-selection-confirmed="confirmExternalSelection"
          :adjustment-passing="adjustmentPassing"
          :searchInput="tableSearchInput"
          :show-column-selection-input="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterResultPreviewTable from "@/modules/kirchgeld/components/FilterResultPreviewTable"
import SimpleFilterSearch from "@/modules/kirchgeld/components/SimpleFilterSearch"
import { GET_EDITABLE_PERSONS } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import { mapGetters } from "vuex"

export default {
  name: 'PersonLookup',
  components: {
    FilterResultPreviewTable,
    SimpleFilterSearch
  },
  props: {
    isExternalSelectionAllowed: {
      type: Boolean,
      required: false,
      default: false
    },
    adjustmentPassing: {
      type: Boolean,
      required: false,
      default: false
    },
    showColumnSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['external-selection-confirmed'],
  data () {
    return {
      persons: [],
      matchCount: 0,
      personTypeOptions: [
        {
          value: 'all',
          text: 'Alle'
        },
        {
          value: 'person_self_defined',
          text: 'Nur selbstangelegte Personen'
        },
        {
          value: 'company',
          text: 'Nur Firmen'
        }
      ],
      selectedPersonTypeOption: 'all',
      tableSearchInput: ''
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['editableIndividuals', 'editableCompanies']),
  },
  methods: {
    applyFilteredData (personList, matchCount) {
      this.persons = personList
      this.matchCount = matchCount
    },
    async getEditableCompanies () {
      if (!this.editableCompanies.length) {
        await this.$store.dispatch('kirchgeld/' + GET_EDITABLE_PERSONS)
      }
      this.applyFilteredData(this.editableCompanies, this.editableCompanies.length)
    },
    async getEditableIndividuals () {
      if (!this.editableIndividuals.length) {
        await this.$store.dispatch('kirchgeld/' + GET_EDITABLE_PERSONS)
      }
      this.applyFilteredData(this.editableIndividuals, this.editableIndividuals.length)
    },
    confirmExternalSelection (value) {
      this.$emit('external-selection-confirmed', value)
    }
  },
  watch: {
    async selectedPersonTypeOption (newValue) {
      if (newValue === 'person_self_defined') {
        await this.getEditableIndividuals()
      } else if (newValue === 'company') {
        await this.getEditableCompanies()
      } else {
        this.persons = []
        this.matchCount = 0
      }

      this.tableSearchInput = ''
    }
  }

}
</script>
