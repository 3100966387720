import {
  GET_STATIC_CONFIGURATION,
  GET_FILTER_PREVIEW,
  GET_FILTER_PREVIEW_WILDCARD,
  GET_FILTERS,
  GET_IMPORT_HISTORY,
  GET_LETTERS,
  GET_PERSON_BY_ID,
  GET_TENANTS,
  IMPORT_DATA,
  RENDER_CSV,
  OAUTH_LOGIN,
  SAVE_FILTER,
  SAVE_LETTER,
  SAVE_PERSON,
  GET_ORGANIZATIONS,
  GET_EDITABLE_PERSONS,
  GET_LETTER_FROM_AUDIT,
  GET_BANK_ACCOUNTS,
  GET_BANK_STATEMENTS,
  UPDATE_PERSON,
  GET_PERSON_LETTERS,
  GET_PERSON_PAYMENTS,
  LOGOUT,
  SAVE_PAYMENT,
  GET_SPECIAL_PURPOSES,
  GET_LETTER,
  CANCEL_DONATION_RECEIPT, DELETE_PAYMENT
} from './action-types'
import { SET_ERROR_MESSAGE, SET_VERSION } from '@/modules/common/store/mutation-types'
import {
  SET_AUTH_TOKEN, SET_BANK_ACCOUNTS, SET_BANK_STATEMENTS,
  SET_EDITABLE_PERSONS,
  SET_STATIC_CONFIGURATION,
  SET_FILTERS,
  SET_LETTERS,
  SET_ORGANIZATIONS,
  SET_SPECIAL_PURPOSES,
  SET_TENANT
} from './mutation-types'
import { cachedActionWrapper, loadingStateWrapper } from '@/modules/common/store/tools'
import { CACHE_KD_FILTER, CACHE_KD_TENANT } from './cache-types'
import axios from "axios"
import config from "@/config"
import router from '../../router'
import { LS_AUTH_TOKEN } from "@/modules/kirchgeld/store/kirchgeld/local-storage-types"
import Vue from "vue"
import { shortenParish } from '../../../../lib/regex-tools'
import { isoToHuman } from '../../../../filters/datetime'

export default {
  namespaced: true,
  state () {
    return {
      tenant: null,
      enums: null,
      auth: null,
      filters: [],
      lettersByUrl: {},
      organizations: [],
      editablePersons: [],
      bankAccounts: [],
      bankStatements: [],
      specialPurposes: []
    }
  },
  getters: {
    api: (state, getters) => {
      return axios.create({
        baseURL: config.kirchgeldApi.rest,
        headers: {
          Authorization: getters.isLoggedIn ? 'Token ' + getters.authToken : null
        }
      })
    },
    enums: (state) => state.enums,
    enumsByName: (state) => {
      if (!state.enums) {
        return {}
      }
      return state.enums.reduce((obj, item) => {
        obj[item.name] = item
        return obj
      }, {})
    },
    filters: (state) => state.filters,
    lettersByUrl: (state) => state.lettersByUrl,
    letters: (state) => Object.values(state.lettersByUrl),
    authToken: (state) => {
      let localStorageAuth = null
      if (!state.auth) {
        const storedAuthToken = JSON?.parse(localStorage.getItem(LS_AUTH_TOKEN)) ?? null

        if (storedAuthToken) {
          localStorageAuth = {
            token: storedAuthToken?.token,
            expiry: new Date(storedAuthToken?.expiry)
          } ?? null
        }
      }

      let token = state?.auth?.token ?? localStorageAuth?.token ?? null
      const expiry = state?.auth?.expiry ?? localStorageAuth?.expiry ?? null
      if (expiry && (expiry.getTime() - (new Date()).getTime()) < 120 * 1000) {
        token = undefined
        localStorage.removeItem(LS_AUTH_TOKEN)
      }
      return token ?? process.env?.VUE_APP_DEV_AUTH_TOKEN ?? null
    },
    isLoggedIn: (state, getters) => getters.authToken !== null,
    organizations: (state) => state.organizations,
    organizationsHumanName: (state) => (state.organizations || []).map(org => ({
      ...org,
      name: shortenParish(org.name) + (org.valid_before ? (" (-" + isoToHuman(org.valid_before, "L") + ")") : ""),
    })),
    editableIndividuals: (state) => state.editablePersons.filter(person => person.type === 'individual'),
    editableCompanies: (state) => state.editablePersons.filter(person => person.type === 'company'),
    bankAccounts: (state) => state.bankAccounts,
    bankStatements: (state) => state.bankStatements,
    specialPurposes: (state) => state.specialPurposes,
    bankAccountsByUrl: (state) => {
      const retval = {}
      state.bankAccounts.forEach(item => { retval[item.url] = item })
      return retval
    }
  },
  mutations: {
    [SET_TENANT]: async (state, { tenant }) => {
      state.tenant = tenant
    },
    [SET_STATIC_CONFIGURATION]: async (state, { enums }) => {
      state.enums = enums
    },
    [SET_FILTERS]: async (state, { filters }) => {
      state.filters = filters
    },
    [SET_AUTH_TOKEN]: async (state, authToken) => {
      if (authToken) {
        localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify({
          token: authToken.token,
          expiry: authToken.expiry.toISOString() ?? null
        }))
      }

      state.auth = authToken
    },
    [SET_LETTERS]: async (state, { letters }) => {
      letters.forEach(letter => {
        Vue.set(state.lettersByUrl, letter.url, letter)
      })
    },
    [SET_ORGANIZATIONS]: async (state, { organizations }) => {
      state.organizations = organizations
    },
    [SET_EDITABLE_PERSONS]: async (state, { editablePersons }) => {
      state.editablePersons = editablePersons
    },
    [SET_BANK_ACCOUNTS]: async (state, { bankAccounts }) => {
      state.bankAccounts = bankAccounts
    },
    [SET_BANK_STATEMENTS]: async (state, { bankStatements }) => {
      state.bankStatements = bankStatements
    },
    [SET_SPECIAL_PURPOSES]: async (state, { specialPurposes }) => {
      state.specialPurposes = specialPurposes
    }
  },
  actions: {
    [GET_TENANTS]: async (context) => {
      if (context.state.tenant) {
        return
      }
      return cachedActionWrapper(context, CACHE_KD_TENANT, async () => {
        const response = await context.getters.api.get('/tenant/')
        context.commit(SET_TENANT, { tenant: response.data.results[0] })
        return response.data[0]
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_STATIC_CONFIGURATION]: async (context) => {
      if (context.state.enums) {
        return
      }
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get('/configuration/static/')
        context.commit(SET_STATIC_CONFIGURATION, { ...response.data })
        context.commit(SET_VERSION, {
          backend: {
            // eslint-disable-next-line camelcase
            buildDate: response.data?.version?.build_date ?? null,
            // eslint-disable-next-line camelcase
            buildId: response.data?.version?.build_id ?? null,
            version: null,
          }
        }, { root: true })
        return response.data[0]
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_FILTERS]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return cachedActionWrapper(context, CACHE_KD_FILTER, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}filter/?limit=600`)
        await context.commit(SET_FILTERS, { filters: response?.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_FILTER_PREVIEW]: async (context, { filter, options } = {}) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        let url = `${context.state.tenant.url}filter/preview/?filter=${filter}`
        if (options) {
          url = url + "&options=" + JSON.stringify(options)
        }
        const response = await context.getters.api.get(url)
        return [response.data?.count ?? 0, response.data?.results ?? []]
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_FILTER_PREVIEW_WILDCARD]: async ({ dispatch }) => {
      return await dispatch(GET_FILTER_PREVIEW, { filter: `[{"op":"and"}]` })
    },
    [GET_PERSON_BY_ID]: async ({ dispatch }, personId) => {
      // FIXME Getting a person by id should be done with the API: .../tenant/.../person/{id}/
      const results = await dispatch(GET_FILTER_PREVIEW, { filter: `[{"op":"and"}, {"field": "person_id",  "exact": "${personId}"}]` })
      return results[0]
    },
    [GET_IMPORT_HISTORY]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}data_import/`)
        return response.data?.results
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [SAVE_FILTER]: async (context, payload) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${context.state.tenant.url}filter/`, payload)
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [SAVE_LETTER]: async (context, payload) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${context.state.tenant.url}letter/`, payload)
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_LETTERS]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}letter/?limit=1000`)
        await context.commit(SET_LETTERS, { letters: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_LETTER]: async (context, letterUrl) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(letterUrl)
        await context.commit(SET_LETTERS, { letters: [response.data] })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_LETTER_FROM_AUDIT]: async (context, letterId) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}letter/${letterId}/audit/`)
        return response.data?.results
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [RENDER_CSV]: async (context, letterId) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        return await context.getters.api.get(`${context.state.tenant.url}letter/${letterId}/render_csv/`)
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [IMPORT_DATA]: async (context, formData) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${context.state.tenant.url}data_import/`, formData)
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [OAUTH_LOGIN]: async (context, params) => {
      loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get('/auth/login/return/', { params })

        const authToken = {
          token: response.data.token,
          expiry: new Date(response.data.expiry)
        }

        await context.commit(SET_AUTH_TOKEN, authToken)
        await router.push({ name: 'kige-index' })
      })
    },
    [SAVE_PERSON]: async (context, payload) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${context.state.tenant.url}person/`, payload)
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [UPDATE_PERSON]: async (context, { url, data, target }) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.patch(url, data, target)
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_ORGANIZATIONS]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}organization/?limit=1000`)
        await context.commit(SET_ORGANIZATIONS, { organizations: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_EDITABLE_PERSONS]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}person/is_editable/?limit=1000`)
        await context.commit(SET_EDITABLE_PERSONS, { editablePersons: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_BANK_ACCOUNTS]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}bank_account/?limit=1000`)
        await context.commit(SET_BANK_ACCOUNTS, { bankAccounts: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_BANK_STATEMENTS]: async (context, { limit }) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        let url = `${context.state.tenant.url}bank_statement/`
        url = (limit) ? `${url}?limit=${limit}` : url
        const response = await context.getters.api.get(url)
        await context.commit(SET_BANK_STATEMENTS, { bankStatements: response.data?.results })
        return response.data
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_PERSON_LETTERS]: async (context, personId) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}person/${personId}/letters/?limit=1000`)
        return response.data?.results
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_PERSON_PAYMENTS]: async (context, personId) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}person/${personId}/payments/?limit=1000`)
        return response.data?.results
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [SAVE_PAYMENT]: async (context, payment) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${context.state.tenant.url}payment/`, payment)
        return response.data?.results
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [DELETE_PAYMENT]: async (context, { paymentId }) => {
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.delete(`${context.state.tenant.url}payment/${paymentId}/`)
        return response.status === 204
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [GET_SPECIAL_PURPOSES]: async (context) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.get(`${context.state.tenant.url}special_purpose/`)
        await context.commit(SET_SPECIAL_PURPOSES, { specialPurposes: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [CANCEL_DONATION_RECEIPT]: async (context, { donationReceipt }) => {
      await context.dispatch(GET_TENANTS)
      return loadingStateWrapper(context, async () => {
        const response = await context.getters.api.post(`${donationReceipt.url}archive/`)
        await context.commit(SET_SPECIAL_PURPOSES, { specialPurposes: response.data?.results })
      }, async (error) => {
        await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      })
    },
    [LOGOUT]: async (context) => {
      localStorage.removeItem(LS_AUTH_TOKEN)
      context.commit(SET_AUTH_TOKEN, null)
      await router.push({ name: 'kige-index' })
    }
  },
}
