<template>
  <v-card flat>
    <person-edit-form
      :person-data-input.sync="personData"
      :person-edit="personEdit"
      :person-url-link="personUrl"
      @person-data-changed="setPersonData"
    />
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        @click="editPerson"
      >Änderungen speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PersonEditForm from "@/modules/kirchgeld/components/PersonEditForm"
import { UPDATE_PERSON } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"

export default {
  name: "PersonEdit.vue",
  components: {
    PersonEditForm,
  },
  props: {
    url: {
      type: String,
    }
  },
  mixins: [
    PersonDataMixin
  ],
  data () {
    return {
      personType: 'individual',
      personData: {},
      personEdit: true,
      personUrl: ''
    }
  },
  methods: {
    async getPerson () {
      const response = await this.$store.getters['kirchgeld/api'].get(this.personUrl)
      this.personData = this.getLatestValidPersonData(response.data)
    },
    async editPerson () {
      this.personData.eigenerfasst = !!this.personData.eigenerfasst

      // eslint-disable-next-line camelcase
      const { id, source, valid_from, valid_before, relations, ...patchData } = this.personData

      patchData.relations = []
      if (relations.length) {
        patchData.relations = relations.map(relation => {
          // eslint-disable-next-line camelcase
          const side_b = typeof relation.side_b === 'string' ? relation.side_b : relation.side_b.url
          const patchRelation = {
            type: relation.type,
            // eslint-disable-next-line camelcase
            side_b
          }

          if (relation.id) {
            patchRelation.id = relation.id
          }
          return patchRelation
        })
      }

      const personPayload = {
        data: patchData
      }
      const updatePersonResponse = await this.$store.dispatch('kirchgeld/' + UPDATE_PERSON,
        {
          url: this.url,
          data: { data: personPayload.data, target: null }
        })
      let updatePerson = {}
      updatePerson = { data: updatePersonResponse, url: this.url }
      const dataId = updatePersonResponse.id
      await this.$router.push(
        {
          name: 'kige-person-detail',
          params: {
            personUrl: updatePerson.url,
            dataId
          }
        }
      )
    },
    setPersonData (personData) {
      this.personData = personData
    }
  },
  watch: {
    url: {
      immediate: true,
      async handler (newValue) {
        this.personUrl = newValue
        await this.getPerson()
      }
    }
  }
}
</script>

<style scoped>

</style>
