export function downloadBlob (data, name, type) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data], { type }))
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadBlobWithBOM (data, name, type) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([
    new Uint8Array([0xEF, 0xBB, 0xBF]),
    data
  ], { type }))
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
