<template>
  <div>
    <v-app-bar
      id="appbar"
      app
      elevation="0"
      color="var(--clr-environment)"
      class=""
      height="60px"
      extension-height="28px"
    >
      <v-row align="center" class="">
        <v-col cols="8" xs="8" sm="8" md="8" lg="3" style="" class="">
          <!-- kollekte.digital Logo -->
          <logo-group :to="to"></logo-group>
          <!-- <logo :to="to"></logo> -->
        </v-col>
        <v-col cols="6" md="5" lg="6" class="hidden-md-and-down" style="" align="center">
          <!-- Menu Items Main Menu in Header Bar, non mobile-->
          <template v-if="topMenuItems" >
            <v-tabs
              id="menu-wrapper"
              centered
              background-color="var(--clr-environment)"
              color="#ffffff"
              optional
              v-model="tabIndex"
            >
              <v-tab class="menu-label menu-label-tab" v-for="(item, index) in topMenuItems" :to="item.to" :key="index">
                <menu-icon
                  v-if="item.icon"
                  :icon="item.icon"
                  :invert-color-white="true"
                  class="mr-2"
                  scale="0.8"></menu-icon>
                <span>
                  {{ item.label }}
                </span>
              </v-tab>
            </v-tabs>
          </template>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="4" lg="3"  class="text-right" style="">
          <v-btn
            class="mx-1"
            v-if="isImpersonated"
            large
            tile
            title="Zurückwechseln"
            @click="switchBack"
          ><v-icon color="brown" x-large>mdi-account-switch</v-icon></v-btn>

          <!-- Dropdown-Menu -->
          <v-menu
            bottom
            left
            offsetX
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="profilebutton"
                v-bind="attrs"
                v-on="on"
                active-class=""
                align="center"
                color="#ffffff"
                text
                class="hidden-md-and-down"
              >
                <menu-icon
                  :invert-color-white="true"
                  class="mr-2"
                  icon="menu"
                  scale="1.7"
                  style=""></menu-icon>
              </v-btn>
            </template>

            <v-list id="mobile-menu">
              <v-list-item
                v-for="item in userDropdownItems" v-bind:key="item.name"
                :to="item.to"
                :class="['dropdown-entry'].concat(item.classes || [])"
                :style="{ 'min-height': (item.seperator ? '2px' : '') }"
              >
                <div v-if="item.seperator" class="menu-seperator"></div>
                <template v-else>
                  <v-list-item-icon class="m-icon">
                    <menu-icon
                      v-if="item.icon"
                      :icon="item.icon"
                      scale="0.6"
                      style="margin-bottom: -7px"
                      class="mr-2"></menu-icon>
                  </v-list-item-icon>
                  <v-list-item-title style="color: var(--clr-environment)">
                    {{ item.label }}
                  </v-list-item-title>
                </template>
              </v-list-item>
              <!-- Eingeloggt -->
              <template v-if="this.$store.state.user.auth.token != null">
                <v-list-item id="linkitem-logout">
                  <v-list-item-icon class="m-icon">
                    <menu-icon icon="logout" scale="0.6" style="margin-bottom: -7px" class="mr-2"></menu-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <router-link id="logout" v-on:click.native="logout" :to="{name: 'login'}" class="dropdown-entry">Abmelden</router-link>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <!-- nicht eingeloggt -->
              <template v-else>
                <v-list-item>
                  <v-list-item-icon class="m-icon">
                    <menu-icon icon="logout" scale="0.6" style="margin-bottom: -7px" class="mr-2"></menu-icon>
                  </v-list-item-icon>
                  <v-list-item-title id="linkitem-login">
                    <router-link id="login" :to="{name: 'login'}" class="dropdown-entry menu-label">Login</router-link>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer">
            <menu-icon
              icon="menu"
              :invert-color-white="true"
              scale="1.7"
              style="cursor: pointer;"
              class="mr-2"></menu-icon>
          </v-app-bar-nav-icon>
        </v-col>
      </v-row>

      <v-progress-linear
        :active="true"
        :value="(loading > 0) ? 0 : 100"
        buffer-value="0"
        stream
        height="5"
        absolute
        bottom
        :color="loading ? '#ffffff' : 'var(--clr-environment)'"
      ></v-progress-linear>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      floating
      left
      temporary
    >
      <v-container>
        <v-row class="background-primary">
          <v-col cols="12" class="ma-3">
            <!-- kollekte.digital Logo -->
            <logo-group :to="to"></logo-group>
          </v-col>
        </v-row>
      </v-container>

      <!-- Mobile main menu, hamurger menu -->
      <v-list
        nav
        dense
        v-if="topMenuItems"
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <template
            v-for="(item, index) in topMenuItems"
          >
            <v-list-item
              v-if="!item.seperator"
              :key="index"
              id="menu-wrapper"
            >
              <template v-if="item.label">
                <router-link :to="item.to" :key="index">
                  <v-list-item-title class="menu-label" v-if="item.label">
                    <menu-icon
                      v-if="item.icon"
                      :icon="item.icon"
                      class="mr-2"
                      scale="0.6"
                      style="margin-bottom: -7px"></menu-icon>
                    {{item.label}}</v-list-item-title><v-list-item-title :class="item.icon" v-else />
                </router-link>
              </template>
            </v-list-item>
          </template>

          <div class="mt-2 mb-2 menu-seperator"></div>

          <template
            v-for="(item, index) in userDropdownItems"
          >
            <v-list-item
              v-if="!item.seperator"
              :key="'second_' + index"
              id="menu-wrapper"
            >
              <template v-if="item.label">
                <router-link :to="item.to" :key="index">
                  <v-list-item-title class="menu-label" v-if="item.label">
                    <menu-icon
                      v-if="item.icon"
                      :icon="item.icon"
                      class="mr-2"
                      scale="0.6"
                      style="margin-bottom: -7px"></menu-icon>
                    <template v-if="item.label">{{item.label}}</template>
                  </v-list-item-title>
                  <v-list-item-title :class="item.icon" v-else />
                </router-link>
              </template>
            </v-list-item>
          </template>

          <div class="mt-2 mb-2 menu-seperator"></div>

          <!-- Eingeloggt -->
          <template v-if="this.$store.state.user.auth.token != null">

            <v-list-item
              :key="'second_logout'"
              id="menu-wrapper"
            >
              <router-link :to="{name: 'login'}">
                <v-list-item-title class="menu-label">
                  <menu-icon
                    class="mr-2"
                    icon="logout"
                    scale="0.6"
                    style="margin-bottom: -7px"></menu-icon>
                  Abmelden
                </v-list-item-title>
              </router-link>
            </v-list-item>
          </template>
          <!-- nicht eingeloggt -->
          <template v-else>
            <v-list-item>
              <v-list-item-icon class="m-icon">
                <menu-icon icon="logout" scale="0.6" style="margin-bottom: -7px" class="mr-2"></menu-icon>
              </v-list-item-icon>
              <v-list-item-title id="linkitem-login">
                <router-link id="login" :to="{name: 'login'}" class="dropdown-entry" style="color: rgba(85, 65, 147, 1);">Login</router-link>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
import MenuIcon from './icons/MenuIcon'
import { mapState, mapGetters } from 'vuex'
import { CLEAR_DATA } from '@/store/mutation-types'
import { RESTORE_AUTH } from '@/store/action-types'
import LogoGroup from '@/components/icons/LogoGroup'
import { mdiAccountDetailsOutline, mdiAccountCircleOutline } from '@mdi/js'

export default {
  name: 'HeaderArea',
  props: {
    title: String,
    to: null,
    hideAvatar: Boolean,
    topMenuItems: null,
    userDropdownItems: null,
    orgcrumbs: null,
    siblings: null
  },
  data () {
    return {
      showUserOptions: false,
      menuOpen: false,
      drawer: false,
      group: null,
      tabIndex: null,
      mdiAccountDetailsOutline,
      mdiAccountCircleOutline
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('user', ['isImpersonated']),
    username () {
      const uname = this.$store.state.user?.user?.name ? this.$store.state.user.user.name : (this.$store.state.user?.user?.username ?? '?')
      return uname === '' ? 'P' : uname
    },
    hasUserMultipleOrganizations () {
      return this.$store.state.user?.user?.permissions.length > 1
    },
    selectedRouteInTopMenu () {
      return (this.topMenuItems ?? []).some(item => this.$route?.name === item?.to?.name)
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(CLEAR_DATA)
    },
    switchBack () {
      this.$store.dispatch('user/' + RESTORE_AUTH)
    },
    getRouteId () {
      return this.$route.query.id
    }
  },
  components: {
    LogoGroup,
    MenuIcon
  },
  watch: {
    group () {
      this.drawer = false
    },
    selectedRouteInTopMenu: {
      handler (newVal) {
        // Reset v-tabs to unset
        if (!newVal) {
          this.tabIndex = null
        }
      }
    }
  },
}
</script>

<style lang="stylus">
:root
  --accentcolor: $clr-primary
#mobile-menu
  .m-icon
    margin-right: 8px
#logout, #passwordchange
  cursor: pointer
  color: var(--clr-environment)
.menu-seperator
  width: 100%;
  height: 1px;
  border-top: 1px solid $clr-primary-text-light
#profilebutton
  padding: 0
  margin: 0
  height: 50px
  min-width: 50px
#menu-wrapper
  .menu-label
    color: var(--clr-environment)
    font-size: 20px
    text-transform: unset
    font-weight: unset
  .menu-label:hover
    border-bottom: 2px solid
  .menu-label-tab
    color: #ffffff
  .m-icon
    margin-right: 10px
.no-background-hover::before
  background-color: transparent !important
#crumbs
  color: var(--clr-environment)

div.v-toolbar__extension
  background-color: #ffffff

</style>
