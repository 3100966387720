<template>
  <v-form ref="changeForm" :value="isFormValid" @submit.prevent="changeEmail">
    <v-card-text class="mb-0 pb-0" id="auth-container">
      <v-alert
        v-if="error"
        dense
        outlined
        type="error"
        icon="mdi mdi-alert-circle"
        color="red"
      >
        <span v-if="error.length">{{ error }}</span>
        <span v-else>Die Änderung der E-Mail-Adresse war leider nicht möglich.</span>
      </v-alert>
      <email-input
        ref="emailInput"
        @input="setEmailValidity"></email-input>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="showCancelOption" @click="cancel" elevation="2">
        abbrechen
      </v-btn>
      <v-btn @click="changeEmail" :disabled="!isFormValid" color="primary" elevation="2" :loading="updating">
        speichern
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>

import EmailInput from "@/components/EmailInput"
import { FETCH_USER_DATA, UPDATE_CURRENT_USER } from "@/store/action-types"
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'
import { mapState } from "vuex"
import { CACHE_USER_DATA } from "@/store/cache-types"

export default {
  name: "EmailForm",
  components: {
    EmailInput
  },
  props: {
    showCancelOption: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'cancel-email-change',
    'email-change-succeeded',
  ],
  data () {
    return {
      error: null,
      email: null,
      isFormValid: false,
      updating: false
    }
  },
  computed: {
    ...mapState('user', ['user']),
    shouldPerformEmailReset () {
      return this.email?.length > 0 || false
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel-email-change')
    },
    setEmailValidity (email, isEmailValid) {
      this.email = email
      this.isFormValid = isEmailValid
    },
    async changeEmail () {
      if (!this.shouldPerformEmailReset) {
        return
      }
      this.updating = true
      try {
        await this.$store.dispatch('user/' + UPDATE_CURRENT_USER, {
          url: this.user.url,
          email: this.email,
        })
        await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_USER_DATA })
        await this.$store.dispatch('user/' + FETCH_USER_DATA)
        this.$emit('email-change-succeeded')
      } catch (e) {
        this.error = 'Die Änderung ist leider fehlgeschlagen. Bitte versuchen Sie es später noch einmal.'
      } finally {
        this.updating = false
        this.$refs.emailInput.resetInputs()
      }
    }
  }
}
</script>

<style scoped>

</style>
