<template>
  <v-card flat>
    <v-card-title>Daten importieren</v-card-title>
    <v-card-text>
      <v-row justify="end">
        <v-col cols="12">
          <v-form v-model="uploadForm">
            <v-file-input
              v-model="uploadFile"
              color="primary"
              outlined
              prepend-icon="mdi-upload"
              label="MEWIS-Datei auswählen"
              :rules="[!!uploadFile || 'Bitte Datei auswählen']"
            ></v-file-input>
            <v-btn
              :disabled="!uploadForm"
              @click="performFileUpload"
              color="primary"
              outlined
            >
              Importieren
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="importHistoryHeaders"
            :items="importHistoryEntries"
            sort-by="created_at"
            :sort-desc="true"
          >
            <template #item.created_at="{ item }">
              {{ momentize(item.created_at) }}
            </template>
            <template #item.state="{ item }">
              {{ enumsByName.DataImportState.values[item.state] }}
            </template>
            <template #item.duration="{ item }">
              {{ ((item.processing_state && item.processing_state.duration) ? item.processing_state.duration : "-") | formatSeconds }}
            </template>
            <template #item.input_lines="{ item }">
              {{ (item.processing_state && item.processing_state.input_lines) ? item.processing_state.input_lines : "-" }}
            </template>
            <template #item.married="{ item }">
              {{ (item.processing_state && item.processing_state.married) ? item.processing_state.married : "-" }}
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { GET_IMPORT_HISTORY, IMPORT_DATA } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import moment from "moment"
import { mapGetters } from 'vuex'

export default {
  name: "ImportData",
  data () {
    return {
      uploadForm: true,
      uploadFile: null,
      importHistoryEntries: [],
    }
  },
  computed: {
    ...mapGetters(
      'kirchgeld',
      ['enumsByName'],
    ),
    importHistoryHeaders () {
      return [
        {
          text: 'Datum',
          value: 'created_at',
        },
        {
          text: 'Status',
          value: 'state',
        },
        {
          text: 'Verarbeitungsdauer',
          value: 'duration',
        },
        {
          text: 'Anzahl Datensätze',
          value: 'input_lines',
        },
        {
          text: 'Anzahl verheiratet',
          value: 'married',
        }
      ]
    }
  },
  filters: {
    formatSeconds (v) {
      return Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(v) + "s"
    }
  },
  methods: {
    async performFileUpload () {
      const formData = new FormData()
      formData.append('file', this.uploadFile)

      await this.$store.dispatch('kirchgeld/' + IMPORT_DATA, formData)
      this.importHistoryEntries = await this.$store.dispatch('kirchgeld/' + GET_IMPORT_HISTORY)
    },
    momentize (dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm:ss')
    }
  },
  async mounted () {
    this.importHistoryEntries = await this.$store.dispatch('kirchgeld/' + GET_IMPORT_HISTORY)
  }
}
</script>
