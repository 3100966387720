<template>
  <div>
    <v-text-field
      v-model="searchInput"
      label="Suchen"
      outlined
      placeholder="Namen eingeben"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

  </div>
</template>

<script>
import { debounce } from '@/lib/debounce'
import { GET_FILTER_PREVIEW } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import moment from "moment"

export default {
  name: 'SimpleFilterSearch',
  emits: ['data-changed'],
  props: {
    defaultQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      searchInput: ''
    }
  },
  mounted () {
    if (this.defaultQuery) {
      this.searchInput = this.defaultQuery
    }
  },
  methods: {
    async updatePreview (searchInput) {
      const tokenList = searchInput.split(' ')
      const filterCriteria = tokenList.map(token => {
        const dateCandidate = moment(token, "L")

        const operatorTerm = {
          op: 'or'
        }
        const birthDateSearchTerm = {
          field: 'geburtsdatum',
          iexact: dateCandidate.format("YYYY-MM-DD")
        }
        const allFilters = [
          operatorTerm,
          {
            field: 'vornamen',
            icontains: token
          },
          {
            field: 'familienname',
            icontains: token
          },
          {
            field: 'familienname_zusatz',
            icontains: token
          },
          {
            field: 'strassenname',
            icontains: token
          },
          {
            field: 'ortsteil',
            icontains: token
          },
          {
            field: 'kommune',
            icontains: token
          },
          {
            field: 'kro_nr',
            icontains: token
          },
          {
            field: 'org_name_by_kro',
            icontains: token
          }
        ]
        if (this.isNumberOnly(token) && dateCandidate.isValid()) {
          return [...allFilters, birthDateSearchTerm]
        } else if (dateCandidate.isValid()) {
          return [operatorTerm, birthDateSearchTerm]
        }
        return allFilters
      })
      const filter = [
        {
          op: 'and'
        },
        ...filterCriteria
      ]

      const [matchCount, res] = await this.$store.dispatch('kirchgeld/' + GET_FILTER_PREVIEW, { filter: JSON.stringify(filter) })
      this.$emit('data-changed', res, matchCount)
      return [matchCount, res]
    },
    isNumberOnly (searchToken) {
      return !isNaN(parseInt(searchToken))
    }
  },
  watch: {
    searchInput: debounce(function (newValue) {
      this.updatePreview(newValue)
    }, 500),
    defaultQuery: function (newValue) {
      this.searchInput = newValue
    }
  }
}
</script>
