<template>
  <v-container>
    <v-row>
      <h4 class="text-h4">Geräte-Provisionierung</h4>
    </v-row>

    <v-row v-if="openProvisioningRequests.length">
      <v-col cols="12">
        <h6 class="text-h6">Offene Provisionierungen</h6>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="request in openProvisioningRequests"
        :key="request.url"
      >
        <device-provisioning-request
          :request="request"
          @set-allowed="setAllowed"
        ></device-provisioning-request>
      </v-col>
    </v-row>

    <v-row
      v-if="openProvisioningRequests.length && finishedProvisioningRequests.length">
      <v-divider></v-divider>
    </v-row>

    <v-row v-if="finishedProvisioningRequests.length">
      <v-col cols="12">
        <h6 class="text-h6">Abgeschlossene Provisionierungen</h6>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="request in finishedProvisioningRequests"
        :key="request.url">
        <device-provisioning-request :request="request"></device-provisioning-request>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

/**
 * TODOs
 * - make component emit data and save
 * - poll regularly
 */
import { FETCH_DEVICE_PROVISIONING_REQUESTS, PATCH_DEVICE_PROVISIONING_REQUEST } from "@/store/action-types"
import { mapState } from "vuex"
import { sortAlphaNumeric } from "@/lib/sort-utils"
import DeviceProvisioningRequest from "@/components/device/DeviceProvisioningRequest"

export default {
  name: "ProvisionDevicesAdmin",
  components: {
    DeviceProvisioningRequest
  },
  watch: {
    now: function (newValue) {
      this.fetchProvisioningRequests()
    }
  },
  computed: {
    ...mapState(['now']),
    ...mapState('device', ['deviceProvisioningRequests']),
    openProvisioningRequests () {
      return this.deviceProvisioningRequests
        .filter(deviceProvisioningRequest => ['pending', 'allowed', 'registering'].includes(deviceProvisioningRequest.state))
    },
    finishedProvisioningRequests () {
      const requests = this.deviceProvisioningRequests
        .filter(deviceProvisioningRequest => deviceProvisioningRequest.state === 'done')

      return sortAlphaNumeric(requests, 'created_at')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchProvisioningRequests()
    })
  },
  methods: {
    fetchProvisioningRequests () {
      Promise.allSettled([
        this.$store.dispatch('device/' + FETCH_DEVICE_PROVISIONING_REQUESTS)
      ])
    },
    setAllowed (contextUrl, deviceName, deviceType) {
      const targetState = 'allowed'
      const contextProvisionRequest = this.deviceProvisioningRequests.find(request => request.url === contextUrl)
      contextProvisionRequest.state = targetState

      const payload = {
        targetState: 'allowed',
        contextUrl,
        deviceName,
        deviceType,
      }

      this.$store.dispatch(
        'device/' + PATCH_DEVICE_PROVISIONING_REQUEST,
        payload
      )
    },
  }
}
</script>

<style scoped>

</style>
