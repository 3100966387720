<template>
  <v-container fill-height fluid>
  <v-row align="center"
      justify="center">
      <v-col cols="1">
        <v-progress-circular indeterminate color="primary" size="140"></v-progress-circular>
        Login-Vorgang
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '@/config'
import { OAUTH_LOGIN } from "@/modules/kirchgeld/store/kirchgeld/action-types"

export default {
  name: 'Login',
  computed: {
    mode () {
      if (this.$route?.query?.code) {
        return "return"
      } else {
        return "start"
      }
    }
  },
  watch: {
    mode: {
      immediate: true,
      async handler (mode) {
        if (mode === 'start') {
          await this.$nextTick()
          window.location.href = config.kirchgeldApi.rest + "auth/login/"
        } else if (mode === 'return') {
          await this.$store.dispatch('kirchgeld/' + OAUTH_LOGIN, { ...this.$route?.query })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
