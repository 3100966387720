<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs"
      v-on="on"> <v-icon>{{ mdiHelp}}</v-icon></v-btn>
    </template>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" v-for="{notification, nameOfMail, id, explanation} in notifications" :key="id">

            <v-card class="py-2">
                  <v-card-title class="text-h5">
                    {{ nameOfMail }}
                  </v-card-title>
                  <v-card-subtitle > {{ notification }}</v-card-subtitle>
                  <v-card-text>
                    <b>explanation: </b>{{ explanation }}
                  </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiClock, mdiHelp } from "@mdi/js"
import notifications from "@/data/notifications"
export default {
  data () {
    return {
      mdiHelp,
      mdiClock,
      notifications,
      dialog: false,
    }
  },
}
</script>
