export const LOGIN = 'login'
export const FINISH_LOGIN = 'finishLogin'
export const LOGOUT = 'logout'
export const IMPERSONATE = 'impersonate'
export const RESTORE_AUTH = 'restoreLogin'

export const FETCH_CONTACTS = 'fetchContacts'
export const FETCH_USER_DATA = 'fetchUserData'
export const FETCH_USER_LIST = 'fetchUserList'
export const FETCH_CREDENTIAL_LIST = 'fetchCredentialList'
export const AUTHORIZE_OAUTH = 'authorizeOauth'
export const FETCH_ORGANIZATIONS = 'fetchOrganizations'
export const PATCH_ORGANIZATION = 'patchOrganization'
export const FETCH_ORGANIZATION_COLLECTION_PLANS = 'fetchOrganizationCollectionPlans'
export const FETCH_DATES_GLOBAL = 'fetchDatesGlobal'
export const FETCH_DATES_FOR_ORGANIZATION = 'fetchDatesForOrganization'
export const ADD_ORGANIZATION = 'addOrganization'
export const ADD_USER = 'addUser'
export const UPDATE_CURRENT_USER = 'updateUser'
export const UPDATE_OTHER_USER = 'updateOtherUser'
export const ADD_COLLECTION_PLAN = 'addCollectionPlan'
export const UPDATE_COLLECTION_PLAN = 'updateCollectionPlan'
export const PATCH_COLLECTION_PLAN = 'patchCollectionPlan'
export const DELETE_COLLECTION_PLAN = 'deleteCollectionPlan'
export const ADD_COLLECTION_PLAN_GROUP = 'addCollectionPlanGroup'
export const DUPLICATE_COLLECTION_PLAN_GROUP = 'duplicateCollectionPlanGroup'
export const DELETE_COLLECTION_PLAN_GROUP = 'deleteCollectionPlanGroup'
export const FETCH_FILLABLE_FIELDS_FOR_COLLECTION = 'fetchFillableCollectionFields'
export const PATCH_FILLABLE_FIELDS_FOR_COLLECTION = 'patchFillableCollectionFields'
export const PATCH_DEVICE = 'patchDevice'
export const FETCH_DEVICES = 'fetchDevices'
export const FETCH_DEVICE_PROVISIONING_REQUESTS = 'fetchDeviceProvisioningRequests'
export const PATCH_DEVICE_PROVISIONING_REQUEST = 'patchDeviceProvisioningRequest'
export const ADD_ORGANIZATION_CONTACT = 'addOrganizationContact'
export const PATCH_ORGANIZATION_CONTACT = 'patchOrganizationContact'
export const DELETE_ORGANIZATION_CONTACT = 'deleteOrganizationContact'
export const ADD_ORGANIZATION_ADDRESS = 'addOrganizationAddress'
export const PATCH_ORGANIZATION_ADDRESS = 'patchOrganizationAddress'
export const UPDATE_ORGANIZATION_ADDRESS = 'updateOrganizationAddress'
export const INVALIDATE_ORGANIZATION_ADDRESS = 'invalidateOrganizationAddress'
export const UPDATE_ORGANIZATION_SETTING = 'updateOrganizationSetting'
export const UPDATE_LOCAL_ORGANIZATION_SETTING = 'updateLocalOrganizationSetting'
export const UPDATE_INHERIT_ORGANIZATION_SETTING = 'updateInheritOrganizationSetting'
export const GENERATE_SECRET_TOKEN = 'generateSecretToken'
export const DELETE_SECRET_TOKEN = 'deleteSecretToken'
export const ADD_DONATION_METER = 'addDonationMeter'
export const DELETE_DONATION_METER = 'deleteDonationMeter'

export const POST_DEVICE_CLOSEOUT = 'postDeviceCloseOut'

export const FETCH_GLOBAL_DEVICES = 'fetchGlobalDevices'
export const FETCH_LOCATIONS = 'fetchLocations'
// @deprecated
export const FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION = 'fetchDashboard'
export const FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION = 'fetchDashboardStatistics'
export const FETCH_COLLECTION_SUMS_FOR_ORGANIZATION = 'fetchCollectionSums'
export const ADD_LOCATION = 'addLocation'
export const FETCH_QR_CODE = 'fetchQrCode'
export const FETCH_CONTENTIMAGES = 'fetchContentImages'
export const ADD_COLLECTION_PLAN_EXTENSION = 'addCollectionPlanExtension'
export const PATCH_COLLECTION_PLAN_EXTENSION = 'patchCollectionPlanExtension'
export const DELETE_COLLECTION_PLAN_EXTENSION = 'deleteCollectionPlanExtension'
export const FETCH_STATIC_CONFIGURATION = 'fetchStaticConfiguration'

export const TOGGLE_RECURSIVE = 'toggleRecursive'
export const UPDATE_RECURSIVE = 'updateRecursive'

export const SEARCH_FIRMWARE_INFORMATION = 'searchFirmwareInformation'

export const FETCH_BILLING_PLANS = 'fetchBillingPlans'
export const FETCH_BILLING_PLAN_CONTRACT_ASSOCIATION = 'fetchBillingPlanContractAssociation'
export const CREATE_BILLING_PLAN_CONTRACT_ASSOCIATION = 'createBillingPlanContractAssociation'
export const UPDATE_BILLING_PLAN_CONTRACT_ASSOCIATION = 'updateBillingPlanContractAssociation'

export const FETCH_CONTRACTS = 'fetchContracts'
export const UPDATE_CONTRACT = 'updateContract'

export const PRE_ANNOUNCE_COLLECTIONS = 'preAnnounceCollections'
export const FETCH_DIRECT_PAYMENT_PAIN_FILES = 'fetchDirectPaymentPainFiles'

export const FETCH_DISBURSEMENT_ORDERS = 'fetchDisbursementOrders'
export const STOP_CASH_PAYMENT = 'stopCashPayment'
export const CANCEL_DISBURSEMENT_ORDER = 'cancelDisbursementOrder'
