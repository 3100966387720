const getCommonPasswords = () => import(/* webpackChunkName: "password-data" */ './password-data')

export async function getIsCommonPassword () {
  const { COMMON_PASSWORDS } = await getCommonPasswords()
  function isCommonPassword (val) {
    if (!val) {
      return false
    }
    return COMMON_PASSWORDS.has(val.toLowerCase().replace(/[^a-z0-9]/g, ''))
  }
  return isCommonPassword
}
