<template>

<v-card>
  <v-card-title>
    {{ collection.name }}
  </v-card-title>
  <v-card-text>

  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="primary"
      text
    >
      Drucken
    </v-btn>

    <v-btn
      color="primary"
      text
    >
      Schließen
    </v-btn>
  </v-card-actions>
</v-card>

</template>

<script>
// import moment from 'moment'

export default {
  name: 'CollectionDetails',
  components: {
  },
  props: {
    collection: null
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="stylus">

</style>
