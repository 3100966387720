<template>
  <div>
    Admin Billing works
  </div>
</template>

<script>
export default {
  name: 'AdminBilling',
}
</script>
