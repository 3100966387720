<template>
  <v-card outlined>
    <v-card-title class="d-flex flex-row justify-space-between">
      <span class="text-caption">
        {{ createdAt }}
      </span>
      <v-chip :color="request.state === 'pending' ? 'primary' : ''">
        {{ state }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <div class="mb-2 text-h5">
        {{ request.pin | groupCharacters(3) }}
      </div>
      <div class="mb-2">
        <span class="font-weight-bold">Quelle: </span>
        <span>{{ request.source }}</span>
      </div>
      <div class="mb-2" v-if="request.request_data">
        <span class="font-weight-bold">Geräte-Typ: </span>
        <span>{{ deviceClass }}</span>
      </div>
    </v-card-text>
    <v-card-actions v-if="request.state === 'pending'">
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="selectedDeviceType"
            :items="availableDeviceTypes"
            label="Geräte-Typ auswählen"
            item-text="label"
            item-value="name"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            v-model="deviceName"
            label="Name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="d-flex flex-row-reverse">
          <v-btn @click="registerDevice">Annehmen</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import GroupCharacterFilter from "@/mixins/group-character-filter"
import moment from "moment"
import { DEVICE_CLASSES, DEVICE_TYPES } from "@/lib/device-db"

const ALLOWED_DEVICE_TYPES = ['peter', 'andrew', 'matt', 'james', 'philip', 'thomas']

export default {
  name: "DeviceProvisioningRequest",
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  emits: ['set-allowed'],
  mixins: [GroupCharacterFilter],
  data () {
    return {
      deviceName: '',
      selectedDeviceType: ''
    }
  },
  computed: {
    createdAt () {
      return moment(this.request.created_at).format('YYYY-MM-DD HH:mm')
    },
    state () {
      switch (this.request.state) {
        case 'allowed':
          return 'Angenommen'
        case 'pending':
          return 'Neu'
        case 'registering':
          return 'in Arbeit'
        case 'done':
          return 'Abgeschlossen'
        default:
          return this.request.state
      }
    },
    deviceClass () {
      // eslint-disable-next-line camelcase
      const boardType = this.request?.request_data?.board_type || null

      if (!boardType) {
        return ''
      }

      const deviceClass = DEVICE_TYPES[boardType].deviceClass

      return DEVICE_CLASSES[deviceClass]
    },
    availableDeviceTypes () {
      const deviceTypes = ALLOWED_DEVICE_TYPES.map((deviceType) => {
        const device = DEVICE_TYPES[deviceType]

        return {
          name: deviceType,
          label: DEVICE_CLASSES[device.deviceClass] + ` (${deviceType})`,
          ...device
        }
      })

      deviceTypes.push({
        name: '',
        label: 'Keine Angabe'
      })

      return deviceTypes
    }
  },
  methods: {
    registerDevice () {
      if (this.request.state !== 'pending') {
        return
      }

      this.$emit('set-allowed', this.request.url, this.deviceName, this.selectedDeviceType)
    }
  }
}
</script>

<style scoped>

</style>
