<template>
  <v-container>
    <template v-if="letters.length === 0">
      Noch keine Briefe erstellt.
      <router-link
        :to="{name:'kige-letter-create'}"
        color="primary"
        tag="v-btn"
      >Brief erstellen
      </router-link>
    </template>
    <v-card
      v-else
      outlined
      color="transparent"
    >
      <v-card-title>
        <v-container>
          <v-row>
            <v-col
              xs="12"
              md="4"
            >
              <v-text-field
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              xs="12"
              md="4"
            >
              <v-select
                outlined
                label="Status"
                v-model="stateFilter"
                :items="stateFilterOptions"
              ></v-select>
            </v-col>
            <v-col
              xs="12"
              md="4"
            >
              <router-link
                :to="{name:'kige-letter-create'}"
                class="float-right"
              >
                <v-btn
                  color="primary"
                >
                  <v-icon>mdi-plus</v-icon>
                  Brief erstellen
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="availableLetters"
        :search="search"
      >
        <template #item="{ item }">
          <letter-entry
            :letter="item"
          ></letter-entry>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>

import {
  GET_FILTERS,
  GET_LETTERS,
} from "@/modules/kirchgeld/store/kirchgeld/action-types"
import { mapGetters } from "vuex"
import LetterEntry from "@/modules/kirchgeld/components/LetterEntry.vue"
import { sortAlphaNumeric } from "@/lib/sort-utils"

export default {
  name: "LetterIndex",
  components: {
    LetterEntry
  },
  data () {
    return {
      finalizedLetterList: [],
      sentLetterList: [],
      headers: [
        {
          value: 'name',
          text: 'Name'
        }
      ],
      search: '',
      stateFilter: 'all',
      stateFilterOptions: [
        {
          value: 'all',
          text: 'Alle'
        },
        {
          value: 'new',
          text: 'Neu'
        },
        {
          value: 'finalized',
          text: 'Abgeschlossen'
        },
        {
          value: 'sent',
          text: 'Gesendet'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['filters', 'letters']),
    availableLetters () {
      return sortAlphaNumeric(this.letters, 'created_at', false)
        .filter(letter => {
          if (this.stateFilter === 'all') {
            return true
          }

          if (this.stateFilter === 'new' && !['finalized', 'sent'].includes(letter.state)) {
            return true
          }

          if (this.stateFilter === 'finalized' && letter.state === 'finalized') {
            return true
          }

          if (this.stateFilter === 'sent' && letter.state === 'sent') {
            return true
          }

          return false
        })
    }
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('kirchgeld/' + GET_FILTERS),
      this.$store.dispatch('kirchgeld/' + GET_LETTERS)
    ])
  },
  methods: {
  }

}
</script>
