import {
  SET_COLLECTION_SUMS_FOR_ORGANIZATION,
  SET_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION,
  SET_DASHBOARD_STATISTICS_FOR_ORGANIZATION,
} from '@/store/mutation-types'
import { INC_LOADING, DEC_LOADING, SET_ERROR_MESSAGE } from '@/modules/common/store/mutation-types'
import { FETCH_COLLECTION_SUMS_FOR_ORGANIZATION, FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION } from '@/store/action-types'
import config from '@/config'

export default {
  namespaced: true,
  state: function () {
    return {
      paymentHistoryAggregateByOrganizationUrl: {},
      statisticsByOrganizationUrl: {}
    }
  },
  getters: {
    statisticsByOrganizationUrl: (state) => state.statisticsByOrganizationUrl
  },
  mutations: {
    [SET_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION]: (state, { organizationUrl, data }) => {
      /* eslint-disable camelcase */
      const byDay = data?.by_day ?? []
      const byMonth = data?.by_month ?? []
      state.paymentHistoryAggregateByOrganizationUrl = {
        ...state.paymentHistoryAggregateByOrganizationUrl,
        [organizationUrl]: {
          ...(state.paymentHistoryAggregateByOrganizationUrl[organizationUrl] ?? {}),
          byDay,
          byMonth
        }
      }
    },
    [SET_DASHBOARD_STATISTICS_FOR_ORGANIZATION]: (state, { organizationUrl, data }) => {
      state.statisticsByOrganizationUrl[organizationUrl] = data
    },
    [SET_COLLECTION_SUMS_FOR_ORGANIZATION]: (state, { organizationUrl, data }) => {
      state.paymentHistoryAggregateByOrganizationUrl = {
        ...state.paymentHistoryAggregateByOrganizationUrl,
        [organizationUrl]: {
          ...(state.paymentHistoryAggregateByOrganizationUrl[organizationUrl] ?? {}),
          collectionSums: data
        }
      }
    }
  },
  actions: {
    [FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION]: async ({ commit, rootGetters, rootState }, { organizationUrl }) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get(`${organizationUrl}dashboard_info/`, {
          params: {
            tz: config.defaultTimezone,
            recursive: rootState.userPrefs.recursive
          }
        })
        await commit(SET_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, { organizationUrl, data: response.data })
      } catch (error) {
        await commit(SET_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, [])
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        await commit(DEC_LOADING, null, { root: true })
      }
    },
    [FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION]: async ({ commit, rootGetters, rootState }, { organizationUrl, since, before }) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get(`${organizationUrl}dashboard_statistics/`, {
          params: {
            tz: config.defaultTimezone,
            recursive: rootState.userPrefs.recursive,
            since,
            before
          }
        })
        await commit(SET_DASHBOARD_STATISTICS_FOR_ORGANIZATION, { organizationUrl, data: response.data })
      } catch (error) {
        await commit(SET_DASHBOARD_STATISTICS_FOR_ORGANIZATION, [])
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        await commit(DEC_LOADING, null, { root: true })
      }
    },
    [FETCH_COLLECTION_SUMS_FOR_ORGANIZATION]: async ({ commit, rootGetters, rootState }, { organizationUrl, yearMonth = null }) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get(
          `${organizationUrl}collection_sums/` +
          (yearMonth ? `${yearMonth}/` : ''), {
            params: {
              tz: config.defaultTimezone,
              recursive: rootState.userPrefs.recursive
            }
          }
        )
        await commit(SET_COLLECTION_SUMS_FOR_ORGANIZATION, { organizationUrl, data: response.data })
      } catch (error) {
        await commit(SET_COLLECTION_SUMS_FOR_ORGANIZATION, [])
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        await commit(DEC_LOADING, null, { root: true })
      }
    }
  }
}
