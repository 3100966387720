<template> <!-- FIXME: Nice background? -->
  <v-card
    class="d-flex align-content-center justify-center flex-wrap"
    style="height: calc(100vh - 31px); background-color: unset; overflow: auto;"
  >
    <v-card
      class="px-6 py-2"
      max-width="450"
      min-width="400"
      z-index="9999"
    >
      <logo
        dark
        class="ma-5 mb-2"
      />

      <template v-if="success">
        Sie haben Ihr Passwort erfolgreich geändert. Sie können sich jetzt <router-link :to="{name: 'home'}">hier</router-link> einloggen.
      </template>
      <template v-else-if="tokenValid === true">
        <h4>Passwort setzen</h4>
        <v-alert
          v-if="wasPasswordRefused"
          outlined>
          <h4 class="pt-0 mt-0"><v-icon class="mr-3 pb-2">mdi-alert</v-icon>Unsicheres Passwort erkannt<v-icon class="ml-3 pb-2">mdi-alert</v-icon></h4>
          <p>
            Um die Sicherheit Ihrer Daten und die der der Spendengeber und -geberinnen zu gewährleisten prüfen wir die Sicherheit Ihres Login-Passwortes. Dabei überprüfen wir, wie einfach ein Angreifer Ihr Passwort erraten könnte, und vergleichen einen Hash Ihres Passwortes mit einer Liste von 600 Millionen öffentlich zugänglichen Passwörtern von <a href="https://haveibeenpwned.com/Passwords">Have I Been Pwned</a>.
          </p>
        </v-alert>
        <password-form
          :show-cancel-option="false"
          :password-reset-auth-token="$route.params.token"
          @password-change-succeeded="onPasswordChangeSucceeded"
          @password-refused-by-policy="onPasswordRefusedByPolicy"
        ></password-form>
      </template>
      <template v-else-if="tokenValid === false">
        Sie sind einem fehlerhaften Link gefolgt – bitte prüfen Sie in Ihren E-Mails,
        ob Sie den kompletten Link kopiert haben. Andernfalls fordern Sie bitte
        eine erneute Mail an. &rightarrow; <a href="/">Zur Startseite</a>
      </template>
      <template v-else>Überprüfe Link &hellip;</template>
    </v-card>
  </v-card>
</template>
<script>
import rest from 'lib/rest'
import Logo from '@/components/icons/Logo'
import PasswordForm from '@/components/PasswordForm'

export default {
  name: 'auth-reset',
  components: {
    PasswordForm,
    Logo
  },
  data () {
    return {
      error: null,
      wasPasswordRefused: false,
      success: false,
      tokenValid: null,
      waiting: false
    }
  },
  mounted () {
    this.$nextTick(async () => {
      try {
        const response = await rest.fetch(
          'user/check_password_token/',
          'POST',
          { token: this.$route.params.token },
          { headers: { Authorization: null } }
        )
        this.tokenValid = response.valid
      } catch (e) {
        this.tokenValid = false
      }
    })
  },
  methods: {
    onPasswordChangeSucceeded () {
      this.success = true
      this.wasPasswordRefused = false
    },
    onPasswordRefusedByPolicy () {
      this.wasPasswordRefused = true
    }
  }
}
</script>
