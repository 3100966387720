import Vue from 'vue'
import Router from 'vue-router'

import HomeSwitcher from './views/HomeSwitcher.vue'
import Login from './views/Login.vue'
import AuthReset from './views/AuthReset.vue'
import UserProfile from './views/UserProfile.vue'
import QRCode from './views/QRCode.vue'
import FillableFields from './views/FillableFields.vue'
import OrganizationCollections from './views/organization/OrganizationCollections.vue'
import OrganizationDashboard from './views/organization/OrganizationDashboard.vue'
import OrganizationWrapper from './views/organization/OrganizationWrapper.vue'
import OrganizationDevices from './views/organization/OrganizationDevices.vue'
import OrganizationPlans from './views/organization/OrganizationPlans.vue'
import DeviceAdmin from './views/admin/DeviceAdmin.vue'
import ProvisionDevicesAdmin from "@/views/admin/ProvisionDevicesAdmin"
import LocationAdmin from './views/admin/LocationAdmin.vue'
import UserAdmin from './views/admin/UserAdmin.vue'

import EvalDonationslist from './views/evaluation/EvalDonationslist.vue'
import EvalSubscriberlist from './views/evaluation/EvalSubscriberlist.vue'
import DocBook from './views/evaluation/DocBook.vue'
import EvalRecurringDonationslist from './views/evaluation/EvalRecurringDonationslist.vue'
import EvalDevices from './views/evaluation/EvalDevices.vue'
import EvalInvoicing from "./views/evaluation/EvalInvoicing"
import EvalDisbursementOrders from "./views/evaluation/EvalDisbursementOrders"

import OrgLocations from './views/management/OrgLocations.vue'
import OrgUsers from "@/views/management/OrgUsers.vue"
import OrgTeam from './views/management/OrgTeam.vue'
import OrgDetails from './views/management/OrgDetails.vue'
import OrgNotification from './views/management/OrgNotification.vue'
import DocInvoices from './views/management/DocInvoices.vue'
import News from './views/News.vue'

import EvaluationStats from './components/revenue/EvaluationStats.vue'

import store from './store'
import config from '@/config'
import Home from '@/views/Home.vue'
import AdminMails from "@/views/admin/AdminMails.vue"
import AdminBilling from "@/views/admin/AdminBilling.vue"
import EvalDirectDebits from "@/views/evaluation/EvalDirectDebits.vue"
import CashPaymentSubmission from "@/views/anonymous/CashPaymentSubmission.vue"
import SecurelyStopCashPayments from "@/views/anonymous/SecurelyStopCashPayments.vue"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: '',
      component: HomeSwitcher,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: { requiresAuth: config.variant !== 'geben.digital' },
        },
        {
          path: '/o/:id/',
          component: OrganizationWrapper,
          meta: { requiresAuth: true },
          children: [
            {
              path: '',
              name: 'organization.dashboard',
              component: OrganizationDashboard
            },
            {
              path: 'dashboard',
              redirect: { name: 'organization.dashboard' }
            },
            {
              path: 'devices',
              name: 'organization.devices',
              component: OrganizationDevices,
            },
            {
              path: 'collection',
              name: 'organization.collections',
              component: OrganizationCollections
            },
            {
              path: 'plans/:year?/',
              name: 'organization.plans',
              component: OrganizationPlans
            },
            {
              path: 'print/:collection?/',
              name: 'organization.print',
              component: EvaluationStats,
              props: {
                isPrintView: true
              }
            },
            {
              path: 'evaluation/devices',
              name: 'evaluation.org.devices',
              component: EvalDevices,
            },
            {
              path: 'evaluation/devices/:deviceClass/',
              name: 'evaluation.org.device',
              component: EvalDevices,
            },
            {
              path: 'evaluation/donationslist',
              name: 'evaluation.org.donationslist',
              component: EvalDonationslist,
            },
            {
              path: 'evaluation/subscriberslist',
              name: 'evaluation.org.subscriberlist',
              component: EvalSubscriberlist,
            },
            {
              path: 'evaluation/dauerspenden',
              name: 'evaluation.org.recurringDonationslist',
              component: EvalRecurringDonationslist,
            },
            {
              path: 'evaluation/book',
              name: 'evaluation.doc.book',
              component: DocBook,
            },
            {
              path: 'evaluation/disbursement-orders',
              name: 'evaluation.org.disbursementOrders',
              component: EvalDisbursementOrders,
            },
            {
              path: 'evaluation/invoicing',
              name: 'evaluation.org.invoicing',
              component: EvalInvoicing
            },
            {
              path: 'evaluation/directdebits',
              name: 'evaluation.org.directdebits',
              component: EvalDirectDebits
            },
            {
              path: 'management/locations',
              name: 'management.org.locations',
              component: OrgLocations,
            },
            {
              path: 'management/users',
              name: 'management.org.users',
              component: OrgUsers,
            },
            {
              path: 'management/team',
              name: 'management.org.team',
              component: OrgTeam,
            },
            {
              path: 'management/details',
              name: 'management.org.details',
              component: OrgDetails,
            },
            {
              path: 'management/notifications',
              name: 'management.org.notifications',
              component: OrgNotification,
            },
            {
              path: 'management/invoices',
              name: 'management.doc.invoices',
              component: DocInvoices,
            },
            {
              path: 'news',
              redirect: { name: 'news' }
            }
          ]
        },
        {
          path: '/admin/locations',
          name: 'admin.locations',
          component: LocationAdmin,
          meta: { requiresAuth: true }
        },
        {
          path: '/admin/devices',
          name: 'admin.devices',
          component: DeviceAdmin,
          meta: { requiresAuth: true }
        },
        {
          path: '/admin/devices/provision',
          name: 'admin.devices.provision',
          component: ProvisionDevicesAdmin,
          meta: { requiresAuth: true }
        },
        {
          path: '/admin/users',
          name: 'admin.users',
          component: UserAdmin,
          meta: { requiresAuth: true }
        },
        {
          path: '/admin/mails',
          name: 'admin.mails',
          component: AdminMails,
          meta: { requiresAuth: true }
        },
        {
          path: '/admin/billing',
          name: 'admin.billing',
          component: AdminBilling,
          meta: { requiresAuth: true }
        },
        {
          path: '/me/profile',
          name: 'auth.profile',
          component: UserProfile,
          label: 'Profile',
          meta: { requiresAuth: true }
        },
        {
          path: '/news',
          name: 'news',
          component: News
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/authorize',
      name: 'authorize',
      component: Login,
      meta: { oauthMode: true }
    },
    {
      path: '/auth/recover/:token',
      name: 'auth.reset',
      component: AuthReset
    },
    {
      path: '/qr',
      name: 'qrcode',
      component: QRCode
    },
    {
      path: '/misc/accounting_fields/:token',
      name: 'fillablefields',
      component: FillableFields
    },
    {
      path: '/cash_payment_submission/:token/',
      component: CashPaymentSubmission,
      meta: { requiresAuth: false },
      props: true,
    },
    {
      path: '/securely_stop_cash_payments/:token/',
      component: SecurelyStopCashPayments,
      meta: { requiresAuth: false },
      props: true,
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({ name: 'home' })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/userAuthToken']) {
      if (from.name !== 'login') {
        next({ name: 'login' })
      } else {
        next()
      }
    } else if (to.matched[0]?.name.startsWith('admin') && !(store.state.user.user && store.state.user.is_superuser)) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else if (to.matched.some(record => record?.name === 'login') && config.variant === 'geben.digital' && !store.getters['user/userAuthToken']) {
    // For geben.digital, do login on home page.
    // FIXME: Make sure that in geben.digital mode no links or redirects to /login exist, then remove this guard
    next({ name: 'home' })
  } else {
    next()
  }
})
export default router
