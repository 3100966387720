const apiBase = dynamicConfig?.API_BASE_URL ?? process.env.VUE_APP_API_BASE_URL
const apiKdBase = dynamicConfig?.API_KD_BASE_URL ?? process.env.VUE_APP_API_KD_BASE_URL
const apiWsBase = dynamicConfig?.API_WS_BASE_URL ?? process.env.VUE_APP_API_WS_BASE_URL
const deployment = dynamicConfig.DEPLOYMENT_TYPE ?? process.env.VUE_APP_DEPLOYMENT ?? process.env.NODE_ENV
const release = process.env.VUE_APP_SENTRY_RELEASE || (process.env.PACKAGE_VERSION ?? null)

const config = {
  backend: {
    rest: `${apiBase}/rest/public/v2/`
  },
  kirchgeldApi: {
    rest: `${apiKdBase}/v1/`
  },
  telemetry: {
    url: `${apiWsBase}/mqtt_ws/v1/`
  },
  deployment,
  variant: process.env.VUE_APP_VARIANT ?? "kollekte.digital",
  kollekteApp: {
    url: dynamicConfig.KOLLEKTEAPP_BASE_URL ?? process.env.VUE_APP_KOLLEKTEAPP_BASE_URL ?? "http://localhost:8080"
  },
  spendeApp: {
    url: dynamicConfig.SPENDEAPP_BASE_URL ?? process.env.VUE_APP_SPENDEAPP_BASE_URL ?? "http://localhost:8080"
  },
  kirchgeldDigital: {
    url: dynamicConfig.KIRCHGELDDIGITAL_BASE_URL ?? process.env.VUE_APP_KIRCHGELDDIGITAL_BASE_URL ?? "http://localhost:8080"
  },
  veranstaltungApp: {
    url: dynamicConfig.VERANSTALTUNGAPP_BASE_URL ?? process.env.VUE_APP_VERANSTALTUNGAPP_BASE_URL ?? "http://localhost:8000"
  },
  defaultTimezone: "Europe/Berlin",
  sentry: {
    dsn: dynamicConfig.SENTRY_DSN ?? process.env.VUE_APP_SENTRY_DSN ?? '',
    environment: dynamicConfig.DEPLOYMENT_TYPE ?? process.env.VUE_APP_SENTRY_ENVIRONMENT ?? deployment,
    release,
  },
  frontendVersion: {
    buildDate: dynamicConfig.FRONTEND_BUILD_DATE ?? null,
    buildId: dynamicConfig.FRONTEND_BUILD_ID ?? null,
    version: dynamicConfig.FRONTEND_VERSION ?? process.env.PACKAGE_VERSION ?? null,
  },
}
export default config
