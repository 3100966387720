<template>
  <v-container v-if="organization">
    <v-row>
      <v-col cols="12">
        <template v-if="relevantDevices.has('john') && (hasKollekteAppOverride === undefined || hasKollekteAppOverride === true)">
          <div  id="iframegenerator">
            <v-row>
              <v-col cols="12">
                <p>Ihr Direktlink: <a :href="kollekteAppUrl" target="_blanc">{{ kollekteAppUrl }}</a></p>
                <p>Sie können kollekte.app per iframe (auch <a href="https://de.wikipedia.org/wiki/Inlineframe" target="_blanc"><i>Inlineframe</i></a>) auf ihrer eigenen Internetseite einbinden. Wählen Sie dafür Ihre Einstellungen und kopieren Sie den Quellcode unter der Vorschau in Ihre Seite.</p>
                <p>Weitere Informationen zum Einbinden sowie Hinweise zum Datenschutz finden Sie <a href="https://digitalespende.de/kollekte-app-auf-der-eigenen-seite-einbinden/">hier</a>.</p>
                <p>Zur einfachen Konfiguration sehen Sie hier eine Live-Vorschau Ihrer kollekte.app.</p>
                <h4>Optionen</h4>
                <div id="framesettings" class="d-flex flex-row">
                <v-checkbox
                  v-model="parishSelection"
                  :disabled="iframeSmall"
                  label="Gemeindeauswahl anzeigen"
                ></v-checkbox>
                <v-checkbox
                  v-model="iframeSmall"
                  label="kleine Variante"
                ></v-checkbox>
                </div>
                <h4>Ausrichtung</h4>
                <v-tabs
                  v-model="tab"
                  align-with-title
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab
                    v-for="item in iframealignment"
                    :key="item.textalign"
                  >
                    {{ item.label }}
                  </v-tab>
                </v-tabs>
                <keep-alive>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="item in iframealignment"
                      :key="item.textalign"
                    >
                      <v-card elevation="2">
                        <div class="iframe-container" style="min-height: 470px">
                          <div v-bind:style='{textAlign: item.textalign, width: "100%"}'><iframe :src="kollekteAppUrl + (parishSelection ? '' : '?mode=embed')" frameBorder="0" v-bind:style="{ width: (iframeSmall ? 300 : 380) + 'px', height: (iframeSmall ? 355 : 440) + 'px' }"></iframe></div>
                        </div>
                        <h4>Ergebnis</h4>
                        <p>Fügen Sie den folgenden HTML-Code in Ihre Website ein:</p>
                        <div>
                          <code class="codeblock">&#x3C;div style=&#x22;text-align: {{ item.textalign }}; width: 100%;&#x22;&#x3E;&#x3C;iframe src=&#x22;{{ kollekteAppUrl }}{{ (parishSelection ? '' : '?mode=embed') }}&#x22; frameBorder=&#x22;0&#x22; style=&#x22;width:{{ iframeSmall ? 300 : 380 }}px; height:{{ iframeSmall ? 355 : 440 }}px;&#x22;&#x3E;&#x3C;/iframe&#x3E;&#x3C;/div&#x3E;
                          </code>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </keep-alive>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-else>
          <p>Für Ihre Organisation ist noch keine kollekte.app konfiguriert. Bitte schreiben Sie uns eine Mail an <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a> wenn Sie denken, dass uns ein Fehler unterlaufen ist oder um einen Vertrag abzuschließen.</p>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FETCH_LOCATIONS, FETCH_ORGANIZATIONS, PATCH_ORGANIZATION } from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
import config from '@/config'

export default {
  name: 'kollekteappEmbed',
  props: {
  },
  data () {
    return {
      activeLocation: null,
      newLocationName: null,
      creating: false,
      error: null,
      newLoc: false,
      tab: null,
      parishSelection: false,
      iframeSmall: false,
      iframealignment: [
        { label: 'links', textalign: 'left' },
        { label: 'mittig', textalign: 'center' },
        { label: 'rechts', textalign: 'right' },
      ],
      colorpicker: null,
      swatches: [
        ['#723288'], ['#327359'], ['#89313D'], ['#3E3E3E']
      ]
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organization: 'currentOrganization', organizationSettingsByUrl: 'organizationSettingsByUrl', organizationRelevantDevicesRawByUrl: 'organizationRelevantDevicesRawByUrl' }),
    ...mapState('location', ['locations']),
    isDev () {
      return config.deployment === 'development'
    },
    organizationSettings () {
      return this.organizationSettingsByUrl[this.organization.url]
    },
    hasKollekteAppOverride () {
      if (!this.organizationSettings) {
        return undefined
      }
      return this.organizationSettings?.override?.enableWA === undefined
        ? undefined
        : this.organizationSettings?.override?.enableWA
    },
    kollekteAppUrl () {
      return config.kollekteApp.url + '/p/' + this.organization.id
    },
    relevantDevices () {
      if (!this.organizationRelevantDevicesRawByUrl) {
        return {}
      }
      return this.organizationRelevantDevicesRawByUrl[this.organization.url]
    },
    options () {
      return {
        size: 512,
        data: this.kollekteAppUrl,
      }
    },
  },
  watch: {
    iframeSmall: function () {
      if (this.iframeSmall) {
        this.parishSelection = false
      }
    },
    organization: function () {
      this.resetColorpicker()
    },
  },
  created () {
  },
  methods: {
    async updateColor (organization) {
      await this.$store.dispatch('organization/' + PATCH_ORGANIZATION, {
        url: this.organization.url,
        data: {
          settings_inherit: { 'kollekte.app': { primary_color: this.colorpicker } }
        }
      })
    },
    resetColorpicker () {
      if (this.organizationSettings['kollekte.app'] && this.organizationSettings['kollekte.app'].primary_color) {
        this.colorpicker = this.organizationSettings['kollekte.app'].primary_color
      } else {
        this.colorpicker = '#723288'
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('location/' + FETCH_LOCATIONS)
      ])
    })
  }
}
</script>
<style lang="stylus">
#iframegenerator
  #framesettings
    .v-input--checkbox
      padding-right: 10px
  .iframe-container
    padding-top: 10px
    padding-bottom: 10px
    border-bottom: 1px solid black

.codeblock
  display: block
  margin-top: 10px
  width: 100%
  min-width: 400px
  min-height: 90px
  border: 1px solid grey
  padding: 10px
</style>
