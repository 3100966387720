<script>
import { HorizontalBar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: HorizontalBar,
  props: {
    title: {
      type: String,
      required: true
    },
    datasets: {
      type: Array,
      required: true,
    },
    labelFormatter: {
      type: Function,
      required: true,
      default: (value, ctx) => {
        return value
      }
    },
    showDataLabels: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      chartData: {
        labels: [this.title],
        datasets: this.datasets
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: false,
            stacked: true,
            barPercentage: 1.0
          }],
          yAxes: [{
            display: false,
            stacked: true,
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            formatter: this.labelFormatter
          }
        }
      },
    }
  },
  mounted () {
    if (this.showDataLabels) {
      this.addPlugin(ChartDataLabels)
    }
    this.renderChart(this.chartData, this.options)
  }
}
</script>
