<template>
  <div>
    <v-sheet
      height="133"
      style="width:16.4em;padding:0.1em"
      elevation="3"
      color="black"
    >
      <div style="height: 133px; width: 16.2em; "
           class="display-simulation-wa d-flex flex-column">
        <div style="font-size: 3em; line-height: 1; text-align: center" class="p1-2 amount" ref="wa_amount">5 €</div>
        <div style="font-size:14px;text-align: center" class="preposition" ref="preposition">{{ preposition }}</div>
        <div class="purpose" :style="{height: purposeFieldHeight + 'px', textAlign: 'center'}">
          <contenteditable
            v-model="purpose"
            :style="['display: block;', {lineHeight: purposeFontSizeSafe + 2 + 'px', fontSize: purposeFontSizeSafe + 'px'}]"
            tag="div"
            :contenteditable="selected && editable"
            ref="purposeDiv"
          />
        </div>
      </div>
    </v-sheet>
    <div style="color: red" v-if="maxLengthError.length">{{ maxLengthError }}</div>
    <span v-show="selected && (!purpose || purpose.length < 3)" style="color: red">
                    Text zu kurz!<br>
                  </span>
    <span>Max. 4 Zeilen Text (inkl. "Für")</span><br>
    <!-- FIXME <span v-if="scope === 'loc'"><br>Nicht verfügbar an diesem Ort</span> -->
  </div>
</template>

<script>
import { splitPurpose } from '@/lib/regex-tools'

export default {
  name: 'DisplayKindKollekteApp',
  props: ["value", "selected", "editable"],
  data () {
    const split = splitPurpose(this.value ?? '')
    return {
      preposition: split.preposition,
      purpose: split.purpose,

      purposeFontSize: null,
      maxLengthError: '',
      delayedActionTimer: null
    }
  },
  computed: {
    purposeFieldHeight () {
      return (this.preposition === '' ? 76 : 58)
    },
    purposeFontSizeSafe () {
      return (this.purposeFontSize === null ? 10 : this.purposeFontSize)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        const split = splitPurpose(newValue ?? '')
        this.preposition = split.preposition
        this.purpose = split.purpose
      }
    },
    purpose (newVal) {
      this.$emit("input", (this.preposition === '' ? '' : (this.preposition + " ")) + newVal)
      this.$nextTick(() => {
        if (this.delayedActionTimer) {
          clearTimeout(this.delayedActionTimer)
        }
        this.delayedActionTimer = setTimeout(() => {
          this.purposeFontSize = 71
          this.$nextTick(this.adjustFontSizeDown)
        }, 1000)
      })
    },
  },
  mounted () {
    this.$nextTick(this.adjustFontSizeDown)
  },
  methods: {
    adjustFontSizeDown () {
      const elem = this.$refs?.purposeDiv?.$refs?.element ?? null
      if (!elem) {
        return
      }
      const height = elem.offsetHeight
      if (height > this.purposeFieldHeight) {
        this.maxLengthError = ''
        if (this.purposeFontSize >= 10) {
          this.purposeFontSize = this.purposeFontSize - 1
          // Schedule another call as long as we're editing it
          this.$nextTick(this.adjustFontSizeDown)
        } else {
          this.maxLengthError = 'Text ist zu lang!'
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.display-simulation-wa
  color: white
  font-family: 'Roboto', "Helvetica Neue", HelveticaNeue, Helvetica, Arial
  padding: 0
  margin: 0
  border: 0

  .display
    color: #eee

    .amount
      padding-bottom: 0.1rem

    .preposition
      padding-bottom: 0.1rem

    .purpose
      hyphens: auto
      font-size: 1.5em

</style>
