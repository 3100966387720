<template>
  <div>
    <div id="print-logo-wrapper">
      <logo class="d-none d-print-block" style="max-height: 40px;" dark/>
    </div>
    <v-app-bar
      id="appbar"
      app
      elevation="0"
      color="primary"
      dark
      height="60px"
      clipped-right
    >
      <v-row no-gutters>
        <v-col cols="10" lg="2">
          <router-link :to="homeLink">
            <logo class="d-print-none" style="max-height: 40px"/>
          </router-link>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-app-bar-nav-icon
            @click="sideDrawer=!sideDrawer"
            class="hidden-lg-and-up d-print-none"
          >
            <v-icon x-large>{{ mdiMenu }}</v-icon>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="10" class="text-center hidden-md-and-down d-print-none">
          <template v-if="inOrganizationContext">
            <v-tabs
              centered
              optional
              background-color="primary"
              v-model="organizationMainTabindex"
            >
              <v-tab
                v-for="(item, index) in organizationMainItems"
                :to="item.to"
                :key="index"
                style="color: white; text-transform: initial; font-weight: normal"
                class="text-h6"
              >
                <menu-icon
                  v-if="item.icon"
                  :icon="item.icon"
                  :invert-color-white="true"
                  class="mr-2"
                  scale="0.8"></menu-icon>
                <span>
                  {{ item.label }}
                </span>
              </v-tab>
            </v-tabs>
          </template>
        </v-col>
      </v-row>
      <v-progress-linear
        :active="true"
        :value="(loading > 0) ? 0 : 100"
        buffer-value="0"
        stream
        height="5"
        absolute
        bottom
        :color="loading ? '#ffffff' : 'var(--clr-environment)'"
      ></v-progress-linear>
    </v-app-bar>

    <v-navigation-drawer
      v-model="sideDrawer"
      app
      right
      clipped
      class="d-print-none"
    >
      <template #prepend>
        <v-list nav v-if="inOrganizationContext">
          <v-list-item class="pr-0">
            <v-list-item-content class="py-0">
              <v-menu v-model="organizationSearchOpen" transition="slide-y-transition" :close-on-content-click="false" attach="#organizationSearchButton">
                <template #activator="{ on, attrs }">
                  <div class="d-flex" id="organizationSearchButton">
                    <span>{{ currentOrganization.name | shortenParish }}</span>
                    <v-spacer/>
                    <v-btn icon class="align-self-center" v-bind="attrs" v-on="on"><v-icon class="ma-0">{{ mdiMagnify }}</v-icon></v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-text>
                    <v-autocomplete
                      :prepend-inner-icon="mdiMagnify"
                      v-model="organizationSearchSelected"
                      :items="organizationSearchItems"
                      :search-input.sync="organizationSearchInput"
                      @change="organizationSearchInput = ''"
                      dense
                      autofocus
                      placeholder="Organisation suchen"
                    >
                      <template #item="{ item }">
                        <div class="d-flex flex-column">
                          <v-breadcrumbs :items="orgBreadcrumb(item.organization)" class="sub-breadcrumb">
          <template #divider><v-icon>></v-icon></template>
          <template #item="{ item }">
            <v-breadcrumbs-item
            >{{ item.text }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
                        <span>
                          {{ item.text | shortenParish }}
                        </span>
                        </div>
                       </template>
                    </v-autocomplete>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-list nav dense>
        <v-list-item
          v-for="(item, index) in organizationMainItems"
          :to="item.to"
          :key="index"
          class="hidden-lg-and-up"
        >
          <v-list-item-icon>
            <menu-icon
              v-if="item.icon"
              :icon="item.icon"
              :invert-color-white="false"
              class="mr-2"
              scale="0.6"></menu-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
        <v-divider class="hidden-lg-and-up"/>
        <organization-nav-fragment v-if="inOrganizationContext"/>
        <template v-if="isSuperuser">
          <v-divider v-if="inOrganizationContext"/>
          <v-list-item><v-list-item-title>✨ Administration ✨</v-list-item-title></v-list-item>
          <v-list-item :to="{name: 'admin.locations'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiCity }}</v-icon></v-list-item-icon>
            <v-list-item-title>Orte</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'admin.devices'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiDevices }}</v-icon></v-list-item-icon>
            <v-list-item-title>Geräte</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'admin.devices.provision'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiLockOpenPlus }}</v-icon></v-list-item-icon>
            <v-list-item-title>Geräteprovisionierung</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'admin.users'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiAccountMultiple }}</v-icon></v-list-item-icon>
            <v-list-item-title>Benutzer</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'admin.mails' }">
            <v-list-item-icon><v-icon color="primary">{{ mdiEmail }}</v-icon></v-list-item-icon>
            <v-list-item-title>Mails</v-list-item-title>
          </v-list-item>
          <!-- Activate with KODI-434 -->
          <v-list-item
            v-if="false"
            :to="{ name: 'admin.billing' }">
            <v-list-item-icon><v-icon color="primary">{{ mdiCurrencyEur }}</v-icon></v-list-item-icon>
            <v-list-item-title>Rechnungspläne</v-list-item-title>
          </v-list-item>
        </template>

      </v-list>
      <template #append>
        <v-divider/>
        <v-list nav dense>
          <v-list-item :to="{name: 'news'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiShimmer }}</v-icon></v-list-item-icon>
            <v-list-item-title>Neuigkeiten</v-list-item-title>
          </v-list-item>
          <template v-if="isLoggedIn">
          <v-list-item :to="{name: 'auth.profile'}">
            <v-list-item-icon><v-icon color="primary">{{ mdiAccount }}</v-icon></v-list-item-icon>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item>
          <v-divider/>
            <v-list-item link @click="switchBack" v-if="isImpersonated">
              <v-list-item-icon>
                <v-icon color="brown">{{ mdiAccountSwitch }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Zurückwechseln
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'login'}" @click.native="logout" v-else>
              <v-list-item-icon>
                <menu-icon icon="logout" scale="0.6"></menu-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Abmelden
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-dialog v-if="isPasswordInsecure" v-model="passwordWarningOpen" max-width="50em">
      <v-alert
        prominent
        type="error"
        transition="slide-y-reverse-transition"
        :icon="mdiAlert"
        class="mb-0"
      >
        <v-row><v-col class="text-h6">Unsicheres Passwort erkannt</v-col></v-row>
        <v-row>
          <v-col v-if="hasPasswordBeenPwned">
            Ihr Passwort wurde in einer Liste von öffentlich zugänglichen Passwörtern gefunden und ist potentiell kompromittiert.
          </v-col>
          <v-col v-else>
            Ihr Passwort ist zu einfach zu erraten und würde einem Angreifer der Passwörter ausprobiert nicht lange standhalten.
          </v-col>
        </v-row>
        <v-row><v-col class="text-body-1"><span style="font-variant: small-caps">Hintergrund:</span>
          Um die Sicherheit Ihrer Daten und die der der Spendengeber und -geberinnen zu gewährleisten prüfen wir die
          Sicherheit Ihres Login-Passwortes.<br>
          Dabei überprüfen wir, wie einfach ein Angreifer Ihr Passwort erraten könnte, und vergleichen einen Hash
          Ihres Passwortes mit einer Liste von 600 Millionen öffentlich zugänglichen Passwörtern von
          <a style="color: white; text-decoration: underline" href="https://haveibeenpwned.com/Passwords">Have I Been Pwned</a>.
        </v-col></v-row>
        <v-row><v-col>
          Wichtig! Wir können Ihr Passwort nicht sehen und wir geben den Hash zum Vergleichen nicht raus. Das passiert auf unseren eigenen Servern.
        </v-col></v-row>
        <v-row>
          <v-col cols="6"><v-btn
          text
          @click="passwordWarningOpen = false"
        >Später ändern</v-btn></v-col>
          <v-col cols="6"><v-btn
          text
          color="white"
          :to="{name: 'auth.profile'}"
          @click="passwordWarningOpen = false"
        >Jetzt ändern</v-btn></v-col>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-main>
      <router-view></router-view>
    </v-main>
    <portal to="app-footer-bottom-left" v-if="shouldShowAdminansicht"><router-link :to="{name: 'home'}" >Adminansicht</router-link></portal>
  </div>
</template>

<script>
import MenuIcon from '@/components/icons/MenuIcon.vue'
import { mapGetters, mapState } from 'vuex'
import Logo from '@/components/icons/Logo.vue'
import { CLEAR_DATA } from '@/store/mutation-types'
import { RESTORE_AUTH } from '@/store/action-types'
import {
  PERM_TYPE_INTERACT_COLLECTION_PLAN,
  PERM_TYPE_INTERACT_GENERAL,
  PERM_TYPE_INTERACT_WRITE_ANY
} from '@/lib/permission-tools'
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCity,
  mdiDevices,
  mdiMenu,
  mdiShimmer,
  mdiLockOpenPlus,
  mdiAccountSwitch,
  mdiAlert,
  mdiMagnify,
  mdiEmail,
  mdiCurrencyEur,
  mdiChevronRight
} from '@mdi/js'
import OrganizationNavFragment from '@/components/OrganizationNavFragment.vue'
import config from '@/config'
import { shortenParish } from '@/lib/regex-tools'

export default {
  name: 'MainWrapper',
  components: {
    OrganizationNavFragment,
    Logo,
    MenuIcon
  },
  filters: {
    shortenParish,
  },
  data () {
    return {
      mdiMenu,
      mdiAccount,
      mdiAccountSwitch,
      mdiAlert,
      mdiShimmer,
      mdiAccountMultiple,
      mdiDevices,
      mdiCity,
      mdiLockOpenPlus,
      mdiMagnify,
      mdiEmail,
      mdiCurrencyEur,
      mdiChevronRight,
      organizationMainTabindex: 0,
      sideDrawer: null,
      passwordWarningOpen: true,
      organizationSearchOpen: false,
      organizationSearchSelected: null,
      organizationSearchInput: '',
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', ['allOrganizations', 'currentOrganization']),
    ...mapGetters('user', ['isLoggedIn', 'isImpersonated', 'isSuperuser', 'hasPermission', 'hasMultipleOrgs']),
    inOrganizationContext () {
      return !!this.currentOrganization
    },
    hasPasswordBeenPwned () {
      // eslint-disable-next-line camelcase
      return this.$store.state?.user?.user?.pw_check_data?.pwnedpasswords?.pwned ?? false
    },
    isPasswordInsecure () {
      // eslint-disable-next-line multiline-ternary
      return ['development', 'demo'].includes(config.deployment) ? false : (
        // eslint-disable-next-line camelcase
        ['insecure'].includes(this.$store.state?.user?.user?.pw_check_status) ||
        // eslint-disable-next-line camelcase
        (this.$store.state?.user?.user?.pw_check_data?.pwnedpasswords?.pwned ?? false)
      )
    },
    homeLink () {
      if (this.inOrganizationContext) {
        return { name: 'organization.dashboard', id: this.currentOrganization.id }
      }
      return { name: "home" }
    },
    organizationSearchItems () {
      return Object.values(this.allOrganizations).map(organization => {
        return {
          text: organization.name,
          value: organization.url,
          organization,
        }
      }).filter(
        item => this.hasPermission(item.organization.url, PERM_TYPE_INTERACT_GENERAL)
      ).sort(
        (a, b) => a.text.localeCompare(b.text)
      )
    },
    shouldShowAdminansicht () {
      if (!['geben.digital', 'kollekte.digital'].includes(config.variant)) {
        return false
      }
      return this.isLoggedIn && (this.isSuperuser || this.hasMultipleOrgs)
    },
    organizationMainItems () {
      const retval = []
      if (this.currentOrganization) {
        if (this.hasPermission(this.currentOrganization.url, PERM_TYPE_INTERACT_COLLECTION_PLAN)) {
          retval.push({ to: { name: 'organization.plans', params: { id: this.currentOrganization.id } }, label: 'Planung', icon: 'planning' })
        }
        if (this.hasPermission(this.currentOrganization.url, PERM_TYPE_INTERACT_WRITE_ANY)) {
          retval.push({ to: { name: 'organization.devices', params: { id: this.currentOrganization.id } }, label: 'Geräte', icon: 'device' })
          retval.push({ to: { name: 'organization.collections', params: { id: this.currentOrganization.id } }, label: 'Einnahmen', icon: 'income' })
        }
      }
      return retval
    },
    selectedRouteInOrganizationMainItems () {
      // Helper variable to enable the watcher to work
      return (this.organizationMainItems ?? []).some(item => this.$route?.name === item?.to?.name)
    },
  },
  methods: {
    logout () {
      this.$store.dispatch(CLEAR_DATA)
    },
    switchBack () {
      this.$store.dispatch('user/' + RESTORE_AUTH)
    },
    orgBreadcrumb (org) {
      const retval = []
      if (org) {
        while (org.parent) {
          retval.unshift(org.parent)
          org = this.allOrganizations[org.parent]
        }
      }
      return retval.map(item => this.allOrganizations[item]).map(organization => {
        return {
          text: shortenParish(organization.name),
        }
      })
    },

  },
  watch: {
    selectedRouteInOrganizationMainItems: {
      handler (newVal) {
        // Reset v-tabs to unset
        if (!newVal) {
          this.organizationMainTabindex = null
        }
      }
    },
    async organizationSearchSelected (organizationUrl) {
      if (organizationUrl && this.allOrganizations[organizationUrl]) {
        this.organizationSearchOpen = false
        this.organizationSearchSelected = null
        this.organizationSearchInput = "" // FIXME Clearing doesn't seem to work
        await this.$router.push({
          name: 'organization.dashboard',
          params: {
            id: this.allOrganizations[organizationUrl].id
          }
        })
      }
    },

  },
}
</script>

<style scoped>
@media print {
  main.v-main {
    /* Need to remove the space reserved for navigation bar when printing */
    padding-right: 0 !important;
  }

  .v-application .primary,
  .v-progress-linear
  {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}
</style>

<style lang="scss">
.sub-breadcrumb {
  &.v-breadcrumbs {
    margin:0;
    padding: 0;
    .v-breadcrumbs__item {
    font-size: 11px;
    color: #b8b8b8;
    }
    .v-breadcrumbs__divider {
      padding: 0 4px;
    }
  }
}
</style>
