<script>
import Home from '@/views/Home'
import store from '@/store'
import Login from '@/views/Login'
import GebenLanding from '@/views/gebendigital/GebenLanding'
import config from '@/config'
import MainWrapper from '@/views/MainWrapper.vue'

export default {
  name: 'HomeSwitcher',
  functional: true,
  components: {
    Home,
    Login,
    GebenLanding,
    MainWrapper,
  },
  render (h, ctx) {
    const homeComponent = store.getters['user/isLoggedIn'] ? MainWrapper : (config.variant === 'geben.digital' ? GebenLanding : Login)
    return h(homeComponent, ctx.data, ctx.children)
  },
}
</script>
