<template>
  <v-container id="eval-deviceslist">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <h1 class="d-none d-print-block">{{ shortOrgaName }}</h1>
        <h2>{{ headline }}</h2>
        <a @click="printing()" class="d-print-none" style="float:right"><v-icon>mdi-printer</v-icon></a>
        <p>Zeitraum: {{ twelveMonthsAgo.format('LL') }} bis <span class="d-print-none">heute</span><span class="d-none d-print-inline">{{ rigthNow }}</span></p>
        <div v-if="!$route.params.deviceClass">
          <p v-for="[deviceClass, line] in Object.entries(aggregateByDeviceClass)" :key="deviceClass">
            <b>{{ DEVICE_CLASSES[deviceClass] }}:</b> {{ line.value | amount }} € ({{ line.count }} Zahlung{{ line.count === 1 ? '' : 'en' }})
          </p>
        </div>
        <div v-else>
          <div
            style="display: flex; flex-direction: row; overflow-x: auto;"
            class="d-print-none"
          >
            <p v-show="!!$route.params.deviceClass" class="v-btn">Filter:</p>
            <v-slide-group
              v-show="!!$route.params.deviceClass"
              v-model="filter"
              mandatory
              multiple
              show-arrows
            >
              <v-spacer></v-spacer>
              <v-slide-item
              v-for="(value, orga_class) in tableClasses"
              :key="orga_class"
              v-slot="{ active, toggle }"
              :value="value.key"
              class="ma-3"
              >
                <v-btn
                  :color="active ? 'primary' : ''"
                  elevation="0"
                  text
                  @click="toggle"
                  class=""
                >{{ value.human }}</v-btn>
              </v-slide-item>
              <v-spacer></v-spacer>
            </v-slide-group>
          </div>
          <template v-if="filter.includes('month')">
            <h3 v-show="$route.params.deviceClass">Einnahmen je Monat</h3>
            <div
              v-show="$route.params.deviceClass"
              v-for="[month, entries] in aggregateMonthSorted"
              :key="month"
              class="mb-3"
            >
              <h4>{{ month | mapMonth }}</h4>
              <EvalTableInner :entries="entries" :collectionPlans="collectionPlans"/>
            </div>
          </template>

          <div v-show="$route.params.deviceClass" class="page-break mt-2"></div>
          <template v-if="filter.includes('device')">
            <h3 v-show="$route.params.deviceClass">Einnahmen nach Gerät</h3>
            <div
              v-show="$route.params.deviceClass"
              v-for="[deviceUrl, entries] in aggregateDeviceSorted"
              :key="deviceUrl"
              class="mb-3"
            >
              <h4>{{ deviceNames[deviceUrl] ? deviceNames[deviceUrl] : "Unbekanntes Gerät" }}</h4>
              <EvalTableInner :entries="entries" :collectionPlans="collectionPlans"/>
            </div>
          </template>

          <div v-show="$route.params.deviceClass" class="page-break mt-2"></div>
          <template v-if="filter.includes('collection')">
            <h3 v-show="$route.params.deviceClass">Einnahmen nach Kollektenzweck</h3>

            <EvalTableInner :entries="aggregateByCollectionPlan" :collectionPlans="collectionPlans"/>
          </template>
        </div>
      </v-col>
    </v-row>
    <template>
      <v-dialog
        v-model="evaluationDialog"
        transition="dialog-top-transition"
        :fullscreen="evalFullscreen"
        scrollable
        width="44em"
      >
        <v-card elevation="0">
          <v-card-text>
            <EvaluationStats
              :evalCollectionPlan="evalCollectionPlan"
              :key="evaluationDialog"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.stop.prevent="evaluationDialog = false"
              color="primary"
            >
              Schließen
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from "vuex"
import EvaluationStats from '@/components/revenue/EvaluationStats'
import { shortenParish } from '@/lib/regex-tools'
import { FETCH_ORGANIZATIONS, } from '@/store/action-types'
import { DEVICE_CLASSES, DEVICE_TYPES } from "@/lib/device-db"
import { loadingStateWrapper } from "@/modules/common/store/tools"
import EvalTableInner from "@/components/evaluation/EvalTableInner"

function updateAggregate (obj, path, value) {
  let target = obj
  for (const p of path) {
    if (!Object.prototype.hasOwnProperty.call(target, p)) {
      target[p] = {}
    }
    target = target[p]
  }
  target.value = (target?.value ?? 0) + value
  target.count = (target?.count ?? 0) + 1
  target.average = target.value / target.count
}

export default {
  name: "eval-deviceslist",
  components: { EvalTableInner, EvaluationStats },
  filters: {
    mapMonth (v) {
      return moment(v).format('MMMM YYYY')
    },
  },
  data () {
    return {
      DEVICE_CLASSES,

      paymentList: [],
      collectionPlans: {},
      deviceNames: {},
      evaluationDialog: false,
      evalCollectionPlan: null,
      evalFullscreen: false,
      rigthNow: moment().format('LLLL'),
      filter: ['month', 'device', 'collection'],
      tableClasses: [{ key: 'month', human: 'Monat' }, { key: 'device', human: 'Geräte' }, { key: 'collection', human: 'Zweck' }],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters("organization", { organization: "currentOrganization" }),
    headline () {
      return this.$route.params.deviceClass ? DEVICE_CLASSES[this.$route.params.deviceClass] : 'Geräte'
    },
    aggregateByDeviceClass () {
      const retval = {}
      for (const payment of this.paymentList) {
        updateAggregate(retval, [DEVICE_TYPES[payment?.device?.type]?.deviceClass ?? 'Unbekannt'], parseFloat(payment.amount))
      }
      return retval
    },
    aggregateByMonth () {
      const retval = {}
      for (const payment of this.paymentList) {
        updateAggregate(retval, [moment(payment.date).format("YYYY-MM"), payment.collection_plan], parseFloat(payment.amount))
      }
      return retval
    },
    aggregateMonthSorted () {
      return Object.entries(this.aggregateByMonth).sort().reverse()
    },
    aggregateByDevice () {
      const retval = {}
      for (const payment of this.paymentList) {
        updateAggregate(retval, [payment.device.url, payment.collection_plan], parseFloat(payment.amount))
      }
      return retval
    },
    aggregateDeviceSorted () {
      return Object.entries(this.aggregateByDevice).sort(
        ([a, x_], [b, y_]) => this.mapDevice(a).localeCompare(this.mapDevice(b))
      )
    },
    aggregateByCollectionPlan () {
      const retval = {}
      for (const payment of this.paymentList) {
        updateAggregate(retval, [payment.collection_plan], parseFloat(payment.amount))
      }
      return retval
    },
    shortOrgaName () {
      return shortenParish(this.organization.name)
    },
    twelveMonthsAgo () {
      return moment().clone().subtract(1, 'year').startOf('month')
    }
  },
  created () {},
  watch: {
    '$route.params.deviceClass': function () {
      this.load()
    },
    organization: function () {
      this.load()
    },
  },
  methods: {
    openEvaluationDialog (eC) {
      // Taken out to fix errors
      // this.evalCollectionPlan = eC
      // this.evaluationDialog = true
    },
    async load () {
      const twelveMonthsAgo = this.twelveMonthsAgo.format('YYYY-MM-DD')
      const thismonth = moment().format('YYYY-MM')
      let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD')
      let endOfMonth = moment(thismonth).clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')
      let monthCounter = 0

      const fullPaymentList = []
      const paymentLoadJobs = []

      // Load payments in parallel
      while (moment(startOfMonth) >= moment(twelveMonthsAgo)) {
        paymentLoadJobs.push(loadingStateWrapper(this.$store, async () => {
          const response = await this.$store.getters.restApi.get(`${this.organization.url}payments/?recursive=false&since=${startOfMonth}&before=${endOfMonth}&tz=Europe/Berlin`)
          if (response.data.length > 0) {
            fullPaymentList.push(...response.data)
          }
        }))

        monthCounter++
        startOfMonth = moment().subtract(monthCounter, 'months').clone().startOf('month').format('YYYY-MM-DD')
        endOfMonth = moment().subtract(monthCounter, 'months').clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')
      }

      await Promise.allSettled(paymentLoadJobs)
      // Make sure to render once before continuing on
      await this.$nextTick()

      this.collectionPlans = {}
      this.paymentList = []
      const relevantPlans = new Set()

      for (const payment of fullPaymentList) {
        const deviceClass = DEVICE_TYPES[payment.device?.type ?? null]?.deviceClass

        if (!this.$route.params.deviceClass || this.$route.params.deviceClass === deviceClass) {
          this.$set(this.deviceNames, payment.device?.url, payment.device?.name)
          this.paymentList.push(payment)
          if (payment.collection_plan) {
            relevantPlans.add(payment.collection_plan)
          }
        }
      }

      await Promise.allSettled(Array.from(relevantPlans).map(planUrl => loadingStateWrapper(
        this.$store,
        async () => {
          const response = await this.$store.getters.restApi.get(`${planUrl}`)
          this.$set(this.collectionPlans, planUrl, response.data)
        }
      )))
    },
    printing () {
      window.print()
    },
  },
  async mounted () {
    await this.$nextTick()
    await Promise.allSettled([
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
    ])
    await this.load()
  },
}
</script>
<style lang="stylus" scoped>
.firstcol
  padding: 0px!important
#eval-donationslist
  #donationstable
    tr
      cursor: pointer
@media all
  .page-break
    display: none
@media print
  .page-break
    display: block
    page-break-before: always
</style>
