export function hsl2Rgb (h, s, l) {
  s = s / 100
  l = l / 100

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c / 2

  let rgb
  if (h >= 0 && h < 60) rgb = [c, x, 0]
  if (h >= 60 && h < 120) rgb = [x, c, 0]
  if (h >= 120 && h < 180) rgb = [0, c, x]
  if (h >= 180 && h < 240) rgb = [0, x, c]
  if (h >= 240 && h < 300) rgb = [x, 0, c]
  if (h >= 300 && h <= 360) rgb = [c, 0, x]

  return rgb.map(function (v) {
    return (255 * (v + m)) | 0
  })
}

export function rgb2Hex (r, g, b) {
  const rgb = b | (g << 8) | (r << 16)
  return "#" + (0x1000000 + rgb).toString(16).slice(1)
}

export function hsl2Hex (h, s, l) {
  const rgb = hsl2Rgb(h, s, l)
  return rgb2Hex(rgb[0], rgb[1], rgb[2])
}

export function hexToHue (H) {
  // Convert hex to RGB first
  let r = 0
  let g = 0
  let b = 0
  if (H.length === 4) {
    r = "0x" + H[1] + H[1]
    g = "0x" + H[2] + H[2]
    b = "0x" + H[3] + H[3]
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2]
    g = "0x" + H[3] + H[4]
    b = "0x" + H[5] + H[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  // eslint-disable-next-line no-unused-vars
  let s = 0
  let l = 0

  if (delta === 0) {
    h = 0
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6
  } else if (cmax === g) {
    h = (b - r) / delta + 2
  } else {
    h = (r - g) / delta + 4
  }
  h = Math.round(h * 60)

  if (h < 0) {
    h += 360
  }
  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  // eslint-disable-next-line no-unused-vars
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  // return "hsl(" + h + "," + s + "%," + l + "%)"
  return h
}
