<template>
  <div id="logo-group" class="pt-2">
    <router-link :to="to.id ? {name: 'organization.dashboard', id: to.id} : {name: 'home'}">
      <logo/>
    </router-link>
  </div>
</template>

<script>
import Logo from '@/components/icons/Logo'

export default {
  name: 'LogoGroup',
  components: {
    Logo
  },
  props: {
    to: null
  }
}
</script>
<style lang="stylus" scoped>
#logo-group
  color: #ffffff
  #logo
    height: 40px
    width: auto
</style>
