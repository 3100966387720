<template>
  <div>
    <v-text-field
      v-model="name"
      :rules="[rules.name]"
      class="mt-4"
      label="Neuer Name"
      placeholder="neuer name"
      type="name"
      autocomplete="new-name"
      @input="inputChanged"
      :loading="name.length < 0"
    >
    </v-text-field>
  </div>
</template>

<script>

export default {
  name: 'NameInput',
  data: function () {
    return {
      name: "",
      rules: {
        name: value => {
          return (value.length < 1) ? 'Bitte geben Sie hier Ihren Namen ein' : ''
        }
      }
    }
  },
  async created () {
  },
  props: {
    value: {
      type: String,
      validator: prop => typeof prop === 'string' || prop === null,
    }
  },
  emits: ['input'],
  computed: {
  },
  methods: {
    inputChanged (event) {
      if (this.name?.length > 0) {
        this.$emit('input', this.name, true)
      } else {
        this.$emit('input', null, false)
      }
    },
    resetInputs () {
      this.name = ""
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        if (newVal === null) {
          this.name = ""
        } else if (typeof newVal === 'string') {
          this.name = newVal
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
