<template>
 <v-container id="news">
   <v-row justify="center">
    <v-col cols="12" md="12" lg="8" >
    <div class="news">
     <h2><v-icon x-large style="margin-bottom: 10px; margin-right: 5px;" color="primary">mdi-sparkles</v-icon>Neuigkeiten</h2>
     <div v-for="(news, index) in newsData.newsData" :key="index">
       <h4 style="color:grey">{{ momentize(news.date) }}</h4>
     <v-card
      v-for="article in news.news"
      :key="article.id"
      style="margin-bottom:1em"
     >
      <v-card-text>
       <v-row>
         <div
          v-for="(tg, index) in article.targets"
          :key="index"
          style="display:flex;margin:1em;margin-right: 0.5em"
         >
        <device-icon size="20" class="mr-2" :device-class="tg"></device-icon>
        {{ targetMap[tg] }}
        </div>
       </v-row>
       <v-card-title style="color: black">
        {{ article.title }}
       </v-card-title>
       <v-card-text><div v-html="article.message"/></v-card-text>
      </v-card-text>
     </v-card>
     </div>
    </div>
   </v-col>
  </v-row>
 </v-container>
</template>

<script>

import DeviceIcon from '../components/icons/DeviceIcon.vue'
import { DEVICE_CLASSES } from "@/lib/device-db"
import moment from "moment"
import { mapState } from 'vuex'

export default {
  name: 'news',
  components: {
    DeviceIcon
  },
  data () {
    return {
      targetMap: {
        ...DEVICE_CLASSES,
        unknown: ''
      }
    }
  },
  methods: {
    momentize (date) {
      return moment(date).locale("de").format('LL')
    }
  },
  computed: {
    ...mapState(["newsData"])
  }
}
</script>
