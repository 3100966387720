<template>
  <v-form ref="changeForm" :value="isFormValid" @submit.prevent="changeName">
    <v-card-text class="mb-0 pb-0" id="auth-container">
      <v-alert
        v-if="error"
        dense
        outlined
        type="error"
        icon="mdi mdi-alert-circle"
        color="red"
      >
        <span v-if="error.length">{{ error }}</span>
        <span v-else>Die Namensänderung war leider nicht möglich.</span>
      </v-alert>
      <name-input ref="nameInput" @input="setNameValidity"></name-input>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="showCancelOption" @click="cancel" elevation="2">
        abbrechen
      </v-btn>
      <v-btn @click="changeName" :disabled="!isFormValid" color="primary" elevation="2" :loading="updating">
        speichern
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>

import NameInput from "@/components/NameInput"
import { FETCH_USER_DATA, UPDATE_CURRENT_USER } from "@/store/action-types"
import { mapState } from "vuex"
import { CACHE_USER_DATA } from "@/store/cache-types"
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'

export default {
  name: "NameForm",
  components: {
    NameInput
  },
  props: {
    showCancelOption: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'cancel-name-change',
    'name-change-succeeded',
  ],
  data () {
    return {
      error: null,
      name: null,
      isFormValid: false,
      updating: false
    }
  },
  computed: {
    ...mapState('user', ['user']),
    shouldPerformNameReset () {
      return this.name?.length > 0 || false
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel-name-change')
    },
    setNameValidity (name, isNameValid) {
      this.name = name
      this.isFormValid = isNameValid
    },
    async changeName () {
      if (!this.shouldPerformNameReset) {
        return
      }
      this.updating = true
      try {
        await this.$store.dispatch('user/' + UPDATE_CURRENT_USER, {
          url: this.user.url,
          name: this.name,
        })
        await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_USER_DATA })
        await this.$store.dispatch('user/' + FETCH_USER_DATA)
        this.$emit('name-change-succeeded')
      } catch (e) {
        this.error = 'Die Änderung ist leider fehlgeschlagen. Bitte versuchen Sie es später noch einmal.'
      } finally {
        this.$refs.nameInput.resetInputs()
        this.updating = false
      }
    }
  }
}
</script>

<style scoped>

</style>
