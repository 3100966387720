<template>
  <v-row>
    <v-col cols="12" sm="6" v-if="accountingData">
      <v-card class="pa-3" outlined>
        <h5>{{ $t('collectionPlan.costCenters') }}</h5>
        <div
          v-for="(accountingValue, accountingField) in accountingData"
          :key="accountingField"
          class="mb-1">
          <template v-if="accountingValue && accountingValue.toString().trim().length && accountingField !== 'bestätigt'">
            <div class="text-caption font-weight-bold">{{ accountingField }}</div>
            <div>{{ accountingValue }}</div>
          </template>
          <template v-if="accountingValue && accountingValue.toString().trim().length && accountingField === 'bestätigt'">
            <div>
              <v-icon small>{{ mdiCheck }}</v-icon>
              {{ $t('collectionPlan.accountingDataVerified') }}
            </div>

          </template>
        </div>
      </v-card>
    </v-col>
    <v-col
      v-if="collection.foreign || (contactInformation && contactInformation.organization)"
      cols="12"
    >
      <v-card outlined class="pa-3">
        <div class="mb-1">
                  <span class="font-weight-bold" v-if="collection.foreign">{{
                      $t('collectionPlan.foreignCollectionHint')
                    }}: </span>
          <span v-if="contactInformation && contactInformation.organization">
                    {{ contactInformation.organization }}
                  </span>
          <span v-else>
                    {{ $t('general.notAvailable') }}
                  </span>
        </div>
      </v-card>
    </v-col>
    <v-col
      v-if="bankingDetails"
      cols="12"
      sm="6"
    >
      <v-card class="pa-3" outlined>
        <h5>{{ $t('banking.bankingDetails') }}</h5>
        <div class="mb-1" v-if="bankingDetails.accountHolderName">
          <div class="text-caption font-weight-bold">{{ $t('banking.accountHolder') }}</div>
          <div>{{ bankingDetails.accountHolderName }}</div>
        </div>
        <div class="mb-1" v-if="bankingDetails.creditInstitution">
          <div class="text-caption font-weight-bold">Kreditinstitut</div>
          <div>{{ bankingDetails.creditInstitution }}</div>
        </div>
        <div class="mb-1" v-if="bankingDetails.iban">
          <div class="text-caption font-weight-bold">IBAN</div>
          <div>{{ bankingDetails.iban | groupCharacters }}</div>
        </div>
        <div class="mb-1" v-if="bankingDetails.bic">
          <div class="text-caption font-weight-bold">BIC</div>
          <div>{{ bankingDetails.bic }}</div>
        </div>
        <div class="mb-1" v-if="bankingDetails.reference">
          <div class="text-caption font-weight-bold">{{ $t('banking.reference') }}</div>
          <div>{{ bankingDetails.reference }}</div>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      v-if="contactInformation"
    >
      <v-card outlined class="pa-3">
        <h5>{{ $t('collectionPlan.contact') }}</h5>
        <div class="mb-1" v-if="contactInformation.contact">
          <div class="text-caption font-weight-bold">
            <v-icon small>mdi-account</v-icon>
          </div>
          <div>{{ contactInformation.contact }}</div>
        </div>
        <div class="mb-1" v-if="contactInformation.organization">
          <div class="text-caption font-weight-bold">
            {{ $t('collectionPlan.organization') }}
          </div>
          <div>{{ contactInformation.organization }}</div>
        </div>
        <div class="mb-1" v-if="contactInformation.phone">
          <div class="text-caption font-weight-bold">
            <v-icon small>mdi-phone</v-icon>
            {{ $t('general.phone') }}
          </div>
          <div>{{ contactInformation.phone }}</div>
        </div>
        <div class="mb-1" v-if="contactInformation.mail">
          <div class="text-caption font-weight-bold">
            <v-icon small>mdi-email</v-icon>
            {{ $t('general.email') }}
          </div>
          <div><a :href="`mailto:${contactInformation.mail}`">{{ contactInformation.mail }}</a></div>
        </div>
        <div
          class="mb-1"
          v-if="contactInformation.street || contactInformation.postalCode || contactInformation.city"
        >
          <div class="text-caption font-weight-bold">
            <v-icon small>mdi-map-marker</v-icon>
            {{ $t('general.address') }}
          </div>
          <div v-if="contactInformation.street">{{ contactInformation.street }}</div>
          <div v-if="contactInformation.postalCode || contactInformation.city">
            <span v-if="contactInformation.postalCode">{{ contactInformation.postalCode }}</span>
            <span v-if="contactInformation.city">{{ contactInformation.city }}</span>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { bankingExtraFields, foreignCollectionExtraFields } from "@/lib/collection-plan-utils"

export default {
  name: 'CollectionPlanDetailsAccountingData',
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    accountingData () {
      return this.collection.meta?.accounting || null
    },
    bankingDetails () {
      const hasRelevantFields = this.checkHasRelevantExternalFields(bankingExtraFields)

      if (!hasRelevantFields) {
        return null
      }

      // eslint-disable-next-line camelcase
      const externalFields = this.collection.meta?.external_fields || null

      return {
        accountHolderName: externalFields?.Kontoinhaber || null,
        creditInstitution: externalFields?.Bank || null,
        iban: externalFields?.IBAN || null,
        bic: externalFields?.BIC || null,
        reference: externalFields?.Verwendungszweck || null
      }
    },
    contactInformation () {
      const hasRelevantFields = this.checkHasRelevantExternalFields(foreignCollectionExtraFields)

      if (!hasRelevantFields) {
        return null
      }

      // eslint-disable-next-line camelcase
      const externalFields = this.collection.meta?.external_fields || null

      return {
        contact: externalFields?.Kontaktperson || null,
        phone: externalFields?.Telefon || null,
        mail: externalFields.Mail || null,
        organization: externalFields?.Organisation || null,
        street: externalFields["Straße"] || null,
        postalCode: externalFields.PLZ || null,
        city: externalFields.Ort || null,
      }
    },
  },
  methods: {
    checkHasRelevantExternalFields (relevantFields) {
      // eslint-disable-next-line camelcase
      const externalFields = this.collection.meta?.external_fields || null

      if (!externalFields) {
        return false
      }

      let hasRelevantFields = false
      relevantFields.forEach((relevantField) => {
        if (relevantField in externalFields) {
          hasRelevantFields = true
        }
      })

      return hasRelevantFields
    },
  }
}
</script>
