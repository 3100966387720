<template>
  <v-card class="eval-card" elevation="0">
    <div v-if="evalCollectionPlanObject">
      <div v-if="!printed">
        <v-card-subtitle v-if="$route.name === 'organization.print'">
          {{ shortParish() }}
        </v-card-subtitle>
        <v-card-title>
          {{ evalCollectionPlanObject.name }}
        </v-card-title>
        <v-card-text v-if="evalMaxDonation === '0.00'">
          <v-icon class="mr-2">mdi-information</v-icon>Für diesen Zweck ist (bisher) keine Spende eingegangen.
        </v-card-text>
        <v-card-text v-else>
          <div v-if="gatheringEvaluation">
            Daten werden geladen...
            <v-progress-linear
              indeterminate
            ></v-progress-linear>
          </div>
          <div v-else>
            <a @click="prnt()" style="float:right"><v-icon>mdi-printer</v-icon></a>
            Spendenzweck
            <span v-if="evalCollectionPlanObject.start">
            im Zeitraum {{ momentize(evalCollectionPlanObject.start) }} - {{ evalCollectionPlanObject.end ? momentize(evalCollectionPlanObject.end) : 'unbegrenzt' }}
          </span>
            <span v-else>
            ungegrenzt
          </span>
            <h4>
              {{ evalDonationCount }} Zahlung{{ evalDonationCount === 1 ? '' : 'en' }} mit einer Summe von {{ evalDonationAmount|amount }} {{ organization.currency|currencySymbol }}
            </h4>
            <div style="display: flex;" class="evaluationBoxContainer mt-2">
              <div class="evaluationBox mr-2">
                <span class="evaluationBoxAmount">{{ evalAverageDonation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')|amount }} {{ organization.currency|currencySymbol }}</span><br>
                Durchschnittliche Spendenhöhe
              </div>
              <div class="evaluationBox mr-2">
                <span class="evaluationBoxAmount">{{ evalMaxDonation|amount }} {{ organization.currency|currencySymbol }}</span><v-spacer />
                Höchste Einzelspende
              </div>
              <div class="evaluationBox  mr-2">
                <span class="evaluationBoxAmount">{{ dayWithHighestDonationAmount.amount|amount }} {{ organization.currency|currencySymbol }}</span><br>
                <div>
                  {{ $t('evaluation.mostDonationsPerDay') }}
                </div>
                <div>
                  {{ $tc('evaluation.atDate', dayWithHighestDonationAmount.hasMultipleDaysWithHighestAmount ? 2 : 1 )}}:
                  {{ dayWithHighestDonationAmount.day }}
                </div>
              </div>
            </div>
            <br>
            <div id="chart-box">
              <div style="max-width: 50%">
                <h4>Bezahlarten</h4>
                <pie-chart :chart-data="chartDataPaymentTypes" :options="options"></pie-chart>
                <p v-for="(index, item) in evalPaymentTypes" :key="item.id">
                  <b :style="{ color: paymentTypeToColor(item) }">{{ paymentTypeToHuman(item) }}:</b> {{ parseFloat(index).toFixed(2)|amount }} {{ organization.currency|currencySymbol }} ({{ evalPaymentTypesCount[item] }} Zahlung{{ evalPaymentTypesCount[item] === 1 ? '' : 'en' }})
                </p>
              </div>
              <div style="max-width: 50%">
                <h4>Geräte</h4>
                <pie-chart :chart-data="chartDataDeviceTypes" :options="options"></pie-chart>
                <p v-for="(amount, item, index) in evalDeviceTypes" :key="item.id">
                  <b :style="{ color: getColorFromPalette(item, index) }">{{ item }}:</b> {{ amount|amount }} {{ organization.currency|currencySymbol }} ({{ evalDeviceTypesCount[item] }} Zahlung{{ evalDeviceTypesCount[item] === 1 ? '' : 'en' }})
                </p>
              </div>
              <!-- <v-col cols="6" xs="12" v-if="false">
                <h4>Einzelspenden</h4>
                <pie-chart :chart-data="chartDataSinglePayments" :options="options"></pie-chart>
              </v-col> -->
            </div>
            <div v-if="evalFeeAmount > 0">
              <h4>Gebühren</h4>
              <single-stacked-bar-chart
                :title="$t('collection.donationsAndFees')"
                :datasets="chartDataDonationsAndFees"
                :label-formatter="currencyAppenderFormatter"
                :styles="{height: '100px'}">
              </single-stacked-bar-chart>
              <div class="d-flex flex-row justify-start">
                <div class="mr-4">
                  <span :style="{ color: colorDonationsMinusFees }"><b>Spenden abzgl. Gebühren:</b></span> {{ evalDonationAmount - evalFeeAmount | amount }}{{ organization.currency|currencySymbol }}
                </div>
                <div>
                  <span :style="{ color: colorFees }"><b>Gebühren:</b></span> {{ evalFeeAmount | amount }}{{ organization.currency|currencySymbol }}
                </div>
              </div>
              <div>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('collection.feeDonationRatioExplanation', { donations: toAmount(evalDonationAmount), fees: toAmount(evalFeeAmount), ratio: donationFeeRatio })}}
                    </span>
                  </template>
                  <span>
                    *{{ $t('collection.feesHint') }}
                  </span>
                </v-tooltip>
              </div>
            </div>
            <div>
              <h4>Einzelspenden</h4>
              <v-data-table
                id="donationstable"
                :headers="headers"
                :items="singleDonations"
                sort-by="date"
                :sort-desc="true"
                item-key="id"
                :items-per-page="isPrintView ? -1 : 10"
              >
                <template #item.date="{ item }">
                  <span>{{ new Date(item.date).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                </template>
                <template #item.amount="{ item }">
                  <span>{{ item.amount | amount }} {{ organization.currency|currencySymbol }}</span>
                </template>
                <template #item.fee_amount="{ item }">
                  <span>{{ item.fee_amount | amount }} {{ organization.currency|currencySymbol }}</span>
                </template>
              </v-data-table>

            </div>
          </div>
        </v-card-text>
      </div>
      <div v-else>
        <a @click="closeWindow()"><b>Fenster schließen und Zurück</b></a>
      </div>
    </div>
  </v-card>
</template>

<script>
import PieChart from '../charts/PieChart.vue'
import SingleStackedBarChart from "@/components/charts/SingleStackedBarChart"
import moment from "moment"
import { mapGetters } from 'vuex'
import {
  FETCH_ORGANIZATION_COLLECTION_PLANS,
  FETCH_ORGANIZATIONS, FETCH_GLOBAL_DEVICES
} from '@/store/action-types'
import { shortenParish } from '@/lib/regex-tools'
import amount from '@/filters/amount'
import { pieChartOptions } from "@/config/chart-options"
import StatsTypeTranslationColorizingMixin from "@/mixins/stats-type-translation-colorizing.mixin"

export default {
  name: "evaluation-stats",
  filters: {
    amount
  },
  mixins: [
    StatsTypeTranslationColorizingMixin
  ],
  components: {
    PieChart,
    SingleStackedBarChart
  },
  data () {
    return {
      tab: null,
      evalMaxDonation: 0,
      evalAverageDonation: 0,
      evalDonationAmountPerDay: 0,
      evalDonationCount: 0,
      evalDonationAmount: 0,
      evalFeeAmount: 0,
      gatheringEvaluation: false,
      evalPaymentTypes: null,
      evalDeviceTypes: null,
      evalPaymentTypesCount: null,
      evalDeviceTypesCount: null,
      singleDonations: [],
      options: pieChartOptions,
      printed: false,
      started: false,
      colorDonationsMinusFees: '#078F6F',
      colorFees: '#7F7F7F'
    }
  },
  props: {
    evalCollectionPlan: {
      required: false,
      default: null
    },
    isPrintView: {
      required: false,
      default: false
    }
  },
  created () {
    if (this.$route.name === 'organization.print') {
      this.options.animation = {
        duration: 0
      }
      this.options.responsiveAnimationDuration = 0 // animation duration after a resize
    }
  },
  computed: {
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapGetters('collectionPlan', ['collectionPlansLookup', 'collectionPlanById', { collectionPlans: 'currentCollectionPlans' }]),
    ...mapGetters('device', ['devicesLookup']),
    headers () {
      const headers = [
        { text: "Zeitpunkt", value: "date" },
        { text: "Zahlungsmittel", value: "payment_type_human" },
        { text: "Bruttospende", value: "amount" },
      ]
      if (this.evalFeeAmount > 0) {
        headers.push({ text: "Gebühr", value: "fee_amount" })
      }

      return headers
    },
    evalCollectionPlanObject () {
      if (!this.collectionPlansLookup) {
        return {}
      }
      return this.evalCollectionPlan ? this.collectionPlansLookup[this.evalCollectionPlan] : this.$route.params.collection ? this.collectionPlanById[this.$route.params.collection] : {}
    },
    chartDataSinglePayments () {
      const chartLabels = []
      const chartColors = []
      const chartData = []

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.singleDonations)) {
        chartLabels.push(value.type === 'unset' ? value.online_payment_type_human : value.type_human)
        // chartColors.push(this.paymentTypeToColor(value.type === 'unset' ? value.online_payment_type : value.type))
        chartColors.push('#554193')
        chartData.push(parseFloat(value.amount).toFixed(2))
      }
      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: chartColors,
            data: chartData
          }
        ]
      }
    },
    chartDataPaymentTypes () {
      const chartLabels = []
      const chartColors = []
      const chartData = []

      if (this.evalPaymentTypes) {
        for (const [key, value] of Object.entries(this.evalPaymentTypes)) {
          chartLabels.push(this.paymentTypeToHuman(key))
          chartColors.push(this.paymentTypeToColor(key))
          chartData.push(parseFloat(value).toFixed(2))
        }
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: chartColors,
            data: chartData
          }
        ]
      }
    },
    chartDataDeviceTypes () {
      const chartLabels = []
      const chartColors = []
      const chartData = []

      if (this.evalDeviceTypes) {
        for (const [key, value] of Object.entries(this.evalDeviceTypes)) {
          chartLabels.push(key)

          const color = this.getColorFromPalette(key, chartColors.length)
          chartColors.push(color)

          chartData.push(parseFloat(value).toFixed(2))
        }
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: chartColors,
            data: chartData
          }
        ]
      }
    },
    donationFeeRatio () {
      if (this.evalFeeAmount <= 0) {
        return 0
      }

      return this.toAmount((this.evalFeeAmount / this.evalDonationAmount) * 100)
    },
    chartDataDonationsAndFees () {
      const datasets = [{
        label: this.$t('collection.donationsMinusFees'),
        data: [this.evalDonationAmount - this.evalFeeAmount],
        barThickness: 30,
        backgroundColor: [
          this.colorDonationsMinusFees
        ]
      }]

      if (this.evalFeeAmount > 0) {
        datasets.push({
          label: this.$tc('collection.fees', 2),
          data: [this.evalFeeAmount],
          barThickness: 30,
          backgroundColor: [
            this.colorFees
          ]
        })
      }

      return datasets
    },
    dayWithHighestDonationAmount () {
      const donationsPerDay = {}

      this.singleDonations.forEach(donation => {
        if (!(donation.date in donationsPerDay)) {
          donationsPerDay[donation.date] = 0
        }

        donationsPerDay[donation.date] += parseFloat(donation.amount)
      })

      const amountsPerDay = Object.entries(donationsPerDay).sort(([day1, amount1], [day2, amount2]) => {
        const difference = amount2 - amount1

        if (difference === 0) {
          return day1 < day2
        }

        return difference
      }).map(([day, amount]) => {
        return {
          day,
          amount
        }
      })

      let hasMultipleDaysWithHighestAmount = false
      if (amountsPerDay.length > 1) {
        hasMultipleDaysWithHighestAmount = amountsPerDay[0].amount === amountsPerDay[1].amount
      }

      return {
        day: moment(amountsPerDay[0].day).format("DD.MM.YYYY"),
        amount: amountsPerDay[0].amount,
        hasMultipleDaysWithHighestAmount
      }
    }
  },
  async mounted () {
    if (this.$route.params.collection && this.$route.params.id) {
      await this.$nextTick()
      await Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES)
      ])
    }
  },
  watch: {
    organization: {
      immediate: true,
      handler (newValue) {
        if (newValue !== null) {
          Promise.allSettled([
            this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, newValue.url)
          ])
        }
      }
    },
    evalCollectionPlanObject: {
      immediate: true,
      handler (newValue) {
        if (newValue !== null && newValue !== undefined && !this.started) {
          if (Object.keys(newValue).length > 0) {
            this.started = true
            // FIXME This is called on opening and closing and does a slow fetch from the server.
            // Fix the fetching. And everything else.
            this.openEvaluationDialog(newValue)
          }
        }
      }
    }
  },
  methods: {
    shortParish () {
      return shortenParish(this.organization.name)
    },
    async prnt () {
      const routeData = this.$router.resolve({ name: 'organization.print', params: { collection: this.evalCollectionPlanObject.id } })
      window.open(routeData.href, '_blank')
    },
    closeWindow () {
      window.close()
    },
    momentize (momDate) {
      return moment(momDate).format('DD.MM.YYYY')
    },
    getDeviceByUrl (deviceUrl) {
      return this.devicesLookup[deviceUrl] ?? []
    },
    async openEvaluationDialog (collectionPlanObject) {
      this.evaluationDialog = true

      this.maxDonation = 0

      let donCount = 0
      let maxDonationCount = 0
      let donAmount = 0
      let feeAmount = 0

      const paymentTypes = {}
      const paymentTypesCount = {}
      const deviceTypes = {}
      const deviceTypesCount = {}

      const endDate = collectionPlanObject ? moment().isAfter(moment(collectionPlanObject.end)) ? moment(collectionPlanObject.end) : moment() : moment()

      const thisMonth = endDate.format('YYYY-MM')
      let monthCounter = 0

      let startOfMonth = moment(thisMonth).clone().startOf('month').format('YYYY-MM-DD')
      let endOfMonth = moment(thisMonth).clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')

      this.gatheringEvaluation = true

      const self = this
      const donationList = []

      const paymentToHuman = this.paymentTypeToHuman

      while (moment(endOfMonth) > moment(collectionPlanObject.start)) {
        const orgaUrl = this.organization.url
        const response = await this.$store.getters.restApi.get(`${orgaUrl}payments/?recursive=false&since=${startOfMonth}&before=${endOfMonth}&tz=Europe/Berlin`)
        response.data.forEach(function (entry) {
          if (entry.collection_plan === collectionPlanObject.url) {
            entry.online_payment_type_human = paymentToHuman(entry.online_payment_type)
            entry.type_human = paymentToHuman(entry.type)
            entry.payment_type_human = entry.type === 'unset' ? entry.online_payment_type_human : entry.type_human
            donationList.push(entry)

            donCount++
            maxDonationCount = Math.max(entry.amount, maxDonationCount)
            donAmount += parseFloat(entry.amount)

            if (entry.fee_amount && entry.is_reconciled) {
              feeAmount += parseFloat(entry.fee_amount)
            }

            const pType = entry.type === 'unset' ? entry.online_payment_type : entry.type
            paymentTypes[pType] = paymentTypes[pType] ? paymentTypes[pType] + parseFloat(entry.amount) : parseFloat(entry.amount)
            paymentTypesCount[pType] = paymentTypesCount[pType] ? paymentTypesCount[pType] + 1 : 1

            const dType = self.getDeviceByUrl(entry.device.url).device_class === 'SP' || self.getDeviceByUrl(entry.device.url).device_class === 'KB' ? self.deviceTypeToHuman(self.getDeviceByUrl(entry.device.url).type) + ' ' + self.getDeviceByUrl(entry.device.url).name : self.deviceTypeToHuman(self.getDeviceByUrl(entry.device.url).type)
            deviceTypes[dType] = deviceTypes[dType] ? deviceTypes[dType] + parseFloat(entry.amount) : parseFloat(entry.amount)
            deviceTypesCount[dType] = deviceTypesCount[dType] ? deviceTypesCount[dType] + 1 : 1
          }
        })
        monthCounter++
        startOfMonth = moment(thisMonth).subtract(monthCounter, 'months').clone().startOf('month').format('YYYY-MM-DD')
        endOfMonth = moment(thisMonth).subtract(monthCounter, 'months').clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')
      }

      this.singleDonations = donationList
      this.evalMaxDonation = parseFloat(maxDonationCount).toFixed(2)
      this.evalAverageDonation = parseFloat(donAmount / donCount).toFixed(2)
      this.evalDonationCount = donCount
      this.evalDonationAmount = parseFloat(donAmount).toFixed(2)
      this.evalFeeAmount = parseFloat(feeAmount).toFixed(2)
      if (collectionPlanObject.start) {
        this.evalDonationAmountPerDay = parseFloat(donAmount / Math.max(1, parseInt(moment.duration(endDate.diff(moment(collectionPlanObject.start))).asDays()))).toFixed(2)
      }
      this.evalPaymentTypes = paymentTypes
      this.evalPaymentTypesCount = paymentTypesCount
      this.evalDeviceTypes = deviceTypes
      this.evalDeviceTypesCount = deviceTypesCount

      this.gatheringEvaluation = false

      this.$nextTick(() => {
        if (!this.printed && this.$route.name === 'organization.print') {
          window.addEventListener('afterprint', (event) => {
            this.printed = true
            window.scrollTo(0, 0)
            window.close()
          })
          window.print()
        }
      })
    },
    currencyAppenderFormatter (value, ctx) {
      const currencySymbol = this.$options.filters.currencySymbol(this.organization.currency)
      return value + ' ' + currencySymbol
    },
    toAmount (value) {
      return amount(value)
    }
  }
}
</script>

<style lang="stylus">
.evaluationBox
  border:1px solid black
  text-align:center
  width: 15em
  padding: 0.5em

  .evaluationBoxAmount
    font-size: x-large
    font-weight: bold

#chart-box
  display: flex
  width: 45em
  justify-content: space-between

#donationstable
  @media print
    font-family: Arial, Verdana, Helvetica, sans-serif

</style>
