<template>
  <v-app>
    <header-area
      :to="{ name: 'zube-index' }"
      title="spendenbescheinigung.digital"
      :top-menu-items="[]"
      :user-dropdown-items="[]"
      :orgcrumbs="[]"
      id="head-area"
    ></header-area>
    <div>spendenbescheinigung.digital funktioniert</div>
    <router-view></router-view>
  </v-app>
</template>

<script>
import HeaderArea from 'components/HeaderArea.vue'
export default {
  name: "ZubeWrapper",
  components: {
    HeaderArea
  }
}
</script>

<style scoped>

</style>
