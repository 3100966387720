<template>
  <span>
    <span class="svg-icon" :id="'icon-' + (icon ? icon : 'notfound')" :class="{ inverted: invertColorWhite }">
        <template v-if="icon === 'evaluation'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M510.16,1.09H167.08c-19.48,0-35.33,15.44-35.33,34.43V547.79c0,19,15.85,34.42,35.33,34.42H510.16c19.48,0,35.32-15.44,35.32-34.42V35.52C545.48,16.53,529.64,1.09,510.16,1.09ZM491.48,528.21H185.75V55.09H491.48Z"/>
              <rect class="cls-1" x="218.08" y="170.65" width="241.08" height="27"/>
              <rect class="cls-1" x="218.08" y="237.02" width="241.08" height="27"/>
              <polygon class="cls-1" points="297.64 411.07 278.55 391.98 253.09 417.44 227.63 391.98 208.54 411.07 234 436.53 208.54 461.99 227.63 481.08 253.09 455.62 278.55 481.08 297.64 461.99 272.18 436.53 297.64 411.07"/>
              <rect class="cls-1" x="218.08" y="303.38" width="241.08" height="27"/>
              <rect class="cls-1" x="337.13" y="448.65" width="122.03" height="27"/>
              <rect class="cls-1" x="218.08" y="104.28" width="241.08" height="27"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'planning'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M637.11,85.22H562.3a90.94,90.94,0,0,0-181.52,0H307.06a90.94,90.94,0,0,0-181.42,0H41.4C14.22,85.22,0,101.92,0,118.41V549c0,16.5,14.22,33.19,41.4,33.19H637.11c27.18,0,41.4-16.69,41.4-33.19V118.41C678.51,101.92,664.29,85.22,637.11,85.22ZM471.54,53.9a37.1,37.1,0,0,1,36.6,31.32h-73.2A37.1,37.1,0,0,1,471.54,53.9Zm-255.19.74a37.09,37.09,0,0,1,36.47,30.58H179.88A37.09,37.09,0,0,1,216.35,54.64ZM624.61,528.32H53.9V139.12h84.91a91.21,91.21,0,0,0,58.52,41.5V139.74h96.19c.13-.2.25-.42.38-.62H394.44a91.09,91.09,0,0,0,67.29,42.24V139.12H624.61Z"/>
              <rect class="cls-2" x="133.11" y="249.84" width="72.6" height="72.6" rx="1.42"/>
              <rect class="cls-2" x="302.85" y="249.84" width="72.6" height="72.6" rx="1.42"/>
              <rect class="cls-2" x="472.59" y="249.84" width="72.6" height="72.6" rx="1.42"/>
              <rect class="cls-2" x="133.11" y="388.69" width="72.6" height="72.6" rx="1.42"/>
              <rect class="cls-2" x="302.85" y="388.69" width="72.6" height="72.6" rx="1.42"/>
              <rect class="cls-2" x="472.59" y="388.69" width="72.6" height="72.6" rx="1.42"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'income'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M591.18,283.55h0C550.06,234.6,515.55,198.26,488.11,175c3.86-16.85-2.07-32.58-15.68-42.54l2.15-3.07c28.61-41.21,39.15-69.23,34.17-90.85-4.53-19.61-28.23-34.59-43.18-36.69-27.68-3.94-78.32,8.88-124.21,21.92L337,25c-6.55-1.11-21.09-4.57-33.08-7.41-30.64-7.28-65.36-15.52-86.15-16-.34,0-34.09-.34-48.12,19.35-11.4,16-3.49,33.93.31,42.56.26.6.5,1.13.72,1.67,6,14.55,21.11,39.44,35.43,61.71-12,6.17-17.37,15.46-19.7,23.2a36.2,36.2,0,0,0-.3,20.46C140.52,204.09,101.57,254.83,87,273.87l-3,3.9c-7.19,9.1-10,12.78-11.21,14.51h0c-29.54,42.29-41.05,77.16-41.05,124.36,0,44.71,12.35,78.74,37.78,104,43.32,43.1,123.41,61.54,267.56,61.54h2.72c110.83-.2,184.85-12,233-37.23,50.48-26.44,75-68.43,75-128.35C647.71,359.42,612.29,308.7,591.18,283.55Zm-300-212.86c35,8.3,44.5,10.4,53.65,8.63,2.1-.4,4.28-1,11.55-3.07,11.55-3.29,33.05-9.4,54.26-14.11,24.23-5.39,36.83-6.45,42.92-6.48-2.52,7.28-9.11,21.5-26.24,45.73-5.45,7.72-10.95,15-15.78,21.17-17.54-.43-41.48-.78-74.46-1.07-24.84-.22-49.61-.33-69.3-.39-14.3-21.43-30.6-46.85-40-63.84C245.16,59.76,271.12,65.92,291.19,70.69Zm48.46,456.92c-126.47.15-200-14.29-231.48-45.59-15-14.93-21.7-35.1-21.7-65.39,0-35.63,8.11-59.95,30.94-92.74,1.37-1.79,7.31-9.44,9.65-12.4l.1-.13c.9-1.15,2-2.6,3.33-4.32,40.35-52.58,75.95-87.6,105.94-104.24l201.68,2.33c16.89,12,50.6,41.52,111,113.47,27.8,33.12,43.75,68.85,43.75,98C592.91,472.55,573.35,527.19,339.65,527.61Z"/>
              <path class="cls-1" d="M406.63,431.88c-9.66,5.73-34.16,16-53.56,16.92-28.05,1.32-53.17-6.88-69.74-22.58a61.89,61.89,0,0,1-19.05-30l-18.39.87-1-14.11,16-.76-.25-3.7c-.19-2.74-.11-5.5,0-8.15l-16.54.78-1-14.11,19.4-.92a65.32,65.32,0,0,1,19.23-34.34c14.78-14.38,35.91-23.71,61.77-24.93,18-.85,33.65,2,44.09,5.57l-5.7,21.6a93.84,93.84,0,0,0-34.71-4.92c-15.42.73-28.63,5.91-37.78,15.23-5.69,5.22-9.28,12.56-11.1,20.1l80.39-3.8,1,14.11-84.33,4a53.75,53.75,0,0,0,0,8l.26,3.83,84.84-4,1,14.11-81.24,3.84a39.6,39.6,0,0,0,13.86,19.93c10.86,8.21,25.52,11.72,41.51,11,15.12-.71,30.56-7.44,37-10.81Z"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'organization'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M674.26,211A26.84,26.84,0,0,1,648,231H30.52A26.84,26.84,0,0,1,4.25,211a26,26,0,0,1,12.44-29.06L325.43,4.76a27.89,27.89,0,0,1,27.65,0L661.82,181.89A26,26,0,0,1,674.26,211ZM227.39,269.6c-5-7-23.94-6-31.92-6s-26.87-1-31.92,6V489c5,7,23.94,6.44,31.92,6.44s26.28-.23,31.92-6.44ZM674.73,522.69h-283V312.61c-8.3-7.28-39.36-6.25-52.49-6.25s-44.18-1-52.48,6.25V522.69H3.77c-2.08,0-3.77,1.4-3.77,3.13v53.27c0,1.73,1.69,3.12,3.77,3.12h671c2.09,0,3.78-1.39,3.78-3.12V525.82C678.51,524.09,676.82,522.69,674.73,522.69ZM530.4,269.6c-5-7-23.94-6-31.92-6s-26.87-1-31.92,6V489c5,7,23.94,6.44,31.92,6.44s26.28-.23,31.92-6.44ZM85,269.6c-5.05-7-23.94-6-31.92-6s-26.87-1-31.92,6V489c5,7,23.94,6.44,31.92,6.44S79.37,495.16,85,489Zm572.33,0c-5.05-7-23.94-6-31.92-6s-26.87-1-31.92,6V489c5,7,23.94,6.44,31.92,6.44s26.27-.23,31.92-6.44Z"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'profile'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M499,161.43A160.34,160.34,0,1,1,338.62,1.09,160.34,160.34,0,0,1,499,161.43ZM338.62,353.66c-159.5,0-288.79,40.06-288.79,89.47V582.21H627.41V443.13C627.41,393.72,498.11,353.66,338.62,353.66Z"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'device'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M27.65,370.47A27.74,27.74,0,0,1,1.59,351.9a28.37,28.37,0,0,1,1.05-21.32,28,28,0,0,1,15.67-14.35L636.8,92.15a27.55,27.55,0,0,1,21,1,28,28,0,0,1,14.38,15.83l0,.14a28,28,0,0,1-1.07,21.37,27.6,27.6,0,0,1-15.65,14.3l-618.21,224-4.47,1.74ZM678.14,215.36a30,30,0,0,0-12.88-23.86A29.29,29.29,0,0,0,638.57,188L400.15,274.45a29.78,29.78,0,0,0-18.1,37.47,482.6,482.6,0,0,0,34.25,79.73c16.95,31.36,27.5,46.27,45.12,63.82,6.26,6.23,40,37.31,89.75,37.31A120.93,120.93,0,0,0,569,491.46c19.6-2.91,37.11-11.74,52-26.25,14.07-13.68,25.69-32.48,34.54-55.87C672.74,363.87,680.34,298.61,678.14,215.36Z"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'logout'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 680 583.49">
            <g>
              <path class="cls-1" d="M608.86,443.79v104c0,19.3-16.8,34.42-38.25,34.42h-464c-21.45,0-38.25-15.12-38.25-34.42V35.52c0-19.31,16.8-34.43,38.25-34.43h464c21.45,0,38.25,15.12,38.25,34.43v104h-54V55.09H122.38V528.21H554.86V443.79ZM559.24,279.86c-.5-.53-50.57-53.7-116.11-121.37l-38.78,37.57c24.85,25.66,47.46,49.22,66,68.59H188.55v54H475.18L406,378.92l35.47,40.71,137-119.34Z"/>
            </g>
          </svg>
        </template>
        <template v-if="icon === 'menu'">
          <svg style="" xmlns="http://www.w3.org/2000/svg" :width="(10 * scaleFactor) + 'mm'" viewBox="0 0 825 583.49">
            <g>
              <path class="cls-1" d="M126.54,158a158,158,0,1,1,158,158A157.95,157.95,0,0,1,126.54,158Zm158,189.37C127.37,347.32,0,386.78,0,435.46v137H569v-137C569,386.78,441.61,347.32,284.49,347.32ZM791.71,462.07H589v110.4H791.71a14.93,14.93,0,0,0,14.92-14.92V477A14.93,14.93,0,0,0,791.71,462.07Zm0-230H446.32a178.47,178.47,0,0,1-92.56,89.88q-7.06,3-14.33,5.34v.26a13.37,13.37,0,0,0,.08,1.42c38,2.25,74.07,6.82,106.41,13.5H791.71a14.93,14.93,0,0,0,14.92-14.92V247A14.93,14.93,0,0,0,791.71,232Zm0-230H370.24a177.9,177.9,0,0,1,86.31,110.4H791.71a14.93,14.93,0,0,0,14.92-14.92V16.91A14.93,14.93,0,0,0,791.71,2Z"/>
            </g>
          </svg>
        </template>
    </span>
    <template v-if="icon === 'news'">
      <v-icon scale="0.6" class="mr-2" style="margin-top: -13px;" color="var(--clr-environment)">{{ mdiShimmer }}</v-icon>
    </template>
    <template v-if="icon === 'dashboard'">
      <v-icon color="var(--clr-environment)" :size="2*24">{{ mdiViewDashboardVariantOutline }}</v-icon>
    </template>
  </span>
</template>

<script>
import { mdiViewDashboardVariantOutline, mdiShimmer } from '@mdi/js'

export default {
  name: 'MenuIcons',
  props: {
    icon: null,
    scale: null,
    invertColorWhite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      mdiViewDashboardVariantOutline,
      mdiShimmer,
    }
  },
  computed: {
    scaleFactor () {
      if (!this.scale) {
        return 1.0
      }
      return (this.scale === null ? 1.0 : this.scale)
    }
  },
  methods: {}
}
</script>
<style lang="stylus">
.svg-icon
  .cls-1,.cls-2
    fill: var(--clr-environment)
    stroke: var(--clr-environment)
    stroke-miterlimit: 10

.svg-icon.inverted
  .cls-1,.cls-2
    fill: #ffffff
    stroke: #ffffff

</style>
