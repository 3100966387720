import { FETCH_STATIC_CONFIGURATION } from '@/store/action-types'
import { cachedActionWrapper } from '@/modules/common/store/tools'
import { CACHE_STATIC_CONFIGURATION } from '@/store/cache-types'
import { SET_STATIC_CONFIGURATION } from '@/store/mutation-types'
import config from '@/config'
import axios from 'axios'

export default {
  namespaced: true,
  state: function () {
    return {
      enumsByName: {},
      permissionParts: {},
      permissionsMap: {},
    }
  },
  getters: {
    permissionsOrdered: (state) => {
      // return ordered list of permissions
      // permissions that contain/imply other permissions will be returned first in the list
      const retval = [...Object.keys(state.permissionParts)]
      retval.sort((a, b) => {
        if (a === b) {
          return 0
        }
        if ([...state.permissionParts[a]].every(item => state.permissionParts[b].has(item))) {
          return -1
        } else if ([...state.permissionParts[b]].every(item => state.permissionParts[a].has(item))) {
          return 1
        }
        return 0
      })
      retval.reverse()
      return retval
    }
  },
  actions: {
    [FETCH_STATIC_CONFIGURATION]: async (context) => {
      return cachedActionWrapper(context, CACHE_STATIC_CONFIGURATION, async () => {
        // This can, and should, be fetched without authentication
        const fetcher = axios.create({ baseURL: config.backend.rest })
        const response = await fetcher.get('configuration/static')
        await context.commit(SET_STATIC_CONFIGURATION, { data: response.data })
      }, async () => {
        await context.commit(SET_STATIC_CONFIGURATION, { data: {} })
      })
    },
  },
  mutations: {
    [SET_STATIC_CONFIGURATION]: (state, { data }) => {
      state.enumsByName = (data?.enums ?? []).reduce((acc, cur) => {
        acc[cur.name] = cur.values
        return acc
      }, {})
      // eslint-disable-next-line camelcase
      state.permissionParts = Object.entries(data?.permission_parts ?? {}).reduce((acc, [key, value]) => {
        acc[key] = new Set(value)
        return acc
      }, {})
      // eslint-disable-next-line camelcase
      state.permissionsMap = Object.entries(data?.permissions_map ?? {}).reduce((acc, [key, value]) => {
        acc[key] = {
          ancestors: new Set(value?.ancestors ?? []),
          own: new Set(value?.own ?? []),
          descendants: new Set(value?.descendants ?? []),
        }
        return acc
      }, {})
    },
  }
}
