<template>
  <sweet-modal ref="wifiListModal" title="Vom Gerät empfangene WLAN-Netze" @close="$emit('close')">
    <template v-if="wifiList.length === 0">
      Das Spendengerät sucht jetzt nach verfügbaren WLANs.<br>
      Das kann bis zu einer Minute dauern.
      <div class="wifiListSpinner">
        <ring-loader color="var(--clr-environment, #554193)" :size="100"></ring-loader>
      </div>

      <!-- <bunt-progress-circular :center="true"/> -->
    </template>
    <table v-else>
      <tr v-for="wifi in wifiList" v-bind:key="wifi.bssid">
        <td>
          <meter min="0" max="65" low="20" high="40" optimum="50" v-bind:value="wifi.wifiStrength"></meter>
        </td>
        <td><span class="mdi mdi-lock" v-if="wifi.flags.includes('[WPA')"></span></td>
        <td>{{ wifi.ssid }}</td>
      </tr>
    </table>
  </sweet-modal>
</template>

<script>
import { RingLoader } from '@saeris/vue-spinners'

export default {
  name: 'DeviceWifiList',
  components: {
    RingLoader,
  },
  props: {
    wifiList: Array,
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.$refs.wifiListModal.open()
          } else {
            this.$refs.wifiListModal.close()
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
