<template>
  <div id="location-admin">
    <div id="location-body" v-if="!Object.keys(organizations).length || !locations.length" class="content">
      <v-progress-circular indeterminate :size="140"/>
    </div>
    <div id="location-body" v-else class="content">
      <div class="location-card" v-if="newLoc !== false">
        <v-text-field v-model="newLocationName" label="Name"/>
        <v-select v-if="specificOrganization === null" label="Organisation" :items="Object.values(organizations)" item-text="name" item-value="url" v-model="newLocationOrganization"></v-select>
        <v-btn @click="createLoc" :loading="creating">Speichern</v-btn>
      </div>
      <div class="location-card new-location" v-else @click="newLoc = true">
        +
      </div>
      <div class="location-card" v-for="location in filteredLocations" @click="activeLocation === location ? activeLocation = null : activeLocation = location" v-bind:key="location.id">
        <div class="location-information">
          <div class="location-name">{{ location.name }}</div>
          <div class="location-org">{{ (organizations && organizations[location.organization]) ? organizations[location.organization].name : "" }}</div>
          <div class="location-indicator">
            <span v-if="location === activeLocation" class="mdi mdi-chevron-up"></span>
            <span v-else class="mdi mdi-chevron-down"></span>
          </div>
          <div class="location-qrcode" v-if="location === activeLocation" v-html="qrCodes[location.url]">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_LOCATION, FETCH_LOCATIONS, FETCH_ORGANIZATIONS, FETCH_QR_CODE } from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'location-admin',
  components: {
  },
  data () {
    return {
      activeLocation: null,
      newLocationName: null,
      newLocationOrganization: null,
      creating: false,
      error: null,
      newLoc: false
    }
  },
  props: {
    specificOrganization: {
      required: false,
      default: null
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organizations: 'allOrganizations' }),
    ...mapState('location', ['locations', 'qrCodes']),
    filteredLocations () {
      if (this.specificOrganization) {
        return this.locations.filter(loc => loc.organization === this.specificOrganization)
      }
      return this.locations
    }
  },
  watch: {
    activeLocation (newValue) {
      if (newValue) {
        this.$store.dispatch('location/' + FETCH_QR_CODE, newValue.url)
      }
    }
  },
  created () {
  },
  methods: {
    async createLoc () {
      this.creating = true
      try {
        await this.$store.dispatch('location/' + ADD_LOCATION, {
          name: this.newLocationName,
          organization: this.specificOrganization ?? this.newLocationOrganization
        })
      } finally {
        this.creating = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('location/' + FETCH_LOCATIONS)
      ])
    })
  }
}
</script>
<style lang="stylus">
#location-admin
  .location-card
    card()
    display: flex
    margin-bottom: 18px
    cursor: pointer
    padding: 16px
    flex-direction: column;
    &.new-location
      text-align: center
      font-size: 30px

    .location-information
      display: flex
      flex-direction: column
      padding: 8px;
      width: 100%

      .location-name
        font-weight: bold
        color: $clr-primary
      .collection-org
        color: $clr-secondary-text-light
    .location-indicator
      position: absolute
      margin-right: 48px;
      margin-top: 6px;
      font-size: 2rem;
      right: 0

</style>
