<template>
  <v-container fluid>
    <template v-if="currentOrganization">
      <v-row v-if="hasMultipleOrgs || parents.length > 1">
        <v-breadcrumbs :items="organizationBreadcrumbs" class="pt-1 pb-1 pl-2">
          <template #divider><v-icon>{{ mdiChevronRight }}</v-icon></template>
          <template #item="{ item }">
            <router-link
              :to="item.to"
              v-if="item.icon"
            >
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </router-link>
            <v-breadcrumbs-item
              v-else
              :to="item.to"
              :disabled="item.disabled"
            >{{ item.text }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>
      <router-view></router-view>
    </template>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { FETCH_ORGANIZATIONS, FETCH_STATIC_CONFIGURATION } from '@/store/action-types'
import { shortenParish } from '@/lib/regex-tools'
import { mdiChevronRight, mdiHome } from '@mdi/js'
import { PERM_TYPE_INTERACT_GENERAL } from '@/lib/permission-tools'

export default {
  name: 'organization-wrapper',
  components: {
  },
  data () {
    return {
      mdiChevronRight,
      mdiHome,
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', ['allOrganizations', 'orgtree', 'currentOrganization']),
    ...mapGetters('user', ['hasPermission', 'hasMultipleOrgs', 'isSuperuser']),
    parents () {
      const retval = []
      if (this.currentOrganization) {
        retval.push(this.currentOrganization.url)
        while (this.allOrganizations[retval[0]]?.parent) {
          retval.unshift(this.allOrganizations[retval[0]]?.parent)
        }
      }
      return retval
    },
    organizationBreadcrumbs () {
      return this.parents.map(item => this.allOrganizations[item]).map(organization => {
        return {
          text: shortenParish(organization.name),
          to: this.hasPermission(organization.url, PERM_TYPE_INTERACT_GENERAL)
            ? {
                name: 'organization.dashboard',
                params: { id: organization.id }
              }
            : null,
          disabled: organization.id === this.currentOrganization.id,
        }
      })
    }
  },
  created () {

  },
  async mounted () {
    await this.$nextTick()
    await Promise.allSettled([
      this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
    ])
  },
  methods: {
  },
}
</script>

<style lang="stylus">
</style>
