<template>
  <v-form>
    <h3 id="general-settings-anchor">
      <template v-if="ddbEntry.hardware">Geräte-Einstellungen</template>
      <template v-else-if="ddbEntry.webApp">App-Einstellungen</template>
    </h3>
    <single-setting
      v-for="s in Object.values(consolidatedValues)"
      :key="s.key"
      v-model="localValues[s.key]"
      :setting="consolidatedValues[s.key]"
      :all-settings="consolidatedValues"
      @clear="clearSingle(s.key)"
      @set-here="setHere(s.key)"
      @upload="uploadFile"
      :device-class="ddbEntry.deviceClass"
    />
    <template v-if="ddbEntry.hardware">
      <!-- FIXME When changing location here also update the settings stack above -->
      <h3 id="change-location-anchor">Ort zuweisen</h3>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p>Indem Sie das Gerät einem Ort zuweisen, übernimmt es die Sammlungspläne des Ortes und der
                übergeordneten Organisationen, sowie ggbf. weitere dort definierte Einstellungen.
                Wenn Sie den Ort ändern, wird das Gerät einen Kassenschnitt im Kontext des bisherigen Ortes durchführen
                und dann die Einstellungen für den neuen Ort übernehmen.
              </p>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="locationSelect"
                :items="locationItems"
                :prepend-icon="mdiCrosshairsGps"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-form>
</template>

<script>
import { settingsForDeviceType } from '@/lib/device-db'
import SingleSetting from '@/components/device/SingleSetting'
import {
  FETCH_CONTENTIMAGES,
  FETCH_LOCATIONS,
  FETCH_ORGANIZATIONS,
  PATCH_DEVICE,
  PATCH_ORGANIZATION
} from '@/store/action-types'
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'
import { mapGetters, mapState } from 'vuex'
import { shortenParish } from '@/lib/regex-tools'
import { mdiCrosshairsGps } from '@mdi/js'
import { erectObj } from '@/lib/settings-tools'
import { CACHE_CONTENTIMAGES } from '@/store/cache-types'

export default {
  name: 'DeviceSettings',
  components: { SingleSetting },
  props: {
    ddbEntry: Object,
    device: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  async mounted () {
    const promises = []
    if (this.ddbEntry?.hardware) {
      promises.push(
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('location/' + FETCH_LOCATIONS),
      )
    }
  },
  data: function () {
    return {
      mdiCrosshairsGps,
      localValues: {},
      // eslint-disable-next-line camelcase
      locationSelect: this.device?.current_location
    }
  },
  methods: {
    reset () {
      if (this?.device?.url) {
        for (const item of this.availableSettings) {
          this.$set(this.localValues, item.key, undefined)
        }
      }
    },
    clearSingle (itemKey) {
      this.$set(this.localValues, itemKey, null)
    },
    setHere (itemKey) {
      this.$set(this.localValues, itemKey, this.consolidatedValues[itemKey]?.currentValue)
    },
    async save () {
      const valueListToSave = Object.entries(this.localValues).filter(([key, value]) => value !== undefined)
      // FIXME HACK HACK HACK: Image objects have the form {"image": "hash"} or null.
      //  We should implement a way to handle this natively. Will be necessary once we get to allowing SVG uploads
      const clearObj = erectObj(valueListToSave.map(([key, value]) => [key.replace(/\/image$/, ''), null]))
      const setObj = erectObj(valueListToSave.map(([key, value]) => [value === null ? key.replace(/\/image$/, '') : key, value]))

      const data = {
        /* For now we'll always set all settings as inherited */
        settings_inherit: setObj,
        settings_local: clearObj,
      }

      const url = this?.ddbEntry?.webApp ? this.currentOrganization.url : this.device.url
      const action = this?.ddbEntry?.webApp ? 'organization/' + PATCH_ORGANIZATION : 'device/' + PATCH_DEVICE

      if (this?.ddbEntry?.hardware) {
        // eslint-disable-next-line camelcase
        if (this.locationSelect !== this.device?.current_location) {
          data.current_location = this.locationSelect
        }
      }

      return await this.$store.dispatch(action, { url, data })
    },
    async uploadFile ({ setting, fileElement, types } = { types: [] }) {
      const formData = new FormData()
      formData.append('file', fileElement)
      for (const type_ of types) {
        formData.append('types', type_)
      }
      formData.append('organization', this.currentOrganization?.url ?? null)

      const result = await this.$store.getters.restApi.post('content/image/', formData)
      const imageObj = result.data
      await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_CONTENTIMAGES })
      await this.$store.dispatch('contentimage/' + FETCH_CONTENTIMAGES)
      this.$nextTick(() => this.$set(this.localValues, setting.key, imageObj.hash))
      // FIXME Handle errors
    },
  },
  computed: {
    ...mapGetters('organization', ['allOrganizations', 'currentOrganization']),
    ...mapState('location', ['locations']),
    availableSettings () {
      return settingsForDeviceType(this.device.type).map(
        item => { return { ...item, key: item.key.replace("$DC", this.ddbEntry.deviceClass) } }
      )
    },
    settingsStack () {
      // FIXME Override with new location?
      if (this.ddbEntry?.webApp) {
        // FIXME Override with device
        return this.$store.getters['settings/settingsMapStackForUrl'](this?.currentOrganization?.url ?? null)
      } else {
        return this.$store.getters['settings/settingsMapStackForUrl'](this?.device?.url ?? null)
      }
    },
    anySettingChanged () {
      return (
        // eslint-disable-next-line camelcase
        (this.ddbEntry?.hardware && this.locationSelect !== this.device?.current_location) ||
        Object.values(this.localValues).some(item => item !== undefined)
      )
    },
    consolidatedValues () {
      const tmp = {}
      for (const [key, stack] of Object.entries(this.settingsStack)) {
        tmp[key] = { }
        if (stack && stack?.length > 0) {
          tmp[key].currentFrame = stack[0]
          if (stack[0]?.source === this.device.url) {
            tmp[key].locallySet = true
          }
          if (stack[0]?.source === this.device.url && stack.length > 1) {
            tmp[key].nextFrame = stack[1]
          } else {
            tmp[key].nextFrame = undefined
          }

          if (key.includes('WS/social_media') && !key.endsWith('social_media')) {
            tmp['WS/social_media'].currentFrame.value = {
              ...tmp['WS/social_media'].currentFrame.value,
              [key.split('/')[2]]: tmp[key].currentFrame.value,
            }
          }
        } else {
          tmp[key].nextFrame = tmp[key].currentFrame = undefined
        }
      }
      const retval = {}
      for (const item of this.availableSettings) {
        retval[item.key] = {
          locallySet: false,
          ...item,
          ...tmp[item.key],
        }
        if (retval[item.key]?.nextFrame === undefined) {
          retval[item.key].nextFrame = {
            inherit: true,
            source: undefined,
            value: item.defaultValue ?? null,
          }
        }
        if (retval[item.key]?.currentFrame === undefined) {
          retval[item.key].currentFrame = retval[item.key].nextFrame
        }
        if (Object.prototype.hasOwnProperty.call(this.localValues, item.key) && this.localValues[item.key] !== undefined) {
          retval[item.key].currentValue = this.localValues[item.key]
          retval[item.key].locallySet = true
        }
        if (retval[item.key]?.currentValue === undefined) {
          retval[item.key].currentValue = retval[item.key]?.currentFrame?.value
        } else if (retval[item.key]?.currentValue === null) {
          retval[item.key].currentValue = retval[item.key]?.nextFrame?.value
          retval[item.key].locallySet = false
        }
      }
      return retval
    },
    locationItems () {
      // FIXME There should be some restriction here
      const retval = []
      const findParents = (current) => {
        const retval = []
        while (current) {
          retval.unshift(current)
          current = this.allOrganizations[current]?.parent
        }
        return retval
      }
      for (const location of this.locations) {
        retval.push({
          location,
          parents: findParents(location.organization),
          value: location.url,
        })
      }

      let commonPrefix = 0
      if (retval.length) {
        commonPrefix = retval[0].parents.length
        for (const [index, value] of retval[0].parents.entries()) {
          if (retval.some(item => item.parents[index] !== value)) {
            commonPrefix = index
            break
          }
        }
      }
      if (commonPrefix > 0) { /* Show at least one organisation. FIXME: Make this smarter if already all prefixes include one organisation */
        commonPrefix--
      }

      for (const item of retval) {
        item.text = [
          ...(commonPrefix > 0 ? ["..."] : []),
          ...item.parents.slice(commonPrefix).map(org => shortenParish(
            this.allOrganizations[org]?.name || "Unbekannte Organisation")
          ),
          item.location.name
        ].join(" / ")
      }

      retval.sort((a, b) => a.text.localeCompare(b.text))

      return retval
    }
  },
  watch: {
    device: {
      immediate: true,
      handler (newVal, oldVal) {
        if (
          oldVal &&
          (newVal?.id !== oldVal?.id)
        ) {
          this.$nextTick(this.reset)
        }
      }
    },
    anySettingChanged: {
      immediate: true,
      handler (newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style scoped>

</style>
