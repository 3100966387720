<template>
  <div>
    <v-navigation-drawer
      app
      right
      clipped
      :permanent="!$vuetify.breakpoint.xsOnly"
      v-model="showDrawer"
      width="10em"
    >
      <v-list dense>
        <v-list-item
          v-for="(item,index) in userDropdownItems"
          :key="item.label"
          :to="item.to"
          exact-active-class="highlighted"
        >
          <template v-if="item.label">
            <router-link
              :key="index"
              :to="item.to"
            >
              <v-list-item-title
                v-if="item.label"
                class="menu-label"
                active-class=""
              >
                <template v-if="item.label">{{ item.label }}</template>
              </v-list-item-title>
              <v-list-item-title
                v-else
                :class="item.icon"
              />
            </router-link>
          </template>
        </v-list-item>
      </v-list>
      <template #append>
        <v-list dense>
          <v-list-item
            id="logout-button"
            class="cursor-pointer"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="menu-label"
              >
                <span class="text-primary">Logout</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      dense
      color="var(--clr-environment)"
      :to="{ name: 'kige-index' }"
      title="kirchgeld.digital"
      id="head-area">
      <v-row align="center" class="">
        <v-col cols="8" xs="8" sm="8" md="8" lg="3" style="" class="">
          <div id="logo-group" class="pt-2">
            <router-link :to="{ name: 'kige-person-lookup' }">
              <logo/>
            </router-link>
          </div>
        </v-col>
      </v-row>
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="showDrawer = !showDrawer" white>
         <menu-icon
           icon="menu"
           :invert-color-white="true"
           scale="1.2"
           style="cursor: pointer;"
           class="mr-2">
         </menu-icon>
        </v-app-bar-nav-icon>
      </span>
    </v-app-bar>
  </div>
</template>
<script>
import menuIcon from "@/components/icons/MenuIcon.vue"
import Logo from "@/components/icons/Logo.vue"
import { LOGOUT } from "@/modules/kirchgeld/store/kirchgeld/action-types"
export default {
  name: "Menu-area",
  components: {
    MenuIcon: menuIcon,
    Logo
  },
  props: {
    title: String,
    to: null,
    hideAvatar: Boolean,
  },
  data () {
    return {
      showDrawer: false,
      windowSize: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    userDropdownItems () {
      return [
        { to: { name: "kige-imports" }, label: 'Import', icon: '' },
        { to: { name: "kige-target-groups-create" }, label: 'Zielgruppe erstellen', icon: '' },
        { to: { name: "kige-letter-create" }, label: 'Brief erstellen', icon: '' },
        { to: { name: "kige-letter-index" }, label: 'Briefe', icon: '' },
        // { to: { name: "kige-groups" }, label: 'Zielgruppen', icon: '' },
        // { to: { name: "kige-person-create" }, label: 'Person erstellen', icon: '' },
        { to: { name: "kige-person-lookup" }, label: 'Personensuche', icon: '' },
        // { to: { name: "kige-person-index" }, label: 'Personen', icon: '' },
        { to: { name: "kige-adjustments" }, label: 'Abgleich ', icon: '' },
        { to: { name: "kige-certificates" }, label: 'Bescheinigungen ', icon: '' },
        // { to: { name: "kige-profile" }, label: 'Profil', icon: 'profile' },
        { to: { name: "kige-legacy-context-k" }, label: 'Altdaten-Import', icon: '' },
      ]
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('kirchgeld/' + LOGOUT)
    }
  }
}
</script>
<style scoped lang="stylus">
.highlighted
  background-color: $clr-environment
  color: white !important
.v-list-item--link:before
  color: white
.router-link-exact-active
  color: #ffffff !important

.router-link-exact-active
  path
    fill: #ffffff !important

#logo-group
  color: #ffffff
  #logo
    height: 40px
    width: auto
</style>
