<template>
  <base-dialog
    max-width="auto"
    :is-open="isOpen"
    @close="isOpen = false"
  >
    <template #dialog-title>Altdatensatz übernehmen</template>
    <template #dialog-content>
      <v-card v-if="item">
        <v-card-text>
          Möchten Sie den folgenden Altdatensatz als <span v-if="isActive">aktive</span><span v-else>inaktive</span> manuell erstellte {{ selectedTypeHuman }} übernehmen?
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4"><v-switch v-model="isActive" :label="'Als aktive ' + selectedTypeHuman + ' übernehmen'" :disabled="!!existingPersonId"/></v-col>
              <v-col cols="4"><v-select v-model="type" :items="availableTypes" :disabled="!!existingPersonId"/> </v-col>
            </v-row>
            <v-row>
              <v-text-field
                v-model="existingPersonId" label="Zu existierender Person zuweisen: Personen-ID"
                :disabled="isActive || type !== 'individual'"
                :persistent-hint="isActive || type !== 'individual'"
                :hint="(isActive || type !== 'individual') ? 'Nicht möglich, wenn als Firma oder aktive Person übernommen werden soll' : null"
                :error-messages="existingPersonError"
                :success-messages="existingPersonSuccess"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <p>
            <span class="text-lg-body-1">{{item.raw_data.Vorname}} {{item.raw_data.Nachname}}</span> (*{{item.raw_data.Geburtsdatum|isoToHuman("L")}}, {{item.raw_data.Geburtsdatum|isoFromNow}})<br>
          </p>
          <template v-if="!item.AdrID">
            -- Keine Adresse --<br>
          </template>
          <address v-else>
            {{item.AdrID.raw_data.Straße}} {{item.AdrID.raw_data.Hausnr}}<br>
            <template v-if="item.AdrID.raw_data.Ortsteil">OT {{item.AdrID.raw_data.Ortsteil}}<br></template>
            {{item.AdrID.raw_data.PLZ}} {{item.AdrID.raw_data.Ort}}
          </address>
          <v-data-table v-if="item.buchungen" :items="item.buchungen.map(item=>item.raw_data)" :headers="BUCHUNGEN_HEADER" disable-filtering disable-sort disable-pagination hide-default-footer>
            <template #item.Buchdatum="{ item }">{{ item.Buchdatum | isoToHuman("L")}}</template>
            <template #item.Betrag="{ item }">{{ item.Betrag | amount }} €</template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="_doImport"
            :disabled = "((isActive || type !== 'individual') && existingPersonId) || (!!existingPersonId && !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(existingPersonId))"
          >
            Ja, übernehmen
          </v-btn>
          <v-btn @click="isOpen = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </base-dialog>

</template>

<script>
import BaseDialog from '@/components/UI/BaseDialog.vue'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { mapGetters } from 'vuex'
import PersonDataMixin from "@/modules/kirchgeld/mixins/person-data.mixin"

const BUCHUNGEN_HEADER = [
  "BuchungsNr",
  "Buchdatum",
  "Betrag",
  "Buchtext",
  "Buchtext2",
].map(item => ({ text: item, value: item }))
export default {
  name: 'LegacyContextKAssign',
  components: { BaseDialog },
  mixins: [
    PersonDataMixin,
  ],
  data () {
    return {
      BUCHUNGEN_HEADER,

      isActive: false,
      type: "individual",
      existingPersonId: null,
      existingPersonError: null,
      existingPersonSuccess: null,
      item: null,
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters('kirchgeld', ['api', 'enums']),
    availableTypes () {
      const personTypeEnum = (this.enums ?? []).find(item => item.name === "PersonType") ?? {}
      return Object.entries(personTypeEnum.values ?? {}).map(([name, description]) => ({
        value: name,
        text: description,
      })) ?? []
    },
    selectedTypeHuman () {
      const personTypeEnum = (this.enums ?? []).find(item => item.name === "PersonType") ?? {}
      return personTypeEnum.values[this.type] ?? "(Datensatz-Typ)"
    }
  },
  methods: {
    import (item) {
      // Ensure that item is not reactive
      this.item = JSON.parse(JSON.stringify(item))
      this.isOpen = true
    },
    async _doImport () {
      const payload = {}
      if (this.existingPersonId) {
        payload.person = `${this.$store.state.kirchgeld.tenant.url}person/${this.existingPersonId}/`
      } else {
        payload.is_active = this.isActive
        payload.type = this.type ?? undefined
      }
      const person = await loadingStateWrapper(this.$store, async () => {
        const response = await this.api.post(`${this.$store.state.kirchgeld.tenant.url}legacy/contextk/stammdaten/${this.item.ID}/import_person/`, payload)
        return response?.data ?? {}
      })

      this.$emit("imported", person)
    }
  },
  watch: {
    existingPersonId: {
      immediate: true,
      async handler (newVal) {
        if (!newVal) {
          this.existingPersonError = null
          this.existingPersonSuccess = null
          return
        }
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(newVal)) {
          try {
            const response = await this.api.get(`${this.$store.state.kirchgeld.tenant.url}person/${newVal}/`)
            if (response?.data?.data) {
              const currentData = this.getLatestValidPersonData(response.data)
              this.existingPersonError = null
              this.existingPersonSuccess = `${currentData.vornamen} ${currentData.familienname}`
            }
          } catch {
            this.existingPersonSuccess = null
            this.existingPersonError = "Person existiert nicht?"
          }
        } else {
          this.existingPersonSuccess = null
          this.existingPersonError = "Ungültige ID"
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
