import { SET_FIRMWARE_INFORMATION } from '@/store/mutation-types'
import { SEARCH_FIRMWARE_INFORMATION } from '@/store/action-types'
import { cachedActionWrapper } from '@/modules/common/store/tools'
import { CACHE_SEARCH_FIRMWARE_INFORMATION } from '@/store/cache-types'

export default {
  namespaced: true,
  state: function () {
    return {
      firmwareInformationByUrl: {},
    }
  },
  getters: {
    firmwareInformationByIdentifier: (state) => {
      const retval = {}
      for (const fi of Object.values(state.firmwareInformationByUrl)) {
        retval[fi.kind + "/" + fi.identifier] = fi
      }
      return retval
    }
  },
  mutations: {
    [SET_FIRMWARE_INFORMATION]: (state, payload) => {
      state.firmwareInformationByUrl = {
        ...state.firmwareInformationByUrl,
        ...payload,
      }
    },
  },
  actions: {
    [SEARCH_FIRMWARE_INFORMATION]: async (context, { kind, identifier }) => {
      return cachedActionWrapper(context, CACHE_SEARCH_FIRMWARE_INFORMATION + kind + "/" + identifier, async () => {
        const response = await context.rootGetters.restApi.get('firmware/?kind=' + kind + '&identifier=' + identifier)
        await context.commit(SET_FIRMWARE_INFORMATION, response.data.reduce((acc, item) => ({ ...acc, [item.url]: item }), {}))
      })
    },
  }
}
