// Variables should align with kbfrontend template-db
export const templateVariables = {
  org_name_str: {
    key: 'Organisationsname',
    description: 'Name der Organisation',
    replacement: 'currentOrganization.name',
    isActive: true
  },
  org_addr_list: {
    // Currently not available
    key: 'org_addr_list',
    description: 'Adresse der Organisation',
    isActive: false
  },
  org_logo_url: {
    // TODO: Clarify availability
    key: 'org_logo_url',
    description: 'Logo der Organisation',
    isActive: false
  },
  payment_amount: {
    key: 'Spendenbetrag',
    description: 'Spendenbetrag (zwei Nachkommastellen)',
    replacement: 'thankYou.amount',
    type: 'amount',
    isActive: true
  },
  payment_currency: {
    key: 'Währungssymbol',
    description: 'Währungssymbol (z.B. Euro)',
    fixedValue: '€',
    isActive: true
  },
  payment_device: {
    key: 'Spendengerät',
    description: 'Spendengerät (z.B. "spende.app", "kollekte.app", "Klingelbeutel")',
    replacement: 'appKind',
    isActive: true
  },
  payment_time: {
    // TODO: Clarify availability
    key: 'payment_time',
    description: 'Datum der Zahlung',
    isActive: false
  },
  payment_hour: {
    // TODO: Clarify availability
    key: 'payment_hour',
    description: 'Uhrzeit der Zahlung im Format "14 Uhr"',
    isActive: false
  },
  payment_type: {
    key: 'Zahlungsmittel',
    description: 'Zahlungsmittel (z.B. SEPA-Lastschrift, PayPal, Kreditkarte, Barzahlung, ...)',
    replacement: 'selectedMethod.method',
    isActive: true
  },
  org_contact_email: {
    key: 'org_contact_email',
    description: 'E-Mail-Adresse der Organisation, im Format org@example.com',
    isActive: false
  },
  org_contact_addr: {
    key: 'org_contact_addr',
    description: 'E-Mail-Adresse der Organisation, im Format "Name der Organisation <org@example.com>"',
    isActive: false
  },
  contract_org_str: {
    key: 'contact_org_str',
    description: 'Name des Vertragspartners',
    isActive: false
  },
  contract_org_addr_list: {
    key: 'contact_org_addr_list',
    description: 'Adresse des Vertragspartners',
    isActive: false
  },
  plan_name_str: {
    key: 'Sammlungszweck',
    description: 'Name der Sammlung, für die gespendet wurde',
    replacement: 'thankYou.purpose',
    isActive: true
  },
  collection_link: {
    key: 'Direktlink',
    description: 'direkter Link zu der Sammlung, für die gespendet wurde',
    isActive: true,
    fixedValue: window.location.href
  }
}
