export const VISIBLE_LIST = 'l'
export const VISIBLE_DETAIL = 'D'
export const SEARCH_DETAIL = 'd'
export const SEARCH_SIMPLE = 's'
export const VIRTUAL_FIELD = 'f'

export const FORM_MANDATORY = 'm'
export const FORM_READONLY = 'r'
export const FORM_EXCLUDE = 'e'
export const COMPANY_EXCLUDE = 'c'

export const personProperties = [
  {
    text: 'Aktionen',
    value: '_actions',
    flags: VISIBLE_LIST,
    priority: 0,
    group: 'actions',
  },
  {
    text: 'Datensatz-ID',
    value: 'id',
    flags: FORM_EXCLUDE,
    priority: 1,
    group: 'general',
  },
  {
    text: 'Personen-ID',
    value: 'person_id',
    flags: FORM_EXCLUDE + VISIBLE_DETAIL,
    priority: 1,
    group: 'general',
  },
  {
    text: 'Inaktiv',
    value: 'inaktiv',
    flags: VISIBLE_LIST,
    priority: 2,
    group: 'general',
  },
  {
    text: 'Erfassungsdatum',
    value: 'created_at',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 2,
    group: 'general',
    format: 'date'
  },
  {
    text: 'Gültig ab',
    value: 'valid_from',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 3,
    group: 'general',
    format: 'date'
  },
  {
    text: 'Gültig bis',
    value: 'valid_before',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 4,
    group: 'general',
    format: 'date'
  },
  {
    text: 'Geschlecht',
    value: 'geschlecht',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL + COMPANY_EXCLUDE,
    priority: 6,
    group: 'personal',
    format: 'enum',
    choices: 'Geschlecht',
  },
  {
    text: 'Anrede (kurz)',
    value: 'anrede',
    flags: VISIBLE_DETAIL + FORM_READONLY + COMPANY_EXCLUDE,
    priority: 7,
    group: 'other',
  },
  {
    text: 'Anrede (lang)',
    value: 'langanrede',
    flags: VISIBLE_DETAIL + FORM_READONLY,
    priority: 8,
    group: 'other',
  },
  {
    text: 'Akad. Grad',
    value: 'akad_grad',
    flags: VISIBLE_DETAIL + COMPANY_EXCLUDE,
    priority: 9,
    group: 'name',
  },
  {
    text: 'Vornamen',
    value: 'vornamen',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + SEARCH_SIMPLE + COMPANY_EXCLUDE,
    priority: 10,
    group: 'name',
  },
  {
    text: 'Rufname',
    value: 'rufname',
    flags: COMPANY_EXCLUDE,
    priority: 10,
    group: 'name',
  },
  {
    text: 'Familienname',
    text_alt: 'Name der Firma',
    value: 'familienname',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + SEARCH_SIMPLE,
    priority: 20,
    group: 'name',
  },
  {
    text: 'Familienname (Zusatz)',
    value: 'familienname_zusatz',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL + SEARCH_SIMPLE + COMPANY_EXCLUDE,
    priority: 21,
    group: 'name',
  },
  {
    text: 'Geburtsname',
    value: 'geburtsname',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + SEARCH_SIMPLE + COMPANY_EXCLUDE,
    priority: 30,
    group: 'name',
  },
  {
    text: 'Geburtsdatum',
    value: 'geburtsdatum',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + SEARCH_SIMPLE + COMPANY_EXCLUDE,
    priority: 40,
    group: 'personal',
    format: 'date'
  },
  {
    text: 'Konfession',
    value: 'konfession',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL + COMPANY_EXCLUDE,
    priority: 45,
    group: 'personal',
    format: 'enum',
    choices: 'Konfession',
  },
  {
    text: 'Straßenname',
    value: 'strassenname',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 50,
    group: 'address',
  },
  {
    text: 'Hausnr.',
    value: 'hausnr',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 60,
    group: 'address',
  },
  {
    text: 'Hausnr. (Zusatz)',
    value: 'hausnr_zusatz',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 70,
    group: 'address',
  },
  {
    text: 'Hausnr. (Teilnr.)',
    value: 'hausnr_teilnr',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL,
    priority: 75,
    group: 'address',
  },
  {
    text: 'Stockwerk / Wohnungsnr.',
    value: 'stockwerks_oder_wohnungsnr',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL,
    priority: 76,
    group: 'address',
  },
  {
    text: 'PLZ',
    value: 'plz',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 80,
    group: 'address',
    format: 'plz',
  },
  {
    text: 'PLZ (Postfach)',
    value: 'plz_postfach',
    flags: VISIBLE_DETAIL,
    priority: 81,
    group: 'address',
    format: 'plz',
  },
  {
    text: 'Postfach Nr.',
    value: 'postfach_nr',
    flags: VISIBLE_DETAIL,
    priority: 82,
    group: 'address',
  },
  {
    text: 'Kommune',
    value: 'kommune',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 83,
    group: 'address',
  },
  {
    text: 'Ortsteil',
    value: 'ortsteil',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 84,
    group: 'address',
  },
  {
    text: 'Anschriften (Zusatz)',
    value: 'anschriften_zusatz',
    flags: VISIBLE_DETAIL + SEARCH_DETAIL,
    priority: 85,
    group: 'address',
  },
  {
    text: 'Wohnungsstatus',
    value: 'wohnungsstatus',
    flags: VISIBLE_DETAIL + COMPANY_EXCLUDE,
    priority: 90,
    group: 'address',
    format: 'enum',
    choices: 'Wohnungsstatus',
  },
  {
    text: 'Einzugsdatum',
    value: 'einzug_datum',
    flags: VISIBLE_DETAIL,
    priority: 91,
    group: 'address',
    format: 'date',
  },
  {
    text: 'Auszugsdatum',
    value: 'auszug_datum',
    flags: VISIBLE_DETAIL,
    priority: 92,
    group: 'address',
    format: 'date',
  },
  {
    text: 'Familienstand',
    value: 'familienstand',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + COMPANY_EXCLUDE,
    priority: 100,
    group: 'personal',
    format: 'enum',
    choices: 'Familienstand',
  },
  {
    text: 'Verwandte Datensätze',
    value: 'relations',
    flags: VISIBLE_DETAIL,
    priority: 105,
    group: 'related',
  },
  {
    text: 'KRO',
    value: 'kro_nr',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL + FORM_EXCLUDE,
    priority: 110,
    group: 'personal',
    text_alt: 'Kirchgemeinde'
  },
  {
    text: 'Kirchgemeinde',
    value: 'org_by_kro',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + VIRTUAL_FIELD,
    priority: 111,
    group: 'personal'
  },
  {
    text: 'KRO (Orig.)',
    value: 'orig_kro_nr',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 115,
    group: 'personal',
  },
  {
    text: 'Sperre',
    value: 'sperre',
    flags: VISIBLE_DETAIL + VISIBLE_LIST + SEARCH_DETAIL,
    priority: 120,
    group: 'other',
    format: 'enumSet',
    choices: 'Sperre',
  },
  {
    text: 'Eigenerfasst',
    value: 'eigenerfasst',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 130,
    group: 'other',
  },
  {
    text: 'Inaktivität',
    value: 'inaktivitaet',
    flags: VISIBLE_DETAIL + FORM_EXCLUDE,
    priority: 140,
    group: 'other',
  },
  {
    text: 'Betreuerstatus',
    value: 'betreuerstatus',
    flags: VISIBLE_DETAIL + COMPANY_EXCLUDE,
    priority: 150,
    group: 'other',
    choices: 'Betreuerstatus',
  },
  {
    text: 'Bemerkungen',
    value: 'bemerkungen',
    flags: VISIBLE_DETAIL,
    priority: 160,
    group: 'remark',
    choices: 'Bemerkungen',
  },
  {
    text: 'Quelle',
    value: 'source',
    flags: VISIBLE_DETAIL,
    priority: 170,
    group: 'general',
  },
  {
    // FIXME Needs API
    text: 'ContextK-Zuordnung',
    value: 'legacy_contextk_stammdaten_id',
    flags: VISIBLE_DETAIL,
    priority: 171,
    group: 'general',
  },
]
