import Router from 'vue-router'
import ZubeWrapper from './layouts/ZubeWrapper'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '/',
    //   name: 'sv-index',
    //   component: SbIndex,
    // },
    {
      path: '/',
      name: 'zube-index',
      component: ZubeWrapper,
    },
  ]
})

export default router
