<template>
  <v-container class="ma-0 pa-0">
    <v-dialog transition="dialog-bottom-transition" v-model="chooserOpen">
      <template v-slot:activator="{ on, attrs }">
        <label class="pl-0 text-body-1 d-block">
          <v-card class="d-flex child-flex" v-if="selectedImageElement">
            <component
              :is="selectedImageElement.component"
              v-if="selectedImageElement.component"
              size="6em"
              ></component>
            <v-img
              v-else
              :aspect-ratio="1"
              :src="selectedImageElement.thumb512c ? selectedImageElement.thumb512c.download_url : selectedImageElement.thumb512.download_url"
              width="6em"
              height="6em"
              min-width="6em"
              min-height="6em"
              contain
              class="flex-shrink-0 flex-grow-0 checkerboard-bg"
            ></v-img>
            <div class="align-self-center flex-grow-1 pl-2">
              <span class="name-limited-width" v-if="selectedImageElement.image.name">{{ selectedImageElement.image.name }}</span>
              <span class="d-inline-block pl-1 overflow-auto" v-if="selectedImageElement.image.width && selectedImageElement.image.height">({{selectedImageElement.image.width}} x {{selectedImageElement.image.height}})</span>
            </div>
            <v-btn class="align-self-center flex-grow-0 mr-4" text color="primary" v-bind="attrs" v-on="on">ändern &hellip;</v-btn>
          </v-card>
        </label>
      </template>
      <v-card dense>
        <v-toolbar flat dense><v-toolbar-title>{{ subject }} wählen oder eigenes {{ subject }} hochladen</v-toolbar-title></v-toolbar>
        <v-card-text class="mb-0 pb-0">
          <v-sheet class="overflow-y-auto my-0" max-height="calc(95vh - 18em)">
            <v-item-group mandatory v-model="selectedImage" @change="chooserOpen = false">
            <v-container>
              <v-row class="align-center">
                <v-col
                  v-for="element in this.selectableImages"
                  :key="element.image.key"
                  cols="10"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="3"
                >
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      :class="[active ? 'activeborder' : 'inactiveborder']"
                      @click="toggle"
                      class="pa-1"
                    >
                      <template v-if="element.component">
                        <!-- FIXME Make this a slot instead? -->
                        <component
                          :is="element.component"
                          size="100%"
                        ></component>
                        Kein Logo gesetzt
                      </template>
                      <v-img
                        v-else
                        :src="element.thumb512.download_url"
                        :id="element.image.url"
                        :lazy-src="element.thumb128 ? element.thumb128.download_url : null"
                        :aspect-ratio="element.thumb512.width / element.thumb512.height"
                        contain
                        class="grey lighten-2 checkerboard-bg"
                      >
                      </v-img>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-form ref="uploadForm" v-model="uploadForm">
            <v-row>
              <slot name="uploadText"></slot>
              <v-col class="flex-grow-1">
                <v-file-input
                  id="image-selector"
                  v-model="imageUploadFile"
                  :rules="uploadFieldRules"
                  accept="image/png, image/jpeg, image/bmp"
                  type="file"
                  prepend-icon="mdi-camera"
                  show-size
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="$emit('upload-file', imageUploadFile)"
                  :disabled="!imageUploadFile || !uploadForm"
                >{{ subject }} hochladen</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { FETCH_CONTENTIMAGES, FETCH_ORGANIZATIONS } from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ImageChooser',
  props: {
    value: String,
    subject: {
      type: String,
      default: "Bild",
    },
    initialValue: {
      optional: true,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    imageType: {
      type: [String, Array],
      optional: true,
    },
    featuredValues: {
      type: Array,
      optional: true,
      default: () => [],
    },
    uploadFieldRules: {
      type: Array,
      default: () => [],
    },
    nullComponent: {
      type: Object,
      optional: true,
      default: null,
    },
    nullText: {
      type: String,
      optional: true,
    }
  },
  data () {
    return {
      selectedImage: 0,
      chooserOpen: false,
      imageUploadFile: null,
      uploadForm: false,
    }
  },
  watch: {
    selectedImageInitial: { // FIXME Not correctly updated on upload
      immediate: true,
      handler (newVal) {
        this.selectedImage = newVal
      }
    },
    selectedImage (newVal) {
      if (newVal >= 0) {
        const hash = this.selectableImages[newVal].image.hash
        if (hash !== this.value) {
          this.$emit("input", hash)
        }
      }
    },
    chooserOpen: {
      immediate: true,
      handler (newVal) {
        if (!newVal) {
          this.imageUploadFile = null
        }
      }
    },
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('contentimage/' + FETCH_CONTENTIMAGES),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
    ])
  },
  computed: {
    ...mapState('contentimage', ['images']),
    ...mapGetters("organization", ['currentOrganization']),
    ...mapGetters("organization", { organizationParents: 'parents' }),
    selectedImageInitial () {
      return this.selectableImages.findIndex(element => this.value === element.image.hash)
    },
    selectedImageElement () {
      if (this.selectedImage >= 0 && this.selectedImage < this.selectableImages.length) {
        return this.selectableImages[this.selectedImage]
      }
      return null
    },
    selectableImages () {
      const retVal = []
      let firstImage = null
      const preferredImages = []

      for (const image of this.images) {
        if (this.imageType) {
          const itCheck = Array.isArray(this.imageType) ? this.imageType : [this.imageType]
          if (!itCheck.some(x => image.types.includes(x))) {
            continue
          }
        }

        if (!image.public) {
          continue
        }

        const nonCroppedThumbs = image.thumbnails.filter(thumb => !thumb.cropped)
        let thumb512 = nonCroppedThumbs.find(thumb => (thumb.width === 512 || thumb.height === 512))
        const thumb128 = nonCroppedThumbs.find(thumb => (thumb.width === 128 || thumb.height === 128))
        const thumb512c = image.thumbnails.find(thumb => (thumb.cropped && thumb.width === 512))

        if (!thumb512) {
          if (thumb128) {
            thumb512 = thumb128
          } else {
            thumb512 = image.thumbnails[0] ?? null
          }
        }

        if (!thumb512) {
          continue
        }

        const element = {
          image,
          thumb128,
          thumb512,
          thumb512c,
        }

        if (image.hash === this.initialValue) {
          firstImage = element
        } else {
          if (image.organization) {
            if (image.organization === this.currentOrganization.url) {
              preferredImages.push(element)
            } else if ((this.organizationParents[this.currentOrganization.url] ?? []).includes(image.organization)) {
              retVal.push(element)
            }
          } else {
            if (this.featuredValues && this.featuredValues.includes(image.hash)) {
              retVal.unshift(element)
            } else {
              retVal.push(element)
            }
          }
        }
      }
      if (firstImage) {
        preferredImages.unshift(firstImage)
      }
      if (this.nullComponent) {
        preferredImages.push({
          image: {
            hash: null,
            key: "nullComponent",
            name: this.nullText ? this.nullText : `Kein ${this.subject} eingestellt`
          },
          component: this.nullComponent,
        })
      }
      return [...preferredImages, ...retVal]
    },

  }

}
</script>

<style scoped lang="stylus">
.activeborder
  border: 4px solid $clr-primary!important
.inactiveborder
  border: 4px solid transparent!important
.name-limited-width
  display: inline-block
  white-space: nowrap
  max-width: 15em
  overflow-x: hidden
  text-overflow: ellipsis
</style>
