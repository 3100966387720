<template>
  <div class="random-background" :style="{ backgroundImage: 'url(' + require('@/assets/kirchgekd_bg.svg') + ')' }">
    <v-container class="landing-container ">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row class="my-1">
                <v-col cols="7" sm="5" lg="3">
                  <logo dark/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" lg="7" xs="12" order="2" order-lg="1">
          <v-card>
            <v-card-text>
              <p class="text-primary">{{$t('kigeLoginInfo.introduction') }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" xs="12" order="1" order-lg="2" >
          <v-card class="fill-height justify-center">
            <v-card-actions class="justify-center align-center">
              <div class="text-center ma-7">
                <router-link :to="{name: 'kige-login'}" >
                  <v-btn color="primary" class="grow" :x-small="$vuetify.breakpoint.xsOnly"><span
                    style="color: white">Login für Benutzer von Kollekte.digital</span></v-btn>
                </router-link>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" lg="7" order="2" order-lg="1">
          <v-card class="mb-4">
            <v-card-text>
              <p class="text-primary font-italic">{{$t('kigeLoginInfo.features') }}</p>
            </v-card-text>
          </v-card>
          <v-card class="mb-4">
            <v-card-title> <h4 class="text-primary">{{ $t('kigeLoginInfo.reasonsToUseHeader')  }} </h4></v-card-title>
            <v-list color="white">
              <v-list-item v-for="item in $t('kigeLoginInfo.reasonsToUse')" :key="item">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-primary">
                  {{ item }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card>
            <v-card-title> <h4 class="text-primary">{{ $t('kigeLoginInfo.benefitsHeader') }}</h4> </v-card-title>
            <v-list color="white">
              <v-list-item v-for="item in $t('kigeLoginInfo.benefits')" :key="item">
                <v-list-item-icon>
                  <v-icon color="primary" small>{{ mdiCircle }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-primary">
                  {{ item }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" order="1" order-lg="2" >
          <v-card class="fill-height justify-center">
                    <span v-for="(image, i) in images"
                          :key="i">
                            <v-img
                              :src="image.path"
                              contain/>
                          </span>
            <v-card-text>
              <p class="text-primary" v-html="$t('kigeLoginInfo.helpWithDigitization')"/>
              <p class="text-primary" v-html="$t('kigeLoginInfo.otherServices')"/>
              <p class="text-primary" v-html="$t('kigeLoginInfo.joinWithUs')"/>
            </v-card-text>
            <v-card-actions>
              <v-layout align-center justify-center>
                <v-btn color="primary" href="https://digitalespende.de/kontakt/"
                       target="_blank"
                       size="small sm-block md-block lg-block xl-block"><p
                  style="color: white">{{ $t('kigeLoginInfo.placeRequest') }}</p></v-btn>
              </v-layout>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
      <v-row class="module-cards">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <div class="text-primary text-h4 text-center">{{ $t('kigeLoginInfo.wayToKige') }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text class="text-center">
              <v-btn fab color="primary" outlined>1</v-btn>
              <div class="text-primary ma-5" v-html="$t('kigeLoginInfo.contact')"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text class="text-center">
              <v-btn fab color="primary" outlined>2</v-btn>
              <p class="text-primary ma-5">{{ $t('kigeLoginInfo.offer')  }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-card-text class="text-center">
              <v-btn fab color="primary" outlined>3</v-btn>
              <p class="text-primary ma-5">{{$t('kigeLoginInfo.possibilityToStart') }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center">
              <a href="https://digitalespende.de/kontakt/" target="_blank">
                <v-btn color="primary">{{ $t('kigeLoginInfo.placeRequest') }}</v-btn>
              </a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" lg="8" order="2">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import logo from "@/components/icons/Logo"
import { mdiCircle } from "@mdi/js"

export default {
  name: "KirchLogin",
  components: {
    logo,
  },
  data () {
    return {
      mdiCircle,
      images: [
        {
          path: require("@/assets/2023-01-26_Anzeigen_Die_Kirche_kollekteapp_ohne_Rahmen.png"),
          text: 'kollekte-digital'
        }
      ],
    }
  }
}
</script>

<style lang="stylus" scoped>
.random-background
  width: 100%
  height: 100%
  position: absolute
  overflow: auto
  top: 0
  left: 0
  background: repeat
.landing-container
  --geben-color: $clr-primary

  .card-center
    margin-left: auto
    margin-right: auto

  .module-cards
    .v-card
      height: 100%
.mx-auto
  margin 0 auto

.italic
  font-style italic
</style>
