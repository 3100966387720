<template>
  <div id="org-team">
    <p>Bald werden Sie hier Ihr Team verwalten können!</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'org-team',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('organization', { organization: 'currentOrganization' }),
  },
  created () {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="stylus">
#org-team p
  padding: 32px
</style>
