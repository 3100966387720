import moment from "moment/moment"

export const sortAlphaNumeric = (list, key, ascending = true) => {
  return list.sort((itemA, itemB) => {
    if (itemA[key] > itemB[key]) {
      return ascending ? 1 : -1
    }
    if (itemA[key] < itemB[key]) {
      return ascending ? -1 : 1
    }

    return 0
  })
}

export const isCurrentlyValid = (version) => {
  const now = moment()

  const validBeforeRaw = version?.valid_before ?? version?.valid_notafter ?? null

  return (!version.valid_from || now.isSameOrAfter(moment(version.valid_from))) &&
    (!validBeforeRaw || now.isBefore(moment(validBeforeRaw)))
}

export const getLatestVersion = (versions) => {
  if (!versions.length) {
    return null
  }

  if (versions.length === 1) {
    return versions[0]
  }

  const latestVersion = versions.find(version => {
    return isCurrentlyValid(version)
  })

  return latestVersion || versions[0]
}
