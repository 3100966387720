<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Abrechnungen</h2>
        <div>
          Hier finden Sie Ihre monatlichen Abgleichlisten und, wenn für Sie zutreffend, Rechnungen.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              hide-details
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="combinedList"
            :search="search"
            @update:sort-by="handleSortBy"
            :item-class="itemClasses"
          >
            <template #item.period="{ item }">
              {{ momentize(item.period_start) }}
            </template>
            <template #item.name="{ item }">
              {{ item.name }}
            </template>

            <template #item.type="{ item }">
              {{ item.type === 'invoice' ? 'Rechnung' : 'Abgleichliste' }}
            </template>

            <template #item.downloaded="{ item }">
              <v-tooltip
                v-if="item.first_download"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="archivedColor(item)"
                  >{{ mdiEmailOpenOutline }}
                  </v-icon>
                </template>
                <span>Heruntergeladen durch {{ item.first_download.user }}<br>am {{
                    simpleDate(item.first_download.date)
                  }}</span>
              </v-tooltip>
              <v-tooltip
                v-else
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="archivedColor(item)"
                  >{{ mdiEmailOutline }}
                  </v-icon>
                </template>
                <span>Ungeöffnet</span>
              </v-tooltip>
            </template>

            <template #item.url="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadReport(item)"
                    :color="archivedColor(item)"
                  >
                    <v-icon>{{ mdiDownloadOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.name }} herunterladen</span>
              </v-tooltip>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mdiEmailOutline, mdiEmailOpenOutline, mdiDownloadOutline } from '@mdi/js'
import { mapGetters } from "vuex"
import { downloadBlob } from "@/lib/file-tools"

export default {
  name: "EvalInvoicing",
  data () {
    return {
      mdiEmailOutline,
      mdiEmailOpenOutline,
      mdiDownloadOutline,
      search: "",
      headers: [
        {
          text: "Zeitraum",
          value: "period",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Typ",
          value: "type"
        },
        {
          text: "Status",
          value: "downloaded"
        },
        {
          text: "Download",
          value: "url"
        }
      ],
      bookingReports: [],
      invoices: [],
      sortByPeriodAsc: true
    }
  },
  computed: {
    ...mapGetters("organization", { organization: "currentOrganization" }),
    combinedList: function () {
      return [...this.bookingReports.map(x => ({
        ...x,
        type: "booking-report"
      })), ...this.invoices.map(x => ({
        ...x,
        type: "invoice"
      }))].sort((entryA, entryB) => {
        if (this.sortByPeriodAsc) {
          return entryA.period_start <= entryB.period_start
        }

        return entryA.period_start >= entryB.period_start
      })
    }
  },
  async mounted () {
    const results = await Promise.all([
      this.$store.getters.restApi.get(`billing/export/booking_report?organization=${this.organization.id}`),
      this.$store.getters.restApi.get(`billing/invoice/?organization=${this.organization.id}`)
    ]);
    [this.bookingReports, this.invoices] = [results[0].data, results[1].data]
  },
  methods: {
    momentize (date) {
      return moment(date).format("MMMM YYYY")
    },
    simpleDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    async downloadReport (report) {
      const response = await this.$store.getters.restApi.get(report.url + "download/", {
        responseType: 'blob',
      })
      const fileExtension = (response.headers['content-type'] === 'application/pdf') ? "pdf" : "xlsx"
      downloadBlob(response.data, `${report.name}.${fileExtension}`, response.headers['content-type'])
    },
    handleSortBy (sortColumns) {
      this.sortByPeriodAsc = sortColumns.includes('period')
    },
    itemClasses (item) {
      return item?.archived ? 'archived' : ''
    },
    archivedColor (item) {
      return item?.archived ? 'grey lighten-1' : ''
    }
  }
}
</script>

<style lang="stylus">
.archived
  color: #BDBDBD
</style>
