export const foreignCollectionExtraFields = [
  'Organisation',
  'Kontaktperson',
  'Telefon',
  'Mail',
  'Straße',
  'PLZ',
  'Ort'
]

export const bankingExtraFields = [
  'Kontoinhaber',
  'Bank',
  'IBAN',
  'BIC',
  'Verwendungszweck'
]
