<template>
  <div>
    <v-text-field
      v-model="email"
      :rules="[rules.email]"
      class="mt-4"
      label="Neue E-Mail-Adresse"
      placeholder="Neue e-mail-Adresse"
      type="name"
      autocomplete="new-email"
      @input="inputChanged"
      :loading="email.length < 0"
    >
    </v-text-field>
  </div>
</template>

<script>

export default {
  name: 'EmailInput',
  data: function () {
    return {
      email: "",
      rules: {
        email: value => {
          return ((value.length < 1) ? 'Bitte geben Sie hier Ihre E-Mail-Adresse ein' : ((/^[^@]+@[^@]{2,}/).test(value) ?? '') || 'Gültige E-Mail-Adresse eingeben.')
        }
      }
    }
  },
  async created () {
  },
  props: {
    value: {
      type: String,
      validator: prop => typeof prop === 'string' || prop === null,
    }
  },
  emits: ['input'],
  computed: {
  },
  methods: {
    inputChanged (event) {
      if (this.email?.length > 0 && this.email.match(/^[^@]+@[^@]{2,}/)) {
        this.$emit("input", this.email, true)
      } else {
        this.$emit("input", null, false)
      }
    },
    resetInputs () {
      this.email = ""
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        if (newVal === null) {
          this.email = ""
        } else if (typeof newVal === 'string') {
          this.email = newVal
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
