<template>
  <v-container id="eval-recurringdonationslist">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <h2>Übersicht über Ihre Dauerspenden</h2>
        <p>
          Hier finden Sie eine Liste der aktiven, sich wiederholenden Spenden vereinbart haben.
          Mit Klick auf den Button mit dem Quadrat (Stop) können Sie einzelne Dauerspenden beenden.
        </p>
        <p v-if="!donationslist">Um diese Ansicht freizuschalten, müssen wir unsere Zahldaten mit Ihrem Kontoauszug abgleichen (z. B. falls ein Gebendenkonto nicht gedeckt war).
           Um diese Funktion einzurichten, wenden Sie sich bitte an <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a></p>
        <template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                hide-details
                single-line
              ></v-text-field>
            </v-card-title>
            <v-data-table
              id="donationstable"
              :headers="headers"
              :items="recurringDonations"
              :search="search"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              sort-by="created_at"
              :sort-desc="true"
              item-key="id"
              show-expand
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="3">
                </td>
                <td :colspan="headers.length - 3">
                  Spendenzweck: {{ item.collection_name }}
                </td>
              </template>
              <template v-slot:item.collection_name="{ item }">
                  {{ item.collection_name.length > 22 ? item.collection_name.substring(0, 22) + '...' : item.collection_name}}
              </template>
              <template v-slot:item.collection_type="{ item }">
                  {{ item.collection_type === 'donation' ? 'Spende' : 'Kollekte' }}
              </template>
              <template v-slot:item.schedule="{ item }">
                {{ toScheduleName(item.schedule) }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ momentize(item.created_at) }}
              </template>
              <template v-slot:item.amount="{ item }">
                <span>{{ item.amount | amount }} €</span>
              </template>
              <template #item.actions="{ item }">
                <!-- TODO: Needs refactoring as soon the state is available over the API -->
                <v-tooltip
                  bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      @click.stop="openRecurringCancellationDialog(item)"
                      color="primary"
                      outlined
                      x-small
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ mdiStop }}</v-icon>
                    </v-btn>
                  </template>
                  Beenden
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </template>
        <!-- <p>* Grenze bis zu der Kontoauszug als Zuwendungsbestätigung reicht.</p> -->
      </v-col>
    </v-row>
    <base-dialog
      :is-open="showRecurringCancellationDialog"
      :max-width="450"
      @close="showRecurringCancellationDialog = false"
    >
      <template #dialog-title>Dauerspende beenden?</template>
      <template #dialog-content>
        <v-card-text v-if="cancellationCandidate" class="mt-3">
          Möchten Sie die Dauerspende von <b>{{ cancellationCandidate.name }}</b> über <b>{{ cancellationCandidate.amount | amount }} €</b> wirklich beenden?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelRecurringDonation()">Ja, beenden</v-btn>
          <v-btn @click="showRecurringCancellationDialog = false">Nein</v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
    <v-snackbar
      v-model="showRecurringCancellationSnackbar"
      :timeout="4000"
    >
      <span v-if="wasCancellationSuccessful">Die Dauerspende wurde erfolgreich beendet.</span>
      <span v-else>Bei der Beendigung der Dauerspende ist etwas schief gelaufen</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { mdiStop } from "@mdi/js"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import amount from "@/filters/amount"
import moment from "moment"
import { ALIGNMENT } from "@/lib/recurrence-tools"
export default {
  name: "eval-recurringdonationslist",
  filters: {
    amount
  },
  components: {
    BaseDialog
  },
  data () {
    return {
      expanded: [],
      singleExpand: false,
      search: "",
      headers: [
        { text: "Angelegt am", value: "created_at" },
        { text: "Turnus", value: "schedule" },
        // { text: "Gerät", value: "device" },
        { text: "E-Mail", value: "email" },
        { text: "Sammlungszweck", value: "plan.name" },
        // { text: "Zahlart", value: "payment_type" },
        { text: "Spendenhöhe", value: "amount" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" }
      ],
      donationslist: [],
      mdiStop,
      showRecurringCancellationDialog: false,
      showRecurringCancellationSnackbar: false,
      cancellationCandidate: null,
      wasCancellationSuccessful: false,
      recurringDonations: []
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters("organization", { organization: "currentOrganization" }),
  },
  methods: {
    openRecurringCancellationDialog (item) {
      this.cancellationCandidate = item
      this.showRecurringCancellationDialog = true
    },
    async cancelRecurringDonation () {
      try {
        // await this.$store.getters.restApi.post(`recurring_payment/${this.cancellationCandidate.recurringPaymentAuthToken}/cancel/`)
        await this.$store.getters.restApi.post(`${this.cancellationCandidate.url}cancel/`)
        this.wasCancellationSuccessful = true
        await this.fetchRecurringDonations()
      } catch {
        this.wasCancellationSuccessful = false
      }
      this.showRecurringCancellationDialog = false
      this.showRecurringCancellationSnackbar = true
    },
    async fetchRecurringDonations () {
      const recurringDonationsResponse = await this.$store.getters.restApi.get(`payment/recurring_payment?organization=${this.organization.id}`)
      this.recurringDonations = recurringDonationsResponse.data
    },
    momentize (date) {
      return moment(date).format("DD.MM.YYYY")
    },
    toScheduleName (schedule) {
      return ALIGNMENT[schedule].name
    }
  },
  async mounted () {
    const response = await this.$store.getters.restApi.get(`${this.organization.url}payment_details/`)
    this.donationslist = response.data

    await this.fetchRecurringDonations()
  },
}
</script>
<style lang="stylus">
#eval-donationslist
  #donationstable
    tr
      cursor: pointer
</style>
