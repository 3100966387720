<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="4" class="pa-0">
              <v-card-title>Neue Zielgruppe erstellen</v-card-title>
            </v-col>
            <v-col cols="12" md="8" class="pa-0">
              <v-card-text>
                <v-text-field
                  v-model.trim="filterName"
                  label="Zielgruppenname*"
                  placeholder="Vergeben Sie einen eindeutigen Namen"
                  dense
                  :rules="[!!filterName || 'Zielgruppenname eingeben']"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card
          outlined
          class="pa-3"
        >
          <v-card-text>
            <filter-criteria-group
              ref="filterCriteriaGroupDefault"
              filter-group-id="default"
              :is-deletion-allowed="false"
            ></filter-criteria-group>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="compileFilters">
              <v-icon left>{{ mdiFilterOutline }}</v-icon>
              Vorschau
            </v-btn>
            <v-btn
              color="primary"
              :disabled="filterName.length <= 0"
              @click="showFilterSaveConfirmationDialog = true">
              <v-icon>mdi-plus</v-icon>
              Zielgruppe erstellen
            </v-btn>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template #activator="{ attrs, on }">
                <v-btn
                  @click="resetFilters"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ mdiBackspaceOutline }}</v-icon>
                </v-btn>
              </template>
              <span>Filter zurücksetzen</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <base-dialog
      :is-open="showFilterSaveConfirmationDialog"
      @close="showFilterSaveConfirmationDialog = false"
    >
      <template #dialog-title>Neue Zielgruppe erstellen</template>
      <template #dialog-content>
        <v-card-text>
          <div class="pt-2">
            Sind Sie sicher, dass Sie die Zielgruppe <b>{{ filterName }}</b> erstellen möchten?
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="saveFilter"
          >
            <v-icon>mdi-check</v-icon>
            Ja, erstellen
          </v-btn>
          <v-btn>
            <v-icon>mdi-cancel</v-icon>
            Nein, abbrechen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import FilterCriteriaGroup from "@/modules/kirchgeld/components/FilterCriteriaGroup"
import { GET_FILTER_PREVIEW, SAVE_FILTER, GET_FILTERS } from "@/modules/kirchgeld/store/kirchgeld/action-types"
import { mdiFilterOutline, mdiBackspaceOutline } from "@mdi/js"
import BaseDialog from "@/components/UI/BaseDialog"
import { INVALIDATE_CACHE } from '@/modules/common/store/action-types'
import { CACHE_KD_FILTER } from '@/modules/kirchgeld/store/kirchgeld/cache-types'

export default {
  name: 'FilterBuilder',
  emit: ['data-changed'],
  components: {
    BaseDialog,
    FilterCriteriaGroup
  },
  data () {
    return {
      filterName: '',
      showFilterSaveConfirmationDialog: false,
      mdiFilterOutline,
      mdiBackspaceOutline
    }
  },
  methods: {
    async compileFilters () {
      const [matchCount, res] = await this.$store.dispatch('kirchgeld/' + GET_FILTER_PREVIEW, { filter: JSON.stringify(this.getFilters()) })
      this.$emit('data-changed', res, matchCount)
    },
    getFilters () {
      return this.$refs.filterCriteriaGroupDefault.getFilterGroup()
    },
    deleteFilter (filterIndexToDelete) {
      this.additionalFilterOperations = this.additionalFilterOperations.filter((operation, index) => index !== filterIndexToDelete)
    },
    resetFilters () {
      this.$refs.filterCriteriaGroupDefault.resetFilterGroup()
    },
    async saveFilter () {
      const payload = {
        name: this.filterName,
        filter: this.getFilters()
      }
      const newFilter = await this.$store.dispatch('kirchgeld/' + SAVE_FILTER, payload)
      await this.$store.dispatch(INVALIDATE_CACHE, { key: CACHE_KD_FILTER })
      await this.$store.dispatch('kirchgeld/' + GET_FILTERS)
      await this.$router.push({
        name: 'kige-letter-create',
        query: {
          preselectedTargetGroupId: newFilter?.id ?? ''
        }
      })
    }
  },
}
</script>
