<template>
  <v-card
    elevation="0"
    class="pa-0 contactcard"
    outlined
  >
    <template v-if="isEditing">
      <v-card-text>
        <v-text-field
          label="E-Mail-Adresse"
          v-model="editContact.email"
        ></v-text-field>
        <v-switch
          v-if="isSuperuser"
          v-model="editContact.attach_files"
          color="primary"
          label="Dokumente anhängen"
        />
        <v-text-field
          label="Ansprechpartner"
          v-model="editContact.name"
        ></v-text-field>
        <v-autocomplete
          id="autocomplete-notification-types"
          chips
          deletable-chips
          :items="contactTypeItems"
          v-model="editContact.types"
          label="Benachrichtigungen für"
          multiple
        >
        <template v-slot:item="data">
          <v-list-item-content >
            <div class="d-flex align-center">
              {{ data.item.text }}
              <v-menu
                v-if="notifications.find(x=> x.nameInFrontend === data.item.value) "
                open-on-hover
                @input="selectedNotification = notifications.find(x=> x.nameInFrontend === data.item.value)"
                top
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  v-bind="attrs"
                  v-on="on" class="ml-2" color="primary" outlined icon small> <v-icon small> {{ mdiHelp }}</v-icon></v-btn>
                </template>
                <v-card v-if="selectedNotification">
                    <v-card-title class="text-h6">{{ selectedNotification.nameOfMail }}
                    </v-card-title>
                    <v-card-subtitle > {{ selectedNotification.notification }}</v-card-subtitle>
                    <v-card-text>
                      {{ selectedNotification.explanation }}
                    </v-card-text>
                  </v-card>
            </v-menu>

            </div>

          </v-list-item-content>
        </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          @click="saveContact"
          color="primary"
        >Speichern</v-btn>
        <v-btn
          color="primary"
          text
          @click="cancelEditing"
        >Abbrechen
        </v-btn>
      </v-card-actions>
    </template>
    <v-card-text v-else>
      <v-list-item>
      <v-list-item-content
        class="content"
      >
        <v-list-item-title class="d-flex align-center">
          <h5 class="text-h5 d-inline-block">{{ contact.email === null ? 'Keine Angabe' : contact.email }}</h5>
          <v-btn
            v-if="hasEditingPermissions"
            icon
            color="grey"
            @click="startEditing"
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>

          <v-btn
            v-if="hasEditingPermissions"
            icon
            color="grey"
            @click="isDeletionDialogOpen = true"
          >
            <v-icon>{{ mdiTrashCan }}</v-icon>
          </v-btn>
        </v-list-item-title>
        <p v-if="contact.name">
          <b>Name: </b>{{ contact.name }}
        </p>
        <p v-if="contact.types">
          <b>Typ:</b> {{ myJoiner(contact.types, ', ', this.enumsByName.ContactType) }}
        </p>
        <p>
          <b>Dokumente anhängen:</b>
          <v-icon small>{{ contact.attach_files ? mdiCheck : mdiClose }}</v-icon>
        </p>
      </v-list-item-content>
    </v-list-item>
    </v-card-text>
    <base-dialog
      v-if="isDeletionDialogOpen"
      :is-open="isDeletionDialogOpen"
      @close="isDeletionDialogOpen = false"
    >
      <template #dialog-title>Kontakt wirklich löschen?</template>
      <template #dialog-content>
        <v-card-text>
          Möchten Sie den Kontakt
          <b v-if="editContact.name">{{ editContact.name }}</b> <template v-if="editContact.email">({{ editContact.email }})</template>
          wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="deleteContact"
          >
            Ja, löschen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="isDeletionDialogOpen = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
  </v-card>
</template>

<script>
import { mdiPencil, mdiTrashCan, mdiCheck, mdiClose, mdiHelp } from "@mdi/js"
import { mapGetters, mapState } from "vuex"
import {
  ADD_ORGANIZATION_CONTACT,
  DELETE_ORGANIZATION_CONTACT,
  PATCH_ORGANIZATION_CONTACT
} from "@/store/action-types"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import { WRITE_ORG_DATA } from '@/lib/permission-tools'
import { ORGANIZATION_FEATURE_ADVANCED_NOTIFICATION } from "@/lib/organization-features"
import notifications from "@/data/notifications"
export default {
  name: "OrganizationNotificationContact",
  emits: ['remove-contact-from-org'],
  components: {
    BaseDialog
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: false,
      default: null
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      notifications,
      isEditing: false,
      isDeletionDialogOpen: false,
      editContact: null,
      mdiPencil,
      mdiTrashCan,
      mdiCheck,
      mdiClose,
      mdiHelp,
      selectedNotification: null,
    }
  },
  mounted () {
    this.editContact = this.contact

    if (!this.editContact?.id) {
      this.isEditing = true
    }
  },
  computed: {
    ...mapState('staticConfiguration', ['enumsByName']),
    ...mapGetters('user', ['isSuperuser', 'hasPermission']),
    ...mapGetters('organization', ['effectiveOrganizationFeaturesByUrl']),
    hasAdvancedNotificationFeature () {
      return this.effectiveOrganizationFeaturesByUrl[this.organization.url].has(ORGANIZATION_FEATURE_ADVANCED_NOTIFICATION)
    },
    contactTypeItems () {
      const items = []
      for (let [value, text] of Object.entries(this.enumsByName.ContactType)) {
        const needsPermissions = (['booking_report_available', 'invoice_available'].includes(value))
        let disabled = needsPermissions && !this.isSuperuser

        if (value === 'announce_collection' && !this.hasAdvancedNotificationFeature) {
          disabled = true
          text += ' (Zusatzfeature benötigt)'
        }

        items.push({
          text,
          value,
          disabled
        })
      }
      return items
    },
    hasEditingPermissions () {
      return this.hasPermission(this.organization.url, WRITE_ORG_DATA)
    }
  },
  methods: {
    startEditing () {
      this.isEditing = true
      this.editContact = JSON.parse(JSON.stringify(this.contact))
    },
    cancelEditing () {
      this.isEditing = false
      this.editContact = JSON.parse(JSON.stringify(this.contact))
      this.$emit('cancel', this.position, !!this.editContact?.id)
    },
    async saveContact () {
      // TODO: #366 - Make sure saving attach_files works.
      const contactPayload = {
        ...this.editContact,
        organization: this.organization.url
      }
      let updatedContact
      if (this.editContact?.id) {
        updatedContact = await this.$store.dispatch('organization/' + PATCH_ORGANIZATION_CONTACT, contactPayload)
      } else {
        updatedContact = await this.$store.dispatch('organization/' + ADD_ORGANIZATION_CONTACT, contactPayload)
        this.editContact.id = updatedContact.id
      }

      if (updatedContact) {
        this.isEditing = false
      }
    },
    async deleteContact () {
      let wasDeletionSuccessful = false
      if (this.editContact?.id) {
        wasDeletionSuccessful = await this.$store.dispatch('organization/' + DELETE_ORGANIZATION_CONTACT, this.editContact)
      }
      this.isEditing = false

      if (wasDeletionSuccessful) {
        this.$emit('remove-contact-from-org', this.position)
      }
      this.isDeletionDialogOpen = false
    },
    myJoiner (arr, sep, map) {
      const retVal = []
      for (const key in arr) {
        const val = arr[key]
        if (map[val]) {
          retVal.push(map[val])
        } else {
          retVal.push(val)
        }
      }
      return retVal.join(sep)
    }
  },
}
</script>

<style lang="stylus" scoped>
.contactcard
  .title
    text-overflow: unset
    white-space: unset
  .content
    text-overflow: unset
    white-space: unset
</style>
