<template>
  <div>
    <v-simple-table class="cash-step-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Anzahl</th>
            <th class="text-left">Art</th>
            <th class="text-right sum-col-head">Summe</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cashStep in cashStepValues" :key="cashStep.value">
            <td class="input-cell">
              <v-text-field
                v-model="cashStep.input"
                dense
                hide-details
                solo
                reverse
                :error="cashStep.error"
                @input="parse(cashStep)"
                @blur="parse(cashStep, true)"
              >
                <template v-slot:append>
                  <v-tooltip
                    v-if="cashStep.error"
                    bottom
                    color="error"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ mdiAlertCircle }}
                      </v-icon>
                    </template>
                    <span>Bitte geben Sie eine gültige Anzahl an</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </td>
            <td>
              {{ cashStep.type }} zu {{ cashStep.value  | currency }}
            </td>
            <td class="text-right">
              <span v-if="cashStep.count">
                = {{ (cashStep.value * cashStep.count) | currency }}
              </span>
            </td>
          </tr>
          <tr class="sum">
            <td></td>
            <td class="text-right">Summe</td>
            <td class="text-right">
                = {{ internalValue | currency}}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mdiAlertCircle } from '@mdi/js'

export default {
  name: "CashPaymentTableInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    cashSteps: {
      type: Array,
      required: false,
      default: () => ([10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1]),
    },
  },
  data: () => ({
    internalValue: 0,
    cashStepValues: [],
    mdiAlertCircle,
  }),
  mounted () {
    this.internalValue = this.value
    this.resetCashStepValues()
  },
  watch: {
    value (newVal) {
      if (newVal !== this.internalValue) {
        this.resetCashStepValues()
        this.internalValue = newVal
      }
    },
    internalValue (newVal) {
      if (newVal !== this.value) {
        this.$emit('input', newVal)
      }
    },
    cashSteps: {
      deep: true,
      handler () {
        this.resetCashStepValues()
      }
    },
  },
  methods: {
    sumCashStepValues () {
      this.internalValue = this.cashStepValues.reduce((s, csv) => s + csv.value * csv.count, 0)
    },
    parse (cashStep, sanitize) {
      const hasInputContent = cashStep.input.trim().length > 0
      const count = hasInputContent ? parseInt(cashStep.input) : 0
      if (isNaN(count) || count < 0) {
        cashStep.error = true
        cashStep.count = 0
      } else {
        cashStep.error = false
        cashStep.count = count
        if (sanitize) {
          cashStep.input = hasInputContent ? '' + count : ''
        }
      }
      this.sumCashStepValues()
    },
    resetCashStepValues () {
      this.cashStepValues.length = 0
      this.cashSteps.forEach(cashStep => {
        this.cashStepValues.push({
          value: cashStep,
          type: cashStep >= 500 ? 'Scheine' : 'Münzen',
          input: '',
          count: 0,
          error: false,
        })
      })
    },
  },
}
</script>

<style scoped>
.sum {
  font-weight: bold;
  border-top-width: 2px;
}

.sum-col-head {
  min-width: 120px;
}
</style>
