<template>
  <v-container id="eval-subscriberlist">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <h2>Übersicht über die Bitte nach weiteren Informationen</h2>
        <p>Eine Liste der Personen, die beim Spenden "Ich möchte mehr Informationen per E-Mail." angekreuzt haben.</p>
        <div
          v-if="subscriberslist.length"
          class="d-flex flex-row-reverse mb-4">
          <v-btn
            @click="exportList"
            color="primary"
            class="d-print-none"
          >
            <span>Download alle Daten</span>
            <v-icon>{{ mdiFileExcel }}</v-icon>
          </v-btn>
        </div>
        <template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                hide-details
                single-line
              ></v-text-field>
            </v-card-title>
            <v-data-table
              id="subscriberstable"
              :headers="headers"
              :items="subscriberslist"
              :search="search"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              sort-by="created_at"
              :sort-desc="true"
              item-key="id"
              show-expand
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td colspan="3">
                </td>
                <td :colspan="headers.length - 3">
                  Spendenzweck: {{ item.collection_name }}
                </td>
              </template>
              <template v-slot:item.collection_type="{ item }">
                  {{ item.collection_type === 'donation' ? 'Spende' : 'Kollekte' }}
              </template>
              <template v-slot:item.collection_name="{ item }">
                  {{ item.collection_name.length > 22 ? item.collection_name.substring(0, 22) + '...' : item.collection_name}}
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
              </template>
              <template v-slot:item.amount="{ item }">
                <span>{{ Number(item.amount).toLocaleString() }} €</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { mdiFileExcel } from '@mdi/js'
import moment from 'moment'
import { downloadBlob } from '../../lib/file-tools'
export default {
  name: "eval-subscriberlist",
  components: {},
  data () {
    return {
      expanded: [],
      singleExpand: false,
      search: "",
      headers: [
        { text: "Zeitpunkt", value: "created_at" },
        { text: "E-Mail-Adresse", value: "email" },
        { text: "Spendenzweck", value: "collection_name" },
        { text: "Bruttospende", value: "amount" },
        { text: "Sammlungsart", value: "collection_type" },
        { text: "", value: "data-table-expand" }
      ],
      mdiFileExcel,
      subscriberslist: [
        /* {
          id: 1,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: 'SP',
          location: 'Zion',
          collection_name: 'Für den Kirchturm',
          type: 'Giro',
          amount: '19 €',
          code: 'JU2F9'
        },
        {
          id: 2,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 3,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 4,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 5,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 6,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 7,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 8,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 9,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 10,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 11,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 12,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Für den Kirchturm und die Bibelbildung',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        },
        {
          id: 13,
          date: '2021-03-27T15:29:52.249763+01:00',
          device: '2SP',
          location: '2Zion',
          collection_name: 'Hallo Welt',
          type: '2Giro',
          amount: '219 €',
          code: '2JU2F9'
        }, */
      ],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters("organization", { organization: "currentOrganization" }),
  },
  created () {},
  methods: {
    async exportList () {
      const response = await this.$store.getters.restApi.get(`${this.organization.url}subscriber_details_xlsx/`, {
        responseType: 'blob',
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.organization.name}_Eintragungen Mailingliste.xlsx`, response.headers['content-type'])
    },
  },
  async mounted () {
    const response = await this.$store.getters.restApi.get(`${this.organization.url}subscriber_details/`)
    this.subscriberslist = response.data
  },
}
</script>
<style lang="stylus">
#eval-subscriberslist
  #subscriberstable
    tr
      cursor: pointer
</style>
