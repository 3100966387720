import { erectObj, flattenObj } from '@/lib/settings-tools'

function classifyUrl (url) {
  /*
    Return Optional[Literal['organization', 'location', 'device']]
   */
  if (typeof url !== 'string') {
    return null
  }
  /* hacky */
  if (url.includes('/organization/')) {
    return 'organization'
  } else if (url.includes('/location/')) {
    return 'location'
  } else if (url.includes('/device/')) {
    return 'device'
  }
  return null
}

export default {
  namespaced: true,
  state: function () {
    return {
    }
  },
  getters: {
    inheritanceOrderForUrl: (state, getters, rootState, rootGetters) => (url) => {
      /*
      Returns List[url] (closest to farthest, including self)
       */
      const retval = []
      if (classifyUrl(url) === 'device') {
        retval.push(url)
        // eslint-disable-next-line camelcase
        url = rootGetters['device/devicesLookup'][url]?.current_location ?? null
      }
      if (classifyUrl(url) === 'location') {
        retval.push(url)
        // eslint-disable-next-line camelcase
        url = rootGetters['location/locationsLookup'][url]?.organization ?? null
      }
      if (classifyUrl(url) === 'organization') {
        while (url !== null) {
          retval.push(url)
          url = rootGetters['organization/allOrganizations'][url]?.parent ?? null
        }
      }
      return retval
    },
    settingsForUrl: (state, getters, rootState, rootGetters) => (url, localSelector = true) => {
      const targetObj = {
        organization: rootGetters['organization/allOrganizations'],
        location: rootGetters['location/locationsLookup'],
        device: rootGetters['device/devicesLookup'],
      }[classifyUrl(url)] ?? {}
      return (targetObj[url] ?? {})[localSelector ? 'settings_local' : 'settings_inherit'] ?? {}
    },
    settingsMapStackForUrl: (state, getters) => (url) => {
      /*
      Returns Dict[key: string, Array[{value: obj, inherit: bool, source: url}]]
       */
      const retval = {}
      for (const currentUrl of getters.inheritanceOrderForUrl(url)) {
        for (const localSelector of [true, false]) {
          const settings = getters.settingsForUrl(currentUrl, localSelector)
          for (const [key, value] of flattenObj(settings)) {
            if (!Object.prototype.hasOwnProperty.call(retval, key)) {
              retval[key] = []
            }
            retval[key].push({
              value,
              inherit: !localSelector,
              source: currentUrl
            })
          }
        }
      }
      return retval
    },
    effectiveSettingsForUrl: (state, getters) => (url, overrideMap = {}) => {
      /*
      Returns obj
       */
      const settingsMapStack = getters.settingsMapStackForUrl(url)
      const settingsKeys = Array.from(Object.keys(settingsMapStack))
      settingsKeys.sort()
      /* The stack is ordered in reverse: index 0 is the item closest to url.
        Filter values by source: Can be inherited (meaning it is set on
        or above url and has inheritance active) or the source is the url itself
        (meaning it is set on url, irrespective or inheritance status).
        Of these, take the first one (no effective value can be at an index > 0).
       */
      const activeSettingsMapStack = Object.fromEntries(
        Object.entries(settingsMapStack).map(
          ([key, values]) =>
            [key, values.filter(
              ({ inherit, source }) => inherit || source === url
            )]
        )
      )
      const activeSettingsStackOrdered = settingsKeys.filter(
        key => !!activeSettingsMapStack[key][0]
      ).map(key => {
        return [key, activeSettingsMapStack[key][0]?.value]
      })

      activeSettingsStackOrdered.push(...Object.entries(overrideMap))
      return erectObj(activeSettingsStackOrdered)
    },
  }
}
