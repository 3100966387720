<template>
  <v-container id="org-users" v-if="hasPermission(organization.url, MANAGE_USERS)">
    <template>
      <h2>Benutzer</h2>
        <p>
          Bitte vergeben Sie Benutzernamen nach dem folgenden Schema:
          <br>
          Name: Tobi Testhase Benutzername: t.testhase E-Mail-Adresse: t.testhase@digitalwolff.de
        </p>
        <p>
          oder für Institutionen
          <br>
          Name: "-" Benutzername: digitalwolff-berlin E-Mail-Adresse: info@digitalwolff.de
        </p>
      <p>
        Bitte beachten Sie, dass Groß- und Kleinschreibung relevant ist und alle Felder Pflichtfelder sind.
      </p>
    </template>
    <v-spacer></v-spacer>
    <UserAdmin :specificOrganization="organization.url"/>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import UserAdmin from "@/views/admin/UserAdmin"
import { MANAGE_USERS } from '@/lib/permission-tools'
import { FETCH_ORGANIZATIONS, FETCH_USER_LIST } from '@/store/action-types'

export default {
  name: "OrgUsers",
  components: {
    UserAdmin
  },
  data () {
    return {
      MANAGE_USERS,
    }
  },
  computed: {
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['hasPermission']),
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('user/' + FETCH_USER_LIST),
      ])
    })
  }
}
</script>

<style scoped>

</style>
