import { FETCH_CONTACTS } from "@/store/action-types"
import { SET_CONTACTS } from "@/store/mutation-types"
import { cachedActionWrapper } from "@/modules/common/store/tools"
import { CACHE_CONTACTS } from "@/store/cache-types"

export default {
  namespaced: true,
  state: function () {
    return {
      contacts: [],
    }
  },
  getters: {
    contacts: (state) => {
      return state.contacts
    },
  },
  mutations: {
    [SET_CONTACTS]: (state, { data }) => {
      state.contacts = data
    }
  },
  actions: {
    [FETCH_CONTACTS]: async (context) => {
      return cachedActionWrapper(context, CACHE_CONTACTS, async () => {
        const response = await context.rootGetters.restApi.get('contact/')
        await context.commit(SET_CONTACTS, { data: response.data })
      }, async () => {
        await context.commit(SET_CONTACTS, { data: [] })
      })
    }
  },
}
