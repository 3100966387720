<template>
  <div>
    <v-card
      text
      v-if="!taskId"
    >
      <v-card-text v-if="isPaymentsOnly && payments[0]">
        Möchten Sie für die Zahlung am {{ payments[0].booking_date | isoToHuman('LL') }} von {{ personName }} über {{ paymentSum | amount }} € eine Einzel-Zuwendungsbestätigung erstellen?
        <br>
        <br>
        Dieser Vorgang kann nicht rückgängig gemacht werden (mit Ausnahme von Stornobuchungen).
        <!-- FIXME multiple payments -->
      </v-card-text>
      <v-card-text v-else-if="selector.organizations">
        Möchten Sie für Gemeinde {{ organizationName }} Zuwendungsbestätigungen für den Zeitraum von
        {{ selector.date_from | isoToHuman('LLL') }} (einschliesslich) bis {{
          selector.date_before | isoToHuman('LLL')
        }} (ausschliesslich) erstellen?
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          :loading="!!loading"
          @click="runCreation()">
          Ja, erstellen
        </v-btn>
        <v-btn
          @click="$emit('done')"
          :disabled="!!loading"
          color="primary"
          text
        >Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
    <task-progress :task-id="taskId" v-else @finished="processResults">
      <template #title>Stapelverarbeitung</template>
      <template #state="{ progress }">
        <template v-if="progress?.state === 'started'">
          Generiere PDF-Dateien <span v-if="progress?.total_count && progress?.done_count">({{progress.done_count}} von {{progress.total_count}})</span>
        </template>
        <template v-else-if="progress?.state === 'finished'">Lade ZIP-Datei</template>
      </template>
    </task-progress>
  </div>
</template>
<script>
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { downloadBlob } from '@/lib/file-tools'
import { mapState } from 'vuex'
import TaskProgress from '@/modules/kirchgeld/components/TaskProgress.vue'

export default {
  name: 'CreateDonationReceipt',
  components: { TaskProgress },
  props: {
    selector: {
      type: Object,
      required: true
    },
    zipName: {
      type: String,
      required: false,
      default: "Zuwendungsbestätigungen.zip",
    },
    personName: {
      type: String,
      required: false,
      default: "",
    }
  },
  emits: ['done'],
  data () {
    return {
      payments: [],
      taskId: null
    }
  },
  computed: {
    ...mapState('kirchgeld', ['organizations']),
    ...mapState(['loading']),
    paymentSum () {
      return this.payments.map(item => item.amount).reduce((a, b) => (a + b), 0)
    },
    isPaymentsOnly () {
      return !!this.selector?.payments?.length
    },
    organizationName () {
      return this.organizations.filter(item => this.selector?.organizations.includes(item?.url)).map(item => item.name).join(", ")
    }
  },
  methods: {
    async runCreation () {
      const payload = this.isPaymentsOnly ? { payments: this.payments.map(item => item.url) } : this.selector
      this.taskId = await loadingStateWrapper(this.$store, async () => {
        const response = await this.$store.getters['kirchgeld/api'].post(`${this.$store.state.kirchgeld.tenant.url}donation_receipt/generate/?background=1`, payload)
        // eslint-disable-next-line camelcase
        return response?.data?.task_id
      })
    },
    async processResults (results) {
      const urls = results.filter(item => !!item?.url).map(item => item.url)
      if (urls.length > 1 && this.zipName) {
        const response = await this.$store.getters['kirchgeld/api'].post(`${this.$store.state.kirchgeld.tenant.url}donation_receipt/multi_download/`, { donation_receipts: urls }, { responseType: 'blob' })
        downloadBlob(response.data, this.zipName, 'application/zip')
      } else {
        for (const result of results) {
          if (result?.url) {
            const response = await loadingStateWrapper(this.$store, async () => {
              return await this.$store.getters['kirchgeld/api'].get(`${result.url}download/`, { responseType: 'blob' })
            })
            downloadBlob(response.data, result.file_name, result.mime_type)
          }
        }
      }

      this.$emit('done', results)
    }
  },
  watch: {
    selector: {
      immediate: true,
      async handler () {
        this.payments = await Promise.all((this.selector?.payments ?? []).map(
          item => loadingStateWrapper(this.$store, async () => {
            const response = await this.$store.getters['kirchgeld/api'].get(item)
            return response?.data ?? {}
          })
        ))
      }
    }
  }
}
</script>
