import { DeviceKB, DeviceSD, DeviceSP, DeviceWA, DeviceWS } from '@/lib/icons'

export const DEVICE_TYPES = {
  peter: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 50,
      absMaxValue: 50,
    },
    connection: {
      wifi: true,
    },
    battery: true,
    display: {
      kind: "KA",
      maxCollections: 1,
    },
    deviceClass: "KB",
  },
  andrew: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 50,
      absMaxValue: 50,
    },
    connection: {
      wifi: true,
    },
    battery: true,
    display: {
      brightness: true,
      kind: "KB",
      maxCollections: 1,
    },
    deviceClass: "KB",
  },
  matt: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 50,
      absMaxValue: 50,
    },
    connection: {
      wifi: "hidden",
      wwan: true,
    },
    ledRing: true,
    battery: true,
    display: {
      brightness: true,
      kind: "KB",
      maxCollections: 1,
    },
    deviceClass: "SD",
  },
  james: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 999,
      absMaxValue: 5000,
    },
    connection: {
      ethernet: true,
      wwan: "optional",
      wifi: "hidden",
    },
    ledRing: true,
    display: {
      brightness: true,
      kind: "SP",
      maxCollections: 3,
    },
    deviceClass: "SP",
  },
  thomas: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 999,
      absMaxValue: 5000,
    },
    connection: {
      ethernet: false,
      wwan: true,
      wifi: "hidden",
    },
    ledRing: false,
    display: {
      brightness: false,
      kind: "SO",
      maxCollections: 3,
    },
    deviceClass: "SP",
  },
  philip: {
    hardware: true,
    input: {
      rotary: true,
      maxValue: 50,
      absMaxValue: 50,
    },
    connection: {
      ethernet: true,
      wifi: "hidden",
    },
    ledRing: true,
    display: {
      brightness: true,
      kind: "SP",
      maxCollections: 3,
    },
    deviceClass: "KS",
  },
  john: {
    webApp: true,
    input: {
      initial: 5,
      maxValue: 999,
      softMaxValue: 300,
      absMaxValue: 999,
    },
    display: {
      kind: "kollekte.app",
    },
    deviceClass: "WA",
  },
  bart: {
    webApp: true,
    input: {
      initial: 5,
      maxValue: 999,
      softMaxValue: 300,
      absMaxValue: 999,
    },
    display: {
      kind: "spende.app",
    },
    deviceClass: "WS",
  },
  generic_ecommerce: {
    webApp: true,
    deviceClass: "GE",
  },
  cash: {
    deviceClass: "GC",
  },
}

export const DEVICE_CLASSES = {
  KB: "Digitaler Klingelbeutel",
  SD: "Digitale Spendendose",
  SP: "Digitale Spendensäule",
  KS: "Mini-Spendensäule",
  WA: "kollekte.app",
  WS: "spende.app",
  GE: "allg. Zahlungsmodul",
  GC: "Bargeld",
}

export const DEVICE_CLASSES_DATIV = {
  KB: "Digitalem Klingelbeutel",
  SD: "Digitaler Spendendose",
  SP: "Digitaler Spendensäule",
  KS: "Mini-Spendensäule",
  WA: "kollekte.app",
  WS: "spende.app",
  GE: "allg. Zahlungsmodul",
  GC: "Bargeld",
  WEB: "kollekte.app/spende.app"
}

export function deviceToClassName (device) {
  // eslint-disable-next-line camelcase
  return DEVICE_CLASSES[device?.device_class ?? null] ?? "Unbekannte Geräteart"
}

export function deviceTypeToClassName (deviceType) {
  return DEVICE_CLASSES[DEVICE_TYPES[deviceType]?.deviceClass ?? null] ?? "Unbekannte Geräteart"
}

export const DEVICE_ICONS = {
  KB: DeviceKB,
  WS: DeviceWS,
  WA: DeviceWA,
  SD: DeviceSD,
  SP: DeviceSP,
  KS: DeviceSP, // FIXME
}

export function entryForDeviceType (deviceType) {
  // Device-DB entry, also merge some default values
  const entry = DEVICE_TYPES[deviceType ?? 'unknown'] ?? {}
  return {
    hardware: false,
    ledRing: false,
    ...entry,
    display: {
      maxCollections: 1,
      ...(entry?.display ?? {})
    },
    connection: entry?.connection ? { ...(entry?.connection ?? {}) } : null,
    // true: available, required; false: not available; "optional": available, optional; "hidden": available, don't display unless connected
  }
}

export function settingsForDeviceType (deviceType) {
  if (!Object.prototype.hasOwnProperty.call(DEVICE_TYPES, deviceType)) {
    return []
  }

  const ddbEntry = DEVICE_TYPES[deviceType]
  const retval = []

  if (ddbEntry?.hardware || ddbEntry?.webApp) {
    retval.push({ key: '$DC/input/initial', type: "initialInputValue", maxValue: ddbEntry?.input?.maxValue ?? 50, minValue: 1, defaultValue: ddbEntry?.input?.initial ?? 10 })
  }

  if (ddbEntry?.hardware || ddbEntry?.webApp) {
    retval.push({ key: '$DC/input/max', type: "maxInputValue", maxValue: ddbEntry?.input?.absMaxValue ?? 50, minValue: 1, defaultValue: ddbEntry?.input?.maxValue ?? 50, readOnly: true })
  }

  if ((ddbEntry?.display?.maxCollections ?? 1) > 1) {
    retval.push({ key: '$DC/display/collection_count', type: "collectionCount", defaultValue: 1, minValue: 1, maxValue: ddbEntry?.display?.maxCollections })
  }

  if (ddbEntry?.ledRing) {
    retval.push({ key: '$DC/ledring/data/color', type: "ledringColor", defaultValue: "#331493" })
    retval.push({ key: '$DC/ledring/mode', type: "ledringMode", defaultValue: undefined })
  }

  if (ddbEntry?.hardware && ["SP", "KS"].includes(ddbEntry?.deviceClass)) {
    retval.push({ key: '$DC/confirmation/min_amount', type: "confirmationMinAmount", defaultValue: 300 })
    if (ddbEntry?.display?.kind === 'SP') {
      retval.push({ key: '$DC/confirmation/contact_email', type: "confirmationContactEmail", defaultValue: undefined })
      // FIXME Special default value
      // FIXME Logo
    }
  }

  if (ddbEntry.deviceClass === "WA") {
    retval.push(
      {
        key: '$DC/primary_color',
        type: 'liturgicalColor',
        defaultValue: "#723288"
      },
    )
  }

  if (ddbEntry.deviceClass === "WS") {
    retval.push(
      {
        key: '$DC/primary_color',
        type: 'freeColor',
        defaultValue: "#03867b"
      },
      {
        key: '$DC/privacy_url',
        type: 'privacyUrl',
        defaultValue: null
      },
      {
        key: '$DC/imprint_url',
        type: 'imprintUrl',
        defaultValue: null
      },
      {
        key: 'assets/$DC/background/image',
        type: 'backgroundImage',
        defaultValue: '0wg85erb0lS,Eqr+6+2Wuqub88QrZ9Ppy;DTYuDkh8w',
      },
      {
        key: 'assets/$DC/logo/image',
        type: 'webappLogo',
        defaultValue: null,
      },
      {
        key: 'assets/$DC/main/image',
        type: 'mainLogo',
        defaultValue: null,
      },
      {
        key: 'assets/$DC/corner/image',
        type: 'cornerLogo',
        defaultValue: null,
      },
      {
        key: '$DC/main_logo_is_text',
        type: 'logoIsText',
        defaultValue: false,
      },
      {
        key: '$DC/thank_you/text',
        type: 'templateTextThankYou',
        defaultValue: 'Sie spendeten %Spendenbetrag%%Währungssymbol% für "%Sammlungszweck%". Vielen Dank!',
      },
      {
        key: '$DC/thank_you_share/text',
        type: 'templateTextThankYouShare',
        defaultValue: 'Ich habe gerade %Spendenbetrag%%Währungssymbol% für "%Sammlungszweck%" an %Organisationsname% gespendet. Spende Du doch auch! %Direktlink%',
      },
      {
        key: '$DC/social_media',
        type: 'socialMedia',
        defaultValue: {
          email: null,
          whatsapp: null,
          facebook: null,
          instagram: null,
          twitterx: null,
          pinterest: null,
          snapchat: null,
          linkedin: null,
        },
      },
      // FIXME: Other images
    )
  }

  return retval
}
