<template>
  <div class="address">
    <v-text-field
      v-if="!hiddenFields.includes('name')"
      label="abweichender Organisationsname"
      v-model="organizationAddress.name"
    />
    <v-text-field
      label="Straße und Hausnummer"
      v-model="organizationAddress.address"
    />
    <v-text-field
      label="Postleitzahl"
      v-model="organizationAddress.postcode"
    ></v-text-field>
    <v-text-field
      label="Ort"
      v-model="organizationAddress.locality"
    ></v-text-field>
    <v-text-field
      label="Land"
      v-model="organizationAddress.region"
    />
    <v-select
      :items="addressTypeItems"
      :menu-props="{ bottom: true, offsetY: true }"
      chips
      deletable-chips
      label="Adresstyp"
      v-model="organizationAddress.type"
    >
    </v-select>
  </div>
</template>

<script>
import { mdiPencil } from "@mdi/js"
import { mapGetters } from "vuex"
import { WRITE_ORG_DATA } from '@/lib/permission-tools'
import { mapOrder } from "@/lib/data-order"
import moment from "moment"

export default {
  name: "OrgDetailsAddressCommon",
  props: {
    organization: {
      type: Object
    },
    orgAddr: {
      type: Object
    },
    hiddenFields: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      isEditingAddr: false,
      mdiPencil,
      addressTypes: {
        primary: 'Hauptadresse',
        billing: 'Rechnungsadresse',
        physical: 'Standort',
        other: 'Sonstige'
      },
      addressItems: [{ text: 'Hauptadresse', value: 'primary' },
        { text: 'Rechnungsadresse', value: 'billing' },
        { text: 'Standort', value: 'physical' },
        { text: 'Sonstige', value: 'other' }],
      existingAddress: null,
      addressTypeGroup: {},
      updatedAddressList: [],
      addressesOrder: ['primary', 'physical', 'billing', 'other'],
      expirationData: {},
      organizationAddress: {}
    }
  },
  computed: {
    ...mapGetters('user', ['isSuperuser', 'hasPermission']),
    addresses () {
      if (!this.organization || !this.organization.addresses) {
        return []
      }
      const updatedAddresses = this.organization.addresses.filter(addr => addr.valid_from && !addr.valid_notafter)
      return mapOrder(updatedAddresses, this.addressesOrder, 'type')
    },
    addressTypeItems () {
      if (!this.organization) {
        return mapOrder(this.addressItems, this.addressesOrder, 'value')
      }
      const checkExpiredType = this.expirationCheck
      const expiredTypes = Object.entries(checkExpiredType)
        .filter(([key, value]) => value.isExpired)
        .map(([key, value]) => key)
      const filteredItems = this.addressItems.filter(item => expiredTypes.includes(item.value))
      const existingAddrTypes = [...new Set(this.organization.addresses.map(org => org.type))]
      const items = [{ text: 'Sonstige', value: 'other' }]
      items.push(...filteredItems)
      for (const [value, text] of Object.entries(this.addressTypes)) {
        if (!existingAddrTypes.includes(value) && !items.find(item => item.value === value)) {
          items.push({
            text,
            value
          })
        }
      }
      return mapOrder(items, this.addressesOrder, 'value')
    },
    hasEditingPermissions () {
      return this.hasPermission(this.organization.url, WRITE_ORG_DATA)
    },
    expirationCheck: function () {
      const currentTime = moment().format()
      const types = new Set(this.organization.addresses.map(addr => addr.type))
      types.forEach(type => {
        const filteredData = this.organization.addresses.filter(obj => obj.type === type)
        const total = filteredData.length
        const isExpired = filteredData.every(obj => moment(obj.valid_notafter).isBefore(currentTime))
        this.$set(this.expirationData, type, { total, isExpired })
      })
      return this.expirationData
    },
  },
  mounted () {
    this.organizationAddress = this.orgAddr
  },
  methods: {
    editOrgAddress (address) {
      this.isEditingAddr = true
      this.organizationAddress = { ...address }
      this.existingAddress = { ...address }
    }
  },
  watch: {
    organizationAddress: {
      deep: true,
      handler (newValue) {
        this.$emit('dataChanged', newValue)
      }
    },
    orgAddr: {
      deep: true,
      handler (newValue) {
        this.organizationAddress = newValue
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.addresscard
  .title
    text-overflow: unset
    white-space: unset
  .content
    text-overflow: unset
    white-space: unset
.address-edit
  margin-top: 20px
</style>
