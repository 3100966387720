import { availableOperators, filterLookups, notOperator } from './config/filter-lookups'
import { personProperties } from './config/person.properties'

export function describeLookup (flt) {
  if (flt?.ref) {
    return `Zielgruppe ${flt.ref}`
  }
  const propName = personProperties.find(item => item?.value === flt?.field)?.text ?? (flt?.field ?? '???')

  return new Array(
    Object.entries(flt).filter(([k, v]) => (k !== 'field')).map(([k, v]) => `${propName} ${filterLookups.find(item => item?.value === k)?.text ?? k} ${v}`)
  ).join(" & ")
}

export function describeFilter (fltl) {
  if (!(fltl || [])) {
    return ''
  }

  if (!Array.isArray(fltl)) {
    return describeLookup(fltl)
  }

  const parts = []
  const op = availableOperators.find(item => item.value === fltl[0].op)
  for (const inner of fltl.slice(1)) {
    const part = describeFilter(inner)
    if (Array.isArray(inner) && inner.length > 2 && inner[0]?.op !== op?.value) {
      parts.push(`(${part})`)
    } else {
      parts.push(part)
    }
  }

  if (op) {
    return parts.join(` ${op.text} `)
  } else if (notOperator.value === fltl[0].op) {
    return ` ${notOperator.text} ${parts.join(' ')}`
  } else {
    throw new Error(`Invalid op: ${fltl[0].op}`)
  }
}
