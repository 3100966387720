<template>
  <v-card
    class="pa-2"
    outlined
  >
    <h6
      v-if="title"
      class="pt-0"
    >{{ title }}</h6>
    <address>
      {{ personData.strassenname }} {{ personData.hausnr }} {{ personData.hausnr_zusatz }}
      <template v-if="personData.hausnr_teilnr">{{ personData.hausnr_teilnr }}</template>
      <template v-if="personData.anschriften_zusatz"><br>{{ personData.anschriften_zusatz }}</template>
      <template v-if="personData.stockwerks_oder_wohnungsnr"><br>Stock/Wohnung {{ personData.stockwerks_oder_wohnungsnr }}</template>
      <br>{{personData.plz}} {{personData.kommune}} <template v-if="personData.ortsteil">OT {{ personData.ortsteil }}</template>
    </address>
  </v-card>
</template>

<script>

export default {
  name: 'PersonAddressView',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    propertyGroup: {
      type: String,
      required: true
    },
    person: {
      type: Object,
      required: true
    },
    personData: {
      type: Object,
      required: true
    },
  },
}
</script>

<style scoped>

</style>
