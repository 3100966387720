<template>
  <v-expansion-panel-content>
    <div class="d-flex flex-row mb-12" v-if="qrCode">
      <div  class="mt-5">
        <span v-show="false" v-html="qrCode"></span>
        <img v-if="qrCode" :src="'data:image/svg+xml,' + qrCode">
      </div>
      <div class="pl-4">
        <h4 class="">Kollekten auszählen</h4>
        <p>
          Unter diesem QR-Code können Sie die Kollekte auf allen
          Spendengeräten auszählen (beendet die Sammlung) - drucken Sie ihn
          sich aus, dann können Sie ihn im Gottesdienst bequem mit dem
          Smartphone scannen, wenn der Zeitpunkt gekommen ist.
        </p>
        <p v-if="isFinalizeLinkEnabled">
          <router-link :to="{ name: 'collection.finalize', params: { token: 'TBD' }}">
            Kollekte jetzt abschließen und auszählen
          </router-link>
        </p>
      </div>
    </div>
  </v-expansion-panel-content>
</template>

<script>
import { FEATURE_FLAG_179_ENABLE_FINALIZE_COLLECTION_LINK } from "@/feature-flags"
export default {
  name: "CountCollectionInLocationPanelContent.vue",
  data () {
    return {
      qrCode: '',
      isFinalizeLinkEnabled: FEATURE_FLAG_179_ENABLE_FINALIZE_COLLECTION_LINK
    }
  },
  props: {
    location: {
      type: Object,
      required: true
    },
  },
  mounted () {
    this.downloadQrCode()
  },
  methods: {
    async downloadQrCode () {
      const response = await this.$store.getters.restApi.get(this.location.url + "qr_code/")
      this.qrCode = response.data
    }
  }
}
</script>
