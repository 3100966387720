<template>
  <v-container id="eval-donationslist">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <h2>Zuwendungsbestätigungen</h2>
        <p v-if="!donationReceiptRequestList.length">Um diese Ansicht freizuschalten, müssen wir unsere Zahldaten mit Ihrem Kontoauszug abgleichen (z. B. falls ein Konto nicht gedeckt war).
           Um diese Funktion einzurichten, wenden Sie sich bitte an <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a></p>
        <p>Eine Liste der Personen, die beim Spenden um eine Zuwendungsbestätigung / Spendenbescheinigung gebeten haben, sowie die ausgestellten Bescheinigungen. Die vollständigen Adressdaten finden Sie in der herunterladbaren Exceltabelle. Das Herunterladen wird im Hintergrund protokolliert. Sollte bei einzelnen Einträgen der Downloadlink fehlen, ist die Zahlung vom jeweiligen Zahlungsdienstleister noch nicht freigegeben.</p>
        <!-- <p>Hier finden Sie Ihre Spendeneinnahmen ab 200 € in 2020* und 300 € in 2021*. Kollekten bleiben anonym und bekommen keine separate Spendenbescheinigung.</p> -->
        <CertificatesDataTable
          :headers="headers"
          :items="donationReceiptRequestList"
          @exportDataMethod="exportList"
          @downloadPdf="download"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { downloadBlob } from '@/lib/file-tools'
import CertificatesDataTable from "@/modules/common/CertificatesDataTable"

export default {
  name: "eval-donationslist",
  components: { CertificatesDataTable },
  data () {
    return {
      headers: [
        { text: "Datum", value: "date" },
        { text: "Name", value: "name" },
        { text: "Straße", value: "address" },
        { text: "Spendenzweck", value: "collection_name" },
        { text: "Bruttospende", value: "amount", align: "right" },
        { text: "Zuwendungsbestätigung", value: "receipt" },
        { text: "Status", value: "status" },
        { value: 'data-table-expand' },
      ],
      donationReceiptRequests: [],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters("organization", ["currentOrganization"]),
    donationReceiptRequestList () {
      return this.donationReceiptRequests.map(item => ({
        ...item,
        name: item.person_data?.name ?? null,
        address: item.person_data?.address ?? null,
        collection_name: item.purpose?.name ?? null,
        amount: item.payments.map(payment => parseFloat(payment.amount)).reduce((acc, cur) => (acc + cur), 0),
        id: item.receipt ? item.receipt.url : (item.archive.length > 0 ? item.archive[0].url : (item.payments[0].id)),
      }))
    },
    donationReceiptRequestsByUrl () {
      return this.donationReceiptRequests.map(
        item => [...(item.receipt ? [item.receipt] : []), ...item.archive]
      ).flat().reduce((acc, cur) => ({ ...acc, [cur.url]: cur }), {})
    },
  },
  methods: {
    async download (url) {
      const response = await this.$store.getters.restApi.get(`${url}/download/`, { responseType: 'blob' })
      downloadBlob(response.data, `${this.donationReceiptRequestsByUrl[url].id}.pdf`, response.headers['content-type'])
    },
    async exportList () {
      const response = await this.$store.getters.restApi.get(`pdata/donation_receipt/xlsx/`, {
        responseType: 'blob',
        params: {
          organization: this.currentOrganization.id
        }
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.currentOrganization.name}_Angefragte Zuwendungsbestätigungen.xlsx`, response.headers['content-type'])
    },
  },
  watch: {
    currentOrganization: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.url) {
          await loadingStateWrapper(this.$store, async () => {
            const response = await this.$store.getters.restApi.get(`${this.currentOrganization.url}donation_receipts/`)
            if (response?.data) {
              this.donationReceiptRequests = response.data.filter(item => item.organization === this.currentOrganization.url)
            }
          })
        }
      }
    },
  },
}
</script>
<style lang="stylus" scoped>

.v-data-table ::v-deep th
  font-size: 0.9em !important/*
.v-data-table ::v-deep td
  font-size: 0.9em !important*/
.archived
  color: #BDBDBD
</style>
