import { FETCH_BILLING_PLANS } from "@/store/action-types"
import { cachedActionWrapper } from "@/modules/common/store/tools"
import { SET_BILLING_PLANS } from "@/store/mutation-types"
import { CACHE_BILLING_PLANS } from "@/store/cache-types"

export default {
  namespaced: true,
  state: () => {
    return {
      billingPlans: []
    }
  },
  getters: {
    billingPlans: state => state.billingPlans,
    billingPlanByUrl: state => {
      const ret = {}

      state.billingPlans.forEach(plan => {
        ret[plan.url] = plan
      })

      return ret
    }
  },
  actions: {
    [FETCH_BILLING_PLANS]: async (context) => {
      return cachedActionWrapper(context, CACHE_BILLING_PLANS, async () => {
        const response = await context.rootGetters.restApi.get('/billing/manage/plan/')
        context.commit(SET_BILLING_PLANS, { data: response.data })
      }, async (context) => {
        return false
      })
    },
  },
  mutations: {
    [SET_BILLING_PLANS]: (state, { data }) => {
      state.billingPlans = data
    }
  }
}
