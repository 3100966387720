<template>
  <v-card>
    <v-card-title>
      <slot name="title">Führe Aktion aus</slot>
    </v-card-title>
    <v-card-text>
      Status:
      <span v-if="!progress?.state">Warte auf freie Serverkapazität</span>
      <slot name="state" v-else :progress="progress">Läuft</slot>
    </v-card-text>
    <v-card-text v-if="progress.total_count && progress.done_count !== undefined">
      <v-progress-linear
        height="25"
        :active="!!progressMode"
        :value="progressMode === 'remaining' ? ((progress.done_count * 100) / progress.total_count) : undefined"
        :indeterminate="progressMode === 'counting'"
      >
        <template v-if="timeRemaining">Verbleibend: {{ timeRemaining }}</template>
        <template v-else-if="progressMode === 'counting'">Abgeschlossen: {{ progress.done_count }} Elemente</template>
      </v-progress-linear>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from 'moment'

export default {
  name: "TaskProgress",
  props: {
    taskId: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
    },
  },
  emits: ["finished"],
  data () {
    return {
      progress: {},
      now: moment(),
      refreshTask: null,
      lastFetch: null,
    }
  },
  mounted () {
    this.refreshTask = window.setInterval(
      this.refresh, 1000
    )
  },
  beforeDestroy () {
    if (this.refreshTask) {
      window.clearInterval(this.refreshTask)
      this.refreshTask = null
    }
  },
  methods: {
    async refresh () {
      this.now = moment()
      if (this.taskId) {
        if (!this.lastFetch || this.now.diff(this.lastFetch, "seconds", true) > 2) {
          window.setTimeout(this.fetch, 0)
        }
      }
    },
    async fetch () {
      this.lastFetch = moment()
      const response = await this.$store.getters['kirchgeld/api'].get(`${this.$store.state.kirchgeld.tenant.url}../../progress/${this.taskId}/`)
      this.progress = response?.data?.state ?? {}
    }
  },
  computed: {
    expectedFinish () {
      // eslint-disable-next-line camelcase
      if (!this.lastFetch || !this.progress?.start_time || !this.progress?.update_time || !this.progress?.done_count || !this.progress?.total_count) {
        return null
      }
      const runTimeSoFar = moment(this.progress.update_time).diff(this.progress.start_time, "seconds", true)
      const timePerItem = runTimeSoFar / parseFloat(this.progress.done_count)
      const remainingTimeAtFetch = (parseFloat(this.progress.total_count) - parseFloat(this.progress.done_count)) * timePerItem
      return this.lastFetch.clone().add(remainingTimeAtFetch, 'seconds')
    },
    timeRemaining () {
      if (!this.expectedFinish) {
        return null
      }
      return this.expectedFinish.from(this.now, true)
    },
    progressMode () {
      if (this.timeRemaining) {
        return "remaining"
      }
      // eslint-disable-next-line camelcase
      if (this.progress?.done_count) {
        return "counting"
      }
      return null
    }
  },
  watch: {
    "progress.state": {
      handler (newVal) {
        if (newVal === 'finished') {
          this.$emit('finished', this.progress.result)
        }
      }
    }
  }
}
</script>
